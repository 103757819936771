import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { objectsConstants } from "../../../src/constants/objects.constants";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { withRouter } from "react-router-dom";
import {mensagemService} from '../../services/mensagem.service'


class LinhaAcaoExcluirMensagem extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    success = () => {
        this.props.success({ message: "Excluído com sucesso." });
        this.props.loading(false);
        if (this.props.initData) {
            this.props.initData();
        }
    }
    errorExcluir = () => {
        this.props.success("Não foi possível excluir a mensagem.");
        this.props.loading(false);
    }


    excluirMensagem = () => {
        this.props.loading(true);
        mensagemService.doDelete(this.props.rowsCheckedValue.map((rowValue) => {
            return rowValue.id;
        })).then(() => {
            this.success();
        }).catch(() => {
            this.errorExcluir();
        });
    }

    render() {
        return <React.Fragment>
            <button type="button" className="btn btn-secondary" onClick={() => {
                ConfirmAcationService.confirmMsg(
                    this.excluirMensagem,
                    "Excluir mensagem",
                    "Você confirma que deseja excluir as mensagens selecionadas?",
                    null,
                    "Cancelar",
                    "Excluir",
                    objectsConstants.TYPE_WARNING
                );
            }}>
                <span className="icon-btn-fechar"></span>
                Excluir mensagem
            </button>
        </React.Fragment>;
    }
}

const mapDispatch = ({
    alert: { success, error, clear },
    load: { loading }
}) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear()
});

export default connect(
    null,
    mapDispatch,
    null,
    { withRef: true }
)(withRouter(LinhaAcaoExcluirMensagem));
