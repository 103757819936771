//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const pendenciaService = {
  resolverPendencia,
  doGet,
  doSave,
  reenviarNotificacao
};


function doGet(id) {
  return defaultService.doGet(`${urlsConstants.PENDENCIA}${id}`);
}

function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.PENDENCIA, entity);
}

function resolverPendencia(pendenciaResolver) {
  return defaultService.doPost(`${urlsConstants.PENDENCIA}resolverPendencia`, pendenciaResolver);
}
function reenviarNotificacao(id) {
  return defaultService.doGet(`${urlsConstants.PENDENCIA}reenviarNotificacao/${id}`);
}



