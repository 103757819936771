//@flow
import * as React from "react";

import { defaultService } from "../../services/defaultService";
import { urlsConstants } from "../../constants/url.constant";
import SelectInput from "../../components/Inputs/SelectInput";
import _ from "lodash";

type Props = {
  label: string,
  name: string,
  value: any,
  onChange: any,
  returnFullObject: boolean,
  messageValidate?: any,
  disabled?: boolean,
  size?: number,
  required: boolean
};

type State = {
  options: Array<any>
};
export default class FilterSearchSelectInput extends React.PureComponent<
  Props,
  State
  > {
  constructor(props: Props) {
    super(props);

    this.state = {
      options: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.parent && !_.isEqual(nextProps.parent, this.props.parent)) ||
      (nextProps.url && nextProps.url != this.props.url)
    ) {
      this.fetchData(nextProps.parent, nextProps.url);
    }
  }
  componentDidMount() {
    this.fetchData(this.props.parent, this.props.url);
  }
  fetchData(parent, url) {
    let fetch;
    if (url) {
      let baseUrl = `${urlsConstants.URL_DOMAIN}${url}`;
      fetch = defaultService.findByFiltro(baseUrl, {}, parent, {});

      try {
        fetch.then(
          data => {
            let options = [];
            options = options.concat(data.data);
            this.setState({ options: options });
          },
          error => {
            console.error("Erro ao montar o combo", error);
          }
        );
      } catch (error) {
        console.error("Erro ao fazer fetch", error);
        let options = [];
        this.setState({ options: options });
      }
    }
  }

  render() {
    return (
      <SelectInput
        id={this.props.id}
        label={this.props.label}
        valueKey={this.props.valueKey ? this.props.valueKey : "id"}
        labelKey={this.props.labelKey ? this.props.labelKey : "nome"}
        name={this.props.name}
        value={this.props.value}
        options={this.state.options}
        onChange={this.props.onChange}
        multi={false}
        returnFullObject={this.props.returnFullObject}
        messageValidate={this.props.messageValidate}
        disabled={this.props.disabled}
        size={this.props.size}
        style={this.props.style}
        required={this.props.required}
        erroMensagem={this.props.erroMensagem}
        className={this.props.className}
        placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
      />
    );
  }
}
