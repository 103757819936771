import { Formik } from "formik";
import _ from "lodash";
import * as React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import connect from "react-redux/es/connect/connect";
import * as Yup from "yup";
import { objectsConstants } from "../../../constants/objects.constants";
import { translate } from "../../../helpers/message.helper";
import { defaultService } from "../../../services/defaultService";
import { pacoteExameService } from "../../../services/pacoteExame.service";
import { userService } from "../../../services/user.service";
import TopoTitleComponente from "../../home/TopoTitleComponente";
import PacoteExameForm from "./PacoteExameForm";
import PacoteExameTopoCrud from "./PacoteExameTopoCrud";

const NewPacoteExame = Yup.object().shape({
    nomePacote: Yup.string()
        .max(250, "Nome muito grande!")
        .required("Obrigatório"),
    prazoRepeticao: Yup.number()
        .min(1, "O valor minimo para o prazo é 1")
        .max(9999, "Valor muito alto!")
        .nullable(),
    exames: Yup.array()
        .min(1, "É necessario informar pelo menos um exame para o pacote")
        .required("Obrigatório"),
    especialidades: Yup.array()
        .min(1, "É necessario informar pelo menos uma especialidade para o pacote")
        .required("Obrigatório"),

});
class PacoteExameEdit extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {
            entity: {}
        };
    }

    goToView = values => {
        this.props.history.push({
            pathname: "/pacoteexame/view/" + (values.id),
            state: { entity: values }
        });
    };

    handleCancel = () => {
        this.props.history.goBack();
    };

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.loading(true);
            pacoteExameService.doGet(this.props.match.params.id).then(
                response => {
                    let user = response.data.data;
                    this.setState({ entity: user }, () => {
                        this.props.loading(false);
                    });
                },
                error => {
                    console.error(error);
                    this.props.loading(false);
                }
            );
        }
    }

    componentDidUpdate() {
        if (this.props.match.params.id && !this.state.entity.id && this.state.entity.id !== this.props.match.params.id) {
            this.props.loading(true);
            pacoteExameService.doGet(this.props.match.params.id).then(
                response => {
                    let user = response.data.data;
                    this.setState({ entity: user }, () => {
                        this.props.loading(false);
                    });
                },
                error => {
                    console.error(error);
                    this.props.loading(false);
                }
            );
        }
    }

    handleChange = (name, value) => {
        const entity = _.cloneDeep(this.formRef.state.values);
        if (userService.isConsultorio(entity)) {
            _.set(entity.secretaria, name, value);
        } else {
            _.set(entity, name, value);
        }

        this.setState({ entity });
    };

    handleSubmit = e => {
        this.formRef.validateForm(this.formRef.values).then(erros => {
            console.error(erros);
            if (_.isEmpty(erros)) {
                this.formRef.handleSubmit();
            }
        });
    };

    getServiceDoSave = () => {
        let entity;
        if (this.formRef) {
            entity = _.cloneDeep(this.formRef.state.values);
        } else {
            entity = this.props.location && this.props.location.state.entity;
        }
        return pacoteExameService;
    };

    render() {
        let _this = this;
        const { entity } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid container-com-topo">
                    <div
                        className={`action-line ${this.state.activeClass} fixed-container-topo`}
                    >
                        <TopoTitleComponente
                            mainTitle={`${entity.id ? "Editar " : "Novo "}  pacote de exame`}
                            canBack={true}
                            backUrl={!entity.id ? null : "/pacoteexame"}
                        />
                        <PacoteExameTopoCrud
                            values={entity}
                            onChange={this.handleChange}
                            handleSubmit={this.handleSubmit}
                            handleCancel={this.handleCancel}
                        ></PacoteExameTopoCrud>
                    </div>
                    <div className={`contentComTopoFix pt-4`}>
                        <Formik
                            validationSchema={NewPacoteExame}
                            validateOnBlur={false}
                            validateOnChange={false}
                            enableReinitialize={true}
                            initialValues={_this.state.entity}
                            onSubmit={(values, actions) => {
                                this.props.loading(true);
                                let serviceSave = this.getServiceDoSave(values);
                                serviceSave.findByNome(values.nomePacote).then(res => {
                                    if (res.data.data.length === 0 || (res.data.data[0] && res.data.data[0].id === values.id)) {
                                        serviceSave.doSave(values).then(
                                            response => {
                                                this.props.success({
                                                    message: `Pacote ${values.nomePacote} de exame, foi ${!values.id ? "criado" : "alterado"
                                                        } com sucesso!`
                                                });
                                                this.props.loading(false);
                                                let id = values.id
                                                    ? values.id
                                                    : defaultService.getIdFromUrl(response.headers.location);

                                                values.id = id;
                                                _this.goToView(values)
                                            },
                                            erros => {
                                                console.error(erros.response);
                                                this.props.error({
                                                    message:
                                                        "Não foi possível criar o pacote, existem erros no formulário!"
                                                });
                                                try {
                                                    let response = erros.response.data;
                                                    if (response && response.messages) {
                                                        for (var i = 0; i < response.messages.length; i++) {

                                                            let erroItem = response.messages[i];
                                                            if (erroItem.target === objectsConstants.GLOBAL) {
                                                                this.props.error({
                                                                    message:
                                                                        translate(erroItem.message.code)
                                                                });
                                                            } else {
                                                                actions.setFieldError(
                                                                    erroItem.fieldName,
                                                                    translate(erroItem.message.code)
                                                                );
                                                            }
                                                            actions.setFieldError(
                                                                erroItem.fieldName,
                                                                translate(erroItem.message.code)
                                                            );
                                                            if (erroItem.target === "GLOBAL") {
                                                                this.props.error({
                                                                    message:
                                                                        translate(erroItem.message.code)
                                                                });
                                                            }

                                                        }

                                                    }
                                                } catch (error) {
                                                    console.error(error);
                                                }

                                                this.props.loading(false);
                                                actions.setSubmitting(false);
                                            }
                                        );
                                    } else {
                                        this.props.loading(false);
                                        this.props.error({
                                            message:
                                                "Não foi possível criar o pacote, já existe um pacote com o nome "+values.nomePacote+" cadastrado!"
                                        });
                                    }
                                });

                            }}
                            ref={form => {
                                this.formRef = form;
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                validationSchema,
                                validateForm,
                                setValues
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <React.Fragment>
                                        <PacoteExameForm
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                            handleChange={handleChange}
                                            touched={touched}
                                            validateForm
                                            setValues
                                        ></PacoteExameForm>
                                    </React.Fragment>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatch = ({
    alert: { success, error, clear },
    load: { loading },
    authentication: { doRefresh }


}) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear(),
    doRefresh: () => doRefresh()
});

function mapStateToProps(state) {
    const { user } = state.authentication;
    return {
        user
    };
}
export default connect(mapStateToProps, mapDispatch, null, { withRef: true })(
    PacoteExameEdit
);
