//@flow
import { FormGroup, Label, Input, InputType } from "reactstrap";
import { ToolTipFeedBack } from "../Utils/ToolTipFeedBack";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { translate } from "../../helpers/message.helper";
import { ToolTipHelp } from "../Utils/ToolTipHelp";
//TODO ALTErar this.handleBorderColor(messageValidate)
type Props = {
  label: string,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange?: any,
  onBlur?: any,
  children?: any,
  required: boolean,
  disabled: boolean,
  style?: any,
  messageValidate?: string
};

export default class CheckBoxInput extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked ?props.checked:false
    };
  }
  _input: any;

  static defaultProps = {
    required: false,
    size: 12,
    disabled: false
  };

  getInput = () => {
    return this._input;
  };

  toggleLine = e => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.disabled) {
      return false;
    }
    this.setState({ checked: !this.state.checked }, () => {
      this.props.onChange(this.props.name,this.state.checked);
    });
  };

  componentWillReceiveProps(nexProps){
    if(this.props.checked!=nexProps.checked){
      this.setState({checked:nexProps.checked});
    }
  }

  render() {
    let messageValidate;

    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    
    return (
      <FormGroup className={`${this.props.className}`}>
        <div className="bottom" onClick={this.toggleLine}>
          <label className="checkbox control control--checkbox">
            <input
              type="checkbox"
              checked={this.state.checked}
              onChange={() => {}}
              ref={input => {
                this._input = input;
              }}
              id={this.props.id}
              name={this.props.name}
              disabled={this.props.disabled}
            />
            <span className={`pl-4 ${this.props.labelClass}`}>{this.props.label}</span>
            {messageValidate && (<><br /><span className={`pl-4 ${this.props.labelClass} ${messageValidate ? "cor-destaque-alerta" : ""
              }`}>{messageValidate}</span></>)}
            <div className="control__indicator"></div>
          </label>
        </div>
      </FormGroup>
    );
  }
}


