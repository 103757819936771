//@flow
import * as React from 'react';
import {Route, Switch} from 'react-router-dom';
import RelatoriosExport from "./RelatoriosExport";

export default class Relatorios extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/relatorios" component={RelatoriosExport} exact />

        </Switch>
      </React.Fragment>
    );
  }
}
