import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Avatar from "../../components/Avatar/Avatar";
import { objectsConstants } from "../../constants/objects.constants";
import { dateHelper } from "../../helpers/date.helper";
import { formatterHelper } from "../../helpers/formatter.helper";
import Icon_atendimentos from "../../img/icon_atendimentos.svg";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { userService } from "../../services/user.service";

class UsuarioTopoView extends PureComponent<Props, State> {
  isAtivo = values => {
    return values.status && values.status.name === "ATIVO" ? true : false;
  };

  getBtnActiveInative = () => {
    const { values } = this.props;
    if (this.props.disableEnabledUser) {
      let textAction = this.isAtivo(values) ? "Inativar" : "Ativar";
      return (
        <button
          type="button"
          className={`btn btn-${
            this.isAtivo(values) ? "secondary" : "primary"
          }`}
          onClick={() => {
            ConfirmAcationService.confirmMsg(
              this.props.disableEnabledUser,
              textAction + " Usuário",
              "Você confirma que deseja " +
                textAction +
                " o usuário: " +
                values.nome,
              null,
              "Cancelar",
              "Confirmar",
              objectsConstants.TYPE_WARNING
            );
          }}
        >
          <span
            className={`icon-btn-${this.isAtivo(values) ? "fechar" : "check"}`}
          ></span>
          {textAction} Usuário
        </button>
      );
    }
  };
  render() {
    const { values, qtdeAtendimento } = this.props;

    return (
      //add classe "bg-branco" abaixo quando esição/criação
      <React.Fragment>
        <div className="px-sm-5 py-sm-2 my-sm-3 bg-branco rounded d-flex flex-column flex-sm-row justify-content-between align-items-center pb-2">
          <div className="bloco-dados-user mr-sm-auto d-flex flex-column flex-sm-row align-items-center align-self-stretch align-self-sm-center">
            <div className="bloco-image-user m-sm-2 mr-sm-4">
              {values && <Avatar user={values}></Avatar>}
            </div>
            <div className="info-user col-12">
              <React.Fragment>
                {" "}
                <p>
                  {this.props.user.cooperativa &&
                    this.props.user.cooperativa.sigla}
                </p>
                <h2 className="font-weight-bold">
                  {values && formatterHelper.getNameOrHifen(values.nome)}
                </h2>
                <p className="font-italic">
                  {" "}
                  {userService.getPerfilAtual(values)}

                </p>
              </React.Fragment>
            </div>
          </div>

          {qtdeAtendimento != undefined && (
            <div className="mr-sm-auto d-flex flex-column flex-sm-row">
              <div className="d-flex align-items-center ">
                <img
                  src={Icon_atendimentos}
                  alt="iconPerfil"
                  className="icon-atentimento"
                />
              </div>
              <div className="info-texto px-sm-3 mt-2 mt-sm-0">
                Total realizado
                <div className={`font-weight-bold f-16`}>
                  {qtdeAtendimento} Atendimentos
                </div>
              </div>
            </div>
          )}

          <div className="info-texto px-sm-3 mt-2 mt-sm-0">
            Cadastrado em
            <div className="font-weight-bold f-16">
              {dateHelper.format(values.creationDateTime, { mode: "DATE" })}
            </div>
          </div>

          <div className="info-texto px-sm-3 mt-2 mt-sm-0">
            Status
            <div
              className={`font-weight-bold f-16 ${values.status &&
                values.status.className}`}
            >
              {values.status && values.status.description}
            </div>
          </div>

          <div className="px-sm-3 mt-2 mt-sm-0 border-left">
            {this.getBtnActiveInative()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(UsuarioTopoView));
