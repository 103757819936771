import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SelectInput from "../../components/Inputs/SelectInput";
import { statusAtendimentoService } from "../../services/statusAtendimento.service";
import * as _ from "lodash";

class SelectSetorAuditor extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { setorAtual: null, setores: [] };
  }

  componentWillMount() {
    statusAtendimentoService.findAllSetor().then(response => {
      let options = response.data;
      this.setState({ setores: options });
    });
  }

  handleChange = (name, value) => {
    this.setState({ setorAtual: value });
  };

  setUserInSetor = () => {
    this.props.loading(true);
    const { setorAtual } = this.state;
    statusAtendimentoService.setSetorAtual(setorAtual.id).then(response => {
      const user = response.data;
      user.setorAtual = setorAtual;
      this.props.closeSelectPerfil({user, selectPerfilAuditor: false,
        selectPerfilAtual: false});
      this.props.loading(false);
    });
  };

  render() {
    const { setores, setorAtual } = this.state;
    const { selectPerfilAuditor } = this.props;
    return (
      <div className={`box menu-auditor ${!selectPerfilAuditor ? "fadeOut" : ""}`}>
        <div className="box-auditor">
          <h2>Setor de atuação</h2>
          <p>
            Selecione abaixo o setor de auditoria que você está trabalhando
            agora:
          </p>
          <label>Setor</label>
          <SelectInput
            name={"setor"}
            value={setorAtual}
            options={setores}
            onChange={this.handleChange}
            returnFullObject={true}
            labelKey={"nome"}
            valueKey={"id"}
            placeholder="Selecione o setor"
          />
        </div>
        <div className="botao-box">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={false}
            onClick={this.setUserInSetor}
            disabled={!setorAtual}
          >
            Confirmar
          </button>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { selectPerfilAuditor } = state.authentication;
  return {
    selectPerfilAuditor
  };
}

const mapDispatch = ({
  alert: { error, clear, success },
  authentication: { closeSelectPerfil },
  load: { loading }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: msg => success(msg),
  closeSelectPerfil: payload => closeSelectPerfil(payload),
  loading: (load: boolean) => loading({ load })
});
export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(SelectSetorAuditor));
