export const formatterHelper = {
  cpfCnpjFormatter,
  getNameOrHifen,
  formatMoney,
  getNameAndLastName,
  getFileSize,
  getLabelEnum,
  getEnum,
  money,
  converterFormatoData,
  formatarDataHora
};
function getLabelEnum(enumValue, options) {
  for (let indexOptions in options) {
    if (enumValue === options[indexOptions].value) {
      return options[indexOptions].label;
    }
  }
}

function getEnum(enumValue, options) {
  for (let indexOptions in options) {
    if (enumValue === options[indexOptions].value) {
      return options[indexOptions];
    }
  }
}
function getNameOrHifen(name: String, defaultValue: String) {
  if (!name) {
    return defaultValue ? defaultValue : "- -";
  }
  try {
    // var firstName = name
    //   .split(" ")
    //   .slice(0, 1)
    //   .join(" ");
    // var lastName = name
    //   .split(" ")
    //   .slice(-1)
    //   .join(" ");
    // if (name.indexOf(" ") !== -1) {
    //   return firstName + " " + lastName;
    // }
    return name;
  } catch (error) {
    return name;
  }
}

function getNameAndLastName(name: String, defaultValue: String) {
  if (!name) {
    return defaultValue ? defaultValue : "- -";
  }

  if (name.split(" ").length === 0) {
    return name;
  }
  var firstName = name
    .split(" ")
    .slice(0, 1)
    .join(" ");
  var lastName = name
    .split(" ")
    .slice(-1)
    .join(" ");
  if (name.indexOf(" ") !== -1) {
    return firstName + " " + lastName;
  }
  return name;
}
function cpfCnpjFormatter(value) {
  const cnpjCpf = value.replace(/\D/g, '');

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
  }

  return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
}
// function cpfCnpjFormatter(values) {
//   if (values) {
//     let onlyNumber = values.replace(/\D/g, "");
//     if (onlyNumber.length <= 11) {
//       onlyNumber = onlyNumber.replace(/(\d{3})(\d)/, "$1.$2");
//       onlyNumber = onlyNumber.replace(/(\d{3})(\d)/, "$1.$2");
//       onlyNumber = onlyNumber.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
//     } else {
//       //Coloca ponto entre o segundo e o terceiro dígitos
//       onlyNumber = onlyNumber.replace(/^(\d{2})(\d)/, "$1.$2");
//       //Coloca ponto entre o quinto e o sexto dígitos
//       onlyNumber = onlyNumber.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
//       //Coloca uma barra entre o oitavo e o nono dígitos
//       onlyNumber = onlyNumber.replace(/\.(\d{3})(\d)/, ".$1/$2");
//       //Coloca um hífen depois do bloco de quatro dígitos
//       onlyNumber = onlyNumber.replace(/(\d{4})(\d)/, "$1-$2");
//     }
//     return onlyNumber;
//   }
// }

function money(valor, onlyNumber) {
  return formatMoney(valor, 2, ",", ".", onlyNumber);
}

function formatMoney(n, c, d, t, onlyNumber) {
  var c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    "R$ " +
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
      Math.abs(n - i)
        .toFixed(c)
        .slice(2)
      : "")
  );
}

function getFileSize(size) {
  var i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
}

function converterFormatoData(dataString) {
  // Verificar se a string possui o formato correto (yyyy-mm-dd)
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (dataString) {
    if (!regex.test(dataString)) {
      throw new Error('Formato de data inválido. Use o formato: yyyy-mm-dd');
    }
    // Dividir a string em partes (ano, mês, dia)
    const [ano, mes, dia] = dataString.split('-');

    // Formatar a data no novo formato (dd/mm/yyyy)
    const dataFormatada = `${dia}/${mes}/${ano}`;
    return dataFormatada;
  }
  return "";
}

function formatarDataHora(dataString) {
  //2024-02-25T03:00:00Z
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;
  if (dataString) {
    if (!regex.test(dataString)) {
      throw new Error('Formato de data inválido. Use o formato: yyyy-mm-ddTHH:MM:SSZ');
    }

    const data = new Date(dataString);

    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    const horas = String(data.getHours()).padStart(2, '0');
    const minutos = String(data.getMinutes()).padStart(2, '0');

    //// Saída: 25/02/2024 03:00
    return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
  }
  return "";
}



