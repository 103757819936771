//@flow
import * as React from 'react';

import SelectInput from './SelectInput';
import _ from 'lodash';
type Props = {
  label: string,
  name: string,
  value: any,
  onChange: any,
  returnFullObject: boolean,
  messageValidate?: any,
  disabled?: boolean,
  size?: number,
  required: boolean
};

type State = {
  options: Array<any>
};
export default class FormSelectInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      options: [],
      defaultValue: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.parent && !_.isEqual(nextProps.parent ,this.props.parent)) {
      this.fetchData(nextProps.parent);
    }
  }
  componentDidMount() {
    this.fetchData(this.props.parent)
  }
  sortByKey(data, key) {
    return data.sort((a, b) => a[key] > b[key] ? 1 : -1)
  }

  fetchData(parent) {
    let _this = this;
    let fetch = parent ? this.props.service(parent) : this.props.service();
    if(fetch) {
      try {
        fetch.then(
            data => {
              let options = [];
              options = options.concat(data.data);
              let defaultValue;
              if (_this.props.defaultSelected && options.length) {
                defaultValue = options[0];
              }
              if (this.props.sortKey) {
                options = this.sortByKey(options, this.props.sortKey);
              }
              this.setState({options: options, defaultValue});
            },
            error => {
              console.error('Erro ao montar o combo', error);
            }
        );
      } catch (error) {
        console.error('Erro ao fazer fetch', error);
        let options = [];
        this.setState({options: options, defaultValue: null});
      }
    }
  }

  render() {
    const { ...otherProps } = this.props;
    return (
      <SelectInput
        id={this.props.id}
        label={this.props.label}
        valueKey={this.props.valueKey ? this.props.valueKey : 'id'}
        labelKey={this.props.labelKey ? this.props.labelKey : 'nome'}
        name={this.props.name}
        value={this.props.value}
        options={this.state.options}
        onChange={this.props.onChange}
        multi={false}
        returnFullObject={this.props.returnFullObject}
        messageValidate={this.props.messageValidate}
        disabled={this.props.disabled}
        size={this.props.size}
        style={this.props.style}
        required={this.props.required}
        helpText={this.props.helpText}
        erroMensagem={this.props.erroMensagem}
        className={this.props.className}
        placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
        defaultValue={this.state.defaultValue}
        components={this.props.components}
        formatOptionLabel={this.props.formatOptionLabel}
        onBlur={this.props.onBlur}
        alertMensagem={this.props.alertMensagem}
        {...otherProps}
      />
    );
  }
}
