//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { objectsConstants } from '../../constants/objects.constants';
import { mensagemService } from '../../services/mensagem.service';
import TopoTitleComponente from '../home/TopoTitleComponente';
import MensagemForm from './MensagemForm';

class MensagemView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: {}
    };
  }

  componentDidMount() {
    this.loadUser();
  }

   loadUser = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      mensagemService.doGet(this.props.match.params.id).then(
        response => {
          let user = response.data;
          user.tipo = '';
          if(user.cooperados && user.cooperados.length > 0) {
            user.tipo = objectsConstants.MENSAGEM_TIPO[0].label
          } else if(user.especialidades && user.especialidades.length > 0)   {       
            user.tipo = objectsConstants.MENSAGEM_TIPO[1].label
          }
          this.setState({ entity: user }, () => {
            this.props.loading(false);
          });          
        },
        error => {
          console.error(error);
          this.props.loading(false);
        }
      );
    }
  };

  render() {
    let _this = this;
    const { entity } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          {(
            <React.Fragment>
              <TopoTitleComponente
                mainTitle={`Dados da mensagem`}
                canBack={true}
                backUrl={'/mensagem'}
              />


              <MensagemForm
                values={entity}
                viewMode={true}
                errors={[]}
                refreshEntity={this.loadUser}
              ></MensagemForm>

            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}





const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(withRouter(MensagemView));
