import { defaultService } from "./defaultService";
import { urlsConstants } from "../constants/url.constant";

export const solicitaExameService = {
    findTableColumns,
    findSearchableColumns,
    findByFilter,
    exportToExcel,
    checkExames
};

function findTableColumns() {
    return defaultService.doGet(`${urlsConstants.SOLICITAEXAME}list/fields`);
}

function findSearchableColumns() {
    return defaultService.doGet(`${urlsConstants.SOLICITAEXAME}search/fields`);
}

function findByFilter(options: Options, filter: any): Promise<Array<>> {
    return defaultService.findByFiltro(
        `${urlsConstants.SOLICITAEXAME}list`,
        options,
        filter
    );
}

function exportToExcel(filter, fields, labels) {
    return defaultService.exportToExcel('solicitaExame', filter, fields, labels);
}

function checkExames(id) {
    return defaultService.doPost(`${urlsConstants.SOLICITAEXAME}checkExames`, id);
}