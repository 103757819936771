import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import btnVoltar from "../../img/btn_voltar.svg";
import Icon_notificacao from "../../img/icon_notificacao.svg";
import Icon_notificacaoTopo from "../../img/icon_topo_notificacao.svg";
import Logo from "../../img/Logo.svg";
import { atendimentoService } from "../../services/atendimento.service";
import {
  notificacaoService,
  taskType
} from "../../services/notificacaoService";
import NotificationResponsavel from "./NotificationResponsavel";
import {loteAtendimentoService} from "../../services/loteAtendimento.service";
import * as _ from 'lodash';
import { objectsConstants } from "../../constants/objects.constants";

const ARRRAY_TASKTYPE={

}

const days = (notification_date) =>{
  let now = new Date();
  notification_date = new Date(notification_date);
  let difference = now.getTime() - notification_date.getTime();
  let TotalDays = Math.floor(difference / (1000 * 3600 * 24));
  return TotalDays;
}

class Notification extends PureComponent<Props, State> {
  gotoPage = page => {
    this.props.toogleNotification();
    this.props.history.push(page);
  };

  rennderAvatarContainer = notification => {
    let type = notificacaoService.getTaskType(notification.taskType);

    if (type === taskType.ATENDIMENTO_PENDENCIA) {
      return (
        <React.Fragment>
          <NotificationResponsavel
            notification={notification}
          ></NotificationResponsavel>
          <div className="">
            <button
              type="button"
              className="btn btn-light p-0"
              onClick={() => {
                this.props.loading(true);
                atendimentoService.findByPendencia(notification.entity).then(
                  response => {
                    let entitys = [response.data];
                    this.props.history.push({
                      pathname: "/guias",
                      state: { entitys, idPendencia: notification.entity }
                    });
                    this.props.loading(false);
                    this.props.toogleNotification();
                  },
                  erros => {
                    this.props.loading(false);
                    console.error(erros);
                  }
                );
              }}
            >
              <span className="icon-visualizar"></span>
              Visualizar
            </button>
          </div>
        </React.Fragment>
      );
    } else if (type === taskType.ATENDIMENTO_DATA_ENVIO_FATURAMENTO) {
      return (
        <React.Fragment>
          <div className="col-12 col-md-6 p-0 mt-4 mt-md-0 text-right">
            <button
              type="button"
              className="btn btn-light p-0"
              onClick={() => {
                let entitys = [{id:notification.entity}];
                this.props.history.push({
                  pathname: "/guias",
                  state: { entitys ,idPendencia: notification.entity}
                });
                this.props.toogleNotification();
              }}
            >
              <span className="icon-visualizar"></span>
              Visualizar
            </button>
          </div>
        </React.Fragment>
      );
    }else if(type===taskType.LOTE_COM_ERROR){

      return (
          <React.Fragment>
            <div className="col-12 col-md-6 p-0 mt-4 mt-md-0 text-right">
              <button
                  type="button"
                  className="btn btn-light p-0"
                  onClick={() => {
                    this.props.history.push("/visualizarLote/" + notification.entity);
                    this.props.toogleNotification();
                  }}
              >
                <span className="icon-visualizar"></span>
                Visualizar
              </button>
            </div>
          </React.Fragment>
      );

    }else if (type === taskType.ATENDIMENTO_GLOSADO) {
      return (
        <React.Fragment>
          <div className="col-12 col-md-2 p-0 mt-4 mt-md-0 text-right">
          <button
              type="button"
              className="btn btn-light p-0"
              onClick={() => {
                this.props.loading(true);
                atendimentoService.findByPendencia(notification.entity).then(
                  response => {
                    let entitys = [response.data];
                    this.props.history.push({
                      pathname: "/guias",
                      state: { entitys, idPendencia: notification.entity }
                    });
                    this.props.loading(false);
                    this.props.toogleNotification();
                  },
                  erros => {
                    this.props.loading(false);
                    console.error(erros);
                  }
                );
              }}
            >
              <span className="icon-visualizar"></span>
              Visualizar
            </button>
          </div>
        </React.Fragment>
      );
    } else if (type === taskType.INCONSISTENCIA_AUXILIARES) {
      return (
        <React.Fragment> 
          <NotificationResponsavel
            notification={notification}
          ></NotificationResponsavel>
          <div className="">
            <button
              type="button"
              className="btn btn-light p-0"
              onClick={() => {
              
                let entitys = [{id:notification.entity}];
                this.props.history.push({
                  pathname: "/guias",
                  state: { entitys ,idPendencia: notification.entity}
                });
                this.props.toogleNotification();
              }}
            >
              <span className="icon-visualizar"></span>
              Visualizar
            </button>
          </div>
        </React.Fragment>
      );
    }else if (type === taskType.ATENDIMENTO_DUPLICIDADE) {
      return (
        <React.Fragment>
          <div className="col-12 col-md-2 p-0 mt-4 mt-md-0 text-right">
          <button
              type="button"
              className="btn btn-light p-0"
              onClick={() => {
                this.props.loading(true);
                atendimentoService.findByPendencia(notification.entity).then(
                  response => {
                    let entitys = [response.data];
                    this.props.history.push({
                      pathname: "/guias",
                      state: { entitys, idPendencia: notification.entity }
                    });
                    this.props.loading(false);
                    this.props.toogleNotification();
                  },
                  erros => {
                    this.props.loading(false);
                    console.error(erros);
                  }
                );
              }}
            >
              <span className="icon-visualizar"></span>
              Visualizar
            </button>
          </div>
        </React.Fragment>
      );
    }
  };

    getColorDetalhe=(notification)=>{
      let type = notificacaoService.getTaskType(notification.taskType);
      if(type===taskType.LOTE_COM_ERROR){
          return 'txtRed';
      }
      return 'txtGreen';
  }

  contentNotification = notification => {    
    if (notification.taskType == "ATENDIMENTO_GLOSADO" || notification.taskType == "ATENDIMENTO_DUPLICIDADE") {
      return (
        <div className="col-10 col-md-9 pr-0">
          <h2 id="tituloNotif" className="m-0 font-weight-bold">
            {notification.titulo}
          </h2>
          <p id="descricaoNotif" className="m-0 descricao">
            {notification.subTitulo}
          </p>
          {/* QUANDO NAO QUISER O TEXTO VERDE COLOCAR A CLASSE D-NONE NO P ABAIXO */}
            
            <p id="greenTextNotif" className={`m-0 quebra-de-linha  ${this.getColorDetalhe(notification)}`} style={{whiteSpace: "initial"}} title={notification.detalhe}>
            {notification.detalhe}
          </p>
        </div>
      )
    } else if (notification.taskType == "INCONSISTENCIA_AUXILIARES") {
      let detalhes = notification.detalhe.trim().split('.');
      return (
        <div className="col-10 pr-0">
          <h2 id="tituloNotif" className="m-0 font-weight-bold">
            {notification.titulo}
          </h2>
          <p id="descricaoNotif" className="m-0 descricao">
            {notification.subTitulo}
          </p>
          {/* QUANDO NAO QUISER O TEXTO VERDE COLOCAR A CLASSE D-NONE NO P ABAIXO */}
          {detalhes.map(detalhe => {
              if(detalhe.length > 0)
              return (
                <p id="greenTextNotif" className={`m-0 ${this.getColorDetalhe(notification)}`} title={detalhe.trim()}>
                  {detalhe.trim()}
                </p>
              )
            })}

          <p className="" style={{ color: 'red' }}>
            Dias em exibição: {days(notification.dataCriacao) === 0 ? 'Menos de um dia' : days(notification.dataCriacao) + ' dias'}
          </p>
        </div>
      )
    } else {
      return (
          <div className={"col-10 pr-0 " + (notification.titulo === objectsConstants.NOTIFICACAO_REMOCAO_GUIA_PROTOCOLO ? "" : "col-md-5")} >
          <h2 id="tituloNotif" className="m-0 font-weight-bold">
            {notification.titulo}
          </h2>
          <p id="descricaoNotif" className="m-0 descricao">
            {notification.subTitulo}
          </p>
          {/* QUANDO NAO QUISER O TEXTO VERDE COLOCAR A CLASSE D-NONE NO P ABAIXO */}
            <p id="greenTextNotif" className={`m-0 quebra-de-linha ${this.getColorDetalhe(notification)}`} title={notification.detalhe}>
            {notification.detalhe}
          </p>
          <p className="" style={{ color: 'red' }}>
            Dias em exibição: {days(notification.dataCriacao) === 0 ? 'Menos de um dia' : days(notification.dataCriacao) + ' dias'}
          </p>
        </div>
      )
    }
  }


  getHtmlContentNotification = notification => {
    return (
      <React.Fragment key={notification.id}>
        <div className="row align-items-center notification">
          <div className="col-2 col-md-1 py-2 pl-3 pr-1">
            <img src={Icon_notificacaoTopo} alt="Ícone Notificação"></img>
          </div>
          {this.contentNotification(notification)}

          {this.rennderAvatarContainer(notification)}

          {/* QUANDO QUISER A NOTIFICAÇÃO COM BOTÕES DE CONFIRMAÇÃO, TIRAR O D-NONE DESTAS DUAS DIV ABAIXO */}
          <div className="col-6 col-md-3 mt-4 mt-md-0 px-1 text-center text-md-right d-none">
            <button className="btn btn-secondary white">Não recebi</button>
          </div>
          <div className="col-6 col-md-3 mt-4 mt-md-0 px-1 text-center text-md-right d-none">
            <button className="btn btn-primary white">Recebi</button>
          </div>
        </div>
      </React.Fragment>
    );
  };
    findByFilter=()=>{
        notificacaoService.findByFilter({page:(this.props.notificacoes.length/10)}).then(response => {
            let notificacoes = _.cloneDeep(this.props.notificacoes);
            notificacoes = _.union(notificacoes,response.data.data.notificacoes);
            this.props.addAllNotificacoes({ notificacoes: notificacoes, notificacoesSize:response.data.data.size });
        });
    }
  render() {
    const { showNotification, notificacoes } = this.props;
    let _this = this;
    return (
      <div
        id="notificationContent"
        className={`d-flex flex-column fixed-top background-notification
        ${showNotification ? "move-in" : "move-out"}`}
      >
        <div className="bg-riscos bottom"></div>
        <header className="navbar fixed-top topo iconNotification px-4">
          <div
            className="btnVoltar mr-auto bd-highlight pl-3"
            onClick={() => {
              this.props.toogleNotification();
            }}
          >
            <img src={btnVoltar} alt="Voltar" className="backNotification" />
          </div>

          <div className="mr-auto bd-highlight">
            <img src={Logo} alt="Logo" className="iconLogo" />
          </div>
        </header>

        <div
          id="bodyNotification"
          className="bodyNotification d-flex flex-column h-100 mx-sm-auto"
        >
          <div
            className={`container-notifications ${!showNotification &&
              "d-none"}`}
          >
            {notificacoes.length > 0 && (
              <React.Fragment>
                {/* COLOCAR NESTE P UMA CONDIÇÃO DE 0, 1 OU N NOTIFICAÇÕES */}
                <p className="qtdNotificacoes">
                  Você possui {this.props.notificacoesSize} notificações
                </p>

                <hr className="max-hr-notifications"></hr>
                <img
                  src={Icon_notificacao}
                  className="icon-notification"
                  alt="Ícone Notificação"
                />

                <div id="contentNotifications">
                  {/* INSERIR AQUI DENTRO CADA NOTIFICAÇÃO */}
                  {notificacoes.map((notification, index) => {
                    return _this.getHtmlContentNotification(notification);
                  })}
                    {this.props.notificacoesSize!==notificacoes.length&&(
                    <button
                        type="button"
                        className="btn btn-light float-right mr-5"
                        onClick={() => {
                            this.findByFilter();
                        }}
                    >
                        <span className="icon-btn-add"></span>
                        Exibir mais
                    </button>
                    )}
                </div>
              </React.Fragment>
            )}
            {/* SE NÃO EXISTIR NENHUMA NOTIFICAÇÃO ESSE H2 APARECE */}
            {notificacoes.length === 0 && (
              <h2 className="semNotificacoes">
                Você não possuí nenhuma notificação
              </h2>
            )}
          </div>
        </div>

        <footer>
          <p>© {new Date().getFullYear()} Sasc Conect - Fencom</p>
        </footer>
      </div>
    );
  }
}

const mapDispatch = ({
  load: { loading },
  notification: { toogleNotification,addAllNotificacoes }
}) => ({
  toogleNotification: () => toogleNotification(),
  loading: (load: boolean) => loading({ load }),
    addAllNotificacoes: (notifications) => addAllNotificacoes(notifications),
});

function mapStateToProps(state) {
  const { showNotification, notificacoes,notificacoesSize } = state.notification;
  const { user } = state.authentication;

  return {
    showNotification,
    user,
    notificacoes,
      notificacoesSize
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(Notification));
