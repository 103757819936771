import * as yup from "yup";

export function createYupSchema(schema, validations) {
  if (!validations.length) {
    return schema;
  }
  let validator = {};
  validations.forEach(validation => {

    let field = yup.reach(schema, validation);

    if (!field) {
      return;
    }
    validator[validation]=field
  });
  const validation= yup.object().shape(validator);
  return validation;
}
