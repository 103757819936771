import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Formik} from "formik";
import iconPendencia from "../../img/icon_pendente_WHITE.svg";
import {relatoriosService} from "../../services/relatorios.service";
import {relatorios} from "../../constants/relatorio.constants";
import SelectInput from "../../components/Inputs/SelectInput";
import {userService} from "../../services/user.service";
let download = require('downloadjs');

class RelatorioTopo extends PureComponent<Props, State> {
  render() {
      const {yup,Filtro,controller} = this.props;
      const isConsultorio = userService.isConsultorio(this.props.user);
      const optionsRelatorio = relatorios.filter(function(r){
          return !r.isAdmin||!isConsultorio;
      })
    return (
      <div className="barra-topo pb-3 p-sm-0 topo-guia bg-branco d-flex flex-row flex-sm-row align-content-stretch flex-wrap">

          <div className={`${(!yup||!controller)?'w-25 ':'w-10-custom '}px-sm-5 py-5 py-sm-4  d-flex align-items-center justify-content-center  text-center`}>

              <div className={'w-100'}>
                  <h2 className={`status-txt text-break`}>Relatório</h2>
                  {(!yup||!controller)&&(
                      <SelectInput
                          className={'w-100'}
                          options={optionsRelatorio}
                          onChange={this.props.onChange}
                          name={this.props.name}
                          id={this.props.id}
                          value={this.props.value}
                          valueKey={"nome"}
                          labelKey={"nome"}
                          returnFullObject={true}
                          required={true}
                          clearable={true}
                          placeholder={"Relatório"}
                          label={``}
                          erroMensagem={this.props.erroMensagem}
                      />
                  )}
                  {(yup&&controller)&&(
                      <p className="mb-0" onClick={()=>{this.props.onChange()}}>  {this.props.name} </p>
                  )}


              </div>
          </div>

          <div className="icone-relatorio-custom d-none d-sm-flex px-sm-5 align-items-center justify-content-center">
              <img src={iconPendencia} alt="Pendencia" className="iconMedio" />
          </div>
          {(!yup||!controller)&&(
          <div className="px-sm-5 py-4 d-flex align-items-center justify-content-center flex-sm-fill">
              <div className="info-texto px-5 px-sm-3 mt-2 mt-sm-0">
                <span className="font-weight-bold font-italic verde-destaque">
                  Quer ajuda?
                </span>
                  <p className="font-italic">
                      Selecione o Relatório que deseja gerar
                  </p>
              </div>
          </div>
          )}
          {yup&&controller&&Filtro&&(
              <Formik
                  validationSchema={yup
                  }
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                  initialValues={{}}
                  onSubmit={(values) => {
                      this.props.loading(true);
                      let numeroGuiaList = [];
                      if (values && values.numeroGuia) {
                          for (let i = 0; i < values.numeroGuia.length; i++) {
                            numeroGuiaList.push(values.numeroGuia[i].numeroGuia)
                          }
                      }
                      values.numeroGuia = numeroGuiaList;

                      relatoriosService.exportRelatorio(values,controller).then(
                          response => {
                              download(
                                  response.data,
                                  (this.props.downloadExcelName
                                      ? this.props.downloadExcelName
                                      : this.props.controller) + '.csv',
                                  'text/html'
                              );
                              this.props.loading(false);
                          },
                          () => {
                              this.props.loading(false);
                              this.props.error('Não foi possível exportar arquivo.');
                          }
                      );
                  }}
                  ref={form => {
                      this.formRef = form;
                  }}
              >{({
                     values,
                     errors,
                     handleChange,
                     handleSubmit,
                     setFieldValue,
                     setValues 
                }) => {
                  console.error(errors)
                  return(
                  <form onSubmit={handleSubmit} className={'d-flex flexGrow2'} noValidate>
                      <Filtro values={values} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} setValues={setValues} />
                      <div className="px-sm-3 d-flex flex-column align-items-center justify-content-center borda-separacao ">
                          <button
                              type="submit"
                              className="btn btn-link pb-0 pb-sm-3 "

                          >
                              <span className="icon-btn-notificacao "></span>
                              Gerar Relatório
                          </button>

                      </div>
                </form>)
              }}
              </Formik>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}
const mapDispatch = ({
                         alert: { success, error, clear, info },
                         load: { loading }
                     }) => ({
    loading: (load: boolean) => loading({ load }),
    success: msg => success(msg),
    info: msg => info(msg),
    error: msg => error(msg),
    clear: () => clear()
});


export default connect(mapStateToProps, mapDispatch)(withRouter(RelatorioTopo));
