import _ from "lodash";

import React, { PureComponent } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import Avatar from "../../../components/Avatar/Avatar";
import SelectInput from "../../../components/Inputs/SelectInput";
import { dateHelper } from "../../../helpers/date.helper";
import { formatterHelper } from "../../../helpers/formatter.helper";
import iconWarning from "../../../img/iconAlert_Atencao.svg";
import Icon_atendimentos from "../../../img/icon_atendimentos.svg";
import Icon_usuario_cooperado from "../../../img/icon_usuarioCooperado.svg";
import { OptionCooperado } from "../../../sascComponents/cooperado/OptionCooperado";
import { cooperadoService } from "../../../services/cooperado.service";
import { userService } from "../../../services/user.service";
import TopoTitleComponente from "../../home/TopoTitleComponente";

const NewUsuario = Yup.object().shape({
  dataNotaFiscal: Yup.string().test(
    "valid-Nome",
    "Nome obrigatório",
    function() {
      const { parent, createError } = this;
      if (parent.nome === undefined) {
        return createError();
      } else {
        return true;
      }
    }
  )
});

class CooperadoSudoTopoNew extends PureComponent<Props, State> {
  confirmAddEspecialidade = (onConfirm, values, setFieldValue,diference) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui confirm-ui-nova css-alert-sasc">
            <img src={iconWarning} alt="icon" />
            <h2>{"NOVAS ESPECIALIDADES"}</h2>
            <p>
              <b>
                {diference.length
                  ? diference.map(item => item.nome).join(", ")
                  : "--"}
              </b>
            </p>
            <p>
              O cooperado {values.nome} possui especialidades que não estão
              vinculadas a este consultório.
            </p>
            <p className="font-bold pt-2">
              Você deseja incluir as novas especialidades ao consultório?
            </p>
            <button
              className="btn btn-secondary white mt-3 mb-4 mx-2"
              onClick={() => {
                onClose();
              }}
            >
              Não
            </button>
            {
              <button
                className="btn btn-primary white mt-3 mb-4 mx-2"
                onClick={() => {
                  setFieldValue('podeAddMaisEspecialidades', true);
                  onConfirm();
                  onClose();
                }}
              >
                Sim
              </button>
            }
          </div>
        );
      }
    });
  };

  compareEspecialidades = (cooperadoEspecialidades, estabelecimentoEspecialidades) => {
    return cooperadoEspecialidades.filter((item) => !estabelecimentoEspecialidades.some((element) => element.id === item.id));
  }

  render() {
    let _this = this;
    const {
      values,
      handleSubmit,
      isSubmitting,
      changeCooperado,
      validateForm,
      setFieldValue,
      estabelecimento,
      createMode
    } = this.props;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={`${values.id ? values.nome : "Novo cooperado"}`}
          canBack={true}
          backUrl={"/cooperados"}
        />
        <div className="px-sm-5 py-sm-2 my-sm-3 bg-branco rounded d-flex flex-column flex-sm-row justify-content-between align-items-center">
          <div className="bloco-dados-user mr-sm-auto d-flex flex-column flex-sm-row align-items-center col-12 col-sm-4">
            <div className="bloco-image-user m-sm-2 mr-sm-4">
              {!values.id && (
                <img
                  src={Icon_usuario_cooperado}
                  className="avatar"
                  alt="iconFoto"
                />
              )}
              {values.id && <Avatar user={values}></Avatar>}
            </div>
            <div className="info-user col-12">
              {values.id && (
                <React.Fragment>
                  {" "}
                  <p>
                    {this.props.user.cooperativa &&
                      this.props.user.cooperativa.sigla}
                  </p>
                  <h2 className="font-weight-bold">
                    {formatterHelper.getNameOrHifen(values.nome)}
                  </h2>
                  <p className="font-italic">
                    {" "}
                    {userService.getPerfilAtual(values)}
                  </p>
                </React.Fragment>
              )}
              {!values.id && (
                <React.Fragment>
                  {" "}
                  <label>Cooperado</label>
                  <SelectInput
                    name={"cooperado"}
                    placeholder={"Digite nome ou número do conselho"}
                    returnFullObject={true}
                    value={values}
                    onChange={(name, value) => {
                      cooperadoService
                        .getFullCooperado(value.id)
                        .then(response => {
                          changeCooperado(name, response.data.data);
                        });
                    }}
                    valueKey={"id"}
                    labelKey={"nome"}
                    multi={false}
                    required={this.props.required}
                    noSize={true}
                    clearable={true}
                    removeDropDown={true}
                    onFetchData={cooperadoService.find}
                    components={{ Option: OptionCooperado }}
                  ></SelectInput>
                </React.Fragment>
              )}
            </div>
          </div>
          {values.id && (
            <React.Fragment>
              <div className="mr-sm-auto d-flex flex-column flex-sm-row">
                <div className="d-flex align-items-center ">
                  <img
                    src={Icon_atendimentos}
                    alt="iconPerfil"
                    className="icon-atentimento"
                  />
                </div>
                <div className="info-texto px-sm-3 mt-2 mt-sm-0">
                  Total realizado
                  <div className={`font-weight-bold f-16`}>
                    {values.qtdeAtendimento ? values.qtdeAtendimento : "--"}
                  </div>
                </div>
              </div>
              <div className="mr-sm-auto info-texto px-sm-3 mt-2 mt-sm-0">
                Cadastrado em:
                <div className="font-weight-bold">
                  {dateHelper.format(values.creationDatetimeEstabelecimento)}
                </div>
              </div>
              <div className="mr-sm-auto info-texto px-sm-3 mt-2 mt-sm-0">
                Status
                {!values.statusEstabelecimento && (
                  <div className={`font-weight-bold f-16`}>--</div>
                )}
                {values.statusEstabelecimento && (
                  <div
                    className={`font-weight-bold f-16 ${values.statusEstabelecimento &&
                      values.statusEstabelecimento.className}`}
                  >
                    {values.statusEstabelecimento &&
                      values.statusEstabelecimento.description}
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
          {!values.id && (
            <div className="mr-sm-auto info-texto px-3 mt-2 mt-sm-0">
              <span className="font-weight-bold font-italic helpText">
                Quer ajuda?
              </span>
              <p className="font-italic">
                01- Pesquise ao lado pelo nome ou número do conselho do
                cooperado que deseja adicionar
              </p>
            </div>
          )}
          <div className="px-sm-3 mt-2 mt-sm-0 border-left">
            {values.id && createMode && (
              <button
                type="button"
                className="btn btn-primary"
                disabled={isSubmitting}
                onClick={() => {
                  //handleSubmit


                  validateForm(values).then(erros => {
                      values.createMode = createMode;
                    if (_.isEmpty(erros)) {
                      if (createMode) {

                        let diference = this.compareEspecialidades(
                          values.especialidades,
                          estabelecimento.especialidades
                        );

                        if (diference.length > 0) {
                          _this.confirmAddEspecialidade(
                            handleSubmit,
                            values,
                            setFieldValue,
                            diference
                          );
                        } else {
                          handleSubmit();
                        }
                      } else {
                        handleSubmit();
                      }
                    }
                  });
                }}
              >
                {`${createMode ? "Adicionar" : "Editar"} cooperado`}
              </button>
            )}
          </div>
        </div>
        <div className="row"></div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(mapStateToProps, null)(withRouter(CooperadoSudoTopoNew));
