//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ConveniosList from './ConveniosList';
import ConveniosView from './ConveniosView';
import ConveniosEdit from './ConveniosEdit';
export default class Convenios extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/convenios" component={ConveniosList} exact />
          <Route path="/convenios/novo" component={ConveniosEdit} />
          <Route path="/convenios/edit/:id" component={ConveniosEdit} />
          <Route path="/convenios/view/:id" component={ConveniosView} />
        </Switch>
      </React.Fragment>
    );
  }
}
