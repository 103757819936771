//@flow
import _ from "lodash";
import * as React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import connect from "react-redux/es/connect/connect";
import BeneficiarioTopoView from "./BeneficiarioTopoView";
import BeneficiarioForm from "./BeneficiarioForm";
import { beneficiarioService } from "../../services/beneficiario.service";
import TopoTitleComponente from "../home/TopoTitleComponente";

class BeneficiarioView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
        entity: {}
      };
  }

  componentDidMount() {
    this.loadPaciente();
  }

  loadPaciente = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      beneficiarioService.doGet(this.props.match.params.id).then(
        response => {
          let paciente = response.data;
          this.setState({ entity: paciente }, () => {
            this.props.loading(false);
          });
        },
        error => {
          console.error(error);
          this.props.loading(false);
        }
      );
    }
  };

  render() {
    const { entity } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          <React.Fragment>
            <TopoTitleComponente
              mainTitle={`Dados do paciente`}
              canBack={true}
              backUrl={'/beneficiarios'}
            />
            <BeneficiarioTopoView
              values={entity}
              viewMode={true}
            ></BeneficiarioTopoView>
            <BeneficiarioForm
              values={entity}
              viewMode={true}
              createMode={false}
              errors={[]}
            ></BeneficiarioForm>
          </React.Fragment>

          <div className="background-lines">
            <div className="bg-riscos"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {    
    user
  };
}

export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  { withRef: true }
)(BeneficiarioView);

