import { Can } from "@casl/react";
import * as _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Collapse, Input } from "reactstrap";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../../node_modules/slick-carousel/slick/slick.css";
import { objectsConstants } from "../../constants/objects.constants";
import Column from "./Column";
import ColumnCheckboxAction from "./ColumnCheckboxAction";
import { datatableConstants } from "./constants/datatable.constants";
import FilterDatatable from "./FilterDatatable";
import HeaderColumn from "./HeaderColumn";
import { ColumnType } from "./type/ColumnType";
import ContentFixedOnTop from "../scrollFixed/ContentFixedOnTop";


let download = require("downloadjs");
const EQUAL_CONDITION = { description: "=" };
const LIKE_CONDITION = { description: "like" };
const ASC = "ASC";
const DESC = "DESC";
class Datatable extends PureComponent<> {
  constructor(props) {
    super(props);
    let listFields = [];
    if (props.listFields) {
      listFields = props.listFields;
    }
    let filterDefault = [{ filterName: props.name }];
    let filterSearch;
    let filterOpened = false;
    if (!_.isEmpty(props.filtroTable) && props.filtroTable[0].filterName === props.name) {
      filterSearch = props.filtroTable;
      filterOpened = true;
    } else {
      filterSearch = filterDefault;
    }

    if (props.noCacheFilter) {
      filterSearch = filterDefault;
    }
    this.state = {
      orderBy: null,
      showPagination: false,
      filterSearch,
      rowsCheckedValue: [],
      rowsCheckedIdValue: [],
      dataTotalSize: 0,
      data: [],
      columns: [],
      listFields: listFields,
      listFieldsSelect: new Map(),
      searchOpen: filterOpened ? true : false,
      lotesOpen: false,
      checkedAll: false,
      checkedAllPages: false,
      unchecked: false,
      disableSearch: false,
      disableExport: false,
      disableName: false,
      exportAtendimento: false,
    };
  }
  componentDidMount() {

    if (!this.props.columns) {
      this.props.service.findTableColumns().then(response => {
        this.updateColumns(response.data);
      });
    } else {
      this.updateColumns(this.props.columns);
    }
    if (this.props.name && this.props.name === 'atendimentos') {
      let rowsCheckedValue = JSON.parse(localStorage.getItem('rowsCheckedValue'))
      let rowsCheckedIdValue = JSON.parse(localStorage.getItem('rowsCheckedIdValue'))
      if (rowsCheckedValue && rowsCheckedIdValue) {
        this.setState({ rowsCheckedValue, rowsCheckedIdValue });
      }
    }
  }



  updateColumns = columns => {
    this.props.service.findSearchableColumns().then(response => {
      let listFields;
      if (this.props.listFields) {
        listFields = this.props.listFields;
      } else {
        listFields = response.data;
      }
      for (var i = 0; i < listFields.length; i++) {
        for (var j = 0; j < columns.length; j++) {
          if (
            listFields[i].path === columns[j].path &&
            listFields[i].fieldName === columns[j].fieldName
          ) {
            columns[j].searchable = true;
          }
        }
      }

      this.setState({ columns, listFields }, () => {
        this.initData();
      });
    });
  };

  initData = () => {
    this.findByFilter([]);
    if (this.sumarioRef) {
      this.sumarioRef.updateList();
    }
  };

  exportarExcel = () => {
    let filter;
    if (this.props.initFilter) {
      filter = _.cloneDeep(this.props.initFilter);
    }
    let columns;
    if (this.props.columnsExport) {
      columns = this.props.columnsExport;
    } else {
      columns = this.props.columns ? this.props.columns : this.state.listFields;
      filter = this.buildFilter(filter, this.state.filterSearch);
    }
    let filterDefault = this.props.filter;
    this.props.service
      .exportToExcel(
        filter,
        _.map(columns, column => {
          if (column.path && column.path.indexOf(".") !== -1) {
            return (
              column.path.substring(column.path.indexOf(".") + 1) +
              "." +
              column.fieldName
            );
          } else {
            return column.fieldName;
          }
        }),
        _.map(columns, column => {
          return column.fieldDescription;
        }),
        filterDefault
      )
      .then(
        response => {
          download(response.data, this.props.name + ".csv", "text/html");
        },
        () => {
          //this.props.error('Não foi possível exportar arquivo.');
        }
      );
  };

  exportarAtendimentosExcel = () => {
    this.props.loading(true);
    const filters = this.props.filtroTable
    let fields = [];

    if (this.props.initFilter) {
      for (var i = 0; i < this.props.initFilter.length; i++) {
        if (this.props.initFilter[i] && this.props.initFilter[i].fieldName === 'situacao') {
          fields.push(this.props.initFilter[i])
        }
      }
    }

    for (var i = 0; i < filters.length; i++) {
      if (filters[i] && filters[i].filter) {
        let f = filters[i].filter
        f.value = filters[i].value
        fields.push(f)
      }
    }

    this.props.service.exportAtendimentos(
      fields
    ).then(response => {
      download(
        response.data,
        this.props.name + '.csv'
        ,
        'text/html'
      );
      this.props.loading(false);
    });

  }

  getFilter = () => {
    let filter;
    if (this.props.initFilter) {
      filter = _.cloneDeep(this.props.initFilter);
    } 
    if (this.state.filterSearch.length > 0) {
      filter = this.buildFilter(filter, this.state.filterSearch);
    }
    return filter;
  };

  findByFilter = initData => {
    let pageSize;
    this.props.loading(true);
    if (this.props.pageSize) {
      pageSize = this.props.pageSize;
    } else {
      pageSize = 10;
    }
    let page;
    if (this.state.data.length > 0 && !initData) {
      page = this.state.data.length / pageSize;
    } else {
      page = 0;
    }
    const filter = this.getFilter();
    if (this.props.onFilter) {
      this.props.onFilter(filter);
    }
    let filterDefault = this.props.filter;

    this.props.service
      .findByFilter(
        {
          pageSize: pageSize,
          page: page,
          order: "",
          direction: ""
        },
        filter
        , filterDefault
      )
      .then(response => {
        const dataTotalSize = response.data.dataTotalSize;
        let data;
        if (!initData) {
          data = _.cloneDeep(this.state.data);
        } else {
          data = initData;
        }
        let showPagination;
        if (response.data.data.length < pageSize) {
          showPagination = false;
        } else {
          showPagination = true;
        }
        data = data.concat(response.data.data);
        this.setState(
          {
            data,
            dataTotalSize,
            showPagination,
            checkedAll: this.state.checkedAllPages
          },
          () => {
            this.props.loading(false);
          }
        );
      },
        erros => {
          this.props.loading(false);
          console.error(erros);
        });
  };

  buildFilter = (filterList, filterSearch) => {
    let filterFields = [];
    let filterSameOrderBy = false;
    if (filterList) {
      for (let i = 0; i < filterList.length; i++) {
        let filter = filterList[i];
        if (filter) {
          if (filter.fieldName) {
            if (
              this.state.orderBy &&
              filter.fieldName === this.state.orderBy.fieldName
            ) {
              filter.orderBy = this.state.orderBy.orderBy;
              filterSameOrderBy = true;
            }

            if (!filter.condition) {
              filter.condition = this.getCondition(filter.type);
            }
            if (!filter.orLogicalOperatorNext) {
              //DEFAULT "AND"
              filter.orLogicalOperatorNext = false;
            }
            filterFields.push(filter);
          }
        }
      }
    }
    filterSearch.map((filterValue, index) => {
      let filterValueClone = _.cloneDeep(filterValue.filter);
      if (filterValueClone) {
        if (
          filterValueClone.fieldName ===
          datatableConstants.ALL_COLUMNS_DATATABLE_FILTER.fieldName
        ) {
          filterSameOrderBy = true;
          this.buildFilterAllColumns(filterFields, filterValue);
        } else {
          filterSameOrderBy = this.updateFilter(
            filterValueClone,
            filterSameOrderBy,
            false,
            filterValue
          );
          filterFields.push(filterValueClone);
        }
      }
    });
    if (
      !filterSameOrderBy &&
      this.state.orderBy &&
      this.state.orderBy.orderBy
    ) {
      filterFields.push(this.state.orderBy);
    }

    return filterFields;
  };
  updateFilter = (
    filterValueClone,
    filterSameOrderBy,
    orLogicalOperatorNext,
    filterValue
  ) => {

    if (
      this.state.orderBy &&
      filterValueClone.fieldName === this.state.orderBy.fieldName
    ) {
      filterValueClone.orderBy = this.state.orderBy.orderBy;
      filterSameOrderBy = true;
    }
    if (!filterValueClone.condition) {
      //DEFAULT "="
      filterValueClone.condition = this.getCondition(filterValueClone.type);
    }
    if (filterValueClone.enumListValues) {
      filterValueClone.enumListValues = null;
    }
    if (!filterValueClone.orLogicalOperatorNext) {
      //DEFAULT "AND"
      filterValueClone.orLogicalOperatorNext = orLogicalOperatorNext;
    }
    if (filterValueClone.type === datatableConstants.ARRAY) {
      filterValueClone.fieldName =
        filterValueClone.fieldName + "." + filterValueClone.labelArrayKey;
    }
    filterValueClone.value = filterValue.value;
    return filterSameOrderBy;
  };
  buildFilterAllColumns = (filterFields, filterValue) => {
    this.state.listFields.map(field => {
      var filterValueClone = _.cloneDeep(field);
      this.updateFilter(filterValueClone, true, true, filterValue);
      filterFields.push(filterValueClone);
    });
  };

  getCondition = type => {
    if (
      datatableConstants.STRING === type ||
      datatableConstants.ARRAY_STRING === type
    ) {
      return LIKE_CONDITION;
    } else {
      return EQUAL_CONDITION;
    }
  };

  changeFilter = (filter, index) => {
    if (
      filter &&
      filter.filter &&
      filter.filter.fieldName &&
      (filter.value || filter.filter.searchEmpty)
    ) {
      let filterSearch = _.cloneDeep(this.state.filterSearch);
      let listFieldsSelect = _.cloneDeep(this.state.listFieldsSelect);
      if (filter.filter.fieldName === datatableConstants.ALL_COLUMNS_DATATABLE_FILTER.fieldName
      ) {
        listFieldsSelect = new Map();
        filterSearch = [];
      }
      if (this.state.filterSearch.length <= index) {
        filterSearch.push(filter);
      } else {
        filterSearch.splice(index, 1, filter);
      }

      if (filter.filter.searchEmpty && !filter.value) {
        filter.value = null;
      }

      listFieldsSelect.set(filter.filter.fieldName, index);
      this.setState({ filterSearch, listFieldsSelect }, () => {
        this.initData();
        this.props.changeFilterTable(filterSearch);

      });
    } else {
      if (typeof filter.value === "string") {
        this.props.error({
          message:
            "É necessario preencher pelo menos 3 caracteres para realizar a pesquisa."
        });
      } else {
        this.props.error({
          message: "É necessario preencher o valor do filtro."
        });
      }
    }
  };

  toggleSearch = () => {
    if (this.state.searchOpen) {
      this.setState(
        {
          searchOpen: !this.state.searchOpen,
          filterSearch: [{}],
          listFieldsSelect: new Map()
        },
        () => {
          this.initData();
          this.props.changeFilterTable(this.state.filterSearch);
        }
      );
    } else {
      this.setState({ searchOpen: !this.state.searchOpen });
    }
  };

  addFilter = () => {
    let filterSearch = _.cloneDeep(this.state.filterSearch);
    filterSearch.push({});
    this.setState({ filterSearch });
  };

  afterActionLine = (rowsCheckedValue) => {
    let checkedAll = false;
    let rowsCheckedIdValue = [];

    if (rowsCheckedValue) {
      rowsCheckedIdValue = rowsCheckedValue.map(item => {
        return (item[this.props.idRow]);
      })
    } else {
      rowsCheckedValue = [];
    }

    this.setState(
      {
        rowsCheckedValue,
        rowsCheckedIdValue,
        checkedAll,
        checkedAllPages: false
      },
      () => {
        if (this.props.onSelectRow) {
          this.props.onSelectRow(this.state.rowsCheckedValue);
        }
        this.initData();
      }
    );
  };

  onCheckedRow = (checked, id, row) => {

    let rowsCheckedValue = _.cloneDeep(this.state.rowsCheckedValue);
    let rowsCheckedIdValue = _.cloneDeep(this.state.rowsCheckedIdValue);
    let checkedAll = false;
    if (checked) {
      rowsCheckedIdValue.push(id);
      rowsCheckedValue.push(row);
      if (rowsCheckedIdValue.length === this.state.data.length) {
        checkedAll = true;
      }
    } else {
      var index = rowsCheckedIdValue.indexOf(id);

      if (index > -1) {
        rowsCheckedValue.splice(index, 1);
        rowsCheckedIdValue.splice(index, 1);
      }
    }
    this.setState({ rowsCheckedValue, rowsCheckedIdValue, checkedAll }, () => {
      if (this.props.onSelectRow) {
        this.props.onSelectRow(this.state.rowsCheckedValue);
      }
    });

    if (this.props.name && this.props.name === 'atendimentos') {
      localStorage.setItem("rowsCheckedValue", JSON.stringify(rowsCheckedValue));
      localStorage.setItem("rowsCheckedIdValue", JSON.stringify(rowsCheckedIdValue));
    }

  };
  setOrderBy = column => {
    if (column.searchable) {
      let orderBy = _.cloneDeep(this.state.orderBy);
      if (
        orderBy !== null &&
        orderBy.fieldName === column.fieldName &&
        column.path === orderBy.path
      ) {
        if (orderBy.orderBy === ASC) {
          orderBy.orderBy = DESC;
        } else {
          orderBy.orderBy = ASC;
        }
      } else {
        orderBy = column;
        orderBy.orderBy = ASC;
      }
      this.setState({ orderBy }, () => {
        this.initData();
      });
    }
  };

  updateCheckedRow = (rowsCheckedValue) => {
    let rowsCheckedIdValue = []
    rowsCheckedValue.map(data => {
      rowsCheckedIdValue.push(data[this.props.idRow]);
    });
    this.setState({ checkedAll: true, rowsCheckedValue, rowsCheckedIdValue });
  }

  checkAllPages = () => {
    this.setState({ checkedAllPages: true });
    this.props.loading(true);
    const filter = this.getFilter();
    if (this.props.onFilter) {
      this.props.onFilter(filter);
    }
    let filterDefault = this.props.filter;

    this.props.service
      .findByFilter(
        {
          pageSize: this.state.dataTotalSize,
          page: 0,
          order: "",
          direction: ""
        },
        filter
        , filterDefault
      )
      .then(response => {
        let data = response.data.data;
        let rowsCheckedValue = [];
        let rowsCheckedIdValue = [];

        data.map(data => {
          rowsCheckedValue.push(data);
          rowsCheckedIdValue.push(data[this.props.idRow]);
        });

        this.setState(
          {
            rowsCheckedValue,
            rowsCheckedIdValue
          },
          () => {
            this.props.loading(false);
          }
        );
      },
        erros => {
          this.props.loading(false);
          console.error(erros);
        });

  };

  checkAll = () => {
    const { checkedAll, unchecked } = this.state;
    let rowsCheckedValue;
    let rowsCheckedIdValue;

    if(this.state.rowsCheckedIdValue.length === 0 && this.state.rowsCheckedValue.length === 0) {
      rowsCheckedValue = [];
      rowsCheckedIdValue = [];
    } else if(unchecked) {
      rowsCheckedValue = this.state.rowsCheckedValue;
      rowsCheckedIdValue = this.state.rowsCheckedIdValue;
      this.state.data.map(data => {
        let remocao  = rowsCheckedValue.indexOf(data);
        if(remocao !== -1) {
          rowsCheckedValue.splice(remocao, 1);
          rowsCheckedIdValue.splice(remocao, 1);
        }
      });
    }
    else {
      rowsCheckedValue = this.state.rowsCheckedValue;
      rowsCheckedIdValue = this.state.rowsCheckedIdValue;
    }

    if (checkedAll) {
      this.setState({
        checkedAll: false,
        checkedAllPages: false,
        unchecked: false,
        rowsCheckedValue,
        rowsCheckedIdValue
      });
    } else {
      this.state.data.map(data => {
        if(rowsCheckedValue.indexOf(data) === -1) {
          rowsCheckedValue.push(data);
          rowsCheckedIdValue.push(data[this.props.idRow]);
        }
      });
      this.setState({ checkedAll: true, rowsCheckedValue, rowsCheckedIdValue, unchecked: true });
    }
    
    if (this.props.name && this.props.name === 'atendimentos') {
      localStorage.setItem("rowsCheckedValue", JSON.stringify(rowsCheckedValue));
      localStorage.setItem("rowsCheckedIdValue", JSON.stringify(rowsCheckedIdValue));
    }
  };

  unCheckAll = (rowsCheckedValue) => {
    if (rowsCheckedValue) {
      let rowsCheckedIdValue = rowsCheckedValue.map(item => {
        return (item[this.props.idRow]);
      })
      this.setState({ checkedAll: false, rowsCheckedValue, rowsCheckedIdValue });

    } else {
      this.setState({ checkedAll: false, rowsCheckedValue: [], rowsCheckedIdValue: [] });
    }


  };

  columnClick = (fieldName, dataRow) => {
    if (this.props.onRowClick) {
      this.props.onRowClick(fieldName, dataRow);
    }
  };

  retornaNomeSituacao = (situacao) => {
    let nome = "";
    switch (situacao) {
      case objectsConstants.SUB_STATUS.NAO_FATURADO:
        nome = " em: Não Faturado";//Não Faturado
        break;
      case objectsConstants.SUB_STATUS.AUDITORIA:
        nome = " em: Auditoria";//Auditoria
        break;
      case objectsConstants.SUB_STATUS.PROTOCOLADAS:
        nome = " em: Protocoladas";//Protocoladas
        break;
      case objectsConstants.SUB_STATUS.PENDENTE:
        nome = " em: Pendente";//Pendente
        break;
      case objectsConstants.SUB_STATUS.PENDENCIA_RESOLVIDA:
        nome = " em: Pendência resolvida";//Pendência resolvida
        break;
      case objectsConstants.SUB_STATUS.RECEBIDA:
        nome = " em: Recebida";//Recebida
        break;
      case objectsConstants.SUB_STATUS.REMOVIDAS_DO_LOTE:
        nome = " em: Removidas do lote";//Removidas do lote 
        break;
      case objectsConstants.SUB_STATUS.FATURAMENTO:
        nome = " em: Faturamento";//Faturamento  
        break;
      case objectsConstants.SUB_STATUS.GLOSADO:
        nome = " em: Glosado";//Glosado
        break;
      case objectsConstants.SUB_STATUS.REPASSADO:
         nome = " em: Repassado";////Repassado
       break;
      default:
        nome = "";
    }

    return nome;
  };

  render() {
    const { ActionLine, lotesOpen, Sumario, ActionButton, disableSearch, disableExport, disableName, exportAtendimento, ...otherProps } = this.props;

    return (
      <React.Fragment>
        <ContentFixedOnTop floatContentTopSize={150} baseConententTopSize={!this.props.lotesOpen ? 150 : 360} idFloatContent={"ActionLine"} idBaseComponente={"TabelaTopoFlutuante"} onHandleScroll={(activeClass) => { this.setState({ activeClass }) }}></ContentFixedOnTop>
        {!disableSearch && <div className={"bloco-pesquisa text-center pt-2"}>
          {!this.state.searchOpen && (
            <Button className="btn btn-light" onClick={this.toggleSearch}>
              <span className="icon-btn-pesquisar"></span>
              Pesquisar
            </Button>
          )}
          {this.state.searchOpen && (
            <Button className="btn btn-secondary" onClick={this.toggleSearch}>
              <span className="icon-btn-fechar"></span>
              Limpar pesquisa
            </Button>
          )}
        </div>}
        <div className="corpo-pesquisa">
          <Collapse isOpen={this.state.searchOpen} mountOnEnter={true}>
            <div className="barra-pesquisa d-flex flex-column flex-sm-row">
              <div className="d-none d-sm-block icone-pesquisa blocoPontas"></div>
              <div className="d-flex flex-fill flex-column flex-sm-row justify-content-center">
                {this.state.filterSearch &&
                  this.state.filterSearch.length === 0 && (
                    <FilterDatatable
                      filter={{}}
                      listFields={this.state.listFields}
                      index={0}
                      changeFilter={this.changeFilter}
                      showAllColumnsFilter={this.props.showAllColumnsFilter}
                    />
                  )}

                {this.state.filterSearch &&
                  this.state.filterSearch.length > 0 &&
                  this.state.filterSearch.map((filter, index) => {
                    return (
                      <FilterDatatable
                        key={`${index}-${filter.filter ? filter.filter.fieldName : ""
                          }`}
                        filter={filter}
                        listFields={this.state.listFields}
                        index={index}
                        listFieldsSelect={this.state.listFieldsSelect}
                        changeFilter={this.changeFilter}
                        showAllColumnsFilter={this.props.showAllColumnsFilter}
                        parent={this.getFilter()}
                      />
                    );
                  })}
              </div>
              <div className={`adicionarPesquisa ${this.state.filterSearch.length === 2 ? "invisible" : ""}`}>
                <button
                  className="btn btn-light"
                  data-toggle="collapse"
                  onClick={this.addFilter}
                  role="button"
                  aria-expanded="false"
                  aria-controls="abrePesquisa"
                >
                  <span className="icon-btn-add"></span>
                  Adicionar campo de pesquisa
                </button>
                {/* )} */}
              </div>
            </div>
          </Collapse>
          <div className="barra-dotted">
            <div className="barra"></div>
          </div>
        </div>
        <div
          className={`${this.state.searchOpen ? "mt-0" : "mt-4"
            } action-line d-flex flex-column flex-sm-row flex-wrap justify-content-between align-items-center p-0 ${this.state.activeClass}`}
          id="ActionLine"
        >
          {ActionLine && this.state.rowsCheckedValue.length > 0 && (
            <div
              className={
                "d-flex flex-column flex-sm-row  justify-content-between align-items-center flex-wrap boxAction"
              }
            >
              <div className="labelInfoAction">
                <label className="control control--checkbox">
                  <Input
                    type={"checkbox"}
                    value={this.state.checkedAll}
                    checked={this.state.checkedAll}
                    className={`classUnchecked`}
                    onClick={this.checkAll}
                  />{" "}
                  <div className="control__indicator"></div>
                </label>
                {!this.state.checkedAllPages && (
                  <React.Fragment>
                    {this.state.rowsCheckedValue.length}{" "}
                  </React.Fragment>
                )}
                {this.state.checkedAllPages && (
                  <React.Fragment>{this.state.dataTotalSize} </React.Fragment>
                )}
                {this.props.name} selecionados
                {this.state.checkedAll &&
                  !this.state.checkedAllPages &&
                  this.state.rowsCheckedValue.length <
                  this.state.dataTotalSize && (
                    <React.Fragment>
                      {" "}
                      -
                      <a className="quebra-linha" onClick={this.checkAllPages}>
                        {" "}
                        Selecionar todos os {this.state.dataTotalSize} registros
                      </a>
                    </React.Fragment>
                  )}
              </div>
              <ActionLine
                updateCheckedRow={this.updateCheckedRow}
                rowsCheckedValue={this.state.rowsCheckedValue}
                getFilter={this.getFilter}
                checkedAllPages={this.state.checkedAllPages}
                dataTotalSize={this.state.dataTotalSize}
                updateAtendimentos={this.initData}
                initData={this.afterActionLine}
                unCheckAll={this.unCheckAll}
                {...otherProps}
              />
              <div className="w-100 p-3 bg-cinza">
                {this.state.rowsCheckedValue.length > 1 && (
                  <button type="button"
                    className="btn btn-sm btn-secondary mr-2"
                    onClick={() => {
                      this.unCheckAll();
                      if (this.props.name && this.props.name === 'atendimentos') {
                        localStorage.removeItem('rowsCheckedValue');
                        localStorage.removeItem('rowsCheckedIdValue');
                      }
                    }}
                  >Limpar todos</button>
                )}
                {this.state.rowsCheckedValue.map(valueRow => {
                  return (
                    <span className="tag">
                      <span> <b>{valueRow[this.props.keyTagItem]}</b></span>
                      <span className="pl-1">
                        <button
                          className="btn btn-icon-only"
                          onClick={() => {
                            this.onCheckedRow(false, valueRow.id, valueRow)
                          }}
                        >
                          <span className="icon-btn-fechar-filtro-red"></span>
                        </button>
                      </span>
                    </span>

                  );
                })}
              </div>
            </div>
          )}
          {(!ActionLine || this.state.rowsCheckedValue.length === 0) && (
            <React.Fragment>
              <div className="d-flex flex-column flex-sm-row flex-wrap justify-content-between align-items-center w-100 action-topo-tabela">
                {!disableName && <span className="labelInfoGrid">
                  <b>
                    {this.state.dataTotalSize} {this.props.name + this.retornaNomeSituacao(this.props.situacao)}
                  </b>
                </span>}
                <div className="d-flex flex-row justify-content-center justify-sm-content-end">
                  <button
                    type="button"
                    className="btn btn-light btDefaultGrid d-block d-md-none"
                    onClick={this.checkAll}
                  >
                    <span className="icon-btn-check-black"></span>
                    Selecionar todos
                  </button>
                  {Sumario && <Can
                    I={objectsConstants.VISUALIZAR}
                    a={objectsConstants.CRUD_LOTES_VIEW}
                    ability={this.props.permissions}
                  >
                    <button
                      type="button"
                      className="btn btn-light btDefaultGrid d-none d-lg-block"
                      onClick={() => this.props.history.push("/justificativas")}
                    >
                      <span className="icon-btn-editar"></span>
                      Justificativas de Reconsulta
                    </button>
                  </Can>}
                  {exportAtendimento && <button
                    type="button"
                    className="btn btn-light btDefaultGrid d-none d-lg-block"
                    onClick={this.exportarAtendimentosExcel}
                  >
                    <span className="icon-btn-ecxel"></span>
                    Exportar para excel
                  </button>}
                  {!disableExport && !exportAtendimento && <button
                    type="button"
                    className="btn btn-light btDefaultGrid d-none d-lg-block"
                    onClick={this.exportarExcel}
                  >
                    <span className="icon-btn-ecxel"></span>
                    Exportar para excel
                  </button>}
                  {ActionButton && (
                    <ActionButton {...otherProps} />
                  )}
                  <Can
                    I={objectsConstants.VISUALIZAR}
                    a={objectsConstants.CRUD_LOTES_VIEW}
                    ability={this.props.permissions}
                  >
                    {Sumario && !lotesOpen && (
                      <button
                        type="button"
                        className="btn btn-light btDefaultGrid "
                        onClick={this.props.toggleLotes}
                      >
                        <span className="icon-btn-lotes"></span>
                        Lotes
                      </button>
                    )}
                    {Sumario && lotesOpen && (
                      <button
                        type="button"
                        className="btn btn-secondary btDefaultGrid "
                        onClick={this.props.toggleLotes}
                      >
                        <span className="icon-btn-fechar"></span>
                        Fechar lotes
                      </button>
                    )}
                  </Can>
                </div>
              </div>
            </React.Fragment>

          )}
        </div>
        {/* INICIO Bloco BOX Lotes */}
        {Sumario && (
          <Sumario
            lotesOpen={lotesOpen}
            classe={this.state.activeClass}
            filter={this.getFilter()}
            rowsCheckedValue={this.state.rowsCheckedValue}
            unCheckAll={this.unCheckAll}
            wrappedComponentRef={c => (this.sumarioRef = c)}
            initData={this.afterActionLine}
          ></Sumario>
        )}

        {/* Fim Bloco BOX Lotes */}

        <table id="TabelaTopoFlutuante" className={"table table-bordered react-code4-table"}>
          {/*INICIO HEADER*/}
          <thead>
            <tr className={""}>
              {this.props.showCheckboxColumn && (
                <th
                  className={`headerCheck ${this.state.checkedAll ? "classChecked" : "classUnchecked"
                    }`}
                >
                  <label className="control control--checkbox">
                    <Input
                      type={"checkbox"}
                      value={this.state.checkedAll}
                      checked={this.state.checkedAll}
                      className={`classUnchecked`}
                      onClick={this.checkAll}
                    />{" "}
                    <div className="control__indicator"></div>
                  </label>
                </th>
              )}
              {this.state.columns.map((column: ColumnType, index) => {
                let classColumn = "";
                if (
                  this.props.classColumn &&
                  this.props.classHeader.length > index
                ) {
                  classColumn = this.props.classHeader[index];
                }

                return (
                  <HeaderColumn
                    key={index}
                    className={classColumn}
                    column={column}
                    title={column.fieldDescription}
                    ASC={ASC}
                    DESC={DESC}
                    onClick={this.setOrderBy}
                    orderBy={this.state.orderBy}
                  />
                );
              })}
            </tr>
          </thead>
          {/*FIM HEADER*/}
          {/*INICIO BODY*/}
          <tbody>
            {this.state.data.map(data => {
              return (
                <tr key={data.id}>
                  {this.props.showCheckboxColumn && (
                    <ColumnCheckboxAction
                      objectRow={data}
                      labelId={this.props.idRow}
                      onChange={this.onCheckedRow}
                      align={"text-center"}
                      checkedAll={this.state.checkedAll}
                      checkedAllPages={this.state.checkedAllPages}
                      rowsCheckedIdValue={this.state.rowsCheckedIdValue}
                    />
                  )}
                  {this.state.columns.map((column: ColumnType, index) => {
                    //Alterar class da primeira coluna da listagem de atendimentos da tela de solicitação de exames e da de integração dos atendimentos
                    let classColumn = ((column.fieldName !== 'inconsistencia' || !data.inconsistencia) !== false)
                      && ((column.fieldName !== 'hasExames' || data.hasExames) !== objectsConstants.EXAME_PRESCRICAO.EXAME_NAO_PRESCRITO) ? '' : 'btn-primary';
                    if (
                      this.props.classColumn &&
                      this.props.classColumn.length > index
                    ) {
                      classColumn = this.props.classColumn[index];
                    }
                    return (
                      <Column
                        key={index}
                        className={classColumn}
                        column={column}
                        filter={this.state.filterSearch}
                        title={column.fieldDescription}
                        value={_.get(data, column.fieldName)}
                        row={data}
                        labelArrayKey={column.labelArrayKey}
                        name={column.fieldName}
                        type={column.type}
                        columnClick={() =>
                          this.columnClick(column.fieldName, data)
                        }
                      />
                    );
                  })}
                </tr>
              );
            })}
            {this.state.data.length === 0 && (
              <tr>
                <td
                  colSpan={this.state.columns.length + 1}
                  className={"text-center"}
                >
                  {" "}
                  Nenhum registro encontrado
                </td>
              </tr>
            )}
          </tbody>
          {/* FIM BODY*/}
        </table>

        <div className="d-flex justify-content-between align-items-center mb-5">
          <p className="label-resultados">
            {" "}
            Exibindo {this.state.data.length} de {this.state.dataTotalSize}{" "}
            registros
          </p>
          {this.state.showPagination && (
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                this.findByFilter();
              }}
            >
              <span className="icon-btn-add"></span>
              Exibir mais
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  lotesCollapse: { toggleLotes },
  filtro: { changeFilterTable }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear(),
  toggleLotes: () => toggleLotes(),
  changeFilterTable: filter => changeFilterTable({ filtroTable: filter })
});

function mapStateToProps(state) {
  const { lotesOpen } = state.lotesCollapse;
  const { permissions } = state.authentication;
  const { filtroTable } = state.filtro;

  return {
    lotesOpen,
    permissions,
    filtroTable
  };
}

export default connect(mapStateToProps, mapDispatch, null, { withRef: true })(withRouter(Datatable));
