import { defaultService } from "./defaultService";
import { urlsConstants } from "../constants/url.constant";

export const pacoteExameService = {
    findAll,
    findTableColumns,
    findSearchableColumns,
    findByFilter,
    doSave,
    doDelete,
    findByNome,
    findByCooperado,
    doGet,
    exportToExcel
};

function findAll() {
    return defaultService.doGet(`${urlsConstants.PACOTEEXAME}list`);
}

function doGet(id) {
    return defaultService.doGet(`${urlsConstants.PACOTEEXAME}${id}`);
}

function doSave(entity: any) {
    return defaultService.doSave(urlsConstants.PACOTEEXAME, entity);
}

function doDelete(ids) {
    return defaultService.doPost(`${urlsConstants.PACOTEEXAME}delete`, ids);
}

function findByNome(input) {
    return defaultService.doGet(`${urlsConstants.PACOTEEXAME}findByNome/${input}`);
}

function findByCooperado(parent) {
    return defaultService.doPost(`${urlsConstants.PACOTEEXAME}findByCooperado`, parent);
}

function findTableColumns() {
    return defaultService.doGet(`${urlsConstants.PACOTEEXAME}list/fields`);
}

function findSearchableColumns() {
    return defaultService.doGet(`${urlsConstants.PACOTEEXAME}search/fields`);
}

function findByFilter(options: Options, filter: any): Promise<Array<>> {
    return defaultService.findByFiltro(
        `${urlsConstants.PACOTEEXAME}list`,
        options,
        filter
    );
}

function exportToExcel(filter, fields, labels) {
    return defaultService.exportToExcel('pacoteexame', filter, fields, labels);
}