import React, { PureComponent } from "react";
import { objectsConstants } from "../../constants/objects.constants";
import iconeAuditoria from "../../img/icon_auditoria_BLACK.svg";

export default class SumarioAuditoria extends PureComponent<
  Props,
  State
> {
  static defaultProps = {
    sumario: objectsConstants.SUMARIO_REPASSE
  }
  constructor(props) {
    super(props);
    this.state = { totalPorStatus: [] };
  }


  render() {
    const { totalPorStatus } = this.props;
    return (
      <React.Fragment>
        {/* Início Auditoria */}
        <div className="row box-branco-repassados mt-2">
          <div className="col-8 offset-2 col-sm-3 offset-sm-0 d-flex flex-column px-4 pb-3 pb-sm-0">
            <div className="pt-3 pt-sm-0 pb-3"><img src={iconeAuditoria} alt="icone" width="80px"></img></div>
            <div className="fz-sm-16">Total de guias em auditoria</div>
          </div>
          <div className="col-12 col-sm-5 border-left fz-sm-11  px-4">
            {totalPorStatus && (
              totalPorStatus.map((status, index) => {
                if (index <= (totalPorStatus.length / 2)) {
                  return (<div className="row mb-4 text-left">
                    <div className="col-8 col-sm-9">{status.status}</div>
                    <div className="col-4 col-sm-3 font-bold">{status.total}</div>
                  </div>)
                }
              })
            )}


          </div>
          <div className="d-block d-sm-none line"></div>
          <div className="col-12 col-sm-4 border-left fz-sm-11 px-4 ">
            {totalPorStatus && (
              totalPorStatus.map((status, index) => {
                if (index > (totalPorStatus.length / 2)) {
                  return (<div className="row mb-4 text-left">
                    <div className="col-8 col-sm-9">{status.status}</div>
                    <div className="col-4 col-sm-3 font-bold">{status.total}</div>
                  </div>)
                }
              })
            )}
          </div>
        </div>
        {/* Fim Auditoria */}
      </React.Fragment>
    );
  }
}
