import React, { PureComponent } from 'react';
import toastIconSucess from './img/icon_check.svg';

export default class IconSucessToast extends PureComponent {
  render() {
    return (
      <div className="contentAvatarToastr">
        <img src={toastIconSucess} alt="avatar" className="icon_sucess" />
      </div>
    );
  }
}
