import React, { PureComponent } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { urlsConstants } from "../../constants/url.constant";
import { formatterHelper } from "../../helpers/formatter.helper";
import anexo from "../../img/icon_anexo_BIG.svg";
import { defaultService } from "../../services/defaultService";


let download = require("downloadjs");

type Props = {
  target?: any,
  itens: []
};

const modifiers = {
  preventOverflow: {
    enabled: false
  },
  flip: {
    enabled: false
  }
};


export class ModalVerAnexos extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    //this.loadFile = this.loadFile.bind(this);

  }


  downLoadFile = (anexo: any) => {
    if (anexo.id) {
      let url = urlsConstants.MIDIA + 'vo/' + anexo.id;
      defaultService.doGet(url).then(result => {
        let dataFile = result.data;
        if (dataFile) {
          let uri = `data:${dataFile.contentType};base64,${dataFile.data}`;
          if (dataFile.data.includes('base64,')) {
            uri = dataFile.data;
          }

          download(uri, dataFile.nome, dataFile.contentType);
        }
      });
    }
    return false;
  };

  render() {

    return (
      <React.Fragment>

        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          backdrop="static"
          modalClassName=""
          className="cinza"
          centered={true}
          modifiers={modifiers}
          target={this.props.target}
        >


          <ModalBody>
            <div className="modalCooperado">
              <div className="row">
                <div className="col-12 text-center ">
                  <img src={anexo} alt="Anexo" />
                </div>
                <div className="col-12 text-center my-2">

                  <h2 className="font-weight-bold">Anexos</h2>
                </div>
              </div>


              <div className="row">
                {this.props.itens && this.props.itens.map((anexo, i) => {
                  return (
                    <div
                      className="px-3 w-100 d-flex flex-row lineFile"
                      id={anexo.nome}
                      key={anexo.id ? anexo.id : i}
                    >

                      <div
                        className="font-italic font-12px cut-text flex-fill"
                        onClick={() => { this.downLoadFile(anexo) }}
                      >
                        {anexo.nome}{" "}
                      </div>
                      <div className="font-10px">
                        {formatterHelper.getFileSize(anexo.tamanho)}
                      </div>

                    </div>
                  );
                })}
              </div>

            </div>
          </ModalBody>
          <ModalFooter>
            <div className="w-100 text-center">
              <button
                className="btn-secondary"
                onClick={() => {
                  this.props.toogle();
                }}
              > Fechar </button>
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}



