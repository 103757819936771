//@flow
import { Formik } from "formik";
import _ from "lodash";
import * as React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import connect from "react-redux/es/connect/connect";
import TopoTitleComponente from "../../home/TopoTitleComponente";
import SolicitacaoExameForm from "./SolicitacaoExameForm";
import SolicitacaoExameTopoCrud from "./SolicitacaoExameTopoCrud";

class SolicitacaoExameEdit extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {
            entity: {}
        };
    }

    handleChange = (name, value) => {
        const entity = _.cloneDeep(this.formRef.state.values);
        _.set(entity, name, value);
        this.setState({ entity });
    };

    render() {
        let _this = this;
        const { entity } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid container-com-topo">
                    <div
                        className={`action-line ${this.state.activeClass} fixed-container-topo`}
                    >
                        <TopoTitleComponente
                            mainTitle={`Nova solicitação de exame`}
                            canBack={false}
                        />
                        <SolicitacaoExameTopoCrud
                            values={entity}
                            onChange={this.handleChange}
                        ></SolicitacaoExameTopoCrud>
                    </div>
                    <div className={`contentComTopoFix pt-4`}>
                        <Formik
                            validateOnBlur={false}
                            validateOnChange={false}
                            enableReinitialize={true}
                            initialValues={_this.state.entity}
                            onSubmit={(values, actions) => {}}
                            ref={form => {
                                this.formRef = form;
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                validationSchema,
                                validateForm,
                                setValues
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <React.Fragment>
                                        <SolicitacaoExameForm
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                            handleChange={handleChange}
                                            touched={touched}
                                            validateForm
                                            setValues={setValues}
                                        ></SolicitacaoExameForm>
                                    </React.Fragment>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatch = ({
    alert: { success, error, clear },
    load: { loading },
    authentication: { doRefresh }


}) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear(),
    doRefresh: () => doRefresh()
});

function mapStateToProps(state) {
    const { user } = state.authentication;
    return {
        user
    };
}
export default connect(mapStateToProps, mapDispatch, null, { withRef: true })(
    SolicitacaoExameEdit
);
