import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Switch, withRouter } from "react-router-dom";
import Toast from "../../components/Toasts/Toast";
import AuthorizedRoute from "../../components/templates/AuthorizedRoute";
import { objectsConstants } from "../../constants/objects.constants";
import IntegracaoAtendimentos from "../IntegraçãoAtendimentos/IntegracaoAtendimentos";
import Atendimento from "../atendimento/Atendimento";
import Lotes from "../atendimento/lotes/Lotes";
import Beneficiario from "../beneficiarios/Beneficiario";
import Consultorios from "../consultorios/Consultorios";
import Convenios from "../convenios/Convenios";
import Cooperado from "../cooperados/Cooperado";
import CooperadoSudo from "../cooperados/cooperadoSudo/CooperadoSudo";
import Cooperativa from "../cooperativa/Cooperativa";
import ErrorImportacaoNotification from "../errorImportacaoNotification/ErrorImportacaoNotification";
import Exame from "../exames/Exame";
import Avisos from "../avisos/Avisos";
import PacoteExame from "../exames/pacote/PacoteExame";
import SolicitacaoExame from "../exames/solicitacao/SolicitacaoExame";
import Guias from "../guias/Guias";
import Redirect from "../guias/componentes/Redirect";
import Justificativas from "../justificativas/Justificativas";
import Mensagem from "../mensagem/Mensagem";
import Menu from "../menu/Menu";
import NotificacoesConsultorios from "../notificacoesConsultorios/NotificacoesConsultorios";
import Notification from "../notification/Notification";
import Perfil from "../perfil/Perfil";
import Relatorios from "../relatorios/Relatorios";
import Usuario from "../usuarios/Usuario";
import Topo from "./Topo";
import DescricoesPendencia from "../descricaoPendencia/DescricoesPendencia";
class Home extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={`${this.props.showMenu ? "" : ""}`}>
        {/* cabecalho */}
        <Toast />
        <Topo></Topo>
        <Menu></Menu>
        <Notification></Notification>
        <ErrorImportacaoNotification></ErrorImportacaoNotification>
        <Switch>
          <AuthorizedRoute
            path="/usuario"
            component={Usuario}
            I={objectsConstants.VISUALIZAR}
            a={objectsConstants.CRUDUSUARIO}
          />

          <AuthorizedRoute
            path="/perfil"
            component={Perfil}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_PERFIL}
          />

          <AuthorizedRoute
            path="/cooperados"
            component={Cooperado}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_COOPERADOS}
          />

          <AuthorizedRoute
            path="/cooperadosSudo"
            component={CooperadoSudo}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_COOPERADOS}
          />
          <AuthorizedRoute
            path={"/relatorios"}
            component={Relatorios}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_CONVENIOS}
          />
          <AuthorizedRoute
            path="/cooperativa"
            component={Cooperativa}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_COOPERATIVAS}
          />

          <AuthorizedRoute
            path="/exame"
            component={Exame}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_EXAME}
          />

          <AuthorizedRoute
            path="/solicitaexame"
            component={SolicitacaoExame}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_EXAME}
          />

          <AuthorizedRoute
            path="/mensagem"
            component={Mensagem}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_EXAME}
          />

          <AuthorizedRoute
            path="/pacoteexame"
            component={PacoteExame}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_CONVENIOS}
          />

          <AuthorizedRoute
            path="/atendimento"
            component={Atendimento}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_ATENDIMENTOS}
          />

          <AuthorizedRoute
            path="/guias"
            component={Guias}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.CRUD_GUIAS}
          />
          <AuthorizedRoute
            path="/lotes"
            component={Lotes}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.CRUD_GUIAS}
          />
          <AuthorizedRoute
            path="/convenios"
            component={Convenios}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_CONVENIOS}
          />
          <AuthorizedRoute
            path="/integracaoAtendimento"
            component={IntegracaoAtendimentos}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.CRUD_GUIAS}
          />

          <AuthorizedRoute
            path="/consultorios"
            component={Consultorios}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_CONSULTORIOS}
          />

          <AuthorizedRoute
            path="/descricoesPendencia"
            component={DescricoesPendencia}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_CONSULTORIOS}
          />

          <AuthorizedRoute
            path="/justificativas"
            component={Justificativas}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_CONSULTORIOS}
          />

          <AuthorizedRoute
            path="/visualizarPendencia"
            component={Redirect}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.CRUD_GUIAS}
          />
          <AuthorizedRoute
            path="/visualizarLote"
            component={Redirect}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.CRUD_GUIAS}
          />
          <AuthorizedRoute
            path="/notificacoesConsultorios"
            component={NotificacoesConsultorios}
            I={objectsConstants.VISUALIZAR}
            a={objectsConstants.BTN_NOTIFICACOES_CONSULTORIOS}
          />
          <AuthorizedRoute
            path="/beneficiarios"
            component={Beneficiario}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_USUARIOS}
          />
          <AuthorizedRoute
            path="/avisos"
            component={Avisos}
            I={objectsConstants.EXECUTAR}
            a={objectsConstants.BTN_AVISOS}
          />
        </Switch>
        <a href="https://wa.me/553132325050" target="blank" className="dwhats"></a>
        <footer>
          <div className="background-lines">
            <div className="bg-riscos bottom"></div>

          </div>
          <a href={"https://fencom.coop.br/sasc-connect/"} target="blank" >Passo a passo para abertura do chamado pelo WhatsApp do Sistema Sasc Connect.</a>
          <p>© {new Date().getFullYear()} Sasc Conect - Fencom</p>
        </footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { alert } = state;
  const { showMenu } = state.menu;

  return {
    loggedIn,
    alert,
    showMenu
  };
}

const mapDispatch = ({
  alert: { error, clear, success },
  authentication: { login },
  load: { loading }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: msg => success(msg),
  login: (user, password) => login({ user, password }),
  loading: (load: boolean) => loading({ load })
});
export default connect(mapStateToProps, mapDispatch)(withRouter(Home));
