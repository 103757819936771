import { Can } from "@casl/react";
import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import BtnLink from "../../../components/Buttons/BtnLink";
import CheckBoxInput from "../../../components/Inputs/CheckBoxInput";
import { objectsConstants } from "../../../constants/objects.constants";
import { OptionAuditor } from "../../../sascComponents/auditor/OptionAuditor";
import { OptionCooperado } from "../../../sascComponents/cooperado/OptionCooperado";
import { pendenciaService } from "../../../services/pendencia.service";
import { userService } from "../../../services/user.service";
import {cooperadoService} from "../../../services/cooperado.service";
import {atendimentoService} from "../../../services/atendimento.service";

class MenuNotificar extends PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = { auditores: [], perfis: [], cooperados: [], atendimentoPendencia:[] };
  }

  componentDidUpdate() {

    // this.setState({ cooperados:  });

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.campoAtual.id !== this.props.campoAtual) {
      // this.resetForm();
    }
  }

  resetForm = () => {
    this.loadAllProfiles();
  };

  onConfirm = () => {
    let atendimentosPendenciaNotificacao = [];
    let perfis = _.filter(this.state.perfis, { checked: true });
    for (let indexPerfil in perfis) {
      let perfil = perfis[indexPerfil];
      let atendimentoPendenciaNotificacao = { tipoPerfil: perfil.tipoPerfil };

      if (perfil.tipoPerfil === objectsConstants.COOPERADO) {
        const cooperados = _.filter(this.state.cooperados, { checked: true });
        if(cooperados.length === 0 ){
          const allCooperados = this.state.cooperados;
          for (let indexCooperado in allCooperados) {
            let cooperado = allCooperados[indexCooperado];
            let atendimentoPendenciaNotificacaoCooperado = _.cloneDeep(
                atendimentoPendenciaNotificacao
            );
            atendimentoPendenciaNotificacaoCooperado.usuario = cooperado;
            atendimentosPendenciaNotificacao.push(
                atendimentoPendenciaNotificacaoCooperado
            );
          }
        }else{
          if (cooperados.length > 0) {
            for (let indexCooperado in cooperados) {
              let cooperado = cooperados[indexCooperado];
              let atendimentoPendenciaNotificacaoCooperado = _.cloneDeep(
                  atendimentoPendenciaNotificacao
              );
              atendimentoPendenciaNotificacaoCooperado.usuario = cooperado;
              atendimentosPendenciaNotificacao.push(
                  atendimentoPendenciaNotificacaoCooperado
              );
            }
          }
        }

      } else if (perfil.tipoPerfil === objectsConstants.AUDITOR) {
        const auditores = _.filter(this.state.auditores, { checked: true });
        if (auditores.length > 0) {
          for (let indexAuditor in auditores) {
            let auditor = auditores[indexAuditor];
            let atendimentoPendenciaNotificacaoAuditor = _.cloneDeep(
                atendimentoPendenciaNotificacao
            );
            atendimentoPendenciaNotificacaoAuditor.usuario = auditor;
            atendimentosPendenciaNotificacao.push(
                atendimentoPendenciaNotificacaoAuditor
            );
          }
        } else {
          atendimentosPendenciaNotificacao.push(
              atendimentoPendenciaNotificacao
          );
        }
      } else {
        atendimentosPendenciaNotificacao.push(atendimentoPendenciaNotificacao);
      }
    }

    this.props.setFieldValue(
        "atendimentoPendenciaNotificacao",
        atendimentosPendenciaNotificacao
    );
    this.props.toggleNotificacaoPendencia();
  };

  reenviarNotificacao = () => {
    this.props.loading(true);
    pendenciaService.reenviarNotificacao(this.props.pendenciaId).then(
        response => {
          this.props.success({ message: "Notificação reenviada com sucesso!" });
          this.props.toggleNotificacaoPendencia();
          this.props.loading(false);

        },
        () => {
          this.props.loading(false);

          this.props.error({
            message: "Não foi possível reenviar notificação."
          });
        }
    );
  };

  componentDidMount() {
    this.loadAllProfiles();
  }

  loadAllProfiles = () => {
    userService.getAllProfiles().then(response => {
      userService
          .findByPerfil(objectsConstants.AUDITOR)
          .then(responseAuditor => {
            let perfis = response.data;
            for (let index = 0; index < perfis.length; index++) {
              let perfil = perfis[index];
              let hasProfile =
                  _.filter(this.props.value, {
                    tipoPerfil: perfil.tipoPerfil
                  }).length > 0;
              perfil.checked = hasProfile;
            }
            this.setState({
              perfis,
              auditores: responseAuditor.data
            });
          });
    });
    atendimentoService.findByIds([this.props.idAtendimentoAtual]).then((response) => {
          let arrayAtendimentos = response.data;
          this.setState({
            atendimentoPendencia: arrayAtendimentos
          });
          this.props.loading(false);
          this.state.atendimentoPendencia.map((atendimento, index) => {
            this.setState({
              cooperados: atendimento.identificacaoAtendimentoExecucao
            })
          })
        }
    )
  };

  usuarioEstaMarcadoNoPerfil = (usuario, tipoPerfil) => {
    for (let index = 0; index < _.size(this.props.value); index++) {
      let valueItem = this.props.value[index];
      if (
          valueItem.usuario &&
          valueItem.usuario.id === usuario.id &&
          valueItem.tipoPerfil === tipoPerfil
      ) {
        return true;
      }
    }
    return false;
  };

  handleChange = (name, value) => {
    let state = _.cloneDeep(this.state);
    _.set(state, name, value);
    this.setState(state);
  };

  render() {
    return (
        <div
            className={`menu-notificar ${
                this.props.openMenuNotificacao ? "aberto" : ""
            }`}
        >
          <div className="topo-notificar text-center py-3 d-flex align-items-center justify-content-between">
            <BtnLink
                type={"button"}
                className={""}
                icone={""}
                title={"Fechar"}
                onSubmit={this.props.toggleNotificacaoPendencia}
                disabled={false}
            />
            <h1 className="font-bold">
              <span className="icon-btn-notificacao"></span>Notificar
            </h1>
            <span className="float-left font-12px acerto-p-m invisible">
            Fechar
          </span>
          </div>

          <div
              className={
                "d-flex flex-column justify-content-center align-items-center py-4 bg-color4"
              }
          >
            <div className={"text-center"}>
              <h2>Pendência</h2>{this.props.campoAtual.label}
            </div>
          </div>
          <p className={"descricao"}>
            Selecione abaixo todos os perfis de usuário que precisam ser
            notificados com está pendência
          </p>
          <hr className={"quebra-linha"} />
          <div className={"box-list-perfil"}>
            <div className={"row"}>
              {this.state.perfis.map((perfil, index) => {
                return (
                    <div className={"col-6"} key={index}>
                      <CheckBoxInput
                          label={perfil.nome}
                          name={`perfis[${index}].checked`}
                          onChange={this.handleChange}
                          labelClass={"label-checkbox"}
                          checked={perfil.checked}
                          style={"mb-0"}
                          disabled={this.props.viewMode}
                      />
                    </div>
                );
              })}
            </div>
          </div>
          <hr className={"quebra-linha"} />
          <div className={"box-list-usuario"}>
            {_.filter(this.state.perfis, {
              checked: true,
              tipoPerfil: objectsConstants.COOPERADO
            }).length > 0 && (
                <React.Fragment>
                  <h5>Cooperados</h5>
                  <div className={"list-usuarios"}>
                    {this.state.cooperados.map((cooperado, index) => {

                      return (
                          <div className={"list-usuario-item d-inline-flex"}>
                            <CheckBoxInput
                                labelClass={"label-checkbox"}
                                className={"d-inline-block"}
                                style={"mb-0"}
                                name={`cooperados[${index}].checked`}
                                onChange={this.handleChange}
                                checked={this.usuarioEstaMarcadoNoPerfil(
                                    cooperado.cooperado,
                                    objectsConstants.COOPERADO
                                )}
                                disabled={this.props.viewMode}
                            />
                            <OptionCooperado data={cooperado.cooperado} />
                          </div>
                      );
                    })}
                  </div>
                </React.Fragment>
            )}
            {_.filter(this.state.perfis, {
              checked: true,
              tipoPerfil: objectsConstants.AUDITOR
            }).length > 0 && (
                <React.Fragment>
                  <h5 className={"pt-2"}>Auditores</h5>
                  <div className={"list-usuarios"}>
                    {this.state.auditores.map((auditor, index) => {
                      return (
                          <div className={"list-usuario-item d-inline-flex"}>
                            <CheckBoxInput
                                labelClass={"label-checkbox"}
                                style={"mb-0"}
                                className={"d-inline-block"}
                                name={`auditores[${index}].checked`}
                                onChange={this.handleChange}
                                checked={this.usuarioEstaMarcadoNoPerfil(
                                    auditor,
                                    objectsConstants.AUDITOR
                                )}
                                disabled={this.props.viewMode}
                            />
                            <OptionAuditor data={auditor} />
                          </div>
                      );
                    })}
                  </div>
                </React.Fragment>
            )}
          </div>
          <hr className={"quebra-linha"} />
          {!this.props.viewMode && (
              <div className={"text-center"}>
                <button
                    className="btn btn-primary white mt-3 mb-4 mx-2"
                    onClick={() => {
                      this.onConfirm();
                    }}
                >
                  ok
                </button>
              </div>
          )}
          {this.props.viewMode && (
              <Can
                  I={objectsConstants.ALTERAR}
                  a={objectsConstants.CRUD_PENDENCIA_CRIAR}
                  ability={this.props.permissions}
              >
                <div className={"text-center"}>
                  <button
                      className="btn btn-primary white mt-3 mb-4 mx-2"
                      onClick={() => {
                        this.reenviarNotificacao();
                      }}
                  >
                    Reenviar notifição
                  </button>
                </div>
              </Can>
          )}
        </div>
    );
  }
}

const mapDispatch = ({ alert: { success, error }, load: { loading } }) => ({
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  success: msg => success(msg)
});

function mapStateToProps(state) {
  const { cooperados} = state.pendencia;
  const {idAtendimentoAtual} = state.selectableInput;
  const { permissions } = state.authentication;

  return {
    cooperados,
    idAtendimentoAtual,
    permissions
  };
}

export default connect(mapStateToProps, mapDispatch)(MenuNotificar);
