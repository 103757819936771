import { Formik } from "formik";
import _ from "lodash";
import * as React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import connect from "react-redux/es/connect/connect";
import * as Yup from "yup";
import { translate } from "../../helpers/message.helper";
import { defaultService } from "../../services/defaultService";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import moment from 'moment';
import { Card, CardBody, CardHeader } from "reactstrap";
import { FormGroup } from "../../components/FormGroup";
import DatePickerInput from "../../components/Inputs/DatePickerInput";
import FormInput from "../../components/Inputs/FormInput";
import FormTextCKEditor from "../../components/Inputs/FormTextCKEditor";
import InputViewEdit from "../../components/Inputs/InputViewEdit";
import SelectInput from "../../components/Inputs/SelectInput";
import { objectsConstants } from "../../constants/objects.constants";
import { dateHelper } from "../../helpers/date.helper";
import EspecialidadesSelectInput from "../../sascComponents/especialidades/EspecialidadesSelectInput";
import EstabelecimentoSelectInput from "../../sascComponents/estabelecimento/EstabelecimentoSelectInput";
import SelectableInput from "../../sascComponents/inputs/SelectableInput";
import { notificacaoService } from "../../services/notificacao.service";
import NotificacaoTopoCrud from "./NotificacaoTopoCrud";
const NewNotificacaoValidator = Yup.object().shape({
  titulo: Yup.string()
    .min(10, "Nome muito curto!")
    .max(250, "Nome muito grande!")
    .required("Obrigatório"),
  tipoAviso: Yup.string().nullable().required("Obrigatório"),
  descricao: Yup.string()
    .required("Obrigatório")
      .max(600, "Quantidade de caracteres excedeu o limite permitido (350)"),
  dataHoraFim: Yup.string().nullable().test('dataHoraFim', 'Data fim anterior a data inicio da notificação.', function () {
    const { parent, createError } = this;
    let dataHoraFim = parent.dataHoraFim;
    let dataHoraInicio = parent.dataHoraInicio;

    if (dataHoraFim&&dataHoraInicio && moment(dataHoraInicio).isAfter(dataHoraFim)) {
      return createError();
    } else {
      return true;
    }
  }),
  estabelecimento: Yup.string().nullable().test('estabelecimento', 'Obrigatório', function () {
    const { parent, createError } = this;

    if (!parent.estabelecimento && parent.tipoAviso === objectsConstants.NOTIFICACAO_CONSULTORIO) {
      return createError();
    } else {
      return true;
    }
  }),
});
class NotificacaoEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: {
        status: { name: "ATIVO" },
        usuarios: [{ idKey: new Date().getTime() }],
      },
      filter : {}
    };
  }
  
  goToViewUser = values => {
    this.props.history.push({
      pathname: "/notificacoesConsultorios/view/" + values.id,
      state: { entity: values }
    });
  };

  handleCancel = () => {
    this.props.history.goBack();
  };


  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      notificacaoService.doGet(this.props.match.params.id).then(
        response => {
          let user = response.data.data;
          this.setState({ entity: user }, () => {
            this.props.loading(false);
          });
        },
        error => {
          console.error(error);
          this.props.loading(false);
        }
      );
    }
  }

  handleChange = (name, value) => {
    const entity = _.cloneDeep(this.formRef.state.values);
    if (userService.isNotificacao(entity)) {
      _.set(entity.secretaria, name, value);
    } else {
      _.set(entity, name, value);
    }

    this.setState({ entity });
  };

  handleSubmit = e => {
    this.formRef.validateForm(this.formRef.values).then(erros => {
      console.error(erros);
      if (_.isEmpty(erros)) {
        this.formRef.handleSubmit();
      }
    });
  };

  getServiceDoSave = () => {
    let entity;
    if (this.formRef) {
      entity = _.cloneDeep(this.formRef.state.values);
    } else {
      entity = this.props.location && this.props.location.state.entity;
    }

    if (userService.isNotificacao(entity)) {
      return notificacaoService;
    }
    return userService;
  };

  render() {
    let _this = this;
    const { entity, viewMode } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          <div
            className={`action-line ${this.state.activeClass} fixed-container-topo`}
          >
            <TopoTitleComponente
              mainTitle={`${entity.id ? "Editar " : "Nova "}  Notificação ${
                entity.usuarioPerfis
                  ? " - " + userService.getPerfilAtual(entity)
                  : ""
              }`}
              canBack={true}
              backUrl={!entity.id ? null : "/notificacoesConsultorios"}
            />
            <NotificacaoTopoCrud
              values={entity}
              onChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              handleCancel={this.handleCancel}
            ></NotificacaoTopoCrud>
          </div>
          <div className={`contentComTopoFix pt-4`}>
            <Formik
              validationSchema={NewNotificacaoValidator}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
              initialValues={_this.state.entity}
              onSubmit={(values, actions) => {
                this.props.loading(true);
                notificacaoService.doSave(values).then(
                  response => {
                    this.props.success({
                      message: `Notificação ${values.titulo}, foi ${
                        !values.id ? "criado" : "alterado"
                      } com sucesso!`
                    });
                    let id = values.id
                      ? values.id
                      : defaultService.getIdFromUrl(response.headers.location);

                    values.id = id;
                    _this.goToViewUser(values);
                    this.props.loading(false);
                  },
                  erros => {
                    console.error(erros.response);
                    this.props.error({
                      message:
                        "Não foi possível criar Notificação, existem erros no formulário!"
                    });
                    try {
                      let response = erros.response.data;
                      if (response && response.messages) {
                        for (var i = 0; i < response.messages.length; i++) {

                          let erroItem = response.messages[i];
                          actions.setFieldError(
                            erroItem.fieldName,
                            translate(erroItem.message.code)
                          );
                        }
                      }
                    } catch (error) {
                      console.error(error);
                    }

                    this.props.loading(false);
                actions.setSubmitting(false);
                  }
                );
               
              }}
              ref={form => {
                this.formRef = form;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                validationSchema,
                validateForm,
                setValues
              }) => (
                <form onSubmit={handleSubmit}>
                  <React.Fragment>
                  <div className="item">
                    <Card className="cardCrud content-form">
                      <CardHeader>
                        <div className="d-flex justify-content-between align-items-center">

                          <div className="px-sm-3">
                            {viewMode && (
                              <button
                                type="button"
                                className="btn btn-light"
                                onClick={this.goToEdit}
                              >
                                <span className="icon-btn-editar"></span>Editar
                              </button>
                            )}
                          </div>
                        </div>
                      </CardHeader>

                      <CardBody className={"form-inside viewMode"}>
                        <div className="row section-form">
                          <h6 className="w-100">Título e Descrição</h6>
                        </div>
                        <div className="row section-form">
                          <FormGroup className="">
                            <InputViewEdit
                                component={FormInput}
                                label={"Título"}
                                type="text"
                                id="titulo"
                                name="titulo"
                                maxLength={250}
                                placeholder="Digite o titulo da notificação"
                                value={values.titulo}
                                onChange={(name, value) => setFieldValue(name, value)}
                                noSize={true}
                                erroMensagem={errors.titulo}
                                viewMode={false}
                                required={true}
                            />
                          </FormGroup>
                          <div className="quebra"></div>
                          <FormGroup className="">  
                            {/*<InputViewEdit
                                component={FormInput}
                                label={"Descrição"}
                                maxLength={1000}
                                type="text"
                                id="descricao"
                                name="descricao"
                                placeholder="Digite a descrição da notificação"
                                value={values.descricao}
                                onChange={(name, value) => setFieldValue(name, value)}
                                noSize={true}
                                erroMensagem={errors.descricao}
                                viewMode={false}
                                required={true}
                            />*/}
                            <InputViewEdit
                              label={"Descrição"}
                              component={FormTextCKEditor}
                              maxLength={300}
                              onChange={(name, value) => {
                                  setFieldValue(name, value);
                              }}
                              viewMode={this.state.viewMode}
                              required={false}
                              name={`descricao`}
                              placeholder="Digite a descrição da notificação"
                              type={"text"}
                              value={values.descricao
                              }
                              clearable={true}
                              defaultValue={<div
                                  dangerouslySetInnerHTML={{__html: values.descricao ? values.descricao : ''}}></div>}
                              id={`descricao`}
                              erroMensagem={
                                  _.get(errors, `descricao`)
                              }
                          />

                                                    
                          </FormGroup>
                          </div>
                          </CardBody>

                          <CardBody className={"form-inside viewMode"}>
                          <div className="row section-form">
                            <h6 className="w-100">Tipo de notificação</h6>
                          </div>
                          <div className="row section-form">
                          
                          <FormGroup className="">  
                              <SelectInput
                                placeholder="Escolha uma opção"
                                label={'Tipo de notificação'}
                                name={'tipoAviso'}
                                options={[{ label: 'Consultorio', value: objectsConstants.NOTIFICACAO_CONSULTORIO }, { label: 'Cooperado', value: objectsConstants.NOTIFICACAO_COOPERADO }]}
                                value={values.tipoAviso}
                                returnFullObject={false}
                                onChange={(name, date) => {
                                  setFieldValue(name, date);
                                  if (date === objectsConstants.NOTIFICACAO_COOPERADO) {
                                    setFieldValue('estabelecimento', null);
                                  }
                                  if (date === objectsConstants.NOTIFICACAO_CONSULTORIO) {
                                    setFieldValue('sexo', null);
                                    setFieldValue('especialidade', null);
                                  }
                                }}
                                labelKey={'label'}
                                valueKey={'value'}
                                required={true}
                                erroMensagem={errors.tipoAviso} />
                          </FormGroup>                   
                          </div>
                        </CardBody>

                        {(values.tipoAviso !== null && <CardBody className={"form-inside viewMode"}>
                          <div className="row section-form">
                            <h6 className="w-100">Datas e Filtro</h6>
                          </div>
                          <div className="row section-form">

                          <FormGroup className="">  
                            <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
                              name={"dataHoraInicio"}
                              label={"Data Início Notificação"}
                              value={values.dataHoraInicio}
                            >
                              <InputViewEdit
                                label="Data Início Notificação"
                                component={DatePickerInput}
                                onChange={(name, date) => {
                                  setFieldValue(name, date);
                                }}
                                defaultValue={
                                  values.dataHoraInicio
                                    ? dateHelper.format(values.dataHoraInicio, {
                                      mode: "DATE"
                                    })
                                    : "--"
                                }
                                mask={"99/99/9999"}
                                name={"dataHoraInicio"}
                                id={"dataHoraInicio"}
                                placeholder="Data Início Notificação"
                                viewMode={viewMode}
                                type={"text"}
                                value={values.dataHoraInicio}
                                erroMensagem={errors.dataHoraInicio}
                                required={false}
                              />
                            </SelectableInput>
                            </FormGroup>
                            <FormGroup className="">  
                            <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
                              name={"dataHoraFim"}
                              label={"Data Fim Notificação"}
                              value={values.dataHoraFim}
                            >
                              <InputViewEdit
                                label="Data Fim Notificação"
                                component={DatePickerInput}
                                onChange={(name, date) => {
                                  setFieldValue(name, date);
                                }}
                                defaultValue={
                                  values.dataHoraFim
                                    ? dateHelper.format(values.dataHoraFim, {
                                      mode: "DATE"
                                    })
                                    : "--"
                                }
                                mask={"99/99/9999"}
                                name={"dataHoraFim"}
                                id={"dataHoraFim"}
                                placeholder="Data Fim Notificação"
                                viewMode={viewMode}
                                type={"text"}
                                value={values.dataHoraFim}
                                erroMensagem={errors.dataHoraFim}
                                required={false}
                              />
                            </SelectableInput>
                          </FormGroup>
                          
                            {values.tipoAviso === objectsConstants.NOTIFICACAO_CONSULTORIO && <FormGroup className="">
                              <InputViewEdit
                                component={EstabelecimentoSelectInput}
                                label={"Estabelecimento"}
                                name="estabelecimento"
                                id="estabelecimento"
                                multi={true}
                                isMulti={true}
                                placeholder="Selecione o estabelecimento"
                                onChange={(name, value) => {
                                  setFieldValue(name, value);
                                }}
                                returnFullObject={true}
                                value={values.estabelecimento}
                                className="sasc-select"
                                required={values.tipoAviso === objectsConstants.NOTIFICACAO_CONSULTORIO}
                                erroMensagem={errors.estabelecimento}
                                viewMode={viewMode}
                                parent={objectsConstants.NOTIFICACAO_CONSULTORIO}
                                defaultValue={
                                  viewMode &&
                                  values.estabelecimento &&
                                  values.estabelecimento.map(item => item.nome).join(", ")
                                }
                              ></InputViewEdit>
                            </FormGroup>}
                            {values.tipoAviso === objectsConstants.NOTIFICACAO_COOPERADO && <><FormGroup className="">
                              <InputViewEdit
                                component={EspecialidadesSelectInput}
                                label={"Especialidades"}
                                name="especialidade"
                                id="especialidade"
                                multi={true}
                                placeholder="Selecione as especialidades"
                                onChange={(name, value) => {
                                  setFieldValue(name, value);
                                }}
                                returnFullObject={true}
                                value={values.especialidade}
                                className="sasc-select"
                              ></InputViewEdit>
                            </FormGroup>
                              <FormGroup className="">
                                <SelectInput
                                  placeholder="Escolha o sexo"
                                  label={'Sexo'}
                                  name={'sexo'}
                                  options={objectsConstants.ENUM_SEXO}
                                  value={values.sexo}
                                  returnFullObject={false}
                                  onChange={(name, date) => {
                                    setFieldValue(name, date);
                                  }}
                                  labelKey={'label'}
                                  valueKey={'value'} />
                              </FormGroup></>}               
                          </div>
                        </CardBody>)}
                      </Card>
                    </div>
                    {/*<NotificacaoForm
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      handleChange={handleChange}
                      touched={touched}
                      validateForm
                      setValues
                    ></NotificacaoForm>*/}
                  </React.Fragment>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading } ,
  authentication: { doRefresh }


}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear(),
  doRefresh:() => doRefresh()
});

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}
export default connect(mapStateToProps, mapDispatch, null, { withRef: true })(
  NotificacaoEdit
);