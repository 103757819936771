import _ from "lodash";
import React, { PureComponent } from "react";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { Label } from "reactstrap";
import { translate } from "../../helpers/message.helper";
import ApiCep from "../../services/zipCode.service";
import { ToolTipFeedBack } from "../Utils/ToolTipFeedBack";

type Props = {
  label: string,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange: any,
  onBlur: any,
  children?: any,
  required: boolean,
  messageValidate?: string,
  placeholder: string,
  disabled: boolean,
  maxLength: number,
  style: any
};

class ZipCodeInput extends PureComponent<Props> {
  static defaultProps = {
    size: 12
  };
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  handleZipCode = (location, zipCode) => {
    let endereco = {};
    if (location.erro) {
      _.set(endereco, "cep", zipCode);
    } else {
      _.set(endereco, "cep", zipCode);
      _.set(endereco, "cidade", { nome: location.localidade, uf: location.uf });
      _.set(endereco, "bairro", location.bairro);
      _.set(endereco, "logradouro", location.logradouro);
    }
    this.props.loading(false);
    this.props.onChange(this.props.name, endereco);
  };

  findEndereco = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.currentTarget;
    this.setState({ value });
    let match = value.match(/\d+/g);
    const _this = this;
    if (match != null && match.join("").length === 8) {
      this.props.loading(true);
      ApiCep.SearchCep(match.join(""))
        .then(response => {
          _this.handleZipCode(response.data, value);
        })
        .catch(function (error) {
          _this.handleZipCode({ erro: true, timeout: true }, value);
        });
    }else {
      this.props.onChangeCEP(this.props.name, value);
    }
  };

  render() {
    const { messages, name } = this.props;
    let messageValidate;
    if (messages != null && messages.length > 0) {
      messages.forEach(function (message, index) {
        if (message.fieldName == name) {
          messageValidate = translate(message.message.code);
        }
      });
    }
    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    return (
      <div className={`${this.props.required ? "input-required" : ""}`}>
        {this.props.label && (
          <Label for={this.props.name} className='label-nowrap' title={this.props.label}>
            {this.props.label}
            {this.props.required && "*"}
          </Label>
        )}
        <InputMask
          mask="99.999-999"
          maskChar=" "
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          onChange={this.findEndereco}
          required={this.props.required}
          placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
          onBlur={this.props.onBlur}
          disabled={this.props.disabled ? this.props.disabled : false}
          className={`form-control ${this.props.className} ${
            messageValidate ? "is-invalid" : ""
            }`}
        >
          {this.props.children}
        </InputMask>
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </div>
    );
  }
}

const mapDispatch = ({
  alert: { error, clear, success },
  authentication: { login },
  load: { loading }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: msg => success(msg),
  login: (user, password) => login({ user, password }),
  loading: (load: boolean) => loading({ load })
});

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(ZipCodeInput);
