import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Avatar from "../../components/Avatar/Avatar";
import iconPerfil from "../../img/icon_perfil.svg";
import { Can } from "@casl/react";
import { Button } from "reactstrap";
import { userService } from "../../services/user.service";


class TopoListagem extends PureComponent<> {
  render() {
    return (
      //add classe "bg-branco" abaixo quando esição/criação
      <div className="px-sm-5 py-sm-2 my-sm-3 rounded d-flex flex-column flex-sm-row justify-content-between align-items-center">
        <div className="bloco-dados-user  mr-sm-auto d-flex flex-column flex-sm-row align-items-center pointer"
          onClick={() => {
            this.props.history.push('/perfil/view/' + this.props.user.id);
          }}
        >

          <div className="bloco-image-user m-sm-2 mr-sm-4">
            <div className="icon-foto-user d-flex align-items-center ">
              <img src={iconPerfil} alt="iconPerfil" />
            </div>
            <Avatar user={this.props.user}></Avatar>
          </div>
          <div className="info-user">
            <p className="txtCooperativa">
              {this.props.user.cooperativa && this.props.user.cooperativa.sigla}
            </p>
            <h2 className="font-weight-bold">{this.props.user.nome}</h2>

            {!this.props.user.setorAtual && (
              <p className="font-italic txtPerfil">
                {userService.getPerfilAtual(this.props.user)}
              </p>
            )}
            {this.props.user.setorAtual && (
              <p className="font-italic txtPerfil ">
                {this.props.user.setorAtual.nome}
              </p>
            )}
          </div>
        </div>
        <div className="px-sm-3 mt-4 mb-3 mt-sm-0 mb-sm-0">
            {this.props.securityActionDo && this.props.securityActionOn && userService.isCooperado(this.props.user) &&(
                <button
                    type="button"
                    className={`btn ${this.props.buttonActionClassName}`}
                    onClick={this.props.buttonAction}
                >
                    <span className={this.props.securityActionIcon}></span>
                    {this.props.securityActionName}
                </button>
            )}
          {this.props.securityActionDo && this.props.securityActionOn && (

            <Can
              I={this.props.securityActionDo}
              a={this.props.securityActionOn}
              ability={this.props.permissions}
            >
              <button
                type="button"
                className={`btn ${this.props.buttonActionClassName}`}
                onClick={this.props.buttonAction}
              >
                <span className={this.props.securityActionIcon}></span>
                {this.props.securityActionName}
              </button>{" "}
            </Can>
          )}
          {/* */}
        </div>

        {this.props.showfilter && <div className="px-sm-3 mt-2 mt-sm-0">
          <Button className="btn btn-light" onClick={this.props.toogleFiltro}>
            <span className="icon-btn-filtro"></span>
            Filtrar
            </Button>
        </div>}

      </div>
    );
  }
}
const mapDispatch = ({ filtro: { toogleFiltro } }) => ({
  toogleFiltro: () => toogleFiltro()
});
function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(TopoListagem));
