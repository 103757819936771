//import { Can } from "@casl/react";
import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import { FormGroup } from "../../../components/FormGroup";
import moment from "moment";
import DatePickerInput from "../../../components/Inputs/DatePickerInput";
import FormInput from "../../../components/Inputs/FormInput";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import SelectInput from "../../../components/Inputs/SelectInput";
import EspecialidadesSelectInput from "../../../sascComponents/especialidades/EspecialidadesSelectInput";
import { procedimentoService } from "../../../services/procedimento.service";

class ExameForm extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            viewMode: true,
            listaHistoricos: []
        };
    }

    handleRowClick = (fieldName, rowData) => {
        this.props.history.push({
            pathname: "/pacoteexame/edit/" + (rowData.id),
            state: { entity: rowData }
        });
    };

    confirmCancel = setValues => {
        this.setState({ viewMode: true });
    };

    goToEdit = () => {
        this.props.history.push({
            pathname: "/pacoteexame/edit/" + this.props.values.id,
            state: { entity: this.props.values }
        });
    };

    render() {
        const {
            values,
            setFieldValue,
            errors,
            handleChange,
            isSubmitting,
            handleSubmit,
            viewMode,
            validateForm,
            setValues
        } = this.props;
        return (
            <React.Fragment>
                <div className="item">
                    <Card className="cardCrud content-form">
                        <CardHeader>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="mr-sm-auto card-title">Dados</div>
                                <div className="px-sm-3">
                                    {viewMode && (
                                        <button
                                            type="button"
                                            className="btn btn-light"
                                            onClick={this.goToEdit}
                                        >
                                            <span className="icon-btn-editar"></span>Editar
                                        </button>
                                    )}
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody className={"form-inside viewMode"}>
                            <div className="row section-form">
                                <h6 className="w-100">Pacote de exame</h6>
                            </div>
                            <div className="row section-form">
                                <FormGroup className="">
                                    <InputViewEdit
                                        component={FormInput}
                                        label={"Nome"}
                                        type="text"
                                        id="nomePacote"
                                        name="nomePacote"
                                        placeholder="Digite o nome do pacote"
                                        value={values.nomePacote}
                                        onChange={(name, value) => setFieldValue(name, value)}
                                        noSize={true}
                                        erroMensagem={errors.nomePacote}
                                        viewMode={viewMode}
                                        required={true}
                                    />
                                </FormGroup>
                                <FormGroup className="">
                                    <InputViewEdit
                                        component={EspecialidadesSelectInput}
                                        label={"Especialidades"}
                                        name="especialidades"
                                        id="especialidades"
                                        multi={true}
                                        placeholder="Selecione as especialidades"
                                        onChange={(name, value) => {
                                            setFieldValue(name, value);
                                        }}
                                        returnFullObject={true}
                                        value={values.especialidades}
                                        className="sasc-select"
                                        required={true}
                                        erroMensagem={errors.especialidades}
                                        viewMode={viewMode}
                                        defaultValue={
                                            viewMode &&
                                            (values &&
                                              values.especialidades &&
                                              values.especialidades.length
                                              ? values.especialidades
                                                .map(item => item.nome)
                                                .join(', ')
                                              : '--')
                                          }
                                    ></InputViewEdit>
                                </FormGroup>
                                <FormGroup className="fixo">
                                    <InputViewEdit
                                        label="Prazo repetição do exame"
                                        component={FormInput}
                                        onChange={(name, date) => {
                                            setFieldValue(name, date);
                                        }}
                                        type={"number"}
                                        name={"prazoRepeticao"}
                                        id={"prazoRepeticao"}
                                        placeholder="Prazo repetição do exame"
                                        viewMode={viewMode}
                                        value={values.prazoRepeticao}
                                        erroMensagem={errors.prazoRepeticao}
                                    />
                                </FormGroup>
                                <FormGroup className="">
                                    <InputViewEdit
                                        label={"Descrição(ões) de exame(s)"}
                                        component={SelectInput}
                                        viewMode={viewMode}
                                        multi={true}
                                        isMulti={true}
                                        onChange={(name, date) => {
                                            setFieldValue(name, date);
                                        }}
                                        required={true}
                                        name={"exames"}
                                        placeholder="Selecione a(s) descrição(ões) de exame(s)"
                                        type={"text"}
                                        value={values.exames}
                                        defaultValue={values.exames ? values.exames.map((e, index) => {
                                            let value = e.descricao;
                                            if (index > 0) {
                                                value = ', ' + value
                                            }
                                            return value;
                                        }) : ''}
                                        clearable={true}
                                        labelKey={"descricao"}
                                        valueKey={"descricao"}
                                        returnFullObject={true}
                                        onFetchData={procedimentoService.find}
                                        erroMensagem={errors.exames}
                                    />
                                </FormGroup>
                                <FormGroup className="">
                                    <InputViewEdit
                                        label={"Código(s) de exame(s)"}
                                        component={SelectInput}
                                        viewMode={viewMode}
                                        multi={true}
                                        isMulti={true}
                                        onChange={(name, date) => {
                                            setFieldValue(name, date);
                                        }}
                                        required={true}
                                        name={"exames"}
                                        placeholder="Selecione o(s) código(s) de exame(s)"
                                        type={"text"}
                                        value={values.exames}
                                        defaultValue={values.exames ? values.exames.map((e, index) => {
                                            let value = e.codigo;
                                            if (index > 0) {
                                                value = ', ' + value
                                            }
                                            return value;
                                        }) : ''}
                                        clearable={true}
                                        labelKey={"codigo"}
                                        valueKey={"codigo"}
                                        returnFullObject={true}
                                        onFetchData={procedimentoService.find}
                                        erroMensagem={errors.exames}
                                    />
                                </FormGroup>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}
const mapDispatch = ({
    alert: { success, error, clear, info },
    load: { loading }
}) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear(),
    info: msg => info(msg)
});

function mapStateToProps(state) {
    const { user, permissions } = state.authentication;

    return {
        user,
        permissions
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(ExameForm));
