import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup } from "reactstrap";
import DatePickerInput from "../../../../components/Inputs/DatePickerInput";
import FormInput from "../../../../components/Inputs/FormInput";
import FormSelectInput from "../../../../components/Inputs/FormSelectInput";
import InputViewEdit from "../../../../components/Inputs/InputViewEdit";
import { dateHelper } from "../../../../helpers/date.helper";
import SelectableInput from "../../../../sascComponents/inputs/SelectableInput";
import { conselhoService } from "../../../../services/conselho.service";
import { enumService } from "../../../../services/enum.service";
import { userService } from "../../../../services/user.service";

class DadosAutorizacaoForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }
  findConselho = () => {
    conselhoService
      .conselhoSolicitante(this.props.values.numeroCarteirinha)
      .then(response => { });
  };

  render() {
    const {
      setFieldValue,
      values,
      errors,
      viewMode
    } = this.props;
    const extension = userService.getExtensionUser(this.props.user);


    return (
      <React.Fragment>
        <div className="row section-form">
          <h6 className="w-100">Dados da autorização</h6>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo2"
            name={"dataAdmissaoHospitalar"}
            label={"Data admissão hospitalar"}
            value={values.dataAdmissaoHospitalar}
          >
            {/* <p>31/12/2019</p> */}
            <InputViewEdit
              label="Data admissão hospitalar"
              component={DatePickerInput}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              defaultValue={
                values.dataAdmissaoHospitalar
                  ? dateHelper.format(values.dataAdmissaoHospitalar, {
                    mode: "DATE"
                  })
                  : "--"
              }
              mask={"99/99/9999"}
              name={"dataAdmissaoHospitalar"}
              id={"dataAdmissaoHospitalar"}
              placeholder="Data admissão hospitalar"
              viewMode={viewMode}
              type={"text"}
              value={values.dataAdmissaoHospitalar}
              erroMensagem={errors.dataAdmissaoHospitalar}
              required={false}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo2"
            name={"diariasAutorizadas"}
            label={"Diárias autorizadas"}
            value={values.diariasAutorizadas}>
            <InputViewEdit
              label="Diárias autorizadas"
              component={FormInput}
              required={false}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              name={"diariasAutorizadas"}
              placeholder="Diárias autorizadas"
              type={"number"}
              viewMode={viewMode}
              erroMensagem={errors.diariasAutorizadas}
              value={values.diariasAutorizadas}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>
          
           <SelectableInput viewMode={viewMode} 
          atendimento={values} 
          className="fixo2 mobile-100"
          name={"acomodacao"}
          label={"Acomodação"}
          value={values.acomodacao && values.acomodacao.description}
          >
          <InputViewEdit
          label="Acomodação"
          required={values.convenio ? enumService.acomodacaoObrigatorio(values.convenio.tiposGuiaProduto, values.type) : false}
          disabled={!values.convenio}
          component={FormSelectInput}
          parent={{type:values.type,convenio:values.convenio}}
          service={enumService.findAcomodacaoConvenioPorTipoGuia}
          sortKey={"description"}
          className={"destaque"}
          name={"acomodacao"}
          defaultValue={
            values.acomodacao ? values.acomodacao.description : "--"
          }
          returnFullObject={true}
          searchable={true}
          labelKey={"description"}
          valueKey={"name"}
          value={values.acomodacao}
          viewMode={viewMode}
          erroMensagem={errors.acomodacao}
          onChange={(name, value) => {
            setFieldValue(name, value);
          }}
          alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
          </SelectableInput>
          <FormGroup ></FormGroup>
          <div className="quebra"></div>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo mobile-100"
            name={"codigoOperadora"}
            label={"Código na operadora"}
            value={values.codigoOperadora}
          >

            <InputViewEdit
              label={"Código na operadora"}
              component={FormInput}
              required={false}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              name={"codigoOperadora"}
              id={"codigoOperadora"}
              // erroMensagem={errors.codigoOperadora}
              placeholder=""
              type={"text"}
              viewMode={extension || viewMode}
              value={values.codigoOperadora}
              disabled={true}
            />
          </SelectableInput>
          <SelectableInput viewMode={viewMode} atendimento={values} className=""
            name={"nome"}
            label={"Nome do contratado"}
            value={values.nome}>
            <InputViewEdit
              label={"Nome do contratado"}
              component={FormInput}
              required={false}
              disabled={true}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              name={"nome"}
              erroMensagem={errors.nome}
              placeholder=""
              type={"text"}
              viewMode={extension || viewMode}
              value={values.nome}
            />
          </SelectableInput>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
            name={"cnes"}
            label={"Código CNES"}
            value={values.cnes}>
            <InputViewEdit
              label={"Código CNES"}
              component={FormInput}
              required={false}
              disabled={true}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              name={"cnes"}
              erroMensagem={errors.cnes}
              placeholder=""
              type={"text"}
              viewMode={extension || viewMode}
              value={values.cnes}
            />
          </SelectableInput>


        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosAutorizacaoForm));
