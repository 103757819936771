import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Avatar from "../../components/Avatar/Avatar";
import RoleSelectInput from "../../components/Inputs/RoleSelectInput";
import iconPhoto from "../../img/icon_photo.svg";
import Icon_usuario_cooperado from "../../img/icon_usuarioCooperado.svg";
import TopoTitleComponente from "../home/TopoTitleComponente";
import { formatterHelper } from "../../helpers/formatter.helper";
import { dateHelper } from "../../helpers/date.helper";
import InputViewEdit from "../../components/Inputs/InputViewEdit";
import iconAtendimento from "../../img/icon_atendimentos.svg";
import iconPerfil from "../../img/icon_perfil.svg";
import {userService} from "../../services/user.service";

class AvisosTopoCrud extends PureComponent<Props, State> {
  render() {
    const {
      values,
      handleSubmit,
      getProfileName,
      handleCancel,
      onChange,
      onChangeProfile
    } = this.props;
    return (
      //add classe "bg-branco" abaixo quando esição/criação
      <React.Fragment>
        <div className="px-sm-4 py-sm-2 my-sm-3 bg-branco rounded d-flex flex-column flex-sm-row justify-content-between align-items-center">
          <div className="px-sm-5 py-sm-2 my-sm-3  rounded d-flex flex-column flex-sm-row justify-content-between align-items-center">
            <div className="bloco-dados-user mr-sm-auto d-flex flex-column flex-sm-row align-items-center">
              <div className="bloco-image-user m-sm-2 mr-sm-4">
                <div className="icon-foto-user d-flex align-items-center ">
                  <img src={iconPerfil} alt="iconPerfil" />
                </div>
                {/* <img src={iconFoto} className="foto-user" alt="iconFoto" /> */}
                <Avatar user={this.props.user}></Avatar>
              </div>
              <div className="info-user">
                <p>
                  {this.props.user.cooperativa && this.props.user.cooperativa.sigla}
                </p>
                <h2 className="font-weight-bold">{this.props.user.nome}</h2>
                <p className="font-italic">
                  {userService.getPerfilAtual(this.props.user)}
                </p>
              </div>
            </div>

            <div className="px-sm-3 mt-2 mt-sm-0"></div>
          </div>

          {!values.id && (
            <div className="info-texto px-sm-3 mt-2 mt-sm-0">
              <span className="font-weight-bold font-italic helpText">
                Quer ajuda?
              </span>
              <p className="font-italic">01- Prencha o formulário</p>
            </div>
          )}
          <div className="px-sm-3 mb-3 border-left justify-content-around">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={handleCancel}
            >
              <span className={`icon-btn-fechar`}></span>
              {"Cancelar"}
            </button>
            <button
              type="submit"
              className="btn btn-primary "
              onClick={handleSubmit}
            >
              <span className={`icon-btn-check`}></span>
              {"Salvar"}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(mapStateToProps, null)(withRouter(AvisosTopoCrud));
