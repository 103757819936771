import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { FormGroup } from "../../components/FormGroup";
import FormInput from "../../components/Inputs/FormInput";
import InputViewEdit from "../../components/Inputs/InputViewEdit";
import { objectsConstants } from "../../constants/objects.constants";
import EspecialidadesSelectInput from "../../sascComponents/especialidades/EspecialidadesSelectInput";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { estabelecimentoService } from "../../services/estabelecimento.service";
import DatePickerInput from "../../components/Inputs/DatePickerInput";
import SelectInput from "../../components/Inputs/SelectInput";
import { dateHelper } from "../../helpers/date.helper";
import EstabelecimentoSelectInput from "../../sascComponents/estabelecimento/EstabelecimentoSelectInput";
import SelectableInput from "../../sascComponents/inputs/SelectableInput";

class AvisosForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: true
    };
  }


  addUsuario = (setFieldValues, values) => {
    let usuarios = _.cloneDeep(values.usuarios);
    usuarios.push({ idKey: new Date().getTime() });
    setFieldValues("usuarios", usuarios);
  };

  addUsuarioAndEdit = (setFieldValues, values) => {
    this.setState({ viewMode: false });
  };

  removerUsuario = (setFieldValues, values, index) => {
    let usuarios = _.cloneDeep(values.usuarios);

    usuarios.splice(index, 1);
    setFieldValues("usuarios", usuarios);
    this.props.info({
      message: `Usuário removido com sucesso!`
    });
  };

  excluirUsuario = (notificacao, usuario) => {
    this.props.loading(true);
    estabelecimentoService.removerUsuario(notificacao, usuario).then(
      response => {

        this.props.success({
          message: `Usuário foi excluido com sucesso!`
        });
        if (this.props.refreshEntity) {
          this.props.refreshEntity();
        }
      },
      erros => {
        console.error(erros.response);
        this.props.error({
          message: "Não foi possível excluir usuário!"
        });
        this.props.loading(false);
      }
    );
    this.props.loading(false);
  };

  cancelEditMode = (e, setValues) => {
    e.stopPropagation();
    ConfirmAcationService.confirmMsg(
      () => {
        this.confirmCancel(setValues);
      },
      `Cancelar Edição `,
      "Você confirma que deseja cancelar a edição dos cronogramas",
      null,
      "Não",
      "Sim",
      objectsConstants.TYPE_CANCEL
    );
  };

  confirmCancel = setValues => {
    this.setState({ viewMode: true });
  };

  userCreated = (setFieldValues, user, values) => {
    this.setState({ viewMode: true }, () => {
      if (this.props.refreshEntity && this.props.viewMode) {
        this.props.refreshEntity();
      } else {
        let usuarios = _.cloneDeep(values.usuarios);
        usuarios.push(user);
        setFieldValues("usuarios", usuarios);
      }
    });
  };

  render() {
    const {
      values,
      setFieldValue,
      errors,
      isSubmitting,
      handleSubmit,
      viewMode,
    } = this.props;
    return (
      <React.Fragment>
        <div className="item">
          <Card className="cardCrud content-form">


            <CardBody className={"form-inside viewMode"}>
              <React.Fragment>
                <div className="item">
                  <Card className="cardCrud content-form">
                    
                    <CardBody className={"form-inside viewMode"}>
                      <div className="row section-form">
                        <h6 className="w-100">Título e Descrição</h6>
                      </div>
                      <div className="row section-form">
                        <FormGroup className="">
                          <InputViewEdit
                              component={FormInput}
                              label={"Título"}
                              type="text"
                              id="titulo"
                              name="titulo"
                              placeholder="Digite o titulo da notificação"
                              value={values.titulo}
                              maxLength={250}
                              onChange={(name, value) => setFieldValue(name, value)}
                              noSize={true}
                              erroMensagem={errors.nome}
                              viewMode={this.state.viewMode}
                              required={true}
                          />
                        </FormGroup>
                        <div className="quebra"></div>
                        <FormGroup className="">
                          <InputViewEdit
                              component={FormInput}
                              label={"Descrição"}
                              type="text"
                              id="descricao"
                              maxLength={1000}
                              name="descricao"
                              placeholder="Digite a descrição da notificação"
                              //value={values.descricao}
                              value={<div
                                dangerouslySetInnerHTML={{__html: values.descricao ? values.descricao : ''}}></div>}
                              onChange={(name, value) => setFieldValue(name, value)}
                              noSize={true}
                              erroMensagem={errors.nome}
                              viewMode={this.state.viewMode}
                              required={true}
                          />
                        </FormGroup>
                      </div>
                    </CardBody>

                    <CardBody className={"form-inside viewMode"}>
                      <div className="row section-form">
                        <h6 className="w-100">Datas e Filtro</h6>
                      </div>
                      <div className="row section-form">

                        <FormGroup className="">
                          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
                                           name={"dataHoraInicio"}
                                           label={"Data Início Notificação"}
                                           value={values.dataHoraInicio}
                          >
                            <InputViewEdit
                                label="Data Início Notificação"
                                component={DatePickerInput}
                                onChange={(name, date) => {
                                  setFieldValue(name, date);
                                }}
                                defaultValue={
                                  values.dataHoraInicio
                                      ? dateHelper.format(values.dataHoraInicio, {
                                        mode: "DATE"
                                      })
                                      : "--"
                                }
                                mask={"99/99/9999"}
                                name={"dataHoraInicio"}
                                id={"dataHoraInicio"}
                                placeholder="Data Início Notificação"
                                viewMode={this.state.viewMode}
                                type={"text"}
                                value={values.dataHoraInicio}
                                erroMensagem={errors.dataHoraInicio}
                                required={false}
                            />
                          </SelectableInput>
                        </FormGroup>
                        <FormGroup className="">
                          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
                                           name={"dataHoraFim"}
                                           label={"Data Fim Notificação"}
                                           value={values.dataHoraFim}
                          >
                            <InputViewEdit
                                label="Data Fim Notificação"
                                component={DatePickerInput}
                                onChange={(name, date) => {
                                  setFieldValue(name, date);
                                }}
                                defaultValue={
                                  values.dataHoraFim
                                      ? dateHelper.format(values.dataHoraFim, {
                                        mode: "DATE"
                                      })
                                      : "--"
                                }
                                mask={"99/99/9999"}
                                name={"dataHoraFim"}
                                id={"dataHoraFim"}
                                placeholder="Data Fim Notificação"
                                viewMode={this.state.viewMode}
                                type={"text"}
                                value={values.dataHoraFim}
                                erroMensagem={errors.dataHoraFim}
                                required={false}
                            />
                          </SelectableInput>
                        </FormGroup>

                        {values.tipoAviso === objectsConstants.NOTIFICACAO_CONSULTORIO && <FormGroup className="">
                          <InputViewEdit
                              component={EstabelecimentoSelectInput}
                              label={"Estabelecimento"}
                              name="estabelecimento"
                              id="estabelecimento"
                              multi={true}
                              isMulti={true}
                              placeholder="Selecione o estabelecimento"
                              onChange={(name, value) => {
                                setFieldValue(name, value);
                              }}
                              returnFullObject={true}
                              value={values.estabelecimento}
                              className="sasc-select"
                              required={true}
                              erroMensagem={errors.estabelecimento && "Obrigatório"}
                              viewMode={this.state.viewMode}
                              defaultValue={
                                this.state.viewMode &&
                                values.estabelecimento  &&
                                values.estabelecimento.map(item => item.nome).join(", ")
                              }
                          ></InputViewEdit>
                        </FormGroup>}

                        {values.tipoAviso === objectsConstants.NOTIFICACAO_COOPERADO && <><FormGroup className="">
                          <InputViewEdit
                            component={EspecialidadesSelectInput}
                            label={"Especialidades"}
                            name="especialidade"
                            id="especialidade"
                            multi={true}
                            placeholder="Selecione as especialidades"
                            onChange={(name, value) => {
                              setFieldValue(name, value);
                            }}
                            returnFullObject={true}
                            value={values.especialidade}
                            className="sasc-select"
                            required={true}
                            erroMensagem={errors.especialidade}
                            viewMode={this.state.viewMode}
                            defaultValue={
                              this.state.viewMode &&
                              values.especialidade &&
                              values.especialidade.map(item => item.nome).join(", ")
                            }
                          ></InputViewEdit>
                        </FormGroup>
                          <FormGroup className="">
                            <InputViewEdit
                              component={SelectInput}
                              placeholder="Escolha o sexo"
                              label={'Sexo'}
                              name={'sexo'}
                              id={'sexo'}
                              options={objectsConstants.ENUM_SEXO}
                              value={values.sexo}
                              defaultValue={
                                this.state.viewMode &&
                                values.sexo &&
                                objectsConstants.ENUM_SEXO.map(item => item.value === values.sexo && item.label)
                              }
                              onChange={(name, date) => {
                                setFieldValue(name, date);
                              }}
                              viewMode={this.state.viewMode}
                              className="sasc-select"
                              labelKey={'label'}
                              valueKey={'value'} />
                          </FormGroup></>}
                      </div>
                    </CardBody>
                  </Card>
                </div>
                {/*<NotificacaoForm
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      handleChange={handleChange}
                      touched={touched}
                      validateForm
                      setValues
                    ></NotificacaoForm>*/}
              </React.Fragment>
            </CardBody>
          </Card>
        </div>




        <div className="item">
          {!viewMode && (
            <div className="d-flex flex-row flex-sm-row pt-2 pb-3 justify-content-center">
              <button
                type="submit"
                className="btn btn-primary d-blokc d-sm-none"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Salvar
              </button>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
const mapDispatch = ({
  alert: { success, error, clear, info },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear(),
  info: msg => info(msg)
});

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(AvisosForm));
