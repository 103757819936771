//@flow
import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";

export const bancoService = {
  findAll
};

function findAll() {
  return defaultService.doGet(`${urlsConstants.BANCO}findAll`);
}
