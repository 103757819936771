//@flow
import React from 'react';
import { toastr } from 'react-redux-toastr';
import IconErrorToast from '../components/ToastIcons/IconErrorToast';
import IconSucessToast from '../components/ToastIcons/IconSucessToast';
import IconWarningToast from '../components/ToastIcons/IconWarningToast';
import IconPendenciaToast from '../components/ToastIcons/IconPendenciaToast';
import IconInfoToast from '../components/ToastIcons/IconInfoToast';
import IconInfoBlackToast from '../components/ToastIcons/IconInfoBlackToast';
const TIMOUT_TOAST = 10000;
export default {
  state: {},
  effects: () => ({
    success: payload => {
      toastr.success(
        (payload.title ? payload.title : ''),
        (payload.code ? payload.code : '') + ' ' + payload.message,
        {
          timeOut: TIMOUT_TOAST,
          className: 'toast-sasc toast-sasc-success',
          icon: <IconSucessToast />,
        }
      );
    },
    error: payload => {
        if(payload.html){
            toastr.error(
                '',
                '',
                {
                    timeOut:payload.time?payload.time:TIMOUT_TOAST,
                    className: 'toast-sasc toast-sasc-erro',
                    icon: <IconErrorToast/>,
                    component: <div className={'mb-2'} style={{color:'inherit'}} dangerouslySetInnerHTML={{__html:(payload.code ? payload.code : '') + ' ' + payload.message }}></div>
                }
            );
        }else {
            toastr.error(
                (payload.code ? payload.code : '') + ' ' + payload.message,
                {
                    timeOut: payload.time?payload.time:TIMOUT_TOAST,
                    className: 'toast-sasc toast-sasc-erro',
                    icon: <IconErrorToast/>
                }
            );
        }
    },
    warning: payload => {
        if(payload.html){
            toastr.warning(
                '',
                '',
                {
                    timeOut:payload.time?payload.time:TIMOUT_TOAST,
                    className: 'toast-sasc toast-sasc-warning',
                    icon: <IconWarningToast/>,
                    component: <div dangerouslySetInnerHTML={{__html:(payload.code ? payload.code : '') + ' ' + payload.message}}></div>
                }
            );
        }else {
            toastr.warning(
                '',
                (payload.code ? payload.code : '') + ' ' + payload.message ,
                {
                    timeOut: TIMOUT_TOAST,
                    className: 'toast-sasc toast-sasc-warning',
                    icon: <IconWarningToast/>,
                    allowHtml: true
                }
            );
        }
    },
    pendencia: payload => {
      toastr.success(
        (payload.title ? payload.title : ''),
        (payload.code ? payload.code : '') + ' ' + payload.message,
        {
          timeOut: TIMOUT_TOAST,
          className: 'toast-sasc toast-sasc-pendencia',
          icon: <IconPendenciaToast />
        }
      );
    },
    clear: () => {
      toastr.clean();
    },
    info: async payload => {
        if(payload.html){
            toastr.info(
                '',
                '',
                {
                    timeOut: TIMOUT_TOAST,
                    className: 'toast-sasc toast-sasc-info',
                    icon: <IconInfoToast/>,
                    component: <div className={'mb-2'} dangerouslySetInnerHTML={{__html:(payload.code ? payload.code : '') + ' ' + payload.message }}></div>
                }
            );
        }else {
            toastr.info(
                '',
                (payload.code ? payload.code : '') + ' ' + payload.message,
                {
                    timeOut: TIMOUT_TOAST,
                    className: 'toast-sasc toast-sasc-info',
                    icon: <IconInfoToast/>
                }
            );
        }
    },
    infoBlack: async payload => {
      toastr.info(
        '',
        (payload.code ? payload.code : '') + ' ' + payload.message,
        {
          timeOut: TIMOUT_TOAST,
          className: 'toast-sasc toast-sasc-info-black',
          icon: <IconInfoBlackToast />
        }
      );
    }

  })
};
