/* eslint-disable */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, loggedIn,dispatch, ...rest }) => {
    return (
  <Route
    {...rest}
    render={props =>
      (loggedIn) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
)};
