import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Collapse, FormGroup } from "reactstrap";
import BtnLink from "../../../components/Buttons/BtnLink";
import { Formik } from "formik";
import _ from "lodash";
import { convenioService } from "../../../services/convenio.service";
import { Can } from "@casl/react";
import { objectsConstants } from "../../../constants/objects.constants";
import { messages } from "../../../helpers/message.helper";
import * as Yup from "yup";
import { ConfirmAcationService } from "../../../services/ConfirmAcationService";
import SelectInput from "../../../components/Inputs/SelectInput";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import CnpjInput from "../../../components/Inputs/CnpjInput";
import CreatableMulti from "../../../components/Inputs/CreatableMulti";
import { formatterHelper } from "../../../helpers/formatter.helper";
import MenuHistorico from "../../guias/historico/MenuHistorico";

const CnpjConvenio = Yup.object().shape({
  // custum validation de um campo

  cnpjCooperativa: Yup.array()
    .min(1, "Obrigatório")
});
class DadosConveniosForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      viewMode: false,
      listaHistoricos: [],
      //viewMode: true
    };
  }

  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  };

  loadHistoryCnpj = () => {
    this.props.loading(true);
    convenioService.historicoCnpj(this.props.convenio.id).then(
      result => {
        this.setState({ listaHistoricos: result.data }, () => {
          this.props.loading(false);
        });
      },
      () => {
        this.props.loading(false);
      }
    );
  };
  setMenuHistorico = status => {
    this.setState({ openMenuHistorico: status }, () => {
      if (this.state.openMenuHistorico) {
        this.loadHistoryCnpj();
        document.body.classList.add("bodyNoScroll");
        document.getElementById("bodyMenu").scrollIntoView();
      } else {
        document.body.classList.remove("bodyNoScroll");
      }
    });
  };
  toggleHistorico = toggle => {
    this.setMenuHistorico(!this.state.openMenuHistorico);
  };

  changeViewMode = e => {
    e.stopPropagation();
    this.setState({ viewMode: !this.state.viewMode });
  };

  confirmCancel = setValues => {
    this.setState({ viewMode: true, collapse: true }, () => {
      setValues(this.props.convenio);
    });
  };
  editMode = e => {
    e.stopPropagation();
    this.setState({ viewMode: false, collapse: true });
  };
  cancelEditMode = (e, setValues) => {
    e.stopPropagation();
    ConfirmAcationService.confirmMsg(
      () => {
        this.confirmCancel(setValues);
      },
      `Cancelar Edição `,
      "Você confirma que deseja cancelar a edição dos dados do convenio",
      null,
      "Não",
      "Sim",
      objectsConstants.TYPE_CANCEL
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          validationSchema={CnpjConvenio}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          //initialValues={{listProcedimentos: [{}], dadosExecucao: [{}], identificacaoProfissionais: [{}]}}
          initialValues={this.props.convenio}
          onSubmit={(values, { setValues }) => {
            this.props.loading(true);
            let convenio = _.cloneDeep(values);

            convenioService.doSave(convenio).then(
              response => {
                this.props.success({
                  message: `Regras salvas com sucesso!`
                });
                this.props.loading(false);
                this.setState({ viewMode: true, collapse: true }, () => {
                  this.props.loadConvenio();
                });
              },
              errors => {
                this.props.error({
                  message:
                    "Não foi possível salvar Cronograma para faturamento."
                });
                this.props.loading(false);
              }
            );
          }}
          ref={form => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validateForm,
            setValues
          }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div
                  className={`mt-4 accordion ${this.state.collapse ? "open" : ""
                    }`}
                >
                  <div className="card content-form cardGuia cardCrud">
                    <div
                      className="card-header d-flex align-items-center flex-column flex-sm-row b-bottom"
                      id="headingOne"
                      onClick={this.toggle}
                    >
                      <div className="flex-grow-1 d-flex align-items-center">
                        <div className="p-1 status-guia">Dados</div>
                      </div>
                      <div className="buttons-right ml-auto">
                        {this.state.viewMode && (
                            <Can
                                I={objectsConstants.ALTERAR}
                                a={objectsConstants.EDITAR_CONVENIO}
                                ability={this.props.permissions}
                            >
                          <BtnLink
                            type={"button"}
                            className={""}
                            icone={"icon-btn-historico"}
                            title={"Histórico"}
                            onSubmit={e => {
                              e.stopPropagation();
                              this.toggleHistorico();
                            }}
                            disabled={false}
                          />

                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-editar"}
                              title={"Editar"}
                              onSubmit={this.editMode}
                              disabled={false}
                            />
                          </Can>
                        )}
                        {!this.state.viewMode && (
                            <Can
                                I={objectsConstants.ALTERAR}
                                a={objectsConstants.EDITAR_CONVENIO}
                                ability={this.props.permissions}
                            >
                            <button
                              type={"button"}
                              className={"btn btn-secondary btn-pequeno mr-3"}
                              onClick={e => this.cancelEditMode(e, setValues)}
                            >
                              Cancelar
                          </button>
                            <button
                              type={"button"}
                              onClick={e => {
                                e.stopPropagation();
                                validateForm(values).then(errors => {
                                  if (_.isEmpty(errors)) {
                                    handleSubmit(values, { setValues });
                                  } else {
                                    this.props.error({
                                      message: messages.ERRO_CRIAR_REGRAS_CONVENIO
                                    });
                                  }
                                });
                              }}
                              className={"btn btn-primary btn-pequeno mr-2"}
                              icone={"icon-btn-check-black"}
                            >
                              Salvar
                          </button>
                          </Can>
                        )}
                      </div>
                      <span
                        className={`accordion ${this.state.collapse ? "icon-traco" : "icon-seta-baixo"
                          }`}
                      ></span>
                    </div>
                    <Collapse isOpen={this.state.collapse}>
                      <div
                        id="guiaUm"
                        className="form-inside collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#guiaUm"
                      >
                        <React.Fragment>
                          <div class="row section-form">
                            <h6 class="w-100">Convênio</h6>
                            <FormGroup>
                              <label>Nome</label>
                              <p class="form-view ">
                                {this.props.convenio && this.props.convenio.nome
                                  ? this.props.convenio.nome
                                  : "- -"}
                              </p>
                            </FormGroup>
                            <FormGroup className="fixo">
                              <label>Registro ANS</label>
                              <p class="form-view ">
                                {this.props.convenio &&
                                  this.props.convenio.registroAns
                                  ? this.props.convenio.registroAns
                                  : "- -"}
                              </p>
                            </FormGroup>
                            <FormGroup className="fixo">
                              <label>CNPJ/SASC</label>
                              <p class="form-view ">
                                {_.get(this.props.convenio, 'cnpj', "- -")}
                              </p>
                            </FormGroup>
                            <FormGroup className="">
                              <InputViewEdit
                                component={CreatableMulti}
                                onChange={setFieldValue}
                                id={`cnpj`}
                                erroMensagem={errors.cnpjCooperativa}
                                label={"CNPJ/Cooperativa"}
                                name={`listCnpjCooperativa`}
                                placeholder="Digite o CNPJ e aperte enter"
                                className={
                                  ""
                                }
                                noResultsText={"Digite o CNPJ"}
                                viewMode={this.state.viewMode}
                                value={values.listCnpjCooperativa}
                                creatable={true}
                                required={true}
                                option={[]}
                                multi
                                apendRoot
                                labelKey={"label"}
                                valueKey={"cnpj"}
                                formatValue={formatterHelper.cpfCnpjFormatter}
                                defaultValue={
                                  this.state.viewMode && values.listCnpjCooperativa &&
                                  <React.Fragment>
                                    {values.listCnpjCooperativa.map(item => <span className="tag"><span>{item.cnpj}</span></span>)}
                                  </React.Fragment>

                                }
                              />
                            </FormGroup>
                          </div>
                        </React.Fragment>
                      </div>
                    </Collapse>
                  </div>
                </div>
              <MenuHistorico
                titulo={this.props.convenio && this.props.convenio.nome}
                subTitulo={"Dados"}
                listaHistoricos={this.state.listaHistoricos}
                toggleHistorico={this.toggleHistorico}
                openMenuHistorico={this.state.openMenuHistorico}
              />
              </form>
            )}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  menu: { showMenu, hideMenu },
  load: { loading },
  alert: { success, error, clear }
}) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu(),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosConveniosForm));
