import React, { PureComponent } from "react";
import classificar from '../../img/btn_voltar.svg';

type Props = { title: string };
export default class HeaderColumn extends PureComponent<Props> {
  constructor(props) {
    super(props);
  }

  render() {
    const { title } = this.props;

    return (
      <th className={`${this.props.className}`} data-title={title} onClick={()=>{this.props.onClick(this.props.column)}}>


        {title}

          {this.props.orderBy && this.props.orderBy.fieldName===this.props.column.fieldName &&this.props.orderBy.path === this.props.column.path&&(
              <span>{this.props.orderBy.orderBy===this.props.ASC &&(
                <img src={classificar} alt="Voltar" className="ascendente" />
              )}
                  {this.props.orderBy.orderBy===this.props.DESC &&(
                    <img src={classificar} alt="Voltar" className="descendente" />
                  )}
              </span>
          )}

      </th>
    );
  }
}
