import { Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardHeader, Modal, ModalBody } from "reactstrap";
import * as Yup from "yup";
import BtnLink from "../../../components/Buttons/BtnLink";
import Control from "../../../components/Buttons/ControlSelectInput";
import ClearIndicator from "../../../components/Buttons/CustomClearIndicatorSelectInput";
import MultiValueRemove from "../../../components/Buttons/CustomCloseSelectInput";
import { FormGroup } from "../../../components/FormGroup";
import CpfInput from "../../../components/Inputs/CpfInput";
import FormInput from "../../../components/Inputs/FormInput";
import FormSelectInput from "../../../components/Inputs/FormSelectInput";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import SelectInput from "../../../components/Inputs/SelectInput";
import { objectsConstants } from "../../../constants/objects.constants";
import iconWarning from '../../../img/iconAlert_Atencao.svg';
import Datatable from "../../../sascComponents/datagrid/Datatable";
import { ConfirmAcationService } from "../../../services/ConfirmAcationService";
import { atendimentoService } from "../../../services/atendimento.service";
import { beneficiarioService } from "../../../services/beneficiario.service";
import { exameService } from "../../../services/exame.service";
import { pacoteExameService } from "../../../services/pacoteExame.service";
import { procedimentoService } from "../../../services/procedimento.service";
import { solicitaExameService } from "../../../services/solicitaexame.service";
import { userService } from "../../../services/user.service";
import LinhaAcaoPrescreverExames from "./LinhaAcaoPrescreverExames";

const fields = [
    {
        "path": "Atendimento",
        "fieldName": "hasExames",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": "",
        "url": "/solicitaExame/getFilterPescricao",
        "fieldDescription": "Prescrição de exame",
        "type": "SELECT"
    },
    {
        "path": "Atendimento",
        "fieldName": "numeroAtendimento",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": "",
        "url": "",
        "fieldDescription": "Nº do atendimento",
        "type": "STRING"
    },
    {
        "path": "Atendimento",
        "fieldName": "dataAtendimento",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": "",
        "url": "",
        "fieldDescription": "Data do atendimento ",
        "type": "DATE"
    },
    {
        "path": "Atendimento",
        "fieldName": "numeroGuia",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": null,
        "url": null,
        "fieldDescription": "Nº da Guia ",
        "type": "STRING"
    },
    {
        "path": "Atendimento.convenio",
        "fieldName": "sigla",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": null,
        "url": "/atendimento/getConveniosPorAtendimento",
        "fieldDescription": "Convênio",
        "type": "SELECT"
    },
    {
        "path": "Atendimento",
        "fieldName": "nomeBeneficiario",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": "",
        "url": "",
        "fieldDescription": "Nome do beneficiário",
        "type": "STRING"
    },
    {
        "path": "Atendimento",
        "fieldName": "numeroCarteira",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": "",
        "url": "",
        "fieldDescription": "Numero carteirinha",
        "type": "STRING"
    },
    {
        "path": "Atendimento",
        "fieldName": "dtype",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": "",
        "url": "/atendimento/getTiposGuia",
        "fieldDescription": "Tipos de Guia",
        "type": "SELECT"
    }
];

const columnsExport = [
    {
        "path": "Atendimento",
        "fieldName": "hasExames",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": "",
        "url": "/solicitaExame/getFilterPescricao",
        "fieldDescription": "Prescrição de exame",
        "type": "SELECT"
    },
    {
        "path": "Atendimento",
        "fieldName": "nomeBeneficiario",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": "",
        "url": "",
        "fieldDescription": "Nome do beneficiário",
        "type": "STRING"
    },
    {
        "path": "Atendimento",
        "fieldName": "numeroCarteira",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": "",
        "url": "",
        "fieldDescription": "Numero carteirinha",
        "type": "STRING"
    },
    {
        "path": "Atendimento",
        "fieldName": "typeGuia",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": "",
        "url": "/atendimento/getTiposGuia",
        "fieldDescription": "Tipos de Guia",
        "type": "SELECT"
    },
    {
        "path": "Atendimento.convenio",
        "fieldName": "sigla",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": null,
        "url": "/atendimento/getConveniosPorAtendimento",
        "fieldDescription": "Convênio",
        "type": "SELECT"
    },
    {
        "path": "Atendimento",
        "fieldName": "numeroAtendimento",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": "",
        "url": "",
        "fieldDescription": "Nº do atendimento",
        "type": "STRING"
    },
    {
        "path": "Atendimento",
        "fieldName": "dataAtendimento",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": "",
        "url": "",
        "fieldDescription": "Data do atendimento ",
        "type": "DATE"
    },
    {
        "path": "Atendimento",
        "fieldName": "numeroGuia",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": null,
        "url": null,
        "fieldDescription": "Nº da Guia ",
        "type": "STRING"
    }
];

class SolicitacaoExameForm extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        let initFilter = null
        const isCooperado = userService.isCooperado(this.props.user);
        if (isCooperado) {
            initFilter = [{
                fieldName: "cooperado",
                fieldDescription: "Id",
                columnOrder: 1,
                searchableField: true,
                value: this.props.user.id,
                type: "NUMBER",
                condition: { description: "=" },

            }];
        }
        this.state = {
            viewMode: true,
            showModalExames: false,
            showModalExamesEmMassa: false,
            atendimento: {},
            initFilter,
            showModal15dias: false,
            exames15dias: [],
            rowExames15dias: {}
        };
    }

    getModalFromRow = (rowData) => {
        let newValues = _.cloneDeep(this.props.values);
        newValues.nomeBeneficiario = rowData.nomeBeneficiario;
        newValues.numeroAtendimento = rowData.numeroAtendimento;
        newValues.numeroCarteira = rowData.numeroCarteira
        this.props.setValues(newValues);

        this.setState({ atendimento: rowData }, () => {
            this.toogleModalExames()
        });
    }

    handleRowClick = (fieldName, rowData) => {
        if (fieldName !== 'hasExames') {
            this.getModalFromRow(rowData);
        } else if (rowData.hasExames === objectsConstants.EXAME_PRESCRICAO.EXAME_NAO_PRESCRITO) {
            this.props.loading(true);
            solicitaExameService.checkExames(rowData.id).then(res => {
                if (res.data && res.data.length) {
                    this.props.loading(false);
                    let exameProcedimento = res.data;

                    let exames = [];

                    exameProcedimento.map(ep => {
                        let exame = { numeroAtendimento: '', dataAtendimento: '', codigo: '' }
                        exame.numeroAtendimento = ep.exame.atendimento.numeroAtendimento
                        exame.dataAtendimento = ep.exame.atendimento.dataAtendimento
                        exame.codigo = ep.procedimento.descricao
                        exames.push(exame)
                    });

                    this.setState({ exames15dias: exames, rowExames15dias: rowData });
                    this.toogleModal15dias();

                } else {
                    this.props.loading(false);
                    this.getModalFromRow(rowData);
                }
            }, error => {
                this.props.loading(false);
                console.error(error);
                this.props.error({
                    message: "Ocorreu um erro ao tentar verificar os últimos atendimentos com exames prescritos do beneficiário do atendimento."
                });
            });
        } else {
            this.getModalFromRow(rowData);
        }
    };

    getAtendimento = (beneficiario) => {
        const isCooperado = userService.isCooperado(this.props.user);
        let filtro = {};
        if (isCooperado) {
            filtro = { beneficiario: beneficiario, cooperado: this.props.user }
        } else {
            filtro = { beneficiario: beneficiario }
        }
        atendimentoService.getAtendimentoByCarteiraAndCpfAndNomeBeneficiario(filtro).then(res => {
            let newValues = _.cloneDeep(this.props.values);
            newValues.numeroAtendimento = res.data.numeroAtendimento;
            newValues.atendimento = res.data;
            this.props.setValues(newValues);
            this.setState({ atendimento: res.data })
            this.props.loading(false);
        }, error => {
            console.error(error)
            let newValues = _.cloneDeep(this.props.values);
            newValues.numeroAtendimento = '';
            this.props.setValues(newValues);
            this.setState({ atendimento: {} })
            this.props.loading(false);
            this.props.error({
                message: "Não existe atendimento digitado para este paciente, favor digitar os dados do atendimento para prosseguir com a solicitação de exames."
            });
        });
    };

    findDadosBeneficiario = () => {
        this.props.loading(true);
        beneficiarioService
            .findByCarteirinha(this.props.values.numeroCarteira).then(response => {
                let newValues = _.cloneDeep(this.props.values);

                newValues.nomeBeneficiario = response.data.nomeBeneficiario;
                if (response.data.cpf) {
                    newValues.cpf = response.data.cpf;
                } else {
                    newValues.cpf = '';
                }

                this.props.setValues(newValues);
                this.getAtendimento(response.data);
            }, error => {
                console.error(error);
                this.props.loading(false);
                let newValues = _.cloneDeep(this.props.values);
                newValues.nomeBeneficiario = '';
                newValues.cpf = '';
                newValues.numeroAtendimento = '';
                this.setState({ atendimento: {} })
                this.props.setValues(newValues);
                this.props.error({
                    message: "Este beneficiário ainda não foi cadastrado. Solicite e preencha todas as informações do beneficiário."
                })
            }
            );
    };

    findDadosBeneficiarioByCpf = () => {
        this.props.loading(true);
        beneficiarioService
            .findByCpf(this.props.values.cpf).then(response => {
                let newValues = _.cloneDeep(this.props.values);
                newValues.nomeBeneficiario = response.data.nomeBeneficiario;
                newValues.numeroCarteira = response.data.numeroCarteira;
                this.props.setValues(newValues);
                this.getAtendimento(response.data);
            },
                error => {
                    console.error(error);
                    this.props.loading(false);
                    let newValues = _.cloneDeep(this.props.values);
                    newValues.nomeBeneficiario = '';
                    newValues.numeroCarteira = '';
                    newValues.numeroAtendimento = '';
                    this.setState({ atendimento: {} })
                    this.props.setValues(newValues);
                    this.props.error({
                        message: "Este beneficiário ainda não foi cadastrado. Solicite e preencha todas as informações do beneficiário."
                    })

                }
            );
    };

    findDadosBeneficiarioByNome = () => {
        this.props.loading(true);
        beneficiarioService
            .findByNome(this.props.values.nomeBeneficiario).then(response => {
                let newValues = _.cloneDeep(this.props.values);
                newValues.cpf = response.data.cpf;
                newValues.numeroCarteira = response.data.numeroCarteira;
                newValues.nomeBeneficiario = response.data.nomeBeneficiario;
                this.props.setValues(newValues);
                this.getAtendimento(response.data);
            },
                error => {
                    console.error(error);
                    this.props.loading(false);
                    let newValues = _.cloneDeep(this.props.values);
                    newValues.cpf = '';
                    newValues.numeroCarteira = '';
                    newValues.numeroAtendimento = '';
                    this.setState({ atendimento: {} })
                    this.props.setValues(newValues);
                    this.props.error({
                        message: "Este beneficiário ainda não foi cadastrado. Solicite e preencha todas as informações do beneficiário."
                    })

                }
            );
    };

    toogleModalExames = () => {
        if (this.state.atendimento && this.state.atendimento.numeroAtendimento) {
            this.setState({ showModalExames: !this.state.showModalExames })
        } else if (this.state.showModalExames) {
            this.setState({ showModalExames: !this.state.showModalExames })
        } else {
            this.props.error({
                message: "Não existe atendimento digitado para este paciente, favor digitar os dados do atendimento para prosseguir com a solicitação de exames."
            });
        }

    }

    toogleModal15dias = () => {
        this.setState({ showModal15dias: !this.state.showModal15dias })
    }

    IdsCooperadosAtendimento = (atendimento) => {
        let ids = []
        if (atendimento.cooperado && atendimento.cooperado.id) {
            ids.push(atendimento.cooperado.id)
        }
        if (atendimento.identificacaoAtendimentoExecucao) {
            for (let i = 0; i < atendimento.identificacaoAtendimentoExecucao.length; i++) {
                if (atendimento.identificacaoAtendimentoExecucao[i].cooperado && atendimento.identificacaoAtendimentoExecucao[i].cooperado.id) {
                    ids.push(atendimento.identificacaoAtendimentoExecucao[i].cooperado.id)
                }
            }
        }
        return ids;
    }

    confirmExames = () => {
        let newValues = _.cloneDeep(this.formRef.state.values);
        let ae = {
            atendimento: newValues.id,
            exames: newValues.exames,
            data: newValues.atendimentoExecucaos.length > 0 && newValues.atendimentoExecucaos[0].dataAtendimento,
            convenio: newValues.convenio.id,
            estabelecimento: newValues.estabelecimento.id,
            numeroCarteira: newValues.numeroCarteira
        }

        if (newValues.exames) {

            exameService.checkPrazo(ae).then(
                response => {
                    if (response.data.length > 0) {

                        let exameProcedimento = response.data;

                        let codExames = [];
                        let dataAten = [];
                        let cooperadosAten = [];

                        exameProcedimento.map(ep => {
                            codExames.push(ep.procedimento)
                            dataAten.push(ep.exame.atendimento.dataAtendimento)
                            cooperadosAten.push(ep.exame.atendimento.cooperado)
                        });

                        const allEqual = arr => arr.every(val => val.id === arr[0].id);
                        const isEqual = allEqual(cooperadosAten);

                        if (isEqual) {
                            let cooperado = cooperadosAten[0];
                            cooperadosAten = [];
                            cooperadosAten.push(cooperado);
                        }

                        ConfirmAcationService.confirmMsg(
                            () => {
                                this.saveExames();
                            },
                            "Exame realizado",
                            <span>
                                {" "}
                                Este beneficiário realizou o(s) exame(s){" "}

                                {codExames.map(e => {
                                    return <span className="font-bold">{e.codigo} - {e.descricao}{", "}</span>
                                })}
                                {" "}solicitado(s) pelo(s) cooperado(s){" "}

                                {cooperadosAten.map(e => {
                                    return <span className="font-bold">{e.nome}{", "}</span>
                                })}
                                {" "}na(s) data(s){" "}

                                {dataAten.map(data => {
                                    return <span className="font-bold">{moment(data).format("DD/MM/YYYY")}{", "}</span>
                                })}
                                . Deseja prosseguir?
                            </span>,
                            <span className="text-danger">{ }</span>,
                            "Não",
                            "Sim",
                            objectsConstants.TYPE_FORWARD
                        );
                    } else {
                        this.saveExames();
                    }
                }
            );
        }
    };

    saveExames = () => {
        this.props.loading(true);
        let newValues = _.cloneDeep(this.formRef.state.values);

        let ae = {atendimento: newValues.id, exames: newValues.exames, pacotes: newValues.pacotes}

        exameService.doSave(ae).then(
            response => {
                this.props.success({
                    message: "Exames preescritos com sucesso!"
                });
                this.props.loading(false);
                let newValues = _.cloneDeep(this.props.values);
                newValues.nomeBeneficiario = '';
                newValues.numeroCarteira = '';
                newValues.cpf = '';
                newValues.numeroAtendimento = '';
                this.props.setValues(newValues);
                this.setState({ atendimento: {} })
                this.dataTable.initData();
                this.toogleModalExames()
            },
            erros => {
                console.error(erros);
                this.props.loading(false);
                this.props.error({
                    message: "Ocorreu um erro ao preescrever os exames"
                });

            }
        );
    };

    render() {
        const {
            values,
            setFieldValue,
            errors,
            viewMode
        } = this.props;
        return (
            <React.Fragment>
                <div className="item">
                    <Card className="cardCrud content-form">
                        <CardHeader>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="mr-sm-auto card-title">Dados</div>
                                <div className="px-sm-3">
                                </div>
                            </div>
                        </CardHeader>

                        <CardBody className={"form-inside viewMode"}>
                            <div className="row section-form">
                                <h6 className="w-100">Solicitação de exame</h6>
                            </div>
                            <div className="row section-form">
                                <FormGroup className="">
                                    <InputViewEdit
                                        component={FormInput}
                                        label={"Nome do Beneficiario"}
                                        type="text"
                                        id="nomeBeneficiario"
                                        name="nomeBeneficiario"
                                        placeholder="Digite o nome do beneficiario"
                                        onBlur={this.findDadosBeneficiarioByNome}
                                        value={values.nomeBeneficiario}
                                        onChange={(name, value) => setFieldValue(name, value)}
                                        noSize={true}
                                        erroMensagem={errors.nomeBeneficiario}
                                        viewMode={viewMode}
                                        required={true}
                                    />
                                </FormGroup>
                                <FormGroup className="">
                                    <InputViewEdit
                                        component={FormInput}
                                        label={"Número da Carteira"}
                                        type="text"
                                        id="numeroCarteira"
                                        name="numeroCarteira"
                                        placeholder="Digite o o número da carteira"
                                        onBlur={this.findDadosBeneficiario}
                                        value={values.numeroCarteira}
                                        onChange={(name, value) => setFieldValue(name, value)}
                                        noSize={true}
                                        erroMensagem={errors.numeroCarteira}
                                        viewMode={viewMode}
                                        required={true}
                                    />
                                </FormGroup>
                                <FormGroup className="">
                                    <InputViewEdit
                                        component={CpfInput}
                                        label={"CPF"}
                                        type="text"
                                        id="cpf"
                                        name="cpf"
                                        placeholder="Digite o cpf"
                                        onBlur={this.findDadosBeneficiarioByCpf}
                                        value={values.cpf}
                                        onChange={(name, value) => setFieldValue(name, value)}
                                        noSize={true}
                                        erroMensagem={errors.cpf}
                                        viewMode={viewMode}
                                        required={true}
                                    />
                                </FormGroup>
                                <FormGroup className="">
                                    <InputViewEdit
                                        component={FormInput}
                                        label={"Atendimento"}
                                        type="text"
                                        id="numeroAtendimento"
                                        name="numeroAtendimento"
                                        value={values.numeroAtendimento}
                                        onChange={(name, value) => setFieldValue(name, value)}
                                        noSize={true}
                                        erroMensagem={errors.numeroAtendimento}
                                        viewMode={true}
                                    />
                                </FormGroup>
                                <FormGroup className="">
                                    <BtnLink
                                        type={"button"}
                                        className={""}
                                        icone={"icon-btn-add"}
                                        title={"Prescrever exames"}
                                        onSubmit={e => {
                                            e.stopPropagation();
                                            this.toogleModalExames()
                                        }}
                                    />
                                </FormGroup>
                            </div>
                        </CardBody>
                    </Card>
                    <Modal
                        isOpen={this.state.showModalExames}
                        toggle={this.toogleModalExames}
                        backdrop="static"
                        modalClassName=""
                        centered={true}
                        size={"lg"}
                    >
                        <ModalBody>
                            <Formik
                                validationSchema={Yup.object().shape({ exames: Yup.string().required('Obrigatorio').nullable() })}
                                validateOnBlur={false}
                                validateOnChange={false}
                                enableReinitialize={true}
                                initialValues={this.state.atendimento}
                                onSubmit={(values, actions) => this.confirmExames()}
                                ref={form => {
                                    this.formRef = form;
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    validateForm,
                                    setValues,
                                    touched
                                }) => {
                                    return (
                                        <form onSubmit={handleSubmit}>

                                            <div className="modalExames">
                                                <div className="row">
                                                    <div className="col-11 text-center my-2">
                                                        <h2 className="font-weight-bold">Exames solicitados:</h2>
                                                    </div>

                                                </div>


                                                <div className="row">
                                                    <div className="flex-fill px-5 m-auto justify-content-center overflow-auto">
                                                        <FormGroup className="">
                                                            <InputViewEdit
                                                                label={"Pacote(s) de exame(s)"}
                                                                component={FormSelectInput}
                                                                multi={true}
                                                                isMulti={true}
                                                                onChange={(name, date) => {
                                                                    setFieldValue(name, date);

                                                                    let examesList = [];
                                                                    let oldExames = values.exames ? values.exames : [];
                                                                    let oldPacotes = values.pacotes ? values.pacotes : [];

                                                                    if (!values.pacotes) {
                                                                        let ve = this.state.atendimento.exames;

                                                                        ve && ve.map((item) => {
                                                                            examesList.push(item);
                                                                        })
                                                                    }

                                                                    oldPacotes && oldPacotes.map((oldP) => {
                                                                        oldP.exames.map((eOld) => {
                                                                            oldExames = oldExames.filter(objeto => objeto.id !== eOld.id);
                                                                        })
                                                                    })

                                                                    date && date.map((e) => {
                                                                        e.exames.map((item) => {
                                                                            examesList.push(item);
                                                                        })
                                                                    })

                                                                    oldExames && oldExames.map((item) => {
                                                                        examesList.push(item);
                                                                    })

                                                                    const arrUniq = [...new Map(examesList.map(v => [v.id, v])).values()]

                                                                    setFieldValue(`exames`, arrUniq)

                                                                }}
                                                                required={false}
                                                                name={"pacotes"}
                                                                placeholder="Pacote(s) de exame(s)"
                                                                value={values.pacotes}
                                                                clearable={true}
                                                                labelKey={"nomePacote"}
                                                                valueKey={"nomePacote"}
                                                                returnFullObject={true}
                                                                parent={this.IdsCooperadosAtendimento(values)}
                                                                service={pacoteExameService.findByCooperado}
                                                                components={{ Control, MultiValueRemove, ClearIndicator }}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className="">
                                                            <InputViewEdit
                                                                label={"Descrição do(s) exame(s)"}
                                                                component={SelectInput}
                                                                multi={true}
                                                                isMulti={true}
                                                                onChange={(name, date) => {
                                                                    setFieldValue(name, date);
                                                                }}
                                                                required={false}
                                                                name={"exames"}
                                                                type={"text"}
                                                                value={values.exames}
                                                                defaultValue={values.exames ? values.exames.map((e, index) => {
                                                                    let value = e.descricao;
                                                                    if (index > 0) {
                                                                        value = ', ' + value
                                                                    }
                                                                    return value;
                                                                }) : ''}
                                                                clearable={true}
                                                                labelKey={"descricao"}
                                                                valueKey={"descricao"}
                                                                returnFullObject={true}
                                                                onFetchData={procedimentoService.find}
                                                                components={{ Control, MultiValueRemove, ClearIndicator }}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className="">
                                                            <InputViewEdit
                                                                label={"Código(s) de exame(s)"}
                                                                component={SelectInput}
                                                                multi={true}
                                                                isMulti={true}
                                                                onChange={(name, date) => {
                                                                    setFieldValue(name, date);
                                                                }}
                                                                required={false}
                                                                name={"exames"}
                                                                type={"text"}
                                                                value={values.exames}
                                                                clearable={true}
                                                                labelKey={"codigo"}
                                                                valueKey={"codigo"}
                                                                returnFullObject={true}
                                                                onFetchData={procedimentoService.find}
                                                                erroMensagem={errors.exames}
                                                                components={{ Control, MultiValueRemove, ClearIndicator }}
                                                            />
                                                        </FormGroup>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="flex-grow px-1 m-auto justify-content-center">

                                                        <button
                                                            className="btn btn-secondary white mt-3 mb-4 mx-2"
                                                            type={"button"}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.toogleModalExames()
                                                            }}
                                                        >
                                                            Cancelar
                                                        </button>
                                                        <button
                                                            className="btn btn-primary white mt-3 mb-4 mx-2"
                                                            type={'submit'}
                                                        >
                                                            Salvar
                                                        </button>

                                                    </div>

                                                </div>

                                            </div>
                                        </form>)
                                }}
                            </Formik>
                        </ModalBody>
                    </Modal>
                    <Modal
                        isOpen={this.state.showModal15dias}
                        toggle={this.toogleModal15dias}
                        backdrop="static"
                        modalClassName=""
                        centered={true}
                        size={"lg"}
                    >
                        <ModalBody>
                            <div>
                                <div className="row">
                                    <div className="col-11 text-center my-2 css-alert-sasc">
                                        <img src={iconWarning} alt="icon" />
                                        <h1 className={'txtGreenDark text-weight-bold'}>Exames realizados nos últimos 15 dias</h1>
                                    </div>
                                    <div className="col-11 my-2">
                                        <span>
                                            {this.state.exames15dias.map(e => (
                                                <div key={e.numeroAtendimento}>
                                                    <span className="font-bold">{e.codigo}</span>
                                                    {", "} no atendimento: <span className="font-bold">{e.numeroAtendimento}</span>
                                                    {", "} na data: <span className="font-bold">{moment(e.dataAtendimento).format("DD/MM/YYYY")}</span>
                                                </div>
                                            ))}
                                        </span>
                                    </div>

                                </div>
                                <div className={'row justify-content-around py-2 px-4 mx-3 align-content-center'}>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            this.toogleModal15dias();
                                        }}
                                    >
                                        Ok
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            this.toogleModal15dias();
                                            this.getModalFromRow(this.state.rowExames15dias)
                                        }}
                                    >
                                        Prescrever
                                    </button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
                <Datatable
                    listFields={fields}
                    showCheckboxColumn={true}
                    columnsExport={columnsExport}
                    idRow={"id"}
                    name={"Atendimento(s) do dia"}
                    service={solicitaExameService}
                    keyTagItem={'numeroAtendimento'}
                    showAllColumnsFilter={false}
                    initFilter={this.state.initFilter}
                    ActionLine={LinhaAcaoPrescreverExames}
                    onRowClick={this.handleRowClick}
                    wrappedComponentRef={c => (this.dataTable = c)}
                    classHeader={[]}
                    classColumn={[]}
                />
            </React.Fragment>
        );
    }
}
const mapDispatch = ({
    alert: { success, error, clear, info },
    load: { loading }
}) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear(),
    info: msg => info(msg)
});

function mapStateToProps(state) {
    const { user, permissions } = state.authentication;

    return {
        user,
        permissions
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(SolicitacaoExameForm));
