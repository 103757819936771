//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const estabelecimentoService = {
    ativarUsuario,
    inativarUsuario,
    doGet,
    validarEstabelecimento,
    dadosContratado,
    doSave,
    findByFilterAtendimento,
    findByFilter,
    findTableColumns,
    findSearchableColumns,
    exportToExcel,
    addUsuario,
    removerUsuario,
    find,
    findAll,
    findConsultoriosAtivos,
    historicoConsultorio,
    findCep,
    findLogradouro,
    findByNome
};

function removerUsuario(consultorio,usuario){
  return defaultService.doPost(`${urlsConstants.ESTABELECIMENTO}removerUsuario/${consultorio.id}`,usuario);
}
function addUsuario(consultorio,usuario){
  return defaultService.doPost(`${urlsConstants.ESTABELECIMENTO}addUsuario/${consultorio.id}`,usuario);
}

function findTableColumns() {
  return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO}list/fields`);
}

function findAll() {
    return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO}findAll`);
}

function findConsultoriosAtivos() {
  return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO}findConsultoriosAtivos`);
}

function findSearchableColumns() {
  return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO}search/fields`);
}

function findByFilter(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.ESTABELECIMENTO}list`,
    options,
    filter
  );
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO}${id}`);
}

function validarEstabelecimento(id) {
  return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO}validarEstabelecimento/${id}`);
}

function dadosContratado(id) {
  return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO}dadosContratado/${id}`);
}

function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.ESTABELECIMENTO, entity);
}

function inativarUsuario(ids){
    return defaultService.doPost(`${urlsConstants.ESTABELECIMENTO}inativar`,ids);
}

function ativarUsuario(ids){
    return defaultService.doPost(`${urlsConstants.ESTABELECIMENTO}ativar`,ids);
}


function findByFilterAtendimento (filter,input){
    filter.descricaoFiltro = input;
    return defaultService.doPost(`${urlsConstants.ATENDIMENTO}getClinicaByFiltroAtendimento`,filter);

}

function exportToExcel(filter, fields, labels) {
  return defaultService.exportToExcel('estabelecimento', filter, fields, labels);
}

function historicoConsultorio(id) {
  return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO}historicoConsultorio/${id}`);
}

function find(convenio,estabelecimento) {
  return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO}find/${convenio.convenio.id}/${estabelecimento}`);
}

function findCep(input) {
  return defaultService.doPost(`${urlsConstants.ESTABELECIMENTO}findCEP`,input);
}

function findLogradouro(input) {
  return defaultService.doPost(`${urlsConstants.ESTABELECIMENTO}findLogradouro`,input);
}

function findByNome(input) {
  return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO}findByNome/${input}`);
}