import React, { PureComponent } from "react";
import people from "../../img/people.svg";

export default class CpfNaoEncontrado extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>

       

        <div className="row">
          <div className="col-12 text-center move-up">
            <img src={people} alt="pessoa" />
          </div>
          <div className="col-12 text-center mt-3">
            <h2>
              <b>{this.props.username}</b>
            </h2>
          </div>
          <div className="col-12 txtCooperado">
            <p><b>
              Infelizmente o CPF informado não foi encontrado no nosso banco
              de dados.
            </b></p>
            <p>
              Confira se foi digitado corretamente ou procure saber com o seu
              supervisor se você já foi cadastrado. Agora se você ainda não é um
              cooperado, clique no botão abaixo:
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col mb-15-negative text-center">
            <button className="btn-secondary" type="button" onClick={() => {this.props.togleComoSerUmCooperado(true);}}>
              Quero ser um Cooperado
            </button>
          </div>
        </div>

      </React.Fragment>
    );
  }
}
