//@flow
import * as React from "react";
import { connect } from "react-redux";
import SelectInput from "../../components/Inputs/SelectInput";
import { beneficiarioService } from "../../services/beneficiario.service";

type Props = {
    label: string,
    name: string,
    value: any,
    onChange: any,
    returnFullObject: boolean,
    messageValidate?: any,
    disabled?: boolean,
    size?: number,
    required: boolean,
    service: any
};

type State = {
    options: Array<any>
};

//const animatedComponents = makeAnimated();

class BeneficiarioFilterSelectInput extends React.PureComponent<Props, State> {
    _isMounted = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            options: []
        };
    }



    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <SelectInput
                isMulti={this.props.isMulti}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={this.props.onChange}
                name={this.props.name}
                id={this.props.id}
                value={this.props.value}
                valueKey={"id"}
                labelKey={"nomeBeneficiario"}
                parent={this.props.filter}
                onFetchData={beneficiarioService.findByFilterAtendimento}
                returnFullObject={this.props.returnFullObject}
                required={this.props.required}
                clearable={true}
                className={this.props.className}
                placeholder={"Beneficiário"}
                label={`Beneficiário`}
                erroMensagem={this.props.erroMensagem}
            />
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    return {
        user
    };
}

export default connect(mapStateToProps)(BeneficiarioFilterSelectInput);
