//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Bar } from "@nivo/bar";
import { Pie } from '@nivo/pie';
import Datatable from '../../../sascComponents/datagrid/Datatable';
import { examePacoteExameService } from '../../../services/examePacoteExame.service';
import { Card, CardBody, CardHeader, FormGroup } from 'reactstrap';
import InputViewEdit from '../../../components/Inputs/InputViewEdit';
import EspecialidadesSelectInput from '../../../sascComponents/especialidades/EspecialidadesSelectInput';
import _ from 'lodash';
import DatePickerInput from '../../../components/Inputs/DatePickerInput';
import { dateHelper } from '../../../helpers/date.helper';
import ConvenioSelectInput from '../../../sascComponents/convenios/ConvenioSelectInput';
import BtnLink from '../../../components/Buttons/BtnLink';
import { relatoriosService } from '../../../services/relatorios.service';
import download from 'downloadjs';

class DashStatusExames extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {
            pacoteSelecaoQuantidade: [],
            especialidadeProcedimentoQuantidade: []
        }

    }

    retrivePacoteSelecaoQuantidade = (filter) => {
        examePacoteExameService.listPacoteSelecaoQuantidade(filter).then(
            response => {
                if (response.data) {
                    let data = [];
                    response.data.map((quantidade) => {
                        let item = { id: '', value: '' }
                        item.id = quantidade.hasPacote
                        item.value = quantidade.quantidade
                        data.push(item)
                    })
                    const totalQuantidades = data.reduce((a, b) => a + b.value, 0);
                    data.forEach((item) => {
                        const porcentagem = (item.value / totalQuantidades) * 100;
                        const percent = porcentagem.toFixed(2);
                        item.id = `${item.id} (${percent}%)`;
                    });
                    this.setState({ pacoteSelecaoQuantidade: data })
                }
            },
            (e) => {
                console.error(e);
            }
        );
    }

    retriveEspecialidadeProcedimentoQuantidade = (filter) => {
        examePacoteExameService.listEspecialidadeProcedimentoQuantidade(filter).then(
            response => {
                if (response.data) {
                    let data = [];
                    response.data.sort((a, b) => a.quantidade - b.quantidade);
                    response.data.map((especialidade) => {
                        let item = { data: '', Especialidade: '' }
                        item.data = especialidade.nome
                        item.Especialidade = especialidade.quantidade
                        data.push(item)
                    })
                    this.setState({ especialidadeProcedimentoQuantidade: data })
                }
            },
            (e) => {
                console.error(e);
            }
        );
    }

    render() {
        const commonProps = {
            width: 900,
            height: 900,
            margin: { top: 60, right: 110, bottom: 350, left: 80 },
            indexBy: "data",
            keys: ["Especialidade"],
            padding: 0.2,
            labelTextColor: "white",
            labelSkipWidth: 16,
            labelSkipHeight: 16,
        };
        const colors = { Especialidade: "green" };
        const {
            values,
            setFieldValue,
            errors,
            handleChange,
            isSubmitting,
            handleSubmit,
            viewMode,
            validateForm,
            setValues
        } = this.props;
        return (

            <React.Fragment>
                <div className="item">
                    <Card className="cardCrud content-form">
                        <CardBody className={"form-inside viewMode"}>
                            <div className="row section-form">
                                <FormGroup className="col">
                                    <InputViewEdit
                                        label={"Data início"}
                                        component={DatePickerInput}
                                        onChange={(name, date) => {
                                            setFieldValue(name, date);
                                        }}
                                        erroMensagem={
                                            _.get(errors, `dataInicio`)
                                        }
                                        defaultValue={
                                            values.dataInicio
                                                ? dateHelper.format(values.dataInicio)
                                                : "--"
                                        }
                                        mask={"99/99/9999"}
                                        name={"dataInicio"}
                                        id={"dataInicio"}
                                        placeholder=""
                                        required={true}
                                        type={"text"}
                                        viewMode={false}
                                        value={values.dataInicio}
                                    />
                                </FormGroup>
                                <FormGroup className="col">
                                    <InputViewEdit
                                        label={"Data fim"}
                                        component={DatePickerInput}
                                        onChange={(name, date) => {
                                            setFieldValue(name, date);
                                        }}
                                        erroMensagem={
                                            _.get(errors, `dataFim`)
                                        }
                                        defaultValue={
                                            values.dataFim
                                                ? dateHelper.format(values.dataFim)
                                                : "--"
                                        }
                                        mask={"99/99/9999"}
                                        name={"dataFim"}
                                        id={"dataFim"}
                                        placeholder=""
                                        required={true}
                                        type={"text"}
                                        viewMode={false}
                                        value={values.dataFim}
                                    />
                                </FormGroup>
                                <FormGroup className="">
                                    <BtnLink
                                        type={"button"}
                                        className={""}
                                        icone={"icon-btn-filtro"}
                                        title={"Gerar indicadores"}
                                        onSubmit={e => {
                                            e.stopPropagation();
                                            this.retrivePacoteSelecaoQuantidade(values);
                                            this.retriveEspecialidadeProcedimentoQuantidade(values)
                                            this.dataTable && this.dataTable.initData();
                                            this.props.loading(false);
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup className="">
                                    <BtnLink
                                        type={"button"}
                                        className={""}
                                        icone={"icon-btn-imprimir"}
                                        title={"Gerar relatório"}
                                        onSubmit={e => {
                                            e.stopPropagation();
                                            this.props.loading(true);
                                            relatoriosService.exportRelatorio(values, 'statusExames').then(
                                                response => {

                                                    var base64 = _.get(response, 'data');
                                                    if (base64 != null) {
                                                        let asciiString = atob(base64);
                                                        let array = new Uint8Array(
                                                            [...asciiString].map((char) => char.charCodeAt(0))
                                                        );
                                                        const file = new Blob([array], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                                                        download(
                                                            file,
                                                            'RelatorioStatusExames.xlsx'
                                                        );
                                                    }
                                                    this.props.loading(false);
                                                },
                                                () => {
                                                    this.props.loading(false);
                                                    this.props.error('Não foi possível exportar arquivo.');
                                                }
                                            );
                                        }}
                                    />
                                </FormGroup>
                            </div>
                            <div className="row section-form">
                                <FormGroup>
                                    <InputViewEdit
                                        component={EspecialidadesSelectInput}
                                        label={"Especialidades"}
                                        name="especialidades"
                                        id="especialidades"
                                        multi={true}
                                        placeholder="Selecione as especialidades"
                                        onChange={(name, value) => {
                                            setFieldValue(name, value);
                                        }}
                                        returnFullObject={true}
                                        value={values.especialidades}
                                        className="sasc-select"
                                        required={true}
                                        erroMensagem={errors.especialidades}
                                        defaultValue={
                                            (values &&
                                                values.especialidades &&
                                                values.especialidades.length
                                                ? values.especialidades
                                                    .map(item => item.nome)
                                                    .join(', ')
                                                : '--')
                                        }
                                    ></InputViewEdit>
                                </FormGroup>
                            </div>
                            <div className="row section-form">
                                <FormGroup>
                                    <ConvenioSelectInput
                                        label={"Convênios"}
                                        name="convenios"
                                        id="convenios"
                                        bloquearConsultorio={false}
                                        isMulti={true}
                                        placeholder="Selecione os convênios"
                                        onChange={(name, value) => {
                                            setFieldValue(name, value);
                                        }}
                                        erroMensagem={errors.convenios}
                                        returnFullObject={true}
                                        value={values.convenios}
                                        className="sasc-select"
                                        disabled={viewMode}
                                        required={true}
                                    />
                                </FormGroup>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="" style={{ backgroundColor: 'white' }}>
                    {(this.state.pacoteSelecaoQuantidade.length && this.state.especialidadeProcedimentoQuantidade.length) ? <>
                        <div className="row text-center">
                            <div className='col'>
                                <Datatable
                                    showCheckboxColumn={false}
                                    idRow={"id"}
                                    //name={"Cooperados"}
                                    service={examePacoteExameService}
                                    disableSearch={true}
                                    disableExport={true}
                                    disableName={true}
                                    classHeader={[
                                        "headerLeft",
                                        "headerLeft"
                                    ]}
                                    classColumn={[]}
                                    wrappedComponentRef={c => (this.dataTable = c)}
                                    initFilter={[
                                        {
                                            fieldName: "dataInicio",
                                            value: values.dataInicio,
                                        },
                                        {
                                            fieldName: "dataFim",
                                            value: values.dataFim,
                                        },
                                        {
                                            fieldName: "especialidades",
                                            value: values.especialidades && values.especialidades.map(item => item.id).join(','),
                                        },
                                        {
                                            fieldName: "convenios",
                                            value: values.convenios && values.convenios.map(item => item.id).join(','),
                                        }
                                    ]}
                                />
                            </div>
                            <div className='col'>
                                <h2 className="col-12 mt-5 font-bold">Seleção do Pacote</h2>
                                <div className="col-12">
                                    <Pie
                                        animate
                                        data={this.state.pacoteSelecaoQuantidade}
                                        height={500}
                                        legends={[]}
                                        margin={{
                                            bottom: 80,
                                            left: 120,
                                            right: 120,
                                            top: 80
                                        }}
                                        theme={{
                                            text: {
                                                fontFamily: '\'SFMono-Regular\', Consolas, \'Liberation Mono\', Menlo, Courier, monospace'
                                            }
                                        }}
                                        width={850}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row text-center ">
                            <div className='col'>
                                <h2 className="col-12 mt-5 font-bold">Quantidade de Exames por Especialidade(s)</h2>
                                <div className="col-12">
                                    <Bar
                                        colors={(bar) => {
                                            return colors[bar.id];
                                        }}
                                        groupMode="stacked"
                                        data={this.state.especialidadeProcedimentoQuantidade}
                                        {...commonProps}
                                        layers={[
                                            "grid",
                                            "axes",
                                            "bars",
                                            "markers",
                                            "legends",
                                        ]}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Quantidade',
                                            legendPosition: 'middle',
                                            legendOffset: -40
                                        }}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: -90,
                                            legend: '20 Especialidades com mais exames',
                                            legendPosition: 'middle',
                                            legendOffset: -525
                                        }}
                                    />
                                </div>
                            </div>
                        </div></> : ""}
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatch = ({
    alert: { success, error, clear },
    load: { loading }
}) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear()
});

export default connect(
    null,
    mapDispatch,
    null,
    { withRef: true }
)(withRouter(DashStatusExames));
