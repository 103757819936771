//@flow

const URL_BASE: string = process.env.REACT_APP_CLIENT_URL_API || '';
const CONTEXT_PATH: string = process.env.REACT_APP_CONTEXT_PATH || '';
const URL = URL_BASE;

export const urlsConstants = {
    URL_DOMAIN: URL,
    CONTEXT_PATH,
    USER_PUBLIC: `${URL}/public/usuario`,
    RELATORIOS: `${URL}/relatorio/`,
    OAUTH_TOKEN: URL_BASE + '/oauth/token',
    USER_ME: `${URL_BASE}/usuario/me`,
    USUARIO: `${URL_BASE}/usuario/`,
    COOPERADO: `${URL_BASE}/cooperado/`,
    MIDIA: `${URL_BASE}/public/midia/`,
    ATENDIMENTO: `${URL_BASE}/atendimento/`,
    CONVENIO: `${URL_BASE}/convenio/`,
    HOSPITAL_LOCAL: `${URL_BASE}/hospitalLocal/`,
    BENEFICIARIO: `${URL_BASE}/beneficiario/`,
    ENUM_API: `${URL_BASE}/enum/`,
    ESPECIALIDADES: `${URL_BASE}/especialidade/`,
    GRAU_API: `${URL_BASE}/grau/`,
    VIA_API: `${URL_BASE}/viaacesso/`,
    TECNICA_API: `${URL_BASE}/tecnica/`,
    BANCO: `${URL_BASE}/banco/`,
    ESTABELECIMENTO: `${URL_BASE}/estabelecimento/`,
    ESTABELECIMENTO_COOPERADO: `${URL_BASE}/estabelecimentoCooperado/`,
    ESTABELECIMENTO_USUARIO: `${URL_BASE}/estabelecimentoUsuario/`,
    STATUS_ATENDIMENTO_SETORES: `${URL_BASE}/statusatendimento/setor/`,
    STATUS_ATENDIMENTO: `${URL_BASE}/statusatendimento/`,
    SUB_STATUS: `${URL_BASE}/subStatus/`,
    LOTE: `${URL_BASE}/lote/`,
    PENDENCIA: `${URL_BASE}/pendencia/`,
    NOTIFICACAO: `${URL_BASE}/notificacao/`,
    ERROR_NOTIFICACAO: `${URL_BASE}/errorNotificacao/`,
    WS_WEBSOCKET: URL_BASE + '/ws',
    CONSELHO: `${URL_BASE}/conselho/`,
    COOPERATIVA: `${URL_BASE}/cooperativa/`,
    PROCEDIMENTO: `${URL_BASE}/procedimento/`,
    CAMPOATENDIMENTO: `${URL_BASE}/campoAtendimento/`,
    CID: `${URL_BASE}/cid/`,
    OPM: `${URL_BASE}/opm/`,
    MODELO_VISUALIZACAO: `${URL_BASE}/modeloVisualizacaoGuia/`,
    REPORT: `${URL_BASE}/public/report/`,
    COMPLEMENTO_CONVENIO: `${URL_BASE}/complementoConvenio/`,
    ACOMODACAO: `${URL_BASE}/acomodacao/`,
    NOTIFICACAO_CONSULTORIO: `${URL_BASE}/notificacaoConsultorio/`,
    ALERT_COOPERATIVA_GUIA: `${URL_BASE}/message_guia/`,
	JUSTIFICATIVA: `${URL_BASE}/justificativa/`,
	PACOTEEXAME: `${URL_BASE}/pacoteexame/`,
    EXAME: `${URL_BASE}/exame/`,
    MENSAGEM: `${URL_BASE}/mensagem/`,
    SOLICITAEXAME: `${URL_BASE}/solicitaExame/`,
    EXAMEPACOTEEXAME: `${URL_BASE}/examePacoteExame/`,
    CONVENIO_USUARIO: `${URL_BASE}/convenioUsuario/`,
    INTEGRACAOATENDIMENTO: `${URL_BASE}/integracaoAtendimento/`,
    CONVENIO_PLANO: `${URL_BASE}/convenioPlano/`,
    AVISOS: `${URL_BASE}/avisoSistemaGeral/`,
    REGIME: `${URL_BASE}/regime/`,
    DESCRICAO_PENDENCIA: `${URL_BASE}/descricaoPendencia/`
};