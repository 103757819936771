import { Can } from "@casl/react";
import * as _ from "lodash";
import * as React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import { Collapse, Fade } from "reactstrap";
import UserAvatar from "../../components/Avatar/Avatar";
import BtnLink from "../../components/Buttons/BtnLink";
import { objectsConstants } from "../../constants/objects.constants";
import IconFaturado from "../../img/icon_faturado_BLACK.svg";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { loteAtendimentoService } from "../../services/loteAtendimento.service";
import { translate } from "../../helpers/message.helper";

function SampleNextArrow(props) {
  const { style, onClick } = props;
  return <div className={"next-icon"} style={{ ...style }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return <div className={"prev-icon"} style={{ ...style }} onClick={onClick} />;
}

class LinhaAcaoAtivarInativarAtendimento extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = { lotes: [], isFull: false, buscarlistalote: false };
  }

  updateList = () => {
    this.setState({ isFull: window.innerWidth > 1919 });
    if(this.props.lotesOpen){
    loteAtendimentoService
      .findByFilter({}, this.props.filter)
      .then(response => {
        this.setState({ lotes: response.data.data.slice(0).reverse() });
      });
    }
  };

async  buscarlista(){
 await loteAtendimentoService
      .findByFilter({}, this.props.filter)
      .then(response => {
        this.setState({ lotes: response.data.data.slice(0).reverse() });
        this.setState({buscarlistalote: true})
        this.props.loading(false)
      })
}

  onWindowResize = () => {}

  componentDidMount() {
    this.updateList();

  }




  canAddUntoThis = (lote, atendimentos) => {
    let cannAdd = true;
    atendimentos.forEach(atendimento => {
      let situacao = _.get(atendimento, "status.situacao");
      let canEdit = situacao === objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA || situacao === objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE;
      let convenio = _.get(atendimento, "estabelecimentoConvenio.convenio.id") ===
        _.get(lote, "convenio.id");
      if (!canEdit || !convenio) {
        cannAdd = false;
      }
    });
    var countBy = _.countBy(
      atendimentos,
      "estabelecimentoConvenio.convenio.id"
    );
    // convenio esta na lista de atendimento e possuem somente atendiementos para mesmo convênio e staus atendimento
    if (cannAdd && Object.keys(countBy).length == 1) {
      return true;
    }
    return false;
  };

  addAtendimenosInLote = (atendimentos, lote) => {

    loteAtendimentoService.addAtendimentos(lote.id, atendimentos).then(
      response => {
        this.props.success({
          message: `Atendimentos adicionados com sucesso!`
        });
        this.props.initData();
        this.props.loteAtual(lote.id);
        this.props.loading(false);
      },
      erros => {
        console.error(erros.response);
        try {
          let response = erros.response.data;
          if (response && response.messages) {
            for (var i = 0; i < response.messages.length; i++) {
              let erroItem = response.messages[i];
              this.props.error({
                message: translate(erroItem.message.code)
              });
            }
          }
        } catch (error) {
          console.error(error);
        }

        this.props.loading(false);
      }
    );
  };

  render() {

    const { isFull } = this.state;
    const qtdSlideResponsive = isFull ? 6 : 4;
    const settingsSlides = {
      dots: false,
      infinite: false,
      slidesToShow: qtdSlideResponsive,
      slidesToScroll: 1,
      lazyLoad: true,
      swipeToSlide: true,
      arrows: true,
      centerMode: false,
      variableWidth: true,
      className: "center variable-width",
      beforeChange: (current,next)=>{
      if((current+(window.screen.width/255)+1)>this.state.lotes.length){
        let page = this.state.lotes.length / 10;
        loteAtendimentoService
            .findByFilter({  page: page}, this.props.filter)
            .then(response => {
              let lotes = _.cloneDeep(this.state.lotes);
              let newLotes = response.data.data.slice(0).reverse();
              for(let i = 0; i<newLotes.length;i++){
                lotes.push(newLotes[i]);
              }
              this.setState({ lotes });
            });
      }
      },
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    if(this.props.lotesOpen && !this.state.buscarlistalote){
      this.props.loading(true);
      this.setState({buscarlistalote: true});
      this.buscarlista()

    }else if (!this.props.lotesOpen && this.state.buscarlistalote){
      this.setState({buscarlistalote: false});
    }

    const listaLotes = this.state.lotes.map(lote => {
      return (
        <React.Fragment key={lote.id}>
          <Can
            I={objectsConstants.VISUALIZAR}
            a={objectsConstants.CRUD_LOTES_VIEW}
            ability={this.props.permissions}
          >
            <Fade in={this.props.lotesOpen} timeout={300}>
              <div
                className={`box-lote ${lote.id == this.props.idLoteAtual &&
                  "borda"}`}
                style={{ width: 255 }}
              >
                <div className="box-lote-info p-3">
                  <div className="box-lote-txt d-flex flex-row">
                    <div className="w-35 d-flex align-items-center justify-content-center">
                      <img
                        src={IconFaturado}
                        className="icon-lote"
                        alt="iconFoto"
                      />
                    </div>
                    <div>
                      <h2>{`Lote ${lote.id}`}</h2>
                      <p className="sub-title">{_.get(lote, "convenio.nome")}</p>
                      <p className="verde-destaque italic">{`${lote.listAtendimentoLength} atendimentos`}</p>
                    </div>
                  </div>
                  <div className=" mt-3 box-lote-criador d-flex flex-row">
                    <div className="w-35 pr-1 d-flex align-items-center justify-content-end">
                      <UserAvatar
                        className=" float-right"
                        user={lote.usuario}
                        size={16}
                      />
                    </div>
                    <div className="">
                      <p className="fz-sm-10 sub-title">{_.get(lote, "usuario.nome")}</p>
                      <p className="fz-sm-10 txtGray">{lote.statusAtual}</p>
                    </div>
                  </div>
                </div>
                {/* add classe "verde" quando footer for verde */}
                {this.props.rowsCheckedValue.length > 0 &&
                  this.canAddUntoThis(lote, this.props.rowsCheckedValue) && (
                    <div className="footer-box-lote verde text-center">
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => {
                          ConfirmAcationService.confirmMsg(
                            () => {
                              this.addAtendimenosInLote(
                                this.props.rowsCheckedValue,
                                lote
                              );
                            },
                            "Adicionar em um lote",
                            <span>
                              {" "}
                            Deseja adicionar os atendimentos selecionados no
                            lote existente?
                          </span>,
                            <span className="verde-destaque">{`${this.props.rowsCheckedValue.length} atendimentos selecionados`}</span>,
                            "Não",
                            "Sim",
                            objectsConstants.TYPE_ADD_IN_LOTE
                          );
                        }}
                      >
                        <span className="icon-btn-add-branco"></span>
                      Adicionar atendimentos
                    </button>
                    </div>
                  )}
                {(this.props.rowsCheckedValue.length <= 0 ||
                  !this.canAddUntoThis(lote, this.props.rowsCheckedValue)) && (
                    <React.Fragment>
                      <div className="footer-box-lote ">
                        <BtnLink
                          type={"button"}
                          className={"float-right"}
                          icone={"icon-visualizar"}
                          title={"Visualizar"}
                          onSubmit={e => {
                            this.props.history.push({
                            pathname: "/lotes/" + lote.id,
                            state: { lote }
                            });                          
                          }}
                          disabled={false}
                        />
                        {(lote.situacaoLote !==
                          objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA || lote.mensagemErroProcessamento) && (<BtnLink
                            type={"button"}
                            className={"float-left"}
                            icone={"icon-refresh"}
                            title={"Sincronizar"}
                            onSubmit={e => {
                              this.props.loading(true);
                              loteAtendimentoService
                                .atualizarStatusLote(lote.id)
                                .then(response => {
                                  this.props.loading(false);
                                  this.props.updateNotificacao(true)
                                  this.props.success({
                                    message: `Lote atualizado com sucesso!`
                                  });
                                  this.props.history.push({
                                    pathname: "/visualizarLote/" + lote.id,
                                    state: { lote }
                                  });
                                }, () => {
                                  this.props.loading(false);
                                  this.props.error({
                                    message: `Não foi possível Sincronizar a situação do lote, tente mais tarde.`
                                  });
                                });
                            }}
                            disabled={false}
                          />)}
                      </div>
                    </React.Fragment>
                  )}
              </div>
            </Fade>
          </Can>
        </React.Fragment>
      );
    });

    return (
      <React.Fragment>
        <Collapse
          className={this.props.classe}
          isOpen={this.props.lotesOpen}
          unmountOnExit={true}
          timeout={{ enter: 2000, exit: 2000 }}
        >
          <Slider {...settingsSlides}>{listaLotes.length > 0 ? listaLotes : <div className="mt-5">Não existe nenhum lote criado.</div>}</Slider>

        </Collapse>
      </React.Fragment >
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  lotesCollapse: { toggleLotes, loteAtual },
  notification: {updateNotificacao}
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear(),
  updateNotificacao: (notifications) => updateNotificacao(notifications),
  toggleLotes: () => toggleLotes(),
  loteAtual: idLoteAtual => loteAtual({ idLoteAtual })
});

function mapStateToProps(state) {
  const { lotesOpen, idLoteAtual } = state.lotesCollapse;
  const { permissions } = state.authentication;

  return {

    lotesOpen,
    permissions,
    idLoteAtual
  };
}

export default connect(mapStateToProps, mapDispatch, null, { withRef: true })(
  withRouter(LinhaAcaoAtivarInativarAtendimento)
);
