import React from 'react';
import { components } from 'react-select';

const MultiValueRemove = (props: MultiValueRemoveProps) => {
    return (
        <components.MultiValueRemove {...props}>
            <button
                type="button"
                className="btn-excluir"
            />
        </components.MultiValueRemove>
    );
};

export default MultiValueRemove;