import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { objectsConstants } from "../../constants/objects.constants";
import { formatterHelper } from "../../helpers/formatter.helper";
import Icon_atendimentos from "../../img/icon_atendimentos.svg";
import Icon_usuarios from "../../img/icon_usuarioCooperado.svg";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import AvatarSimples from "../../components/Avatar/AvatarSimples";


class ConveniosTopoView extends PureComponent<Props, State> {
  isAtivo = values => {
    return values.statusContratoConvenio && values.statusContratoConvenio.name === "ATIVO" ? true : false;

  };

  getBtnActiveInative = () => {
    const { values } = this.props;
    let textAction = this.isAtivo(values) ? "Bloquear" : "Desbloquear";
    return (
      <button
        type="button"
        className={`btn btn-${this.isAtivo(values) ? "secondary" : "primary"}`}
        onClick={() => {
          ConfirmAcationService.confirmMsg(
            this.props.disableEnabledConvenio,
            textAction + " Convênio",
            "Você confirma que deseja " +
            textAction +
            " o convênio: " +
            values.nome,
            null,
            "Cancelar",
            "Confirmar",
            objectsConstants.TYPE_WARNING
          );
        }}
      >
        <span
          className={`icon-btn-${this.isAtivo(values) ? "fechar" : "check"}`}
        ></span>
        {textAction} Convênio
      </button>
    );
  };
  render() {

    const { values, qtdeAtendimento, qtdeProfissionais } = this.props;
    return (
      //add classe "bg-branco" abaixo quando esição/criação
      <React.Fragment>
        <div className="barra-topo px-3 bg-branco rounded d-flex flex-column flex-sm-row align-content-stretch flex-wrap">
          <div className="bloco-dados-user px-sm-3 py-4 d-flex align-items-center justify-content-center flex-fill">
            {values && <AvatarSimples nome={values.nome}></AvatarSimples>}
            <div className="info-user pl-3">
              <React.Fragment>
                {" "}
                <p>
                  Convênio
                </p>
                <h2 className="font-weight-bold">
                  {formatterHelper.getNameOrHifen(values.nome)}
                </h2>
              </React.Fragment>
            </div>
          </div>

          {qtdeAtendimento !== undefined && (
            <div className="px-sm-5 py-2 py-sm-4  d-flex align-items-center justify-content-center flex-fill">
              <div className="d-flex align-items-center ">
                <img src={Icon_atendimentos} alt="iconPerfil" className="icon-atentimento" />
              </div>
              <div className="info-texto px-3 mt-2 mt-sm-0">
                Total realizado
                <div
                  className={`font-weight-bold f-16`}
                >
                  {values.quantidadeAtendimentos} Atendimentos
                </div>
              </div>
            </div>
          )}

          {qtdeProfissionais !== undefined && (
            <div className="px-sm-5 py-2 py-sm-4  d-flex align-items-center justify-content-center flex-fill">
              <div className="d-flex align-items-center ">
                <img src={Icon_usuarios} alt="iconUsuarios" className="icon-atentimento" />
              </div>
              <div className="info-texto px-3 mt-2 mt-sm-0">
                Profissionais vinculados
                <div
                  className={`font-weight-bold f-16`}
                >
                  {values.quantidadeCooperados} profissionais
                </div>
              </div>
            </div>
          )}

          <div className="info-texto px-sm-5 py-2 py-sm-4  d-flex align-items-center justify-content-center">
            <div className="info-texto px-3 mt-2 mt-sm-0">
              Status
            <div
                className={`font-weight-bold f-16 ${values.status &&
                  values.status.className}`}
              >
                {values.status && values.status.description}
              </div>
            </div>
          </div>
          {
           // Não permitir inativar convenio até que sasc tenha integracao para inativar convenio
              <div className="px-sm-3 py-2 d-flex flex-row align-items-center justify-content-center border-left">

                {this.getBtnActiveInative()}
              </div> }
              </div>
            </React.Fragment>);

          }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(ConveniosTopoView));
