import * as React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import { objectsConstants } from "../../constants/objects.constants";
import { Can } from "@casl/react";
import * as _ from "lodash";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { loteAtendimentoService } from "../../services/loteAtendimento.service";
import { atendimentoService } from "../../services/atendimento.service";
import { defaultService } from "../../services/defaultService";
import { translate } from "../../helpers/message.helper";
import { Modal, ModalBody } from 'reactstrap';
import fechar from "../../img/icon_fechar_BLACK.svg";
import { statusAtendimentoService } from "../../services/statusAtendimento.service";
import SelectInput from "../../components/Inputs/SelectInput";
import iconCriarLote from '../../img/icon_criarLote.svg';
import { Formik } from "formik";
import * as Yup from "yup";
import { FormGroup } from "../../components/FormGroup";
import FormTextArea from "../../components/Inputs/FormTextArea";
import FormInput from "../../components/Inputs/FormInput";
import { userService } from "../../services/user.service";
let download = require('downloadjs');
class LinhaAcaoAtivarInativarAtendimento extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.faturado = false;
        this.state = {
            filter: {},
            toogleModalEncaminhaAtendimento: false,
            convenio: null,
            convenios: null,
            toogleModalCriarLote: false,
            toggleJustificativaCancelamentoGuia: false,
            statusEdicao: [],
            statusEdicaoEncaminhamento: [],
            atendimentosProtocolo: [],
            showIncluirProtocolo: false,
            numeroProtocoloEncam: 0,
            toogleModalCriarProtocolo: false,
            convenioProtocolo: null
        };
    }

    visualizarAtendimentos = () => {
        this.props.history.push({
            pathname: "/guias",
            state: { entitys: this.props.rowsCheckedValue }
        });

    };
    toogleModalEncaminhaAtendimento = () => {
        this.setState({ toogleModalEncaminhaAtendimento: !this.state.toogleModalEncaminhaAtendimento });
    }
    toogleModalCriarLote = () => {
        this.setState({ toogleModalCriarLote: !this.state.toogleModalCriarLote });
    }

    toogleModalCriarProtocolo = () => {
        this.setState({ toogleModalCriarProtocolo: !this.state.toogleModalCriarProtocolo });
    }

    abreModalEncaminharAtendimento = () => {
        this.props.history.push({
            pathname: "/guias",
            state: { entitys: this.props.rowsCheckedValue }
        });
    };

    canCreateLote = () => {
        let canCreate = true;
        this.props.rowsCheckedValue.forEach(atendimento => {
            let situacao = _.get(atendimento, "status.situacao");
            let nome = _.get(atendimento, "status.nome");
            if (situacao === objectsConstants.SITUACAO_ATENDIMENTO.FATURAMENTO) {
                this.faturado = true;
            } else {
                this.faturado = false;
            }

            let canEdit = situacao === objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA /*|| situacao === objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE*/
                || situacao === objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA
                || situacao === objectsConstants.SITUACAO_ATENDIMENTO.REMOVIDAS_DO_LOTE
                || situacao === objectsConstants.SITUACAO_ATENDIMENTO.PENDENCIA_RESOLVIDA
                || nome === objectsConstants.INCONSISTENCIA_AUXILIARES;
            if (!canEdit) {
                canCreate = false;
            }
        });
        return canCreate;
    };

    exportarExcel = () => {
        this.props.loading(true);
        const ids = this.props.rowsCheckedValue.map(atendimento => atendimento.id);
        let fields = [];

        if (ids.length) {
            let idsFiltro = {
                "path": "Atendimento",
                "fieldName": "select",
                "onlyNumber": false,
                "searchEmpty": false,
                "emptyTextSearch": "",
                "url": "",
                "fieldDescription": "Id do atendimento",
                "type": "ARRAY",
                "value": ids
            }
            fields.push(idsFiltro)
        }

        atendimentoService.exportAtendimentos(
            fields
        ).then(response => {
            download(
                response.data,
                'AtendimentosSelecionados.csv'
                ,
                'text/html'
            );
            this.props.loading(false);
        });

    }

    addAtendimenosInLote = atendimentos => {
        this.props.loading(true);
        let rowsCheckedValue = this.props.rowsCheckedValue;
        let convenio = atendimentos[0].estabelecimentoConvenio.convenio;
        let lote = { atendimentos, convenio };
        loteAtendimentoService.doSave(lote).then(
            response => {
                let id = lote.id
                    ? lote.id
                    : defaultService.getIdFromUrl(response.headers.location);
                let totalAtendimentos = lote.atendimentos.length;
                let totalLotes = parseInt((totalAtendimentos - 1) / 100);
                let ids = '' + id;
                for (let i = 1; i <= totalLotes; i++) {
                    ids = (id - i) + ',' + ids;
                }
                this.props.success({
                    message: `Lote(s) ${ids} criado(s) com sucesso!`
                });

                this.removeAtendimentosSelecionadosLote(lote.atendimentos);

                let atendimentosConvenios = [];
                for (let i = 0; i < rowsCheckedValue.length; i++) {
                    if (_.get(rowsCheckedValue[i], "estabelecimentoConvenio.convenio.id") !== convenio.id) {

                        atendimentosConvenios.push(rowsCheckedValue[i]);
                    }
                }

                this.props.unCheckAll(atendimentosConvenios);
                this.props.loteAtual(id)
                this.props.initData(atendimentosConvenios);
                this.props.loading(false);
                this.props.abreLotes();
            },
            erros => {
                console.error(erros.response);
                try {
                    let response = erros.response.data;
                    if (response && response.messages) {
                        for (var i = 0; i < response.messages.length; i++) {
                            let erroItem = response.messages[i];
                            this.props.error({
                                message: translate(erroItem.message.code)
                            });
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
                this.props.loading(false);
            }
        );

    };

    removeAtendimentosSelecionadosLote = (atendimentos) => {
        //remove da lista de atendimentos selecionados os atendimentos que receberam lote
        let rowsCheckedValueOld = JSON.parse(localStorage.getItem("rowsCheckedValue"));
        let rowsCheckedIdValueOld = JSON.parse(localStorage.getItem("rowsCheckedIdValue"));

        const newValue = rowsCheckedValueOld.filter(value => {
            return !atendimentos.some(aten => aten.id === value.id)
        })
        const newIdValue = rowsCheckedIdValueOld.filter(value => {
            return !atendimentos.some(aten => aten.id === value)
        })

        localStorage.setItem("rowsCheckedValue", JSON.stringify(newValue));
        localStorage.setItem("rowsCheckedIdValue", JSON.stringify(newIdValue));
    }

    changeStatus = (name, value) => {
        const filter = _.cloneDeep(this.state.filter);
        _.set(filter, name, value);
        this.setState({ filter, submitFilter: false });

    };

    toogleIncluirProtocolo = () => {
        this.setState({ showIncluirProtocolo: !this.state.showIncluirProtocolo })
    }

    toogleModalExclusao = () => {
        this.setState({ toggleJustificativaCancelamentoGuia: false })
        //this.setState({toogleModalEncaminhaAtendimento:false})
    }

    removerProtocolo = () => {
        this.props.loading(true);
        atendimentoService.removerProtocolo(_.map(_.filter(this.props.rowsCheckedValue, function (a) {
            return a.status.situacao ===
                objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA;
        }), 'id')).then(r => {
            this.props.loading(false);
            this.props.updateAtendimentos()
            this.props.success({
                message: `Atendimentos removidos com sucesso`
            });
            this.props.loading(false);
        })
    }

    incluirProtocolo = () => {
        this.props.loading(true);
        atendimentoService.incluirNoProtocolo(_.map(this.props.rowsCheckedValue, 'id'), this.state.numeroProtocolo).then(r => {
            let rowsCheckedValue = _.cloneDeep(this.props.rowsCheckedValue);
            rowsCheckedValue.map(r => {
                r.numeroProtocolo = this.state.numeroProtocolo;
            })
            this.props.success({
                message: `Atendimentos incluidos com sucesso`
            });
            this.props.updateCheckedRow(rowsCheckedValue);
            this.props.updateAtendimentos()
            this.toogleIncluirProtocolo();
            this.props.loading(false);
        })
    }

    imprimirProtocolo = () => {
        let protocolSelected = _.uniq(_.map(this.props.rowsCheckedValue, 'numeroProtocolo'));
        this.props.loading(true);
        atendimentoService.imprimirProtocolo(protocolSelected[0]).then(response => {
            var base64 = _.get(response, 'data.data');
            if (base64 != null) {
                let asciiString = atob(base64);
                let array = new Uint8Array(
                    [...asciiString].map((char) => char.charCodeAt(0))
                );
                const file = new Blob([array], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }
            this.props.loading(false);
        }).catch(r => {
            this.props.error({
                message: `Protocolo sem atendimentos`
            });
            this.props.loading(false);
        })
    }

    encaminharAtendimentos = (status, justificativa) => {
        this.props.loading(true);
        let idsAtendimentosPorOrdemDeSelecao;
        if (this.state.convenioProtocolo === null) {
            idsAtendimentosPorOrdemDeSelecao = _.map(this.props.rowsCheckedValue, (item, index) => ({ idAtendimento: item.id, posicao: index }));
        } else {
            let atendimentosInProtocolo = _.filter(this.props.rowsCheckedValue, (atendimento) => {
                return _.get(atendimento, 'estabelecimentoConvenio.convenio.id') === _.get(this.state.convenioProtocolo, 'id');
            })
            idsAtendimentosPorOrdemDeSelecao = _.map(atendimentosInProtocolo, (item, index) => ({ idAtendimento: item.id, posicao: index }));
        }
        this.setState({ convenioProtocolo: null })
        atendimentoService.encaminharAtendimentos(status.id, idsAtendimentosPorOrdemDeSelecao, justificativa).then(
            response => {
                this.props.success({
                    message: `Atendimentos encaminhados para ${status.nome}`
                });
                var base64 = _.get(response, 'data.data');
                if (base64 != null) {
                    let extension = response.data.nome.split(".").pop()
                    this.setState({ numeroProtocoloEncam: response.data.nome.split(".")[0] });
                    if (extension === "txt") {
                        download(
                            response.data.data,
                            'atendimentosRetornados' + '.txt',
                            'text/plain'
                        );
                    } else {
                        let asciiString = atob(base64);
                        let array = new Uint8Array(
                            [...asciiString].map((char) => char.charCodeAt(0))
                        );

                        let file = new Blob([array], { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }


                }
                let atendimentosConvenios = [];
                
                this.props.loading(false);
                this.props.updateAtendimentos();
                this.props.initData(atendimentosConvenios);
                this.props.loading(false);

                this.toogleModalEncaminhaAtendimento();


            },
            erros => {
                console.error(erros.response.data.messages);
                if (erros.response.status === 400) {
                    if (erros.response.data && erros.response.data.messages) {
                        for (let i = 0; i < erros.response.data.messages.length; i++) {
                            this.props.error({
                                message:
                                    translate(erros.response.data.messages[i].message.code)
                            });
                        }
                    } else {
                        this.props.error({
                            message:
                                "Os atendimentos não pode ser encaminhado para Protocolo, pois existem pendências nos atendimentos. Atendimentos: " + erros.response.data + ""
                        });
                    }
                }
                this.props.loading(false);
            }
        );

    };

    encaminharAtendimentosPorOrdemDeSelecao = (status, justificativa) => {
        this.props.loading(true);
        let idsAtendimentosPorOrdemDeSelecao;
        if (this.state.convenioProtocolo === null) {
            idsAtendimentosPorOrdemDeSelecao = _.map(this.props.rowsCheckedValue, (item, index) => ({ idAtendimento: item.id, posicao: index }));
        } else {
            let atendimentosInProtocolo = _.filter(this.props.rowsCheckedValue, (atendimento) => {
                return _.get(atendimento, 'estabelecimentoConvenio.convenio.id') === _.get(this.state.convenioProtocolo, 'id');
            })
            idsAtendimentosPorOrdemDeSelecao = _.map(atendimentosInProtocolo, (item, index) => ({ idAtendimento: item.id, posicao: index }));
        }
        this.setState({ convenioProtocolo: null })
        atendimentoService.encaminharAtendimentoPorOrdemDeSelecao(status.id, idsAtendimentosPorOrdemDeSelecao, justificativa).then(
            response => {
                this.props.success({
                    message: `Atendimentos encaminhados para ${status.nome}`
                });
                var base64 = _.get(response, 'data.data');
                if (base64 != null) {
                    let extension = response.data.nome.split(".").pop()
                    this.setState({ numeroProtocoloEncam: response.data.nome.split(".")[0] });
                    if (extension === "txt") {
                        download(
                            response.data.data,
                            'atendimentosRetornados' + '.txt',
                            'text/plain'
                        );
                    } else {
                        let asciiString = atob(base64);
                        let array = new Uint8Array(
                            [...asciiString].map((char) => char.charCodeAt(0))
                        );

                        let file = new Blob([array], { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }


                }
                let atendimentosConvenios = [];
                
                this.props.loading(false);
                this.props.updateAtendimentos();
                this.props.initData(atendimentosConvenios);
                this.props.loading(false);

                this.toogleModalEncaminhaAtendimento();


            },
            erros => {
                if (erros.response.status === 400) {
                    if (erros.response.data && erros.response.data.messages) {
                        for (let i = 0; i < erros.response.data.messages.length; i++) {
                            this.props.error({
                                message:
                                    translate(erros.response.data.messages[i].message.code)
                            });
                        }
                    } else {
                        this.props.error({
                            message:
                                "Os atendimentos não pode ser encaminhado para Protocolo, pois existem pendências nos atendimentos. Atendimentos: " + erros.response.data + ""
                        });
                    }
                }
                this.props.loading(false);
            }
        );

    };



    async validarPrazoGeracaoProtocolo(status) {
        this.props.loading(true);
        let idsAtendimentos = _.map(this.props.rowsCheckedValue, 'id');
        let prazoGerarProtocolo = [];       
        await atendimentoService.findAllAtendimentosPrazoGeracaoProtocolo(idsAtendimentos).then(response => {
            prazoGerarProtocolo.push(response.data);
        })
        this.props.loading(false)

        if (prazoGerarProtocolo.length > 0)
            return !prazoGerarProtocolo.some(ele => ele === false)
        else
            return true;
    };

    validarQuantidadeAtendimentosGeracaoProtocolo() {
        let idsAtendimentos = _.map(this.props.rowsCheckedValue, 'id');
        if (objectsConstants.STATUS_ENCAMINHAMENTO.NOME === this.state.filter.status.nome && idsAtendimentos.length > 100) {
            return true
        } else {
            return false
        }
    }

    bloqueiaEdicaoStatusGuiaRecebidaPerfilCooperadoConsultorio = () => {
        const user = userService.getCurrentUser();
        const tipoPerfil = user.perfilAtual.perfil.tipoPerfil;
        if ((tipoPerfil === objectsConstants.CONSULTORIO || tipoPerfil === objectsConstants.COOPERADO)) {
            return true
        }
        return false;
    }

    validaEdicaoGuiaRecusada = (status, boolean) => {
        const user = userService.getCurrentUser();
        const tipoPerfil = user.perfilAtual.perfil.tipoPerfil;

        if ((tipoPerfil === objectsConstants.ADMINISTRADOR || tipoPerfil === objectsConstants.PROTOCOLO) && boolean) {
            if (status.situacao === objectsConstants.SITUACAO_ATENDIMENTO.NAO_FATURADO) {
                //GUIA RECUSADA
                return status.nome === objectsConstants.STATUS_RECUSADO.NOME ? false : true
            }
        }
        return true
    }

    validaEmcaminhamentoGuiaPendente = (status) => {
        const user = userService.getCurrentUser();
        const tipoPerfil = user.perfilAtual.perfil.tipoPerfil;

        if ((tipoPerfil === objectsConstants.ADMINISTRADOR || tipoPerfil === objectsConstants.PROTOCOLO)) {
            if (status.situacao === objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE) {
                return status.nome === objectsConstants.PENDENTE ? false : true
            }
        }
        return true
    }

    validarPermisaoEditarGuiaInternacaoStatusNaoFaturado = (status) => {
        const user = userService.getCurrentUser();
        const tipoPerfil = user.perfilAtual.perfil.tipoPerfil;
        if (status.situacao === objectsConstants.SITUACAO_ATENDIMENTO.NAO_FATURADO
            && status.nome === objectsConstants.NAO_FATURADO
            && (tipoPerfil === objectsConstants.ADMINISTRADOR
                || tipoPerfil === objectsConstants.PROTOCOLO)) {
            return false;
        }        
        return true;
    }

    async isAtendimentoRecebidosMais15Dias() {
        this.props.loading(true)
        let statusRecebidosMasi15dias = [];
        if (!this.bloqueiaEdicaoStatusGuiaRecebidaPerfilCooperadoConsultorio()) {
            let tipoGuia = this.props.rowsCheckedValue.map(ele => ele.tipoGuia)
                .filter((elem, pos, arr) => arr.indexOf(elem) == pos);
            let type = [];

            for (const i of tipoGuia) {
                for (const j of objectsConstants.TIPOS_GUIA_CONVENIO) {
                    if (i === j.label) if (!type.includes(j.id)) type.push(j.id)
                }
            }

            let convenios = this.props.rowsCheckedValue.map(ele => ele.convenio.id)
                .filter((elem, pos, arr) => arr.indexOf(elem) == pos);

            await statusAtendimentoService.findAllByAtendimentosStatusRecebido15Dias(type, convenios).then(response => {
                statusRecebidosMasi15dias.push(...response.data);
            })
        }
        this.props.loading(false)

        if (statusRecebidosMasi15dias.length > 0)
            return !statusRecebidosMasi15dias.some(ele => ele === false)
        else
            return true;
    }

    isAuditoria = (boolean) => {
        const user = userService.getCurrentUser();
        const tipoPerfil = user.perfilAtual.perfil.tipoPerfil;
        let statusNaoPermitidos = [];
        statusAtendimentoService.findAllByAtendimentosComProtocolo(this.props.rowsCheckedValue
            .filter(t => t.numeroProtocolo)).then(response => {
                if (response.data && response.data.length > 0) {
                    let status = response.data;
                    if ((tipoPerfil === objectsConstants.AUDITOR || tipoPerfil === objectsConstants.AUDITOR_SEM_SETOR || tipoPerfil === objectsConstants.PROTOCOLO) &&
                        user.cooperativa.id === objectsConstants.COOPMED.id && this.props.filter.situacao === objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA) {
                        status = status.filter(st => st.nome !== objectsConstants.GUIA_RECUSADA);
                    }
                    this.setState({ statusEdicaoEncaminhamento: status })
                }
            });
        let statusCancelamento = _.filter(this.state.statusEdicao, { statusCancelamento: true }).length > 0
        for (let i = 0; i < this.props.rowsCheckedValue.length; i++) {
            let atendimento = this.props.rowsCheckedValue[i];
            if ((atendimento.status.situacao !== objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA
                && atendimento.status.situacao !== objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE
                && atendimento.status.situacao !== objectsConstants.SITUACAO_ATENDIMENTO.PENDENCIA_RESOLVIDA
                && atendimento.status.situacao !== objectsConstants.SITUACAO_ATENDIMENTO.REMOVIDAS_DO_LOTE
                && atendimento.status.situacao !== objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA
                && this.validaEdicaoGuiaRecusada(atendimento.status, boolean)
                && this.validaEmcaminhamentoGuiaPendente(atendimento.status)
                && this.validarPermisaoEditarGuiaInternacaoStatusNaoFaturado(atendimento.status)
            )
                ||
                (atendimento.status.situacao === objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA
                    && this.bloqueiaEdicaoStatusGuiaRecebidaPerfilCooperadoConsultorio())) {
                return false;
            }
            if (!((atendimento.status.situacao === objectsConstants.SITUACAO_ATENDIMENTO.PENDENCIA_RESOLVIDA
                || atendimento.status.situacao === objectsConstants.SITUACAO_ATENDIMENTO.REMOVIDAS_DO_LOTE
                || atendimento.status.situacao === objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA)
                && statusCancelamento)
                && (this.state.statusEdicao.length > 0
                    && !atendimento.status.primeiroStatus
                    && _.filter(this.state.statusEdicao, { nome: atendimento.status.nome }).length === 0)
                && !this.bloqueiaEdicaoStatusGuiaRecebidaPerfilCooperadoConsultorio()) {
                statusNaoPermitidos.push(atendimento.status);
            }
        }
        return statusNaoPermitidos.length === 0;
    };

    isStatusAuditoria = () => {
        for (let i = 0; i < this.props.rowsCheckedValue.length; i++) {
            let atendimento = this.props.rowsCheckedValue[i];
            if (
                !atendimento.status ||
                atendimento.status.situacao !==
                objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA
            ) {
                return false;
            }
        }
        return true;
    }

    changeNumeroProtocolo = (name, numeroProtocolo) => {
        this.setState({ numeroProtocolo })
    }

    verificacaoAtendimentosLote =() => {
        let listAtendimento = [];

        this.props.rowsCheckedValue.forEach(atendimento => {
            let situacao = _.get(atendimento, "status.situacao");
            let nome = _.get(atendimento, "status.nome");

            let canEdit = situacao === objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA /*|| situacao === objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE*/
                || situacao === objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA
                || situacao === objectsConstants.SITUACAO_ATENDIMENTO.REMOVIDAS_DO_LOTE
                || situacao === objectsConstants.SITUACAO_ATENDIMENTO.PENDENCIA_RESOLVIDA
                || nome === objectsConstants.INCONSISTENCIA_AUXILIARES;
            if (!canEdit) {
                listAtendimento.push(atendimento.numeroAtendimento);
            }
        });
        return listAtendimento;
    }

    
    render() {
        let totalSelected = 0;
        const { lotesOpen, disableExport } = this.props;
        if (this.props.checkedAllPages) {
            totalSelected = this.props.dataTotalSize;
        } else {
            totalSelected = this.props.rowsCheckedValue.length;
        }
        let protocolSelected = _.uniq(_.map(this.props.rowsCheckedValue, 'numeroProtocolo'));
        return (

            <React.Fragment>
                <Modal
                    isOpen={this.state.showIncluirProtocolo}
                    toggle={this.toogleIncluirProtocolo}
                    backdrop="static"
                    modalClassName=""
                    className=""
                    centered={true}
                >
                    <ModalBody>
                        <div className="modalCooperado">
                            <div className="row">
                                <div className="col-11 text-center my-2">
                                    <h2 className="font-weight-bold">Incluir atendimento(s) no protocolo</h2>
                                </div>
                                <div className="col-1">
                                    <a onClick={() => { this.toogleIncluirProtocolo(); }}
                                        className="btn-fechar d-flex justify-content-end">
                                        <img src={fechar} alt="fechar" className="pointer" />
                                    </a>
                                </div>
                            </div>


                            <div className="row">
                                <div className="flex-grow px-3 m-auto justify-content-center">
                                    <label>Número do Protocolo</label>
                                    <FormInput
                                        className="min-width"
                                        name={"status"}
                                        placeholder={"Número do protocolo"}
                                        value={this.state.numeroProtocolo}
                                        onChange={this.changeNumeroProtocolo}
                                    />
                                </div>
                                <div className="flex-grow px-3 m-auto  pt-3 justify-content-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary "
                                        disabled={false}
                                        onClick={() => {
                                            if (this.state.numeroProtocolo) {
                                                ConfirmAcationService.confirmMsg(
                                                    () => {
                                                        this.incluirProtocolo();

                                                    },
                                                    "Incluir atendimentos no protocolo",
                                                    <span>
                                                        Deseja realmente incluir{" "}
                                                        {`${totalSelected.length === 1
                                                            ? "o atendimento "
                                                            : " os atendimentos"
                                                            } `}
                                                        no protocolo{" "}
                                                        <span className="font-bold">
                                                            {this.state.numeroProtocolo}
                                                        </span>
                                                        ?
                                                    </span>,
                                                    `${this.state.numeroProtocolo} `,
                                                    "Não",
                                                    "Sim",
                                                    objectsConstants.TYPE_FORWARD
                                                );
                                            } else {
                                                this.setState({ showIncluirProtocolo: true })
                                            }
                                        }
                                        }
                                    >
                                        <span className={`icon-btn-add`}></span>
                                        {"Incluir atendimentos"}
                                    </button>
                                </div>

                            </div>

                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.toogleModalEncaminhaAtendimento}
                    toggle={this.toggle}
                    backdrop="static"
                    modalClassName=""
                    className=""
                    centered={true}
                >
                    <ModalBody>
                        <div className="modalCooperado">
                            <div className="row">
                                <div className="col-11 text-center my-2">
                                    <h2 className="font-weight-bold">Encaminhar atendimentos</h2>
                                </div>
                                <div className="col-1">
                                    <a onClick={() => { this.toogleModalEncaminhaAtendimento(); }}
                                        className="btn-fechar d-flex justify-content-end">
                                        <img src={fechar} alt="fechar" className="pointer" />
                                    </a>
                                </div>
                            </div>


                            <div className="row">
                                <div className="flex-grow px-3 m-auto justify-content-center">
                                    <label>Encaminhar</label>
                                    <SelectInput
                                        className="min-width"
                                        name={"status"}
                                        placeholder={"Selecione o status"}
                                        returnFullObject={true}
                                        labelKey={"nome"}
                                        valueKey={"id"}
                                        options={_.filter(this.state.statusEdicaoEncaminhamento, (status) => {
                                            let atendimento = this.props.rowsCheckedValue[0];
                                            if (atendimento.status.situacao ===
                                                objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE) {
                                                return status.statusCancelamento;
                                            }
                                            return true;
                                        })}
                                        value={this.state.filter.status}
                                        onChange={this.changeStatus}
                                        parent={this.props.rowsCheckedIdValue}
                                        loadingPlaceholder={"Selecione um atendimento"}
                                    />
                                </div>
                                <div className="flex-grow px-3 m-auto  pt-3 justify-content-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary "
                                        disabled={false}
                                        onClick={async () => {
                                            let convenios = _.uniqBy(_.map(this.props.rowsCheckedValue, function (n) { return _.get(n, 'estabelecimentoConvenio.convenio') }), 'id');
                                            if (this.state.filter.status.statusRecebimentoGuia) {
                                                ConfirmAcationService.confirmMsg(
                                                    () => {
                                                        this.encaminharAtendimentos(this.state.filter.status);
                                                        this.setState({ filter: {} });
                                                    },
                                                    "Encaminhar atendimentos",
                                                    <span>
                                                        Atenção,atendimentos do(s) protocolo(s) {_.map(_.uniqBy(_.map(this.props.rowsCheckedValue, function (n, index) { _.get(n, 'numeroProtocolo') })), function (p, i) { if (i > 0) { return `, ${p}` } else { return p; } })} que não foram selecionados, serão retornados para aguardando envio para cooperativa.
                                                    </span>

                                                    ,
                                                    ` `,
                                                    "Não",
                                                    "Sim",
                                                    objectsConstants.TYPE_FORWARD
                                                );
                                            }
                                            else if (!this.state.filter.status.statusCancelamento) {
                                                if (this.state.filter.status.nome == objectsConstants.STATUS_ENCAMINHAMENTO.NOME) {
                                                    if (await this.validarPrazoGeracaoProtocolo(this.state.filter.status)) {
                                                        ConfirmAcationService.confirmMsgPrazoFaturaVencida(
                                                            () => {
                                                            },
                                                            "Existem guias selecionadas com o prazo de faturamento vencido.",
                                                            <span>
                                                                {" "}
                                                                Guia vencida - prazo de faturamento superior a negociação com convênio. Favor entrar em contato com a cooperativa.
                                                                <br />
                                                                Para prosseguir com a geração do protocolo das demais guias, favor desmarcar a(s) guia(s) vencida(s) e encaminhar novamente.
                                                            </span>,
                                                            null,
                                                            "OK",
                                                            null,
                                                            objectsConstants.TYPE_WARNING
                                                        );
                                                    } else if (this.validarQuantidadeAtendimentosGeracaoProtocolo()) { 
                                                        this.props.error({
                                                            message:
                                                                "Não é possível gerar protocolo de mais de 100 atendimentos por vez."
                                                        });
                                                    } else if (!userService.getCurrentUser().cooperativa.geraProtocoloSemLimiteConvenio && convenios.length > 1) {
                                                        this.setState({ convenios, toogleModalCriarProtocolo: true })
                                                    } else {
                                                        ConfirmAcationService.confirmMsg(
                                                            () => {
                                                                this.encaminharAtendimentos(this.state.filter.status);
                                                                this.setState({ filter: {} });
                                                            },
                                                            "Encaminhar atendimentos",
                                                            <span>
                                                                Deseja realmente encaminhar{" "}
                                                                {`${totalSelected.length === 1
                                                                    ? "o atendimento "
                                                                    : " os atendimentos"
                                                                    } `}
                                                                para o status de{" "}
                                                                <span className="font-bold">
                                                                    {this.state.filter.status.nome}
                                                                </span>
                                                                ?
                                                            </span>,
                                                            `${this.state.filter.status.nome} `,
                                                            "Não",
                                                            "Sim",
                                                            objectsConstants.TYPE_FORWARD
                                                        );
                                                    }
                                                }else {
                                                    ConfirmAcationService.confirmMsg(
                                                        () => {
                                                            this.encaminharAtendimentos(this.state.filter.status);
                                                            this.setState({ filter: {} });
                                                        },
                                                        "Encaminhar atendimentos",
                                                        <span>
                                                            Deseja realmente encaminhar{" "}
                                                            {`${totalSelected.length === 1
                                                                ? "o atendimento "
                                                                : " os atendimentos"
                                                                } `}
                                                            para o status de{" "}
                                                            <span className="font-bold">
                                                                {this.state.filter.status.nome}
                                                            </span>
                                                            ?
                                                        </span>,
                                                        `${this.state.filter.status.nome} `,
                                                        "Não",
                                                        "Sim",
                                                        objectsConstants.TYPE_FORWARD
                                                    );
                                                }

                                            } else {
                                                this.setState({ toggleJustificativaCancelamentoGuia: true })
                                            }
                                        }
                                        }
                                    >
                                        <span className={`icon-btn-check`}></span>
                                        {"Encaminhar"}
                                    </button>
                                </div>

                            </div>

                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.toggleJustificativaCancelamentoGuia}
                    toggle={() => { this.setState({ toggleJustificativaCancelamentoGuia: !this.state.toggleJustificativaCancelamentoGuia }) }}
                    backdrop="static"
                    modalClassName=""
                    className=""
                    centered={true}
                >
                    <ModalBody>
                        <Formik
                            validationSchema={Yup.object().shape({ justificativa: Yup.string().required('Obrigatorio') })}
                            // validationSchema={validateObject.validate(value, { abortEarly: false, context:{cooperativa:value.cooperativa,graus:this.state.graus} })}
                            validateOnBlur={false}
                            validateOnChange={false}
                            enableReinitialize={true}
                            initialValues={{}}
                            onSubmit={(values, actions) => {
                                this.encaminharAtendimentos(this.state.filter.status, values.justificativa);
                                this.setState({ filter: {}, toggleJustificativaCancelamentoGuia: false });
                            }}
                            ref={form => {
                                this.formRef = form;
                            }}
                        >
                            {({
                                values,
                                errors,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                validateForm,
                                setValues,
                                touched
                            }) => {
                                return (
                                    <form onSubmit={handleSubmit}>

                                        <div className="modalCooperado">
                                            <div className="row">
                                                <div className="col-11 text-center my-2">
                                                    {this.state.filter && this.state.filter.status && this.state.filter.status.nome === objectsConstants.STATUS_RECUSADO.NOME && <h2 className="font-weight-bold">{' '} <span className="font-bold cor-destaque-alerta">
                                                        {'Caso confirme a recusa desta guia, o atendimento não será faturado pela cooperativa.'}
                                                    </span></h2>}
                                                    <h2 className="font-weight-bold">Informe o motivo de encaminhar as guias para o status{' '} <span className="font-bold">
                                                        {this.state.filter && this.state.filter.status ? this.state.filter.status.nome : ''}
                                                    </span>:</h2>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="flex-fill px-5 m-auto justify-content-center">
                                                    <FormGroup className='col-12'>
                                                        <FormTextArea label={"Justificativa"}
                                                            id={'justificativa'}
                                                            className={'textArea'}
                                                            type={'textarea'}
                                                            disabled={false}

                                                            onChange={handleChange}
                                                            name={"justificativa"}
                                                            erroMensagem={errors.justificativa}
                                                            placeholder=""

                                                            value={values.justificativa} />
                                                    </FormGroup>

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="flex-grow px-1 m-auto justify-content-center">

                                                    <button
                                                        className="btn btn-secondary white mt-3 mb-4 mx-2"
                                                        onClick={() => {
                                                            this.toogleModalExclusao();
                                                        }}
                                                        type={'button'}
                                                    >
                                                        Cancelar
                                                    </button>
                                                    <button
                                                        className="btn btn-primary white mt-3 mb-4 mx-2"
                                                        type={'submit'}
                                                    >
                                                        Encaminhar
                                                    </button>

                                                </div>

                                            </div>

                                        </div>
                                    </form>)
                            }}
                        </Formik>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.toogleModalCriarLote}
                    toggle={this.toggleCriarLote}
                    backdrop="static"
                    modalClassName=""
                    className=""
                    centered={true}
                >
                    <ModalBody>


                        <div className="confirm-ui css-alert-sasc w-100"><img src={iconCriarLote}
                            alt="icon" /><h2>Novo lote</h2><p><b></b></p>
                            <div className="row body-confirm"><span> Existem atendimentos com convênios diferentes, para criar o lote selecione o convenio desejado?</span>
                            </div>
                            <SelectInput
                                className="min-width"
                                name={"setor"}
                                value={this.state.convenio}
                                options={this.state.convenios}
                                onChange={(name, value) => {
                                    this.setState({ convenio: value })
                                }}
                                returnFullObject={true}
                                labelKey={"nome"}
                                valueKey={"id"}
                                placeholder="Selecione o Convenio"
                            />



                            <button className="btn btn-secondary white mt-3 mb-4 mx-2" onClick={() => { this.toogleModalCriarLote() }}>Não</button>
                            <button disabled={!this.state.convenio} dclassName="btn btn-primary white mt-3 mb-4 mx-2" onClick={() => {
                                this.addAtendimenosInLote(_.filter(this.props.rowsCheckedValue, (atendimento) => {
                                    return _.get(atendimento, 'estabelecimentoConvenio.convenio.id') === _.get(this.state.convenio, 'id');
                                })); this.toogleModalCriarLote();
                            }}>Sim</button>
                        </div>





                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.toogleModalCriarProtocolo}
                    toggle={this.toogleModalCriarProtocolo}
                    backdrop="static"
                    modalClassName=""
                    className=""
                    centered={true}
                >
                    <ModalBody>
                        <div className="confirm-ui css-alert-sasc w-100"><img src={iconCriarLote}
                            alt="icon" /><h2>Novo protocolo</h2><p><b></b></p>
                            <div className="row body-confirm"><span> Existem atendimentos com convênios diferentes, para criar o protocolo selecione o convenio desejado?</span>
                            </div>
                            <SelectInput
                                className="min-width"
                                name={"setor"}
                                value={this.state.convenioProtocolo}
                                options={this.state.convenios}
                                onChange={(name, value) => {
                                    this.setState({ convenioProtocolo: value })
                                }}
                                returnFullObject={true}
                                labelKey={"nome"}
                                valueKey={"id"}
                                placeholder="Selecione o Convenio"
                            />
                            <button className="btn btn-secondary white mt-3 mb-4 mx-2" onClick={() => { this.toogleModalCriarProtocolo() }}>Não</button>
                            <button disabled={!this.state.convenioProtocolo} dclassName="btn btn-primary white mt-3 mb-4 mx-2" onClick={() => {
                                this.encaminharAtendimentos(this.state.filter.status);
                                this.toogleModalCriarProtocolo();
                            }}>Sim</button>
                        </div>
                    </ModalBody>
                </Modal>
                <div className="d-flex flex-row justify-content-center justify-sm-content-end">
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                            if (!this.isAuditoria(true)) {
                                ConfirmAcationService.confirmMsg(
                                    () => {
                                    },
                                    "Existe(m) atendimento(s) já pendente(s),faturado(s), glosado(s) ou repassado(s)",
                                    <span>
                                        {this.verificacaoAtendimentosLote().map((item) => (
                                            <div key={item}>
                                                {item}
                                            </div>
                                        ))}
                                        {" "}
                                        Não é possivel encaminhar atendimento(s) pendente, faturado, glosado ou repassado. Ou vc não tem permissão para encaminhar o(s) atendimento(s) com o status selecionado.
                                    </span>,
                                    null,
                                    "OK",
                                    null,
                                    objectsConstants.TYPE_WARNING
                                );
                            } else {
                                this.toogleModalEncaminhaAtendimento();
                            }
                        }}
                    >
                        <span className="icon-btn-encaminhar"></span>
                        {totalSelected === 1 ? 'Encaminhar atendimento' : 'Encaminhar atendimentos'}
                    </button>
                    {protocolSelected.length == 1 && (
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={this.imprimirProtocolo}
                        >
                            <span className="icon-btn-imprimir"></span>
                            Imprimir protocolo
                        </button>
                    )}
                    {!disableExport && <button
                        type="button"
                        className="btn btn-light btDefaultGrid d-none d-lg-block"
                        onClick={this.exportarExcel}
                    >
                        <span className="icon-btn-ecxel"></span>
                        Exportar para excel
                    </button>}
                    <Can
                        I={objectsConstants.ALTERAR}
                        a={objectsConstants.RECEBIMENTO_PROTOCOLO}
                        ability={this.props.permissions}
                    >
                        {this.props.situacao}
                        {protocolSelected.length == 1
                            && (this.props.situacao == objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA
                                || this.props.situacao == objectsConstants.SITUACAO_ATENDIMENTO.PROTOCOLADAS
                                || this.props.situacao == objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA) && (
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={() => {
                                        ConfirmAcationService.confirmMsg(
                                            () => {
                                                this.removerProtocolo();
                                            },
                                            "Remover atendimento(s) do protocolo",
                                            <span>Atenção, os atendimentos selecionados, serão retornados para aguardando envio para cooperativa e serão removidos do protocolo. Deseja continuar?</span>,
                                            null,
                                            "Não",
                                            "Sim",
                                            objectsConstants.TYPE_WARNING
                                        );
                                    }}
                                >
                                    <span className="icon-btn-cancelar"></span>
                                    Remover atendimento(s) do protocolo
                                </button>
                            )}
                        {protocolSelected.length == 1
                            && (this.props.situacao == objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA
                                || this.props.situacao == objectsConstants.SITUACAO_ATENDIMENTO.PROTOCOLADAS
                                || this.props.situacao == objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA)
                            && (
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={this.toogleIncluirProtocolo}
                                >
                                    <span className="icon-btn-add"></span>
                                    Incluir atendimento(s) do protocolo
                                </button>
                            )}
                    </Can>
                    {protocolSelected.length > 1 && (
                        <button
                            type="button"
                            className="btn btn-light"
                            disabled={true}
                        >
                            <span className="icon-btn-imprimir"></span>
                            Para imprimir o protocolo, selecione somente um número de protocolo
                        </button>
                    )}
                    {totalSelected <= 10 && (
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={this.visualizarAtendimentos}
                        >
                            <span className="icon-visualizar"></span>
                            Visualizar todos
                        </button>
                    )}
                    {totalSelected > 10 && (
                        <span className={"px-2 labelInfoAction half"}>
                            Não é possivel visualizar mais de 10 atendimentos
                        </span>
                    )}

                    <Can
                        I={objectsConstants.ALTERAR}
                        a={objectsConstants.CRUD_LOTES_CRIAR}
                        ability={this.props.permissions}
                    >
                        <button
                            type="button"
                            className="btn btn-light"
                            disabled={''}
                            onClick={async () => {
                                let convenios = _.uniqBy(_.map(this.props.rowsCheckedValue, function (n) { return _.get(n, 'estabelecimentoConvenio.convenio') }), 'id');
                                if (await this.isAtendimentoRecebidosMais15Dias()) {
                                    if (this.canCreateLote() && convenios.length === 1) {
                                        ConfirmAcationService.confirmMsg(
                                            () => {
                                                this.addAtendimenosInLote(this.props.rowsCheckedValue);
                                            },
                                            "Novo lote",
                                            <span>
                                                {" "}
                                                Deseja realmente criar um novo lote com estes atendimentos
                                                selecionados?
                                            </span>,
                                            <span className="verde-destaque">{`${this.props.rowsCheckedValue.length} atendimentos selecionados`}</span>,
                                            "Não",
                                            "Sim",
                                            objectsConstants.TYPE_FORWARD
                                        );
                                    } else {
                                        if (!this.isAuditoria(false) || !this.isStatusAuditoria()) {
                                            ConfirmAcationService.confirmMsg(
                                                () => {
                                                },
                                                "Existe(m) atendimento(s) já pendente(s),faturado(s), glosado(s) ou repassado(s)",
                                                <span>
                                                        {this.verificacaoAtendimentosLote().map((item) => (
                                                            <div key={item}>
                                                                {item}
                                                            </div>
                                                        ))}
                                                    
                                                    {" "}
                                                    Não é possivel criar lote com atendimento pendente, faturado, glosado ou repassado.
                                                </span>,
                                                null,
                                                "OK",
                                                null,
                                                objectsConstants.TYPE_WARNING
                                            );
                                        } else {
                                            this.setState({ convenios, toogleModalCriarLote: true })
                                        }
                                    }
                                } else {
                                    ConfirmAcationService.confirmMsg(
                                        () => {
                                        },
                                        "Criação de lote Bloqueada",
                                        <span>
                                            {" "}
                                            Favor entrar em contato com o administrador da cooperativa.
                                        </span>,
                                        null,
                                        "OK",
                                        null,
                                        objectsConstants.TYPE_WARNING
                                    );
                                }
                            }}
                        >
                            <span className="icon-btn-add"></span>
                            Criar lotes
                        </button>
                    </Can>
                    <Can
                        I={objectsConstants.VISUALIZAR}
                        a={objectsConstants.CRUD_LOTES_VIEW}
                        ability={this.props.permissions}
                    >
                        <button
                            type="button"
                            className={`btn ${lotesOpen ? "btn-secondary" : "btn-light"
                                }`}
                            onClick={this.props.toggleLotes}
                        >
                            <span
                                className={`${lotesOpen ? "icon-btn-fechar" : "icon-btn-lotes"
                                    }`}
                            ></span>
                            {`${!lotesOpen ? "Lotes" : "Fechar lotes"}`}
                        </button>
                    </Can>
                </div>
            </React.Fragment>

        );
    }
}

const mapDispatch = ({
    alert: { success, error, clear },
    load: { loading },
    lotesCollapse: { toggleLotes, loteAtual, abreLotes }
}) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear(),
    toggleLotes: () => toggleLotes(),
    abreLotes: () => abreLotes(),
    loteAtual: (idLoteAtual) => loteAtual({ idLoteAtual }),
});

function mapStateToProps(state) {
    const { lotesOpen } = state.lotesCollapse;
    const { permissions } = state.authentication;

    return {
        lotesOpen,
        permissions
    };
}

export default connect(mapStateToProps, mapDispatch, null, { withRef: true })(
    withRouter(LinhaAcaoAtivarInativarAtendimento)
);

