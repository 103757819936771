//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';
import {objectsConstants} from "../constants/objects.constants";
export const alertCooperativaGuiaService = {
  findbyTipoGuia

};


function findbyTipoGuia(tipoGuia) {
  let type;
  for(let i=0; i< objectsConstants.TIPOS_GUIA_REGRA_CONVENIO.length;i++){
    if(objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].label===tipoGuia){
      type = objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].id;
    }
  }
  return defaultService.doGet(`${urlsConstants.ALERT_COOPERATIVA_GUIA}${type}`);
}


