import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { objectsConstants } from "../../constants/objects.constants";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { cooperadoService } from "../../services/cooperado.service"
import { withRouter } from "react-router-dom";
import _ from 'lodash';
import { cooperativaService } from '../../services/cooperativa.service';
class LinhaAcaoCooperativa extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    imprimirDadosCooperativa = () => {
        let cooperativalSelected = _.uniq(_.map(this.props.rowsCheckedValue, 'id'));
        this.props.loading(true);
        cooperativaService.imprimirCooperativa(cooperativalSelected[0]).then(response => {
            var base64 = _.get(response, 'data.data');
            if (base64 != null) {
                let asciiString = atob(base64);
                let array = new Uint8Array(
                    [...asciiString].map((char) => char.charCodeAt(0))
                );
                const file = new Blob([array], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }
            this.props.loading(false);
        }).catch(r => {
            this.props.error({
                message: `Cooperativa não encontrada`
            });
            this.props.loading(false);
        })
    }

    render() {
        let cooperativalSelected = _.uniq(_.map(this.props.rowsCheckedValue, 'id'));

        return <React.Fragment>
             {cooperativalSelected.length == 1 && (
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={this.imprimirDadosCooperativa}
                        >
                            <span className="icon-btn-imprimir"></span>
                            Imprimir Cooperativa
                        </button>
                    )}
        </React.Fragment>;
    }
}

const mapDispatch = ({
    alert: { success, error, clear },
    load: { loading }
}) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear()
});

export default connect(
    null,
    mapDispatch,
    null,
    { withRef: true }
)(withRouter(LinhaAcaoCooperativa));