import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Avatar from "../../../components/Avatar/Avatar";
import { formatterHelper } from "../../../helpers/formatter.helper";
import iconPhoto from "../../../img/icon_photo.svg";

class SolicitacaoExameTopoCrud extends PureComponent<Props, State> {
  render() {
    const {
      values,
      onChange
    } = this.props;
    return (
      //add classe "bg-branco" abaixo quando esição/criação
      <React.Fragment>
        <div className="px-sm-4 py-sm-2 my-sm-3 bg-branco rounded d-flex flex-column flex-sm-row justify-content-between align-items-center">
          <div className="bloco-dados-user mr-sm-auto d-flex flex-column flex-sm-row align-items-center align-self-stretch align-self-sm-center">
            <div className="bloco-image-user m-sm-2 mr-sm-4">
              <Avatar
                user={values}
                editable={true}
                name="midia"
                tipoPerfil={'ADMINISTRADOR'}
                onChange={(name, value) => onChange(name, value)}
              >
                {values.usuarioPerfis && (
                  <div className="icon-foto-user photo d-flex align-items-center ">
                    <img src={iconPhoto} alt="iconPerfil" />
                  </div>
                )}
              </Avatar>
            </div>
            <div className="info-user col-12">
              <React.Fragment>
                {" "}
                <p>
                  {this.props.user.usuarioCooperativas.map(cooperativa => {
                    return cooperativa.cooperativa.sigla;
                  })}
                </p>
                <h2 className="font-weight-bold">
                  {formatterHelper.getNameOrHifen(values.nome)}
                </h2>
                <p className="font-italic"> Solicitação de Exame</p>
              </React.Fragment>
            </div>
          </div>
          {!values.id && (
            <div className="info-texto px-sm-3 mt-2 mt-sm-0">
              <span className="font-weight-bold font-italic helpText">
                Quer ajuda?
              </span>
              <p className="font-italic">01- Prencha o formulário</p>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(mapStateToProps, null)(withRouter(SolicitacaoExameTopoCrud));
