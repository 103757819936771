import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormInput from "../../../../components/Inputs/FormInput";
import InputViewEdit from "../../../../components/Inputs/InputViewEdit";
import SelectInput from "../../../../components/Inputs/SelectInput";
import { conselhoService } from "../../../../services/conselho.service";
import { cooperadoService } from "../../../../services/cooperado.service";
import { userService } from "../../../../services/user.service";
import SelectableInput from "../../../../sascComponents/inputs/SelectableInput";
import _ from "lodash";
import { convenioService } from "../../../../services/convenio.service";

class DadosSolicitanteGuiaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }
  findConselho = () => {
    conselhoService
      .conselhoSolicitante(this.props.values.numeroCarteirinha)
      .then(response => { });
  };

  render() {
    const {
      setFieldValue,
      values,
      errors,
      viewMode
    } = this.props;
    const extension = userService.getExtensionUser(this.props.user);


    return (
      <React.Fragment>
        <div className="row section-form">
          <h6 className="w-100">Dados do Contratado Solicitante</h6>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo mobile-100"
            name={"codigoOperadora"}
            label={"Código na operadora"}
            value={values.codigoOperadora}
          >

            <InputViewEdit
              label={"Código na operadora"}
              component={FormInput}
              required={false}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              name={"codigoOperadora"}
              id={"codigoOperadora"}
              placeholder=""
              type={"text"}
              viewMode={extension || viewMode}
              value={values.codigoOperadora}
              disabled={true}
            />
          </SelectableInput>
          <SelectableInput viewMode={viewMode} atendimento={values} className=""
            name={"nome"}
            label={"Nome do contratado"}
            value={values.nome}>
            <InputViewEdit
              label={"Nome do contratado"}
              component={FormInput}
              required={false}
              disabled={true}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              name={"nome"}
              erroMensagem={errors.nome}
              placeholder=""
              type={"text"}
              viewMode={extension || viewMode}
              value={values.nome}
            />
          </SelectableInput>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
            name={"cnes"}
            label={"Código CNES"}
            value={values.cnes}>
            <InputViewEdit
              label={"Código CNES"}
              component={FormInput}
              required={false}
              disabled={true}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              name={"cnes"}
              erroMensagem={errors.cnes}
              placeholder=""
              type={"text"}
              viewMode={extension || viewMode}
              value={values.cnes}
            />
          </SelectableInput>
          <div className="quebra"></div>

                    <SelectableInput viewMode={viewMode} atendimento={values} className="central"
                      name={`cooperado.conselho`}
                      label={"Conselho profissional"}
                      value={values.cooperado && values.cooperado.conselho && values.cooperado.conselho.sigla ?
                        _.get(values, 'cooperado.conselho.sigla')
                      : ""}
                    >

                      <InputViewEdit
                        label="Conselho profissional"
                        component={SelectInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        className={""}
                        id={`cooperadoconselho`}
                        name={`cooperado.conselho`}
                        placeholder=""
                        viewMode={viewMode}
                        required={false}
                        erroMensagem={
                          _.get(errors, `cooperado.conselho.id`)
                        }
                        valueKey={"id"}
                        labelKey={"sigla"}
                        returnFullObject={true}
                        defaultSelected={true}
                        value={values.cooperado && values.cooperado.conselho && values.cooperado.conselho.sigla ?
                          _.get(values, 'cooperado.conselho') : ""}
                        defaultValue={
                          values.cooperado && values.cooperado.conselho && values.cooperado.conselho.sigla ?
                          _.get(values, 'cooperado.conselho.sigla')
                          : ""
                        }
                        options={this.props.listConselho}
                      />
                    </SelectableInput>

                    <SelectableInput viewMode={viewMode} atendimento={values} className="central"
                      name={`cooperado.ufConselho`}
                      label={"UF"}
                      value={values.cooperado && values.cooperado.ufConselho}
                    >
                      <InputViewEdit
                        label="UF"
                        component={SelectInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        className={""}
                        id={`cooperadoufConselho`}
                        name={`cooperado.ufConselho`}
                        placeholder=""
                        viewMode={viewMode}
                        required={false}
                        erroMensagem={_.get(errors, `cooperado.ufConselho`)}
                        valueKey={"id"}
                        labelKey={"nome"}
                        returnFullObject={false}
                        defaultSelected={true}
                        value={values.cooperado  ? values.cooperado.ufConselho : ""}
                        defaultValue={values.cooperado ? values.cooperado.ufConselho : ""}
                        options={this.props.listUfsConselho}
                      />
                    </SelectableInput>

          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
            name={"cooperado.conselho.numero"}
            label={"Número do conselho"}
            value={values.cooperado && values.cooperado.numeroConselho
              //&& values.cooperado.conselho
              //&& values.cooperado.conselho.numero
            }>
            <InputViewEdit
              label={"Número do conselho"}
              required={true}
              component={SelectInput}
              onChange={(name, date) => {
                setFieldValue("cooperado", date);
                setFieldValue("identificacaoAtendimentoExecucao[0].cooperado", date);
                
                if(values.type && values.convenio && values.atendimentoProcedimentos[0]){
                  let procedimentos = values.atendimentoProcedimentos;
                  let listProcedimentos = [];
                  for (let p = 0; p < procedimentos.length; p++) {
                    listProcedimentos.push(procedimentos[p].codigoProcedimento); 
                  }
                  
                 convenioService.findRegraEspecialidadeProcedimentoPorTipoGuia(values.type,values.convenio,date.id,listProcedimentos).then(
                   response => {
                     if(response.data.viaAcesso || response.data.grau){
                      setFieldValue("identificacaoAtendimentoExecucao[0].grau", response.data.grau ? response.data.grau.grau : null);
                     }else{
                      setFieldValue("identificacaoAtendimentoExecucao[0].grau", null);
                     }
                    });
                }
              }}
              name={"cooperado.conselho.numero"}
              id={"cooperadoconselhonumero"}
              erroMensagem={
                errors &&
                errors.cooperado &&
                errors.cooperado.numeroConselho
              }
              placeholder="Nº do Conselho"
              viewMode={viewMode}
              parent={values.estabelecimento.id}
              valueKey={"numeroConselho"}
              labelKey={"numeroConselho"}
              returnFullObject={true}
              value={values.cooperado && values.cooperado.conselho && values.cooperado.conselho.sigla && !_.isEmpty(values.cooperado) && values.cooperado}
              defaultValue={values.cooperado && values.cooperado.conselho && values.cooperado.numeroConselho}
              onFetchData={cooperadoService.findByNomeOuConselhoEstabelecimento}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>
          <SelectableInput viewMode={viewMode} atendimento={values} className=""
            name={"cooperado"}
            label={"Nome do profissional"}
            value={values.cooperado && values.cooperado.nome}>
            <InputViewEdit
              label={"Nome do profissional"}
              required={true}
              component={SelectInput}
              parent={values.estabelecimento.id}
              onFetchData={cooperadoService.findByNomeEstabelecimento}
              className={"destaque"}
              name={"cooperado"}
              id={"cooperadonome"}
              erroMensagem={
                errors &&
                errors.cooperado &&
                errors.cooperado.nome
              }
              returnFullObject={true}
              searchable={true}
              defaultValue={values.cooperado &&values.cooperado.nome ? values.cooperado.nome : "--"}
              labelKey={"nome"}
              valueKey={"id"}
              value={values.cooperado}
              viewMode={viewMode}
              onChange={(name, value) => {
                setFieldValue(name, value);
                setFieldValue("identificacaoAtendimentoExecucao[0].cooperado", value);

                if(values.type && values.convenio && values.atendimentoProcedimentos[0]){
                  let procedimentos = values.atendimentoProcedimentos;
                  let listProcedimentos = [];
                  for (let p = 0; p < procedimentos.length; p++) {
                    listProcedimentos.push(procedimentos[p].codigoProcedimento); 
                  }
                  
                 convenioService.findRegraEspecialidadeProcedimentoPorTipoGuia(values.type,values.convenio,value.id,listProcedimentos).then(
                   response => {
                     if(response.data.viaAcesso || response.data.grau){
                      setFieldValue("identificacaoAtendimentoExecucao[0].grau", response.data.grau ? response.data.grau.grau : null);
                     }else{
                      setFieldValue("identificacaoAtendimentoExecucao[0].grau", null);
                     }
                    });
                }
              }}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo mobile-100"
            name={"codigoCboSolicitante"}
            label={"Código CBO"}
            value={values.codigoCboSolicitante}>
            <InputViewEdit
              label={"Código CBO"}
              required={true}
              component={FormInput}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              name={"codigoCboSolicitante"}
              id={"codigoCboSolicitante"}
              erroMensagem={errors.codigoCboSolicitante}
              placeholder=""
              type={"text"}
              viewMode={viewMode}
              disabled={true}
              value={values.cooperado && values.cooperado.codigoCbo}
            />
          </SelectableInput>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosSolicitanteGuiaForm));
