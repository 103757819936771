import * as _ from "lodash";
import moment from "moment";
import React, { PureComponent } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Input, Modal, ModalBody } from "reactstrap";
import { objectsConstants } from "../../../constants/objects.constants";
import { urlsConstants } from "../../../constants/url.constant";
import iconEncaminhar from "../../../img/icon_encaminhar.svg";
import ConvenioFormSelect from "../../../sascComponents/convenios/ConvenioFormSelect";
import { atendimentoService } from "../../../services/atendimento.service";
import { conselhoService } from "../../../services/conselho.service";
import { convenioService } from "../../../services/convenio.service";
import { estabelecimentoService } from "../../../services/estabelecimento.service";
import { loteAtendimentoService } from "../../../services/loteAtendimento.service";
import { statusAtendimentoService } from "../../../services/statusAtendimento.service";
import { userService } from "../../../services/user.service";
import GuiaAtendimentoForm from "../../guias/GuiaAtendimentoForm";
import GuiaConsultaForm from "../../guias/GuiaConsultaForm";
import GuiaHonorariosForm from "../../guias/GuiaHonorariosForm";
import GuiaPesquisa from "../../guias/GuiaPesquisa";
import GuiaSADTForm from "../../guias/GuiaSADTForm";
import GuiaTopo from "../../guias/GuiaTopo";
import EdicaoEmMassaTopo from "../../guias/edicaoEmMassa/EdicaoEmMassaTopo";
import MenuHistorico from "../../guias/historico/MenuHistorico";
import MenuModelView from "../../guias/menuModelView/MenuModelView";
import PendenciasTopo from "../../guias/pendencias/PendenciasTopo";
import ResolverPendenciasTopo from "../../guias/pendencias/ResolverPendenciasTopo";
import TopoTitleComponente from "../../home/TopoTitleComponente";
import LotesTopo from "./LotesTopo";
class LotesList extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      atendimentos: [],
      showModalAtendimento: false,
      showPagination: false,
      paginaAtual: 1,
      pageSize: 10,
      selectedAtendimentos: [],
      rowsCheckedValue: [],
      rowsCheckedIdValue: [],
      dataTotalSize: 0,
      checkedAll: false,
      checkedAllPages: false,
      lote: {},
      atendimentoHistorico: {},
      listStatusEncaminhamento: [],
      listUfsConselho: [],
      listConselho: [],
      atendimentoCooperadoPadrao:[]
    };
  }

  componentDidMount() {
    if ( this.props.location.state ) {
      this.props.loading(true);
      statusAtendimentoService.findAllByAtendimentos().then(response=>{
        if(response.data&&response.data.length>0) {
          this.setState({listStatusEncaminhamento: response.data})
        }
      })

      conselhoService.findListUfsConselho().then(response=>{
        if(response.data&&response.data.length>0) {
          this.setState({listUfsConselho: response.data})
        }
      })

      conselhoService.findAll().then(response=>{
        if(response.data&&response.data.length>0) {
          this.setState({listConselho: response.data})
        }
      })
      loteAtendimentoService.getListAtendimentoPorLote(this.props.location.state.lote.id)
        .then(response => {
          let atendimentos = response.data;
          const lote = { ...this.props.location.state.lote };
          lote.convenio = atendimentos[0].convenio;
          this.setState({ atendimentos, lote: lote });
          this.props.loading(false);
          let showPagination;
          if (atendimentos.length > this.state.pageSize) {
            showPagination = true;
          } else {
            showPagination = false;
          }
          this.setState({ showPagination });
          this.atualizarConvenioAtendimentos(atendimentos);
          this.props.loading(false);
        })
        .catch(error => {
          this.props.error({
            message: 'Error: Ocorreu um erro no lote.'
          });
          this.props.loading(false);
        });
    } else {
      let lote =_.get(this.props.location,'state.lote',{});
      this.setState({ lote, atendimentos: [{}], filterSearch: [] }, () => {
        this.props.loading(false);
      });
    }
    let extension = userService.getExtensionUser(this.props.user);
    const isAuditor = userService.isAuditor(this.props.user);
    if (
      this.props.filtroAtendimento &&
      this.props.filtroAtendimento.estabelecimento
    ) {
      extension = this.props.filtroAtendimento.estabelecimento.id;
    }

    if (extension && !isAuditor) {
      estabelecimentoService.doGet(extension).then(result => {
        let estabelecimento = _.cloneDeep(this.state.estabelecimento);
        estabelecimento = result.data.data;
        this.setState({ estabelecimento });
      });
    }
    this.props.closePendecia();
  }

  changeFilter = filterSearch => {
    this.setState({ filterSearch });
  };

  atualizarConvenioAtendimentos = (listaAtendimentos) =>{
    let lista = [];
    if(listaAtendimentos && listaAtendimentos.length > 0){
      lista =  listaAtendimentos.map((atendimento) => {
        atendimento.convenio = listaAtendimentos[0].convenio;
        return atendimento;
     })

    }
    this.setState({ atendimentos:lista});
  }

  //clonar o state numa let (REGRA!!!!)
  //atualiza o state atendimentos  -> com a lista alterada no edição massa topo
  //percorrer a lista até achar o id na lista -> ao achar atualiza no state atual
  updateListaDeAtendimentosEdicaoEmMassa = (listaAtendimentos) =>{
    this.setState({ atendimentos:listaAtendimentos});

  }
  updateListaDeAtendimentos = (listaAtendimentos) =>{

    let arrayAtendimentosState = _.cloneDeep(this.state.atendimentos);

    for(let i=0;i < listaAtendimentos.length ;i++) {
      for (let j = 0; j < arrayAtendimentosState.length; j++) {
        if (listaAtendimentos[i].id === arrayAtendimentosState[j].id){
              arrayAtendimentosState[j] = listaAtendimentos[i];
        }
      }
    }

    this.setState({ atendimentos:arrayAtendimentosState});

  }

  updateSaved = (atendimento, index) => {
    let arrayAtendimentos = _.cloneDeep(this.state.atendimentos);
    if (atendimento === null) {
      arrayAtendimentos.splice(index, 1);
    } else {
      arrayAtendimentos[index] = atendimento;
    }
    this.updateAtendimentos(arrayAtendimentos);
  };

  updateAtendimentos = (arrayAtendimentos, lote) => {
    let thisLote = this.state.lote;
    let ids = [];
    for (let i = 0; i < arrayAtendimentos.length; i++) {
      ids.push(arrayAtendimentos[i].id);
    }
    atendimentoService.findByIds(ids).then(response => {
      let arrayAtendimentos = response.data;
      this.setState({
        atendimentos: arrayAtendimentos,
        checkedAll: false,
        rowsCheckedValue: [],
        rowsCheckedIdValue: [],
        lote: lote ? lote : thisLote
      });
    });
  };

  addAtendimento = () => {
    let arrayAtendimentos = _.cloneDeep(this.state.atendimentos);
    arrayAtendimentos.push({});
    this.setState({ atendimentos: arrayAtendimentos });
  };

  cancelEditCreate = (values, index) => {
    let arrayAtendimentos = _.cloneDeep(this.state.atendimentos);
    if (arrayAtendimentos[index].id == undefined) {
      arrayAtendimentos = arrayAtendimentos.filter((item, i) => i !== index);
      this.setState({ atendimentos: arrayAtendimentos });
    }
  };
  filterAtendimentos = atendimentos => {
    let atendimentoFilter = [];
    if (this.state.filterSearch && this.state.filterSearch.length > 0) {
      for (let i = 0; i < this.state.filterSearch.length; i++) {
        const { filter } = this.state.filterSearch[i];
        let sentenca = this.state.filterSearch[i].value;
        if (moment.isMoment(sentenca)) {
          sentenca = sentenca.format("YYYY-MM-DD");
        }
        for (
          let indexAtendimento = 0;
          indexAtendimento < atendimentos.length;
          indexAtendimento++
        ) {
          const atendimento = atendimentos[indexAtendimento];
          let showAtendimento = false;
          for (var propName in atendimento) {
            var propValue = atendimento[propName];
            showAtendimento =
              showAtendimento ||
              this.validaFilterValue(propValue, sentenca, propName, filter);
          }
          if (showAtendimento) {
            atendimentoFilter.push(atendimento);
          }
        }
      }
    } else {
      atendimentoFilter = _.cloneDeep(atendimentos);
    }

    return atendimentoFilter;
  };

  setaClasseSentencaEncontrada = propName => {
    let ele1 = document.getElementsByClassName("sentencaEncontrada");
    for (let i = 0; i < ele1.length; i++) {
      ele1[i].classList.remove("sentencaEncontrada");
    }
    let ele = document.querySelectorAll('[name="' + propName + '"]');
    for (let i = 0; i < ele.length; i++) {
      ele[i].classList.add("sentencaEncontrada");
    }
  };

  validaFilterValue = (propValue, filterValue, propName, filter) => {
    if (typeof propValue === "string") {
      if (propName === filter.fieldName) {
        this.setaClasseSentencaEncontrada(propName);
        if (propValue.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1) {
          return true;
        }
      }
    }
    if (Object.prototype.toString.call(propValue) === "[object Array]") {
      let showAtendimento = false;
      for (let i = 0; i < propValue.length; i++) {
        showAtendimento =
          showAtendimento ||
          this.validaFilterValue(propValue[i], filterValue, propName, filter);
      }
      return showAtendimento;
    }
    if (Object.prototype.toString.call(propValue) === "[object Object]") {
      let showAtendimento = false;
      for (var propNameInner in propValue) {
        const propNamePass =
          propName === filter.fieldName ? propName : propNameInner;

        showAtendimento =
          showAtendimento ||
          this.validaFilterValue(
            propValue[propNameInner],
            filterValue,
            propNamePass,
            filter
          );
      }
      return showAtendimento;
    }
    return false;
  };



  onCheckedRow = (checked, id, row) => {
    let rowsCheckedValue = _.cloneDeep(this.state.rowsCheckedValue);
    let rowsCheckedIdValue = _.cloneDeep(this.state.rowsCheckedIdValue);
    let checkedAll = false;

    if (checked) {
      rowsCheckedIdValue.push(row.id);
      rowsCheckedValue.push(row);
      if (rowsCheckedIdValue.length === this.state.atendimentos.length) {
        checkedAll = true;
      }
    } else {
      var index = rowsCheckedIdValue.indexOf(id);

      if (index > -1) {
        rowsCheckedValue.splice(index, 1);
        rowsCheckedIdValue.splice(index, 1);
      }
    }
    this.setState({ rowsCheckedValue, rowsCheckedIdValue, checkedAll });
  };
  checkAll = () => {
    const { checkedAll } = this.state;
    let rowsCheckedValue = [];
    let rowsCheckedIdValue = [];
    if (checkedAll) {
      this.setState({
        checkedAll: false,
        checkedAllPages: false,
        rowsCheckedValue,
        rowsCheckedIdValue
      });
    } else {
      this.state.atendimentos.map(data => {
        rowsCheckedValue.push(data);
        rowsCheckedIdValue.push(data.id);
      });
      this.setState({ checkedAll: true, rowsCheckedValue, rowsCheckedIdValue });
    }
  };

  confirmMudarCovenio = (
    title,
    mensagem,
    subMensagem,
    textCancelBtn,
    textConfirmBtn,
    type
  ) => {
    let _this = this;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui css-alert-sasc confirm-ui-form">
            <img src={iconEncaminhar} alt="icon" />
            <h2 className="mb-3">{title}</h2>
            <p>
              <b>{subMensagem}</b>
            </p>
            <div className="row body-confirm cardCrud ">{mensagem}</div>

            <button
              className="btn btn-secondary white mt-3 mb-4 mx-2"
              onClick={() => {
                onClose();
              }}
            >
              {textCancelBtn}
            </button>
            {textConfirmBtn && (
              <button
                className="btn btn-primary white mt-3 mb-4 mx-2"
                onClick={() => {
                  if (!this.state.convenio || _.get(this.state.convenio, 'status.name') === "INATIVO") {
                    _this.setState({ erroConvenio: "Selecione um convênio ativo." });
                    this.props.error({
                      message: "Selecione um convênio ativo."
                    });
                  } else {
                    _this.handleAlterarConvenioDoLoteAtendimento();
                    onClose();
                  }
                }}
              >
                {textConfirmBtn}
              </button>
            )}
          </div>
        );
      }
    });
  };

  handleAlterarConvenioDoLoteAtendimento = () => {
    const { convenio, lote } = this.state;
    this.props.loading(true);
    atendimentoService.alterarConvenioLote(convenio, lote.id).then(
      response => {
        this.props.success({
          message: `Lote ${lote.id}, foi alterado e encaminhado para faturamento.`
        });
        this.setState({ lote: response.data.data }, () => {
          this.updateAtendimentos(this.state.atendimentos);
        });
        this.props.loading(false);
      },
      () => {
        this.props.error({
          message:
            "O lote não pode ser encaminhado para SASC, contate o Administrador para mais informações."
        });
        this.props.loading(false);
      }
    );
  };

  atendimentosSemNumeroGuia=()=>{
    let arrayNumeroAtendimentos=[];
    let atendimentos = this.state.atendimentos;
    for (let i=0;i < atendimentos.length;i++){
      if(!_.get(atendimentos[i],'numeroGuia')){
        arrayNumeroAtendimentos.push(_.get(atendimentos[i],'numeroAtendimento'))
      }
    }
    return arrayNumeroAtendimentos;
  }

  handleEncaminharLoteAtendimento = (status, lote) => {
    this.props.loading(true);
    let _this = this;
    let arrayNumeroAtendimentos = this.atendimentosSemNumeroGuia();
    if(!arrayNumeroAtendimentos.length){
      atendimentoService.encaminharParaFaturamento(status.id, lote.id).then(
          response => {
            this.props.success({
              message: `Lote ${lote.id} encaminhado para faturamento.`
            });
            this.setState({ lote: response.data.data, mudarConvenio: false }, () => {
              this.updateAtendimentos(this.state.atendimentos);
            });
            this.props.loading(false);
          },
          erros => {
            let response = erros.response.data;
            if ((erros.response.status === 400 || erros.response.status === 422) 
            && ((typeof response == 'string'?true:false) || (typeof response.data == 'string'?true:false))) {
              this.props.error({
                message:
                     " Contate o Administrador para mais informações."
              });
              this.props.error({
                message:
                erros.response.data
              });
            } else {
              this.state.atendimentoCooperadoPadrao = erros.response.data;
              this.toogleModalAtendimentoCooperadoPadrao()
            }

            if (response && response.messages) {
              let hasOtherPendencia = false;
              for (var i = 0; i < response.messages.length; i++) {
                let erroItem = response.messages[i];

                if (erroItem.message.code === 'ATENDIMENTO_SEM_NUM_GUIA_NO_LOTE') {
                  this.props.error({
                    message:
                        "O lote não pode ser encaminhado para Faturamento, pois existem atendimentos sem número de Guia."
                  });
                  hasOtherPendencia = true;
                  break;
                }
                else if (erroItem.message.code === "CONVENIO_LOTE_INATIVO" && !hasOtherPendencia) {
                  _this.setState({ mudarConvenio: true, status, lote });
                } else {
                  this.props.error({
                    message:
                    erroItem.message.code
                  });
                  hasOtherPendencia = true;
                  break;
                }
              }
            }
            this.props.loading(false);
          }
      );
    }else{
      this.props.loading(false);
      this.props.error({
        message:
            `O lote não pode ser encaminhado para Faturamento, pois os atendimentos ${arrayNumeroAtendimentos.join(',')} estão sem número de Guia.`
      });
    }

  };

  handleDeleteLote = lote => {
    this.props.loading(true);

    loteAtendimentoService.doDelete(lote).then(
      () => {
        this.props.success({
          message: `Lote excluido sucesso!`
        });
        this.props.history.push({
          pathname: "/atendimento"
        });
        this.props.loading(false);
      },
      erros => {
        this.props.error({
          message: "Não foi possível excluir lote."
        });
        this.props.loading(false);
      }
    );
  };

  exibeMais = () => {
    let index = this.state.paginaAtual * this.state.pageSize;

    if (index < this.state.atendimentos.length) {
      let paginaAtual = this.state.paginaAtual + 1;
      this.setState({ paginaAtual });
      let page = Math.ceil(
        this.state.atendimentos.length / this.state.pageSize
      );
      if (page === paginaAtual) {
        let showPagination;
        showPagination = false;
        this.setState({ showPagination });
      }
    } else {
    }
  };

  toogleModalAtendimentoCooperadoPadrao = () => {
    this.setState({ showModalAtendimento: !this.state.showModalAtendimento })
  }

  handleEncaminharAtendimento = status => {

    this.props.loading(true);
    const idsAtendimentosPorOrdemDeSelecao = _.map(this.state.rowsCheckedIdValue, (item, index) => ({ idAtendimento: item, posicao: index }));
    atendimentoService
      .encaminharAtendimentos(status.id, idsAtendimentosPorOrdemDeSelecao)
      .then(
        response => {
          this.props.success({
            message: `Atendimentos encaminhados para ${status.nome}`
          });
          this.updateAtendimentos(this.state.atendimentos);
          this.props.loading(false);
        },
        erros => {
          if (erros.response.status === 400) {
            this.props.error({
              message:
                "Os atendimentos não pode ser encaminhado para Protocolo, pois existem pendências nos atendimentos."
            });
          }

          this.props.loading(false);
        }
      );
  };

  getValidationScheme = (type) => {
    let validationScheme = {};
    switch (type) {
      case objectsConstants.ENUM_TIPOS_GUIA.CONSULTA:
        validationScheme = objectsConstants.validateGuiaConsulta;
        break;
      case objectsConstants.ENUM_TIPOS_GUIA.HONORARIO:
        validationScheme = objectsConstants.validateHonorarios;
        break;
      default:
        validationScheme = objectsConstants.validateGuiaSADT;
        break;
    }
    return validationScheme;
  };

  setMenuHistorico = (status, atendimento) => {
    this.setState({ openMenuHistorico: status, listaHistoricos: [] }, () => {
      if (this.state.openMenuHistorico) {
        this.loadHistoryAtendimento(atendimento);

        document.body.classList.add("bodyNoScroll");
        document.getElementById("bodyMenu").scrollIntoView();
      } else {
        document.body.classList.remove("bodyNoScroll");
      }
    });
  };
  loadHistoryAtendimento = atendimento => {
    this.props.loading(true);
    atendimentoService.historico(atendimento.id).then(
      result => {
        this.setState(
          { listaHistoricos: result.data, atendimentoHistorico: atendimento },
          () => {
            this.props.loading(false);
          }
        );
      },
      error => {
        this.setState({ listaHistoricos: [] }, () => {
          this.props.loading(false);
        });
      }
    );
  };

  toggleHistorico = atendimento => {
    this.setMenuHistorico(!this.state.openMenuHistorico, atendimento);
  };

  impressao = (atendimento, nomeRelatorio) => {
    let _this = this;
    let urlReport = urlsConstants.REPORT + nomeRelatorio + "/" + atendimento.id;
    if(this.props.user.cooperativa&&this.props.user.cooperativa.bloquearDataAtendimentoConsulta){
      atendimentoService.bloquearDataAtendimento(atendimento.id).then(()=>{
        _this.updateAtendimentos(this.state.atendimentos)
      });
    }
    window.open(urlReport, "_blank");
  };

  removeLote = atendimento => {
    this.setState({
      atendimentos: _.filter(this.state.atendimentos, function (atendimentoState) {
        return atendimento.id !== atendimentoState.id;
      }
      )
    })


  }

  renderAtendimentos = (atendimento, index) => {
    const validationSchema = this.getValidationScheme(atendimento.type);
    if (atendimento.type) {
      let GuiaAtual = null;
      switch (atendimento.type) {
        case objectsConstants.ENUM_TIPOS_GUIA.CONSULTA:
          GuiaAtual = GuiaConsultaForm;
          break;
        case objectsConstants.ENUM_TIPOS_GUIA.HONORARIO:
          GuiaAtual = GuiaHonorariosForm;
          break;
        default:
          GuiaAtual = GuiaSADTForm;
          break;
      }
      return (
        <GuiaAtendimentoForm
          //<Drag
          removeLote={this.removeLote}
          index={index}
          collapse={this.state.atendimentos.length < 2 || !atendimento.id}
          moveGuia={this.moveAtendimento}
          atendimento={atendimento}
          onSaved={this.updateSaved}
          onCancelEditCreate={this.cancelEditCreate}
          onCheckedAtendimento={this.onCheckedRow}
          onClickPendencia={this.onClickPendenciaRow}
          imprimir={this.impressao}
          rowsCheckedIdValue={this.state.rowsCheckedIdValue}
          checkedAll={this.state.checkedAll}
          key={atendimento.id}
          id={atendimento.id}
          toggleHistorico={this.toggleHistorico}
          criarGuiaPassoPasso={this.state.criarGuiaPassoPasso}
          GuiaForm={GuiaAtual}
          initData={atendimento}
          validateObject={validationSchema}
          wrappedComponentRef={c => (this.guiaFormItem = c)}
          listStatusEncam={this.state.listStatusEncaminhamento}
          listUfsConselho={this.state.listUfsConselho}
          listConselho={this.state.listConselho}
          lote={this.state.lote}
        />
      );
    }
  };

  handleCheckedList = () =>{
    if(this.state.rowsCheckedValue.length > 0){
        return ( this.state.rowsCheckedValue);
    }else{
      return( this.state.atendimentos);
    }

  }

  render() {
    const { atendimentos, paginaAtual, pageSize, mudarConvenio } = this.state;
    const indexOfLastTodo = paginaAtual * pageSize;
    const listaFiltrada = this.filterAtendimentos(atendimentos);
    const currentTodos = listaFiltrada.slice(0, indexOfLastTodo);
    let canEdit =
      _.get(this.state.lote, "situacaoLote") ===
      objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA;
    let _this = this;
    if (mudarConvenio) {
      _this.confirmMudarCovenio(
        "Este lote não pode ser encaminhado para o faturamento, pois o convênio está inativo.",
        <span>
          {" "}
          Escolha abaixo um convênio ativo para alterar o convênio do lote:{" "}
          <div className="section-form">
            <ConvenioFormSelect
              label={"Convênio"}
              required={true}
              placeholder="Selecione um convênio"
              component={ConvenioFormSelect}
              sortKey={"nome"}
              service={convenioService.findAll}
              className={"destaque"}
              name={"convenio"}
              returnFullObject={true}
              searchable={true}
              labelKey={"nome"}
              valueKey={"id"}
              erroMensagem={_this.state.erroConvenio}
              value={this.state.convenio}
              parent={this.state.estabelecimento}
              onChange={(name, value) => {
                _this.setState({ convenio: value });
              }}
            />
          </div>
          <p className="font-bold pt-2">
            Deseja realmente alterar o convênio deste lote?
          </p>
        </span>,
        ``,
        "Cancelar",
        "Alterar",
        objectsConstants.TYPE_FORWARD
      );
    }

    let listaAtendimentos = this.handleCheckedList();

    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          <TopoTitleComponente
            mainTitle={`Lote ${this.props.match.params.id}`}
            subTitle=" "
            canBack={true}
          />

          <div
            className={`action-line ${this.state.activeClass} fixed-container-topo`}
            id="pendenciaTopo"
          >
            {this.state.lote.id &&
            !this.props.pendenciaOpen &&
            !this.props.resolverPendenciaOpen &&
            !this.props.edicaoEmMassaOpen &&
            this.state.rowsCheckedIdValue.length < 1 && (
                <LotesTopo
                  handleEncaminharLoteAtendimento={
                    this.handleEncaminharLoteAtendimento
                  }
                  handleEncaminharAtendimento={this.handleEncaminharAtendimento}
                  rowsCheckedValue={this.state.rowsCheckedValue}
                  atendimentos={this.state.atendimentos}
                  lote={this.state.lote}
                  handleDeleteLote={this.handleDeleteLote}
                  onAddAtendimento={this.addNovoAtendimento}
                />
              )}
            {this.state.lote.id &&
              !this.props.pendenciaOpen &&
              !this.props.resolverPendenciaOpen &&
              !this.props.edicaoEmMassaOpen &&
              this.state.rowsCheckedIdValue.length > 0 && (
                <GuiaTopo
                  rowsCheckedIdValue={this.state.rowsCheckedIdValue}
                  listaAtendimentos={this.state.atendimentos}
                  handleEncaminharAtendimento={this.handleEncaminharAtendimento}
                />
              )}
            {this.props.pendenciaOpen && (
              <PendenciasTopo
                atendimento={this.state.atendimentoComPendencia}
                onSaved={this.updateSaved}
              />
            )}
            {this.props.resolverPendenciaOpen && (
              <ResolverPendenciasTopo
                atendimento={this.state.atendimentoComPendencia}
                onSaved={this.updateSaved}
              />
            )}


            {this.props.edicaoEmMassaOpen && (
                <EdicaoEmMassaTopo
                    atendimento={this.state.atendimentoComPendencia}
                    listaAtendimentos={listaAtendimentos}
                    onSaved={this.updateListaDeAtendimentosEdicaoEmMassa}
                />
            )}

          </div>
          <div
            id="conteudoAtendimentos"
            className={`contentComTopoFixLote`}
          >
            <hr className="text-center w-75"></hr>

            <div className="background-lines">
              <div className="bg-riscos"></div>
            </div>

            {canEdit && (
              <div
                className={`action-line d-flex flex-column flex-sm-row flex-wrap justify-content-between align-items-center p-0`}
              >
                {this.state.rowsCheckedValue.length === 0 && (
                  <button
                    type="button"
                    className="btn btn-light btDefaultGrid"
                    onClick={this.checkAll}
                  >
                    <span className="icon-btn-check-black"></span>
                    Selecionar todos
                  </button>
                )}
                {this.state.rowsCheckedValue.length > 0 && (
                  <div
                    className={
                      "d-flex flex-column flex-sm-row  justify-content-between align-items-center boxAction"
                    }
                  >
                    <div className="labelInfoAction  mr-sm-auto">
                      <label className="control control--checkbox">
                        <Input
                          type={"checkbox"}
                          value={this.state.checkedAll}
                          checked={this.state.checkedAll}
                          className={`classUnchecked`}
                          onClick={this.checkAll}
                        />{" "}
                        <div className="control__indicator"></div>
                      </label>
                      {!this.state.checkedAllPages && (
                        <React.Fragment>
                          {this.state.rowsCheckedValue.length}{" "}
                        </React.Fragment>
                      )}
                      {this.state.checkedAllPages && (
                        <React.Fragment>
                          {this.state.atendimentos}{" "}
                        </React.Fragment>
                      )}
                      {this.props.name} selecionados
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="searchGuia">
              <GuiaPesquisa changeFilter={this.changeFilter}></GuiaPesquisa>
            </div>
            {this.state.atendimentos &&
              currentTodos.map((atendimento, index) => {
                return this.renderAtendimentos(atendimento, index)
              })}
          </div>
          <MenuModelView
            titulo={""}
            subTitulo={"Modelo"}
            atendimentos={this.state.atendimentos}
            />
          {this.state.atendimentoHistorico.id && (
            <MenuHistorico
              titulo={this.state.atendimentoHistorico.numeroAtendimento}
              subTitulo={this.state.atendimentoHistorico.status.nome}
              listaHistoricos={this.state.listaHistoricos}
              toggleHistorico={this.toggleHistorico}
              openMenuHistorico={this.state.openMenuHistorico}
            />
          )}
          <div className="pagination-button d-flex justify-content-end align-items-right my-2 mb-5">
            {this.state.showPagination && (
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  this.exibeMais();
                }}
              >
                <span className="icon-btn-add"></span>
                Exibir mais
              </button>
            )}
          </div>
        </div>

        <Modal
          isOpen={this.state.showModalAtendimento}
          toggle={this.toogleModalAtendimentoCooperadoPadrao}
          backdrop="static"
          modalClassName=""
          className=""
          centered={true}
        >
          <ModalBody>
            <div className="modalCooperado">
              <div className="row">
                <div className="col-12 text-center my-2">
                  <h2 className="font-weight-bold">Existem atendimentos para o cooperado padrão</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-left mx-3 mt-3">

                  <span>
                    {" "}
                    Atendimentos com cooperado padrão:{" "}
                  </span>
                </div>
                <div className="col-10 text-left ml-3 mb-3 pr-2 ">
                  {this.state.atendimentoCooperadoPadrao.join(', ')}
                </div>
              </div>
              <div className="row">
                <div className="flex-grow px-1 m-auto justify-content-center">

                  <button
                    className="btn btn-secondary white mt-3 mb-3 mx-2"
                    type={"button"}
                    onClick={() => {
                      this.toogleModalAtendimentoCooperadoPadrao()
                    }}
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  pendencia: { togglePendencia, atendimentoAtual, closePendecia },
  edicaoEmMassa: { toggleEdicaoEmMassa, atendimentoParaEditar }
}) => ({
  closePendecia: () => closePendecia(),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear(),
  togglePendencia: () => togglePendencia(),
  atendimentoAtual: idAtendimentoAtual =>
    atendimentoAtual({ idAtendimentoAtual }),
  atendimentoParaEditar: atendimento => atendimentoParaEditar(atendimento),
  toggleEdicaoEmMassa: () => toggleEdicaoEmMassa(),
});

function mapStateToProps(state) {
  const { pendenciaOpen, idAtendimentoAtual, resolverPendenciaOpen} = state.pendencia;
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;
  const { filtroAtendimento } = state.filtro;
  const { edicaoEmMassaOpen,idAtendimentoParaEditar } = state.edicaoEmMassa;

  return {
    pendenciaOpen,
    resolverPendenciaOpen,
    idAtendimentoAtual,
    loggedIn,
    alert,
    user,
    permissions,
    filtroAtendimento,
    edicaoEmMassaOpen,
    idAtendimentoParaEditar
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(LotesList));
