//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import UsuarioList from './UsuarioList';
import UsuarioEdit from './UsuarioEdit';
import UsuarioView from './UsuarioView';
export default class Usuario extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/usuario" component={UsuarioList} exact/>
          <Route path="/usuario/novo" component={UsuarioEdit}  />
          <Route path="/usuario/edit/:id" component={UsuarioEdit}  />
          <Route path="/usuario/view/:id" component={UsuarioView}  />
         
        </Switch>
      </React.Fragment>
    );
  }
}
