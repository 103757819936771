//@flow
import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";
import { objectsConstants } from "../constants/objects.constants";

export const enumService = {
  findTipoAtendimento,
  findAcomodacoes,
  findAcomodacaoConvenioPorTipoGuia,
  acomodacaoObrigatorio,
  findCaraterAtendimento,
  findTipoInternacao,
  findRegimeInternacao,
  findTipoDoenca,
  findIndicacaoAcidente,
  findTipoTempoDoenca,
  findCaraterInternacao,
  findCid,
  findAcomodacoesPorTipoGuiaAndConvenioAndCooperativa,
};
function findTipoTempoDoenca() {
  return defaultService.doGet(`${urlsConstants.ENUM_API}findTipoTempoDoenca`);
}
function findTipoAtendimento() {
  return defaultService.doGet(`${urlsConstants.ENUM_API}findTipoAtendimento`);
}
function findAcomodacoes() {
  return defaultService.doGet(`${urlsConstants.ENUM_API}findAcomodacoes`);
}

function findAcomodacaoConvenioPorTipoGuia(atendimento) {
  let type;
  for (let i = 0; i < objectsConstants.TIPOS_GUIA_REGRA_CONVENIO.length; i++) {
    if (
      objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].label === atendimento.type
    ) {
      type = objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].id;
    }
  }
  if (atendimento && atendimento.convenio && atendimento.type) {
    return defaultService.doGet(
      `${urlsConstants.ENUM_API}findByConvenioAndTipoGuia/${atendimento.convenio.id}/${type}`
    );
  }
}
function acomodacaoObrigatorio(tiposGuiaProduto, tipoGuia) {
  if (tiposGuiaProduto) {
    for (let i = 0; i < tiposGuiaProduto.length; i++) {
      if (
        objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[tiposGuiaProduto[i].id]
          .label === tipoGuia
      ) {
        return true;
      }
    }
  }
  return false;
}

function findCaraterAtendimento() {
  return defaultService.doGet(
    `${urlsConstants.ENUM_API}findCaraterAtendimento`
  );
}
function findTipoInternacao() {
  return defaultService.doGet(`${urlsConstants.ENUM_API}findTipoInternacao`);
}
function findRegimeInternacao() {
  return defaultService.doGet(`${urlsConstants.ENUM_API}findRegimeInternacao`);
}
function findTipoDoenca() {
  return defaultService.doGet(`${urlsConstants.ENUM_API}findTipoDoenca`);
}
function findIndicacaoAcidente() {
  return defaultService.doGet(`${urlsConstants.ENUM_API}findIndicacaoAcidente`);
}

// Sobrescrever
function findCaraterInternacao() {
  return defaultService.doGet(
    `${urlsConstants.ENUM_API}findCaraterAtendimento`
  );
}

function findCid() {
  return defaultService.doGet(`${urlsConstants.ENUM_API}findIndicacaoAcidente`);
}

function findAcomodacoesPorTipoGuiaAndConvenioAndCooperativa(parent) {
  if (parent && parent.idConvenio && parent.tipoGuia) {
    return defaultService.doGet(
      `${urlsConstants.ENUM_API}findAcomodacoesPorTipoGuiaAndConvenioAndCooperativa/${parent.idConvenio}/${parent.tipoGuia}`
    );
  }
}
