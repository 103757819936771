//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import LotesList from './LotesList';
export default class Lotes extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/lotes/:id" component={LotesList} />
        </Switch>
      </React.Fragment>
    );
  }
}
