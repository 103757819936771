import {defaultService} from "./defaultService";
import {urlsConstants} from "../constants/url.constant";


export const relatoriosService = {
    exportRelatorio
};

function exportRelatorio(filter,controller) {
     return defaultService.doPost(`${urlsConstants.RELATORIOS}${controller}`,filter)
}

