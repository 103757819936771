import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { FormGroup } from "../../../../components/FormGroup";
import DatePickerInput from "../../../../components/Inputs/DatePickerInput";
import InputViewEdit from "../../../../components/Inputs/InputViewEdit";
import { dateHelper } from "../../../../helpers/date.helper";
import SelectableInput from "../../../../sascComponents/inputs/SelectableInput";
import { enumService } from "../../../../services/enum.service";
import FormSelectInput from "../../../../components/Inputs/FormSelectInput";
import * as _ from "lodash";

class DadosInternacaoGuiaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  // findDadosBeneficiario = () => {
  //   beneficiarioService
  //     .findByCarteirinha(this.props.values.numeroCarteira)
  //     .then(response => {
  //       let newValues = _.cloneDeep(this.props.values);
  //       newValues.dataValidadeCarteira = response.data.dataValidadeCarteira;
  //       newValues.nomeBeneficiario = response.data.nomeBeneficiario;
  //       newValues.cartaoNacionalSaude = response.data.cartaoNacionalSaude;
  //       this.props.setValues(newValues);
  //     });
  // };

  sendToStorange = (values) => {
    try {
      values.rascunho &&
        localStorage.setItem("rascunho", JSON.stringify(values));
    } catch (error) {
      if (
        error instanceof DOMException &&
        (error.code === 22 ||
          error.code === 1014 ||
          error.name === "QuotaExceededError" ||
          error.name === "NS_ERROR_DOM_QUOTA_REACHED")
      ) {
        console.error("Limite de armazenamento local atingido");
      } else {
        console.error("Erro ao salvar no armazenamento local:", error.message);
      }
    }
  };

  render() {
    const { setFieldValue, values, errors, viewMode } = this.props;
    return (
      <React.Fragment>
        <div className={`row ${this.props.className}`}>
          <h6 className="w-100">Dados da Internacao</h6>

          <SelectableInput
            viewMode={viewMode}
            atendimento={values}
            className="fixo4 mobile-100"
            name={"acomodacao"}
            label={"Acomodação"}
            value={values.acomodacao && values.acomodacao.description}
          >
            <InputViewEdit
              label="Acomodação"
              required={true}
              disabled={!values.convenio}
              component={FormSelectInput}
              parent={{ type: values.type, convenio: values.convenio }}
              service={enumService.findAcomodacaoConvenioPorTipoGuia}
              sortKey={"description"}
              className={"destaque"}
              name={"acomodacao"}
              defaultValue={
                values.acomodacao ? values.acomodacao.description : "--"
              }
              returnFullObject={true}
              searchable={true}
              labelKey={"description"}
              valueKey={"name"}
              value={values.acomodacao}
              viewMode={viewMode}
              erroMensagem={errors.acomodacao}
              onChange={(name, value) => {
                setFieldValue(name, value);
              }}
              onBlur={() => this.sendToStorange(values)}
              alertMensagem={
                values.convenio
                  ? values.convenio.listRegraMensagemTipoGuia
                  : null
              }
            />
          </SelectableInput>
          <SelectableInput
            viewMode={viewMode}
            atendimento={values}
            className="fixo"
            name={"dataIniFaturamento"}
            label={"Data do início do faturamento"}
            value={
              values.dataIniFaturamento &&
              dateHelper.format(values.dataIniFaturamento, { mode: "DATE" })
            }
          >
            <InputViewEdit
              label={"Data do início do faturamento"}
              component={DatePickerInput}
              onChange={(name, date) => {
                let data = moment(date).format("YYYY-MM-DD");
                let hora = moment(new Date()).format("HH:mm:ss");
                let dataHora = data + "T" + hora;

                setFieldValue(name, dataHora);
              }}
              onBlur={() => this.sendToStorange(values)}
              erroMensagem={_.get(errors, `dataIniFaturamento`)}
              defaultValue={
                values.dataIniFaturamento
                  ? dateHelper.format(values.dataIniFaturamento)
                  : "--"
              }
              mask={"99/99/9999"}
              name={"dataIniFaturamento"}
              id={"dataIniFaturamento"}
              placeholder=""
              required={true}
              type={"text"}
              viewMode={viewMode}
              value={values.dataIniFaturamento}
              alertMensagem={
                values.convenio
                  ? values.convenio.listRegraMensagemTipoGuia
                  : null
              }
            />
          </SelectableInput>
          <SelectableInput
            viewMode={viewMode}
            atendimento={values}
            className="fixo4"
            name={"dataFimFaturamento"}
            label={"Data do fim do faturamento"}
            value={
              values.dataFimFaturamento &&
              dateHelper.format(values.dataFimFaturamento, { mode: "DATE" })
            }
          >
            <InputViewEdit
              label={"Data do fim do faturamento"}
              component={DatePickerInput}
              onChange={(name, date) => {
                let data = moment(date).format("YYYY-MM-DD");
                let hora = moment(new Date()).format("HH:mm:ss");
                let dataHora = data + "T" + hora;

                setFieldValue(name, dataHora);
              }}
              onBlur={() => this.sendToStorange(values)}
              erroMensagem={_.get(errors, `dataFimFaturamento`)}
              defaultValue={
                values.dataFimFaturamento
                  ? dateHelper.format(values.dataFimFaturamento)
                  : "--"
              }
              required={true}
              mask={"99/99/9999"}
              name={"dataFimFaturamento"}
              id={"dataFimFaturamento"}
              placeholder=""
              type={"text"}
              viewMode={viewMode}
              value={values.dataFimFaturamento}
              alertMensagem={
                values.convenio
                  ? values.convenio.listRegraMensagemTipoGuia
                  : null
              }
            />
          </SelectableInput>
          <div className="form-group form-group-boorder col"></div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu(),
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions,
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosInternacaoGuiaForm));
