import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { objectsConstants } from "../../../constants/objects.constants";
import Datatable from "../../../sascComponents/datagrid/Datatable";
import TopoListagem from "../../../sascComponents/topo/TopoListagem";
import { pacoteExameService } from "../../../services/pacoteExame.service";
import TopoTitleComponente from "../../home/TopoTitleComponente";
import LinhaAcaoExcluirPacoteExame from "./LinhaAcaoExcluirPacoteExame";


class PacoteExameList extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        
        let initFilter = null
        this.state = {
            initFilter
        };
    }
    closeMenu = () => {
        this.props.showMenu();
    };
    handleRowClick = (fieldName, rowData) => {
        this.props.history.push({
            pathname: "/pacoteexame/view/" + (rowData.id),
            state: { entity: rowData }
        });
    };

    render() {
        const { permissions } = this.props;
        return (
            <div className="container-fluid container-com-topo">
                <TopoTitleComponente
                    mainTitle="Pacote(s) de Exame(s)"
                    subTitle=" "
                    canBack={true}
                    backUrl={'/exame'}
                />
                <TopoListagem
                    securityActionDo={objectsConstants.ALTERAR}
                    securityActionOn={objectsConstants.CRUDCONSULTORIOS_CADASTRAR}
                    buttonActionClassName={"btn-light"}
                    buttonAction={() => {
                        this.props.history.push("/pacoteexame/novo");
                    }}
                    securityActionIcon={"icon-btn-add"}
                    securityActionName={"Cadastrar pacote"}
                    showfilter={false}
                />
                <hr className="text-center w-75"></hr>

                <div className="background-lines">
                    <div className="bg-riscos"></div>
                </div>

                <Datatable
                    showCheckboxColumn={true}
                    idRow={"id"}
                    name={"Pacote(s) de exame(s)"}
                    service={pacoteExameService}
                    showAllColumnsFilter={false}
                    //initFilter={this.state.initFilter}
                    ActionLine={LinhaAcaoExcluirPacoteExame}
                    onRowClick={this.handleRowClick}
                    //noCacheFilter={this.props.noCacheFilter}
                    classHeader={[
                        "headerLeft",
                        "headerCenter",
                        "headerCenter",
                        "headerCenter",
                        "headerLeft"
                    ]}
                    classColumn={[]}
                />
            </div>
        );
    }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
    showMenu: () => showMenu(),
    hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
    const { loggedIn, user, permissions } = state.authentication;
    const { alert } = state;

    return {
        loggedIn,
        alert,
        user,
        permissions
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(PacoteExameList));
