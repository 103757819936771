import React, { PureComponent } from 'react';
import InputMask from 'react-input-mask';
import { FormGroup, Label } from 'reactstrap';
import { translate } from '../../helpers/message.helper';
import { connect } from 'react-redux';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import { ToolTipHelp } from '../Utils/ToolTipHelp';
import { objectsConstants } from '../../constants/objects.constants';

type Props = {
  label: string,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange: any,
  onBlur: any,
  children?: any,
  required: boolean,
  messageValidate?: string,
  placeholder: string,
  disabled: boolean,
  maxLength: number,
  style: any
};
class CpfInput extends PureComponent<Props> {
  static defaultProps = {
    size: 12
  };

  handleBorderColor = messageValidate => {
    let borderColor;
    if (messageValidate) {
      borderColor = '#dc3545';
    }
    return {
      borderColor
    };
  };

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    if (this.props.onChange) {
      this.props.onChange(name, value);
    }
  };

  render() {
    const { messages, name, alertMensagem } = this.props;
    const value = this.props.value ? this.props.value : ""
    let messageValidate;
    if (messages != null && messages.length > 0) {
      messages.forEach(function (message, index) {
        if (message.fieldName == name) {
          messageValidate = translate(message.message.code);
        }
      });
    }
    let mensagemAlert;
  
    if (alertMensagem) {
      let tipoGuia = objectsConstants.TIPOS_GUIA.find(element => { if (element.label === localStorage.getItem("tipoGuia")) return element; });

      mensagemAlert= alertMensagem.find(element =>
        this.props.name.toLowerCase().includes(element.campoAtendimentoTipoGuia.campoAtendimento.nomeCampo.toLowerCase())
        && this.props.label.toLowerCase().includes(element.campoAtendimentoTipoGuia.campoAtendimento.label.toLowerCase())
        && tipoGuia.id == element.tipoGuia.id);
    }

    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    return (
      <div className={`${this.props.required ? "input-required" : ""} ${this.props.style}`}>
        {this.props.label && (
          <Label for={this.props.name} className='label-nowrap' title={this.props.label}>
            {this.props.label}
            {this.props.required && '*'}
          </Label>
        )}
        {mensagemAlert && (
          <React.Fragment>
            <i className="icon-exclamation" id={'help_' + mensagemAlert.id} />
            <ToolTipHelp
              target={'help_' + mensagemAlert.id}
              message={mensagemAlert.mensagemAlerta}
            />
          </React.Fragment>
        )}
        <InputMask
          mask="999.999.999-99"
          maskChar=""
          id={this.props.id}
          name={this.props.name}
          value={value}
          onChange={this.handleChange}
          required={this.props.required}
          placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
          onBlur={this.props.onBlur}
          disabled={this.props.disabled ? this.props.disabled : false}
          className={`form-control ${messageValidate ? 'is-invalid' : ''}`}
        >
          {this.props.children}
        </InputMask>
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(CpfInput);
