//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import BeneficiarioEdit from './BeneficiarioEdit';
import BeneficiarioList from './BeneficiarioList';
import BeneficiarioView from './BeneficiarioView';
export default class Beneficiario extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/beneficiarios" component={BeneficiarioList} exact/>
          <Route path="/beneficiarios/view/:id" component={BeneficiarioView}  />
          <Route path="/beneficiarios/edit/:id" component={BeneficiarioEdit}  />
        </Switch>
      </React.Fragment>
    );
  }
}
