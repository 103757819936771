import React, { PureComponent } from "react";
import iconMenu from "../../img/icon_topo_menu.svg";
import iconNotificacao from "../../img/icon_topo_notificacao.svg";
import iconIntegracao from "../../img/convert-arrow.png";
import btnVoltar from "../../img/btn_voltar.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { notificacaoService } from "../../services/notificacaoService";
import { ErrorImportacaoNotificationService } from "../../services/errorImportacaoNotificationService";
import { userService } from "../../services/user.service";
import { cooperativaService } from "../../services/cooperativa.service";
import { avisosService } from "../../services/avisos.service";

class Topo extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    
    notificacaoService.findByFilter({}).then(response => {
      this.handleTasks(response.data.data);
    });

     avisosService.findAll().then(response => {
          const messages = response.data.map(msg => ({
              message: `${msg.titulo}\n${msg.descricao}`,
              html: true,
          }));
          this.props.warning(messages);
      });
    
    ErrorImportacaoNotificationService.findByFilter({}).then(response =>{
      this.handleErrors(response.data);
    });
    this.state = {
      tasks: [],
      numberTasks: 0,
      errors: [],
      errorsNumber: 0,
    };
  }
  handleBack = () => {
    const { backUrl } = this.props;
    if (backUrl) {
      this.props.history.push(backUrl);
    } else {
      this.props.history.goBack();
    }
    localStorage.removeItem("rascunho");
  };

  handleTasks(data) {
    this.props.addAllNotificacoes({
      notificacoes: data.notificacoes,
      notificacoesSize: data.size,
    });
    this.setState({ tasks: data.notificacoes, numberTasks: data.size });
  }
  handleErrors(response) {
    this.props.addAllErrors({ errorsImportacaoNotifications: response });
    this.setState({ errors: response, errorsNumber: response.length });
  }

  isExibirIconeIntegracao() {
    if (
      this.props.user &&
      cooperativaService.isExibirIconeIntegracao(this.props.user.cooperativa) &&
      userService.isPerfilCooperativa()
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { mainTitle, showMenu, canBack, backConfirmObject } =
      this.props;
    const { numberTasks, errorsNumber } = this.state;
    return (
      <React.Fragment>
        <div className={`container-fluid topo ${showMenu ? "invisible" : ""}`}>
          <div className="d-flex justify-content-between align-items-center px-2 px-sm-4 pt-4">
            {!canBack && (
              <img
                src={iconMenu}
                alt="Ícone Menu"
                onClick={this.props.toogleMenu}
                className="iconMenu"
              />
            )}
            {canBack && (
              <div
                onClick={() => {
                  if (backConfirmObject) {
                    ConfirmAcationService.confirmMsg(
                      this.handleBack,
                      backConfirmObject.title,
                      backConfirmObject.message,
                      backConfirmObject.subMessage,
                      "Não",
                      "Sim",
                      backConfirmObject.type
                    );
                  } else {
                    this.handleBack();
                  }
                }}
                className="action actionTopo"
              >
                {" "}
                <img src={btnVoltar} alt="voltar" className="backMenu" />
                <span className="d-none d-sm-inline"> Voltar</span>
              </div>
            )}
            <h1 className="font-weight-bold text-topo">
              {mainTitle ? mainTitle : this.props.user.cooperativa.sigla}
            </h1>

            {this.isExibirIconeIntegracao() && (
              <div
                className="action actionTopo"
                onClick={this.props.toogleErrorImportacaoNotification}
              >
                <img
                  src={iconIntegracao}
                  alt="Ícone Error de Importação"
                  className="iconError"
                />

                {errorsNumber > 0 && (
                  <span className="badge-error badge-danger">
                    {errorsNumber}
                  </span>
                )}
              </div>
            )}

            <div
              className="action actionTopo"
              onClick={this.props.toogleNotification}
            >
              <img
                src={iconNotificacao}
                alt="Ícone Notificação"
                className="iconNotification float-right"
              />
              {numberTasks > 0 && (
                <span className="badge badge-danger">{numberTasks}</span>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  menu: { toogleMenu, hideMenu },
  notification: { toogleNotification, addAllNotificacoes },
  errorImportacaoNotifications: {toogleErrorImportacaoNotification, addAllErrors},
  alert: { error, clear, success, warning }
}) => ({
  hideMenu: () => hideMenu(),
  toogleMenu: () => toogleMenu(),
  toogleNotification: () => toogleNotification(),
  addAllNotificacoes: (notifications) => addAllNotificacoes(notifications),
  toogleErrorImportacaoNotification: () => toogleErrorImportacaoNotification(),
  addAllErrors: (errorsImportacaoNotifications) =>
    addAllErrors(errorsImportacaoNotifications),
  warning: messages => {    
    messages.forEach(msg => {
            warning(msg)
        });
    }
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;
  const { showMenu } = state.menu;
  const { mainTitle, subTitle, canBack, backUrl, backConfirmObject } =
    state.pageTitle;

  return {
    loggedIn,
    alert,
    user,
    permissions,
    mainTitle,
    subTitle,
    canBack,
    backUrl,
    showMenu,
    backConfirmObject,
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(Topo));
