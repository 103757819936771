import React, { PureComponent } from "react";
import { objectsConstants } from "../../constants/objects.constants";
import sombra from "../statusAtendimentosCustom/img/sombra.svg";

const defaultState = {
  active: null,
  colActive: "col-active",
  colInitial: "",
  lineActive: "line"
};
export default class StatusAtendimentosCustom_desktop extends PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);
    if (props.activeIndex >= 0 && props.activeIndex != null) {
      this.state = {
        active: props.activeIndex,
        colActive: "col-active",
        colInitial: "col-1",
        lineActive: this.getClassActive(props.activeIndex)
      };
    } else {
      this.state = defaultState;
    }
  }

  componentWillReceiveProps(nexProps) {
    if (nexProps.activeIndex === -1) {
      this.setState(defaultState);
    }
  }

  toggleClass = (index, quantidadeMaiorZero) => {
    if (quantidadeMaiorZero) {
      const currentState = this.state.active;
      let colInitial = "";
      let active = null;
      let lineActive = "line";
      if (currentState !== index) {
        active = index;
        colInitial = "col-1";
        lineActive = this.getClassActive(index);
      }
      this.setState({ colInitial, active, lineActive }, () => {
        if (this.props.onChangeStatus) {
          this.props.onChangeStatus(index);
        }
      });
    }
  };

  getClassActive(index) {
    let lineActive = "line";
    switch (index) {
      case 0:
        lineActive = "line-custom-1";
        break;
      case 1:
        lineActive = "line-custom-2";
        break;
      case 2:
        lineActive = "line-custom-3";
        break;
      case 3:
        lineActive = "line-custom-4";
        break;
      case 4:
        lineActive = "line-custom-5";
        break;
      case 5:
        lineActive = "line-custom-6";
        break;
      case 6:
        lineActive = "line-custom-7";
        break;
      case 7:
        lineActive = "line-custom-8";
        break;
      case 8:
        lineActive = "line-custom-9";
        break;
      case 9:
        lineActive = "line-custom-10";
        break;
      default:
        lineActive = "line";
    }
    return lineActive;
  }

  getIconeStatus(subStatus, off) {
    let icone = "iconeAuditoria";
    switch (subStatus.situacao) {
      case objectsConstants.SUB_STATUS.PENDENCIA_RESOLVIDA:
        icone = off ? "iconeAuditoriaOFF" : "iconeAuditoria";//Pendência resolvida
        break;
      case objectsConstants.SUB_STATUS.RECEBIDA:
        icone = off ? "iconeAuditoriaOFF" : "iconeAuditoria";//Recebida
        break;
      case objectsConstants.SUB_STATUS.REMOVIDAS_DO_LOTE:
        icone = off ? "iconeAuditoriaOFF" : "iconeAuditoria";//Removidas do lote
        break;
      case objectsConstants.SUB_STATUS.FATURAMENTO:
        icone = off ? "iconeFaturamentoOFF" : "iconeFaturamento";//Faturamento
        break;
      case objectsConstants.SUB_STATUS.GLOSADO:
        icone = off ? "iconeGlosadoOFF" : "iconeGlosado";//Glosado
        break;
      case objectsConstants.SUB_STATUS.REPASSADO:
        icone = off ? "iconeRepassadoOFF" : "iconeRepassado";//Repassado
        break;
      case objectsConstants.SUB_STATUS.AUDITORIA:
        icone = off ? "iconeAuditoriaOFF" : "iconeAuditoria";//Auditoria
        break;
      case objectsConstants.SUB_STATUS.NAO_FATURADO:
        icone = off ? "iconeAuditoriaOFF" : "iconeAuditoria";//Não Faturado
        break;
      case objectsConstants.SUB_STATUS.PENDENTE:
        icone = off ? "iconePendenteOFF" : "iconePendente";//Pendente
        break;
      default:
        icone = off ? "iconeAuditoriaOFF" : "iconeAuditoria";
    }
    return icone;
  }

  bandeirasUsuario() {
    const user = this.props.user;
    let bandeiraPermitida = false;
    if (user.usuarioSubStatus.length > 0) {
      bandeiraPermitida = true;
    }
    return bandeiraPermitida;
  }

  render() {
    return (
      <React.Fragment>
        {/* DESKTOP */}
        <div className="componenteStatus">

          {/*<div className="row custom-sub-status">*/}
          <div
            id="statusAtendimento"
            className="row horizonScrollDesktop custom-sub-status"
          >

            {this.props.listaSubStatus &&
              this.props.listaSubStatus.map((subStatus, indexSub) => {
                return (
                  <React.Fragment key={indexSub}>
                    {(this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.ADMINISTRADOR
                    || this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.PROTOCOLO
                    || this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.SUDO) 
                    && (
                    <div
                      className={`col ${this.state.active === indexSub
                        ? this.state.colActive
                        : this.state.colInitial
                        }`}
                      onClick={() => {
                        this.toggleClass(indexSub, subStatus.count > 0);
                      }} >
                      <div
                        className={`row ${subStatus.count === 0 ? "colorOFF" : ""} conteudoComponente`}>
                      <div
                        className={`${this.state.active === indexSub
                          ? "col-2 mt-0 fade-in"
                          : "col-12 mt-4"
                          }`}>
                        <div
                          className={`${subStatus.count === 0
                            ? this.getIconeStatus(subStatus, true)
                            : this.getIconeStatus(subStatus, false)
                            }`}
                        ></div>
                      </div>
                      <div
                        className={`${this.state.active === indexSub
                          ? "col-9 mb-0 fade-in"
                          : "col-12 mb-4"
                          }`}
                      >
                        <h3 style={{color: subStatus.nome === "Pendente" ? "yellow" : ''}}>
                          {subStatus.count}{" "}
                          {/*<p className="textoAtendimento">
                            {this.state.active === indexSub ? "ATENDIMENTO" : ""}
                        </p>*/}
                        </h3>
                        <p style={{color: subStatus.nome === "Pendente" ? "yellow" : ''}}>{subStatus.nome}</p>                       
                      </div>
                    </div>
                    <div className="seta-sub-menu">
                      <img className="sombra" src={sombra} alt="sombra" />
                    </div>
                  </div>)}
                  {((this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.AUDITOR_SEM_SETOR
                     || this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.COOPERADO) && !this.bandeirasUsuario()
                  && (subStatus.situacao !== objectsConstants.SUB_STATUS.REMOVIDAS_DO_LOTE &&
                    (this.props.cooperativa.sigla.toUpperCase() == objectsConstants.SANTACOOP.name? true : 
                      subStatus.situacao !== objectsConstants.SUB_STATUS.PENDENCIA_RESOLVIDA
                      ))) 
                    && (
                    <div
                      className={`col ${this.state.active === indexSub
                        ? this.state.colActive
                        : this.state.colInitial
                        }`}
                      onClick={() => {
                        this.toggleClass(indexSub, subStatus.count > 0);
                      }} >
                      <div
                        className={`row ${subStatus.count === 0 ? "colorOFF" : ""} conteudoComponente`}>
                      <div
                        className={`${this.state.active === indexSub
                          ? "col-2 mt-0 fade-in"
                          : "col-12 mt-4"
                          }`}>
                        <div
                          className={`${subStatus.count === 0
                            ? this.getIconeStatus(subStatus, true)
                            : this.getIconeStatus(subStatus, false)
                            }`}
                        ></div>
                      </div>
                      <div
                        className={`${this.state.active === indexSub
                          ? "col-9 mb-0 fade-in"
                          : "col-12 mb-4"
                          }`}
                      >
                        <h2>
                          {subStatus.count}{" "}
                          {this.state.active === indexSub ? "Atendimentos" : ""}
                        </h2>
                        <p>{subStatus.nome}</p>
                      </div>
                    </div>
                    <div className="seta-sub-menu">
                      <img className="sombra" src={sombra} alt="sombra" />
                    </div>
                  </div>)}
                  {((this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.AUDITOR_SEM_SETOR
                     || this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.COOPERADO)
                  && this.bandeirasUsuario()) 
                    && (
                    <div
                      className={`col ${this.state.active === indexSub
                        ? this.state.colActive
                        : this.state.colInitial
                        }`}
                      onClick={() => {
                        this.toggleClass(indexSub, subStatus.count > 0);
                      }} >
                      <div
                        className={`row ${subStatus.count === 0 ? "colorOFF" : ""} conteudoComponente`}>
                      <div
                        className={`${this.state.active === indexSub
                          ? "col-2 mt-0 fade-in"
                          : "col-12 mt-4"
                          }`}>
                        <div
                          className={`${subStatus.count === 0
                            ? this.getIconeStatus(subStatus, true)
                            : this.getIconeStatus(subStatus, false)
                            }`}
                        ></div>
                      </div>
                      <div
                        className={`${this.state.active === indexSub
                          ? "col-9 mb-0 fade-in"
                          : "col-12 mb-4"
                          }`}
                      >
                        <h2>
                          {subStatus.count}{" "}
                          {this.state.active === indexSub ? "Atendimentos" : ""}
                        </h2>
                        <p>{subStatus.nome}</p>
                      </div>
                    </div>
                    <div className="seta-sub-menu">
                      <img className="sombra" src={sombra} alt="sombra" />
                    </div>
                  </div>)}
                  {(this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.CONSULTORIO && !this.bandeirasUsuario()
                  && (subStatus.situacao !== objectsConstants.SUB_STATUS.PENDENCIA_RESOLVIDA
                  && subStatus.situacao !== objectsConstants.SUB_STATUS.REMOVIDAS_DO_LOTE
                  && ((this.props.user.cooperativa.id == objectsConstants.COOPANESTTM.id 
                    || (subStatus.situacao !== objectsConstants.SUB_STATUS.GLOSADO
                    && subStatus.situacao !== objectsConstants.SUB_STATUS.REPASSADO))
                    || this.props.user.cooperativa.id == objectsConstants.COOPANESTAL.id))) 
                    && (
                    <div
                      className={`col ${this.state.active === indexSub
                        ? this.state.colActive
                        : this.state.colInitial
                        }`}
                      onClick={() => {
                        this.toggleClass(indexSub, subStatus.count > 0);
                      }} >
                      <div
                        className={`row ${subStatus.count === 0 ? "colorOFF" : ""} conteudoComponente`}>
                      <div
                        className={`${this.state.active === indexSub
                          ? "col-2 mt-0 fade-in"
                          : "col-12 mt-4"
                          }`}>
                        <div
                          className={`${subStatus.count === 0
                            ? this.getIconeStatus(subStatus, true)
                            : this.getIconeStatus(subStatus, false)
                            }`}
                        ></div>
                      </div>
                      <div
                        className={`${this.state.active === indexSub
                          ? "col-9 mb-0 fade-in"
                          : "col-12 mb-4"
                          }`}
                      >
                        <h2>
                          {subStatus.count}{" "}
                          {this.state.active === indexSub ? "Atendimentos" : ""}
                        </h2>
                        <p>{subStatus.nome}</p>
                      </div>
                    </div>
                    <div className="seta-sub-menu">
                      <img className="sombra" src={sombra} alt="sombra" />
                    </div>
                  </div>)}
                  {(this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.CONSULTORIO
                  && this.bandeirasUsuario()) 
                    && (
                    <div
                      className={`col ${this.state.active === indexSub
                        ? this.state.colActive
                        : this.state.colInitial
                        }`}
                      onClick={() => {
                        this.toggleClass(indexSub, subStatus.count > 0);
                      }} >
                      <div
                        className={`row ${subStatus.count === 0 ? "colorOFF" : ""} conteudoComponente`}>
                      <div
                        className={`${this.state.active === indexSub
                          ? "col-2 mt-0 fade-in"
                          : "col-12 mt-4"
                          }`}>
                        <div
                          className={`${subStatus.count === 0
                            ? this.getIconeStatus(subStatus, true)
                            : this.getIconeStatus(subStatus, false)
                            }`}
                        ></div>
                      </div>
                      <div
                        className={`${this.state.active === indexSub
                          ? "col-9 mb-0 fade-in"
                          : "col-12 mb-4"
                          }`}
                      >
                        <h2>
                          {subStatus.count}{" "}
                          {this.state.active === indexSub ? "Atendimentos" : ""}
                        </h2>
                        <p>{subStatus.nome}</p>
                      </div>
                    </div>
                    <div className="seta-sub-menu">
                      <img className="sombra" src={sombra} alt="sombra" />
                    </div>
                  </div>)}
                  </React.Fragment>
          )
              })}
        </div>
          {/* Início componente de sumario */}
        {this.props.children}
        {/* Fim sumario */}

        <div className={`${this.state.lineActive}`}></div>
      </div >
      </React.Fragment >
    );
  }
}
