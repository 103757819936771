import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Avatar from "../../components/Avatar/Avatar";
import { userService } from "../../services/user.service";


class UsuarioCardTopo extends PureComponent<> {
    render() {
        return (
            <div className="bloco-dados-user mt-4 mt-sm-0 mr-sm-5 d-flex flex-column flex-sm-row align-items-center pointer" >
                <div className="bloco-image-user m-sm-2 mr-sm-4">
                    <Avatar user={this.props.user}></Avatar>
                </div>
                <div className="info-user">
                    <p className="txtCooperativa verde-destaque">
                        Visualizando perfil
                        <span className="pl-1">
                            <button
                                className="btn btn-icon-only"
                                onClick={() => { this.props.removeFilter('cooperado'); this.props.changeFilterProps('cooperado'); }}
                            >
                                <span className="icon-btn-fechar-filtro-red"></span>
                            </button>
                        </span>
                    </p>
                    <h2 className="font-weight-bold">{this.props.user.nome}</h2>

                    {!this.props.user.setorAtual && (
                        <p className="font-italic txtPerfil">
                             {userService.getPerfilAtual(this.props.user)}
                        </p>
                    )}
                    {this.props.user.setorAtual && (
                        <p className="font-italic txtPerfil ">
                            {this.props.user.setorAtual.nome}
                        </p>
                    )}
                </div>
            </div>
        );
    }
}


const mapDispatch = ({ filtro: { toogleFiltro, removeFilter } }) => ({
    toogleFiltro: () => toogleFiltro(),
    removeFilter: (filterRemove) => removeFilter({ filterRemove })
});
function mapStateToProps(state) {
    const { permissions } = state.authentication;

    return {

        permissions
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(UsuarioCardTopo));
