//@flow
type State = {
  load: boolean
};

export default {
  state: { showMenu: false },
  reducers: {
    toogleMenu: (state: State) => {
      let menu = !state.showMenu;
      if (menu) {
        document.body.classList.add('bodyNoScroll', 'menuOpened');
        document.getElementById("bodyMenu").scrollIntoView();
      } else {
        document.body.classList.remove("bodyNoScroll");
      }
      return Object.assign({}, state, {
        showMenu: menu
      });
    },
    hideMenu: (state: State) => {
      document.body.classList.remove('bodyNoScroll', 'menuOpened');
      return Object.assign({}, state, {
        showMenu: false
      });
    },
    showMenu: (state: State) => {
      document.body.classList.add('bodyNoScroll', 'menuOpened');
      document.getElementById("bodyMenu").scrollIntoView();
      return Object.assign({}, state, {
        showMenu: true
      });
    }
  }
};
