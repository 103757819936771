import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Avatar from "../../components/Avatar/Avatar";
import { formatterHelper } from "../../helpers/formatter.helper";
import { objectsConstants } from "../../constants/objects.constants";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";

class BeneficiarioTopoCrud extends PureComponent<Props, State> {
  render() {
    const {
      values,
      handleSubmit,
      handleCancel,
      onChange
    } = this.props;
    return (
      //add classe "bg-branco" abaixo quando esição/criação
      <React.Fragment>
        <div className="px-sm-4 py-sm-2 my-sm-3 bg-branco rounded d-flex flex-column flex-sm-row justify-content-between align-items-center">
          <div className="bloco-dados-user mr-sm-auto d-flex flex-column flex-sm-row align-items-center align-self-stretch align-self-sm-center">
            <div className="bloco-image-user m-sm-2 mr-sm-4">
              <Avatar
                user={values}
                onChange={(name, value) => onChange(name, value)}
              >
              </Avatar>
            </div>
            <div className="info-user col-12">
              <React.Fragment>
                {" "}
                <h2 className="font-weight-bold">
                  {formatterHelper.getNameOrHifen(values.nomeBeneficiario)}
                </h2>
                <p className="font-italic">Beneficiário</p>
              </React.Fragment>
            </div>
          </div>
          {!values.id && (
            <div className="info-texto px-sm-3 mt-2 mt-sm-0">
              <span className="font-weight-bold font-italic helpText">
                Quer ajuda?
              </span>
              <p className="font-italic">01- Prencha o formulário</p>
            </div>
          )}
          <div className="px-sm-3 mb-3 border-left justify-content-around">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={
                () => ConfirmAcationService.confirmMsg(
                  handleCancel,
                  "Cancelar edição do beneficiário",
                  "Todos os dados preenchidos serão perdidos "+
                  "ao cancelar a edição do beneficiário ",                  
                  null,
                  "Cancelar",
                  "Confirmar",
                  objectsConstants.TYPE_WARNING
                )
              }
            >
              <span className={`icon-btn-fechar`}></span>
              {"Cancelar"}
            </button>
            <button
              type="submit"
              className="btn btn-primary "
              onClick={handleSubmit}
            >
              <span className={`icon-btn-check`}></span>
              {"Salvar"}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(mapStateToProps, null)(withRouter(BeneficiarioTopoCrud));
