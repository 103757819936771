//@flow
import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";

export const especialidadesService = {
  findAll
};

function findAll() {
  return defaultService.doGet(`${urlsConstants.ESPECIALIDADES}findAll`);
}
