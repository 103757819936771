//@flow
import * as React from "react";
import { Route, Switch } from "react-router-dom";
import GuiasList from "./GuiasList";
import NovoAtendimento from "./NovoAtendimento";
export default class Guias extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          {/* <Route path="/guias" component={GuiaSADT} /> */}
          <Route path="/guias" component={GuiasList} exact />
          <Route path="/guias/criar" component={NovoAtendimento} />
        </Switch>
      </React.Fragment>
    );
  }
}
