import { PureComponent } from "react";

export default class ContentFixedOnTop extends PureComponent<> {
    static defaultProps={
        baseConententTopSize:150,

    }
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    var d = document.getElementById(this.props.idFloatContent);
    var t = document.getElementById(this.props.idBaseComponente);
    let activeClass = "normal";      
    
    

    if (d.offsetTop < document.documentElement.scrollTop + this.props.floatContentTopSize) {
        activeClass = "flutuante";
    }

    if (t.offsetTop >= document.documentElement.scrollTop + this.props.baseConententTopSize) {
        activeClass = "normal";
        }
    

    this.props.onHandleScroll(activeClass);
  }

  render() {
    return null;
  }
}

