import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Avatar from "../../components/Avatar/Avatar";
import { formatterHelper } from "../../helpers/formatter.helper";

class BeneficiarioTopoView extends PureComponent<Props, State> {

  render() {
    const { values } = this.props;
    return (
      <React.Fragment>
        <div className="px-sm-5 py-sm-2 my-sm-3 bg-branco rounded d-flex flex-column flex-sm-row justify-content-between align-items-center pb-2">
          <div className="bloco-dados-user mr-sm-auto d-flex flex-column flex-sm-row align-items-center align-self-stretch align-self-sm-center">
            <div className="bloco-image-user m-sm-2 mr-sm-4">
              {values && <Avatar user={values}></Avatar>}
            </div>
            <div className="info-user col-12">
              <React.Fragment>
                {" "}
                <h2 className="font-weight-bold">
                  {formatterHelper.getNameOrHifen(values.nomeBeneficiario)}
                </h2>
                <p className="font-italic">
                  {" "}
                  Beneficiário
                </p>
              </React.Fragment>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(BeneficiarioTopoView));
