import { Formik } from "formik";
import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { FormGroup } from "../../components/FormGroup";
import CpfInput from "../../components/Inputs/CpfInput";
import FormInput from "../../components/Inputs/FormInput";
import InputViewEdit from "../../components/Inputs/InputViewEdit";
import { userService } from "../../services/user.service";

const NewUsuarioCooperativa = Yup.object().shape({
  nome: Yup.string().required("Obrigatório"),
  cpf: Yup.string().required("Obrigatório"),
  password: Yup.string().when("id", (id, schema) => {
    if (id === null || id === undefined) {
      return schema.required("Obrigatório");
    }
    return schema;
  })
});
class CooperativaUsuarioForm extends PureComponent<Props, State> {
  goToViewUser = values => {
    this.props.history.push({
      pathname: "/cooperativa/view/" + values.id,
      state: { entity: values }
    });
  };

  handleSubmit = e => {
    this.formRef.validateForm(this.formRef.values).then(erros => {
      console.error(erros);
      if (_.isEmpty(erros)) {
        this.formRef.handleSubmit();
      }
    });
  };

  render() {
    let viewMode = false;
    return (
      <Formik
        validationSchema={NewUsuarioCooperativa}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values, actions) => {
          this.props.loading(true);
          this.props.loading(false);
          actions.setSubmitting(false);
        }}
        ref={form => {
          this.formRef = form;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          validationSchema,
          validateForm,
          setValues
        }) => (
            <form onSubmit={handleSubmit}>
              <div className="row section-form">
                <FormGroup className="fixo">
                  <InputViewEdit
                    component={CpfInput}
                    label="CPF"
                    id="secretariaCpf"
                    name="cpf"
                    value={values.cpf}
                    placeholder="Digite o CPF"
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    required={true}
                    erroMensagem={_.get(errors, `cpf`)}
                    onBlur={() => {

                      if (values.cpf.length) {
                        this.props.loading(true)

                        userService.existByCpf(values.cpf).then(response => {
                          if (response.data) {
                            setValues(response.data)
                          }
                          this.props.loading(false)

                        }, () => {
                          this.props.loading(false)
                        });
                      }

                    }}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Nome"}
                    type="text"
                    id="secretariaNome"
                    name={`nome`}
                    value={values.nome}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    placeholder="Digite o nome"
                    viewMode={viewMode}
                    required={true}
                    erroMensagem={_.get(errors, `nome`)}
                    disabled={userService.isCooperado(values)}
                  />
                </FormGroup>
                <FormGroup className="fixo">
                  <InputViewEdit
                    component={FormInput}
                    label="Senha"
                    type="password"
                    placeholder={"Digite a Senha"}
                    id="secretariaPassword"
                    name={`password`}
                    value={values.password}
                    required={values.id ? false : true}
                    onChange={(name, value) => setFieldValue(name, value)}
                    viewMode={viewMode}
                    defaultValue={"**********"}
                    erroMensagem={_.get(errors, `password`)}
                    disabled={userService.isCooperado(values)}
                  />
                </FormGroup>
              </div>
            </form>
          )}
      </Formik>
    );
  }
}
const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(CooperativaUsuarioForm));
