//@flow
import * as React from "react";
import { connect } from "react-redux";
import makeAnimated from "react-select/animated";
import { convenioService } from "../../services/convenio.service";
import MultiSelect from "../../components/Inputs/MultiSelect";
import { components } from "react-select";
import SelectInput from "../../components/Inputs/SelectInput";

type Props = {
  label: string,
  name: string,
  value: any,
  onChange: any,
  returnFullObject: boolean,
  messageValidate?: any,
  disabled?: boolean,
  size?: number,
  required: boolean,
  service: any
};

type State = {
  options: Array<any>
};

const Option = props => {
  return (
    <div className="checkbox control control--checkbox">
      <components.Option {...props} className="react-select__option">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <span className="pl-4">{props.label}</span>
        <div className="control__indicator"></div>
      </components.Option>
    </div>
  );
};
const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.nome}</span>
  </components.MultiValue>
);
const animatedComponents = makeAnimated();

class ConvenioSelectInput extends React.PureComponent<Props, State> {
  _isMounted = false;

  constructor(props: Props) {
    super(props);

    this.state = {

      options: []
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.parent != prevProps.parent) {
      this.fetch();
    }
  }
    fetch = () =>{
    if(!this.props.bloquearConsultorio) {
      convenioService.findAll(this.props.parent).then(response => {
        let options = response.data;
        if (this._isMounted) {
          this.setState({options});
        }
      });
    }else{
      convenioService.findAllBloquearConsultorio(this.props.parent).then(response => {
        let options = response.data;
        if (this._isMounted) {
          this.setState({options});
        }
      });
    }
    }
  componentDidMount() {
    this._isMounted = true;
    this.fetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <SelectInput
        label={this.props.label}
        options={this.state.options}
        isMulti={this.props.isMulti}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option, MultiValue, animatedComponents }}
        onChange={this.props.onChange}
        allowSelectAll={true}
        name={this.props.name}
        id={this.props.id}
        value={this.props.value}
        valueKey={"id"}
        labelKey={"nome"}
        returnFullObject={this.props.returnFullObject}
        required={this.props.required}
        clearable={true}
        className={this.props.className}
        placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
        erroMensagem={this.props.erroMensagem}
        disabled={this.props.disabled}
      />
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(ConvenioSelectInput);
