//@flow
type State = {
  load: boolean
};

function setFilter(name,filtro) {
  localStorage.setItem(name, JSON.stringify(filtro));
}
function getFilter(name) {
  if (process.env.NODE_ENV === "development") {
    if (localStorage.getItem(name) !== null) {
      return JSON.parse(localStorage.getItem(name));
    }
    return {};
  } 
  return {};
}
export default {
  state: {
    showMenu: false,
    filtroTable: getFilter("filtroTable"),
    filtroAtendimento: getFilter("filtro"),
    filterRemove: null
  },
  reducers: {
    toogleFiltro: (state: State) => {
      let menu = !state.showMenu;
      if (menu) {
        document.body.classList.add("bodyNoScroll");
        document.getElementById("bodyMenu").scrollIntoView();
      } else {
        document.body.classList.remove("bodyNoScroll");
      }
      return Object.assign({}, state, {
        showMenu: menu
      });
    },
    hideFiltro: (state: State) => {
      document.body.classList.remove("bodyNoScroll");
      return Object.assign({}, state, {
        showMenu: false
      });
    },
    showFiltro: (state: State) => {
      document.body.classList.add("bodyNoScroll");
      return Object.assign({}, state, {
        showMenu: true
      });
    },
    changeFilter: (state: State, payload: any) => {
      setFilter("filtro",payload.filtroAtendimento);
      return Object.assign({}, state, {
        filtroAtendimento: payload.filtroAtendimento
      });
     
    },
    changeFilterTable: (state: State, payload: any) => {
      setFilter("filtroTable",payload.filtroTable);
      return Object.assign({}, state, {
        filtroTable: payload.filtroTable
      });
    },
    removeFilter: (state: State, payload: any) => {
      return Object.assign({}, state, {
        filterRemove: payload.filterRemove
      });
    },
    removeAllFilters: (state: State) => {
      return Object.assign({}, state, {
        filtroTable: {},
        filtroAtendimento: {},
      })
    }
  }
};
