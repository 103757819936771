import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { objectsConstants } from "../../constants/objects.constants";
import Datatable from "../../sascComponents/datagrid/Datatable";
import TopoListagem from "../../sascComponents/topo/TopoListagem";
import { estabelecimentoService } from "../../services/estabelecimento.service";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import LinhaAcaoAtivarInativarConsultorio from "./LinhaAcaoAtivarInativarConsultorio";


class ConsultorioList extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    const extension = userService.getExtensionUser(this.props.user);
    const isAuditor = userService.isAuditor(this.props.user);
    let initFilter = null
    if (extension && !isAuditor) {
      initFilter = [{
        path: "Estabelecimento",
        fieldName: "id",
        fieldDescription: "Id",
        columnOrder: 1,
        searchableField: true,
        value: extension,
        type: "NUMBER",
        condition: { description: "=" },

      }];
    }

    this.state = {
      initFilter
    };
  }
  closeMenu = () => {
    this.props.showMenu();
  };
  handleRowClick = (fieldName, rowData) => {
    this.props.history.push({
      pathname: "/consultorios/view/" + (rowData.id),
      state: { entity: rowData }
    });
  };



  componentDidUpdate = () => {}
  render() {
    return (
      <div className="container-fluid container-com-topo">
        <TopoTitleComponente
          mainTitle="Consultórios"
          subTitle=" "
          canBack={false}
        />
        <TopoListagem
          securityActionDo={objectsConstants.ALTERAR}
          securityActionOn={objectsConstants.CRUDCONSULTORIOS_CADASTRAR}
          buttonActionClassName={"btn-light"}
          buttonAction={() => {
            this.props.history.push("/consultorios/novo");
          }}
          securityActionIcon={"icon-btn-add"}
          securityActionName={"Novo Consultório"}
          showfilter={false}
        />
        <hr className="text-center w-75"></hr>

        <div className="background-lines">
          <div className="bg-riscos"></div>
        </div>

        <Datatable
          showCheckboxColumn={true}
          onRowClick={this.handleRowClick}
          idRow={"id"}
          keyTagItem={"nome"}
          classHeader={[
            "headerCenter",
            "headerLeft",
            "headerCenter",
            "headerCenter",
            "headerCenter"

          ]}
          classColumn={[
            "colunaFoto",
            "colunaGra",
            "colunaMed",
            "colunaMed",
            "colunaPeq"
          ]}
          service={estabelecimentoService}
          name={"Consultório(s)"}
          showAllColumnsFilter={false}
          ActionLine={LinhaAcaoAtivarInativarConsultorio}
          initFilter={this.state.initFilter}

        />
      </div>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(ConsultorioList));
