import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { enumTipoErrors } from "../../constants/objects.constants";
import { Formik } from "formik";
import { ErrorImportacaoNotificationService } from "../../services/errorImportacaoNotificationService";
import FormSelectInput from "../../components/Inputs/FormSelectInputErroIntegracao";
import { convenioService } from "../../services/convenio.service";
import { cooperadoService } from "../../services/cooperado.service";
import { estabelecimentoService } from "../../services/estabelecimento.service";

type Props = {
  errorImportacaoNotifications: ?any,
};

class CardErrorImportacaoNotification extends PureComponent<Props> {
  constructor(props) {
    super(props);
    this.state = {
      showUpdate: false,
    };
  }

  render() {
    const { showUpdate } = this.state;
    const { errorImportacaoNotifications } = this.props;
    const tipoErro = errorImportacaoNotifications ? errorImportacaoNotifications.tipoErro : null;
    const descricaoTipoErro = tipoErro ? tipoErro.description : "Erro desconhecido";

    return (
      <React.Fragment key={errorImportacaoNotifications ? errorImportacaoNotifications.id : undefined}>
        <div className="row align-items-center notification">
          <div className="col-2 col-md-1 py-2 pl-3 pr-1"></div>
          <div className="col-10 col-md-10 pr-0">
            <h2 id="tituloNotif" className="m-0 font-weight-bold">
              {descricaoTipoErro}
            </h2>
            {/* Exibindo mensagens baseadas no tipo de erro */}
            {descricaoTipoErro === enumTipoErrors.ATENDIMENTO_CONVENIO_NAO_ENCONTRADO && (
              <>
                <p id="descricaoNotif" className="m-2 descricao color-red">
                  CNPJ do Convênio não encontrado: {errorImportacaoNotifications ? errorImportacaoNotifications.causaErro : "Detalhes não disponíveis"}
                </p>
                {errorImportacaoNotifications && errorImportacaoNotifications.numeroAtendimento && (
                  <p id="descricaoNotif" className="m-2 descricao color-green">
                    Atendimento: {errorImportacaoNotifications.numeroAtendimento}
                  </p>
                )}
              </>
            )}
            {descricaoTipoErro === enumTipoErrors.ATENDIMENTO_ESTABELECIMENTO_NAO_ENCONTRADO && (
              <>
                <p id="descricaoNotif" className="m-2 descricao color-green">
                  CNPJ do Estabelecimento não encontrado: {errorImportacaoNotifications ? errorImportacaoNotifications.causaErro : "Detalhes não disponíveis"}
                </p>
                {errorImportacaoNotifications && errorImportacaoNotifications.numeroAtendimento && (
                  <p id="descricaoNotif" className="m-2 descricao color-green">
                    Atendimento: {errorImportacaoNotifications.numeroAtendimento}
                  </p>
                )}
              </>
            )}
            {descricaoTipoErro === enumTipoErrors.ATENDIMENTO_COOPERADO_NAO_ENCONTRADO && (
              <>
                <p id="descricaoNotif" className="m-2 descricao color-green">
                  CPF do Cooperado não encontrado: {errorImportacaoNotifications ? errorImportacaoNotifications.causaErro : "Detalhes não disponíveis"}
                </p>
                {errorImportacaoNotifications && errorImportacaoNotifications.observacaoErro && (
                  <p id="descricaoNotif" className="m-2 descricao color-green">
                    Nome do Cooperado: {errorImportacaoNotifications.observacaoErro}
                  </p>
                )}
                {errorImportacaoNotifications && errorImportacaoNotifications.numeroAtendimento && (
                  <p id="descricaoNotif" className="m-2 descricao color-green">
                    Atendimento: {errorImportacaoNotifications.numeroAtendimento}
                  </p>
                )}
              </>
            )}
          </div>
          {showUpdate === false && (
            <div className="col-12 col-md-12 mt-4 mt-md-0 px-1 text-center text-md-center">
              <button
                onClick={() => this.setState({ showUpdate: true })}
                className="btn btn-primary white mt-3"
              >
                Alterar
              </button>
            </div>
          )}

          {showUpdate && (
            <React.Fragment>
              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={errorImportacaoNotifications || {}}
                enableReinitialize={true}
                ref={(form) => { this.formRef = form; }}
                onSubmit={(values, actions) => {
                  {
                    errorImportacaoNotifications.tipoErro.description ===
                      enumTipoErrors.ATENDIMENTO_CONVENIO_NAO_ENCONTRADO &&
                      ErrorImportacaoNotificationService.saveNewCnpjConvenio(
                        values.convenio,
                        errorImportacaoNotifications.id
                      )
                        .then((response) => {
                          actions.setSubmitting(false);
                          ErrorImportacaoNotificationService.findByFilter(
                            {}
                          ).then((response) => {
                            this.props.addAllErrors({
                              errorsImportacaoNotifications: response.data,
                            });
                          });
                        })
                        .catch(() => {
                          actions.setSubmitting(false);
                          this.props.loading(false);
                        });
                  }
                  {
                    errorImportacaoNotifications.tipoErro.description ===
                      enumTipoErrors.ATENDIMENTO_ESTABELECIMENTO_NAO_ENCONTRADO &&
                      ErrorImportacaoNotificationService.saveNewCnpjEstabelecimento(
                        values.estabelecimento,
                        errorImportacaoNotifications.id
                      )
                        .then((response) => {
                          actions.setSubmitting(false);
                          ErrorImportacaoNotificationService.findByFilter(
                            {}
                          ).then((response) => {
                            this.props.addAllErrors({
                              errorsImportacaoNotifications: response.data,
                            });
                          });
                        })
                        .catch(() => {
                          actions.setSubmitting(false);
                          this.props.loading(false);
                        });
                  }
                  {
                    errorImportacaoNotifications.tipoErro.description ===
                      enumTipoErrors.ATENDIMENTO_COOPERADO_NAO_ENCONTRADO &&
                      ErrorImportacaoNotificationService.saveNewCpfCooperado(
                        values.cooperado,
                        errorImportacaoNotifications.id
                      )
                        .then((response) => {
                          actions.setSubmitting(false);
                          ErrorImportacaoNotificationService.findByFilter(
                            {}
                          ).then((response) => {
                            this.props.addAllErrors({
                              errorsImportacaoNotifications: response.data,
                            });
                          });
                        })
                        .catch(() => {
                          actions.setSubmitting(false);
                          this.props.loading(false);
                        });
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="col-12 col-md-12 mt-4 mt-md-4  m-5 text-center text-md-center">
                      <div className="col-12 col-md-12 mt-4 mt-md-4 px-0 ml-4">
                        {descricaoTipoErro === enumTipoErrors.ATENDIMENTO_CONVENIO_NAO_ENCONTRADO && (
                          <React.Fragment>
                            <div className="form-group col-12 col-lg-12">
                              <label>Convênio*:</label>
                              <FormSelectInput
                                id="convenio"
                                onFetchData={(inputValue) => {
                                  if (inputValue && inputValue.length >= 1) {
                                    return convenioService.findByNome(inputValue);
                                  }
                                  return Promise.resolve([]);
                                }}
                                labelKey="nome"
                                name="convenio"
                                placeholder="Digite para buscar um convênio"
                                value={values.convenio}
                                returnFullObject={true}
                                onChange={setFieldValue}
                                clearable={true}
                                errorMensagem={errors.convenio}
                                searchable={true}
                              />
                            </div>
                          </React.Fragment>
                        )}
                        {descricaoTipoErro === enumTipoErrors.ATENDIMENTO_ESTABELECIMENTO_NAO_ENCONTRADO && (
                          <React.Fragment>
                            <div className="form-group col-12 col-lg-12">
                              <label>Estabelecimento*:</label>
                              <FormSelectInput
                                id="estabelecimento"
                                onFetchData={(inputValue) => {
                                  if (inputValue && inputValue.length >= 1) {
                                    return estabelecimentoService.findAll(inputValue);
                                  }
                                  return Promise.resolve([]);
                                }}
                                labelKey="nome"
                                name="estabelecimento"
                                placeholder="Digite para buscar um estabelecimento"
                                value={values.estabelecimento}
                                returnFullObject={true}
                                onChange={setFieldValue}
                                clearable={true}
                                errorMensagem={errors.estabelecimento}
                                searchable={true}
                              />
                            </div>
                          </React.Fragment>
                        )}
                        {descricaoTipoErro === enumTipoErrors.ATENDIMENTO_COOPERADO_NAO_ENCONTRADO && (
                          <div className="form-group col-12 col-lg-12">
                            <label>CPF do Cooperado*:</label>
                            <FormSelectInput
                              id="cooperado"
                              onFetchData={(inputValue) => {
                                if (inputValue && inputValue.length >= 1) {
                                  return cooperadoService.findByNome(inputValue);
                                }
                                return Promise.resolve([]);
                              }}
                              labelKey="nome"
                              name="cooperado"
                              placeholder="Digite para buscar um cooperado"
                              value={values.cooperado}
                              returnFullObject={true}
                              onChange={setFieldValue}
                              clearable={true}
                              errorMensagem={errors.cooperado}
                              searchable={true}
                            />
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-row flex-sm-row ">
                        <div className="col-6 col-md-6 mt-4 mt-md-4 px-0 m-3">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary white"
                          >
                            Salvar
                          </button>
                        </div>
                        <div className="col-6 col-md-6 mt-4 mt-md-4 px-0 ">
                          <button
                            onClick={() => this.setState({ showUpdate: false })}
                            className="btn btn-primary white"
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  load: { loading },
  errorImportacaoNotifications: {
    toogleErrorImportacaoNotification,
    addAllErrors,
  },
}) => ({
  toogleErrorImportacaoNotification: () => toogleErrorImportacaoNotification(),
  loading: (load: boolean) => loading({ load }),
  addAllErrors: (errorsImportacaoNotifications) =>
    addAllErrors(errorsImportacaoNotifications),
});

function mapStateToProps(state) {
  const { showError, errorsImportacaoNotifications } =
    state.errorImportacaoNotifications;
  const { user } = state.authentication;

  return {
    showError,
    user,
    errorsImportacaoNotifications,
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(CardErrorImportacaoNotification));



