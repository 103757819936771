import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {FormGroup} from "../../../components/FormGroup";
import DatePickerInput from "../../../components/Inputs/DatePickerInput";
import * as _ from "lodash";
import {dateHelper} from "../../../helpers/date.helper";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import CascadeSelectInput from "../../../components/Inputs/CascadeSelectInput";
import {statusAtendimentoService} from "../../../services/statusAtendimento.service";
import SelectInput from "../../../components/Inputs/SelectInput";
//import {userService} from "../../../services/user.service";
import {objectsConstants} from "../../../constants/objects.constants";


class FiltroRelatorioEncaminhamentoPorStatus extends PureComponent<Props, State> {
    constructor(props) {
        super(props);

    }

    cacularData = () => {
        let dataAtual = new Date();
        let milissegundos_por_dia = 1000 * 60 * 60 * 24;
        return new Date(dataAtual.getTime() - (90 * milissegundos_por_dia));
    }

    render() {
        const {values,errors,setFieldValue} = this.props;
        return (
            <React.Fragment>
                <div className={'d-flex flex-fill flex-row align-items-center justify-content-around borda-separacao'}>
                    <FormGroup className="col-3">
                        <InputViewEdit
                            label={"Data início"}
                            component={DatePickerInput}
                            onChange={(name, date) => {
                                setFieldValue(name, date);
                            }}
                            erroMensagem={
                                _.get(errors, `dataInicioEncaminhamento`)
                            }
                            defaultValue={
                                values.dataFimEncaminhamento ? 
                                dateHelper.format(values.dataFimEncaminhamento) 
                                : (values.dataInicioEncaminhamento = this.cacularData())

                            }
                            mask={"99/99/9999"}
                            name={"dataInicioEncaminhamento"}
                            id={"dataInicioEncaminhamento"}
                            placeholder=""
                            required={true}
                            type={"text"}
                            viewMode={false}
                            value={values.dataInicioEncaminhamento}
                        />
                    </FormGroup>
                    <FormGroup className="col-3">
                        <InputViewEdit
                            label={"Data fim"}
                            component={DatePickerInput}
                            onChange={(name, date) => {
                                setFieldValue(name, date);
                            }}
                            erroMensagem={
                                _.get(errors, `dataFimEncaminhamento`)
                            }
                            defaultValue={
                                values.dataFimEncaminhamento
                                    ? dateHelper.format(values.dataFimEncaminhamento)
                                    : (values.dataFimEncaminhamento=new Date())
                            }
                            mask={"99/99/9999"}
                            name={"dataFimEncaminhamento"}
                            id={"dataFimEncaminhamento"}
                            placeholder=""
                            required={true}
                            type={"text"}
                            viewMode={false}
                            value={values.dataFimEncaminhamento}
                        />
                    </FormGroup>
                    <FormGroup className="col-3">
                    <SelectInput
                        required={false}
                        label={"Tipo de guia"}
                        className=""
                        name={"tipoGuia"}
                        value={values.tipoGuia}
                        options={
                                 _.orderBy(objectsConstants.TIPOS_GUIA, ['name'],
                                ['asc'])
                        }
                        onChange={(name, value) => {
                            setFieldValue(name, value);
                        }}
                        returnFullObject={false}
                        labelKey={"name"}
                        valueKey={"id"}
                        placeholder="Tipo de guia de atendimento"
                    />
                    </FormGroup>
                    <FormGroup className="col-3">
                        <label>Encaminhar</label>
                        <CascadeSelectInput
                            className="min-width"
                            name={"status"}
                            placeholder={"Selecione o status"}
                            returnFullObject={true}
                            labelKey={"nome"}
                            valueKey={"id"}
                            service={statusAtendimentoService.findAllByAtendimentos}
                            value={values.status}
                            onChange={setFieldValue}
                            erroMensagem={
                                _.get(errors, `status`)
                            }
                            loadingPlaceholder={"Selecione um atendimento"}
                        />
                    </FormGroup>
                </div>

            </React.Fragment>
        );
    }
}

const mapDispatch = ({ filtro: { toogleFiltro, removeFilter }, alert: { success, error }, load: { loading } }) => ({
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    success: msg => success(msg),
    toogleFiltro: () => toogleFiltro(),
    removeFilter: (filterRemove) => removeFilter({ filterRemove })
});

function mapStateToProps(state) {
    const { showMenu } = state.filtro;
    const { permissions } = state.authentication;
    const { filterRemove } = state.filtro;

    return {
        showMenu,
        permissions,
        filterRemove
    };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(FiltroRelatorioEncaminhamentoPorStatus));
