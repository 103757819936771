//@flow
import React, {Component} from "react";
import * as _ from "lodash";
import {Label} from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import Async from "react-select/async";
//import "react-select/dist/react-select.css";
/*import '../../css/selectInput.css';*/
import {ToolTipFeedBack} from "../Utils/ToolTipFeedBack";
import {translate} from "../../helpers/message.helper";
import {connect} from "react-redux";
import {ToolTipHelp} from "../Utils/ToolTipHelp";
import moment from "moment";
import { result } from "lodash";
import { objectsConstants } from "../../constants/objects.constants";

type Props = {
    id?: string,
    label: string,
    name: string,
    value?: any,
    size?: number,
    onChange: any,
    parent?: any,
    onFetchData?: any,
    valueKey: string,
    labelKey: string,
    options?: any,
    multi?: boolean,
    required: boolean,
    messageValidate?: string,
    returnFullObject: boolean,
    clearable: boolean,
    creatable: boolean,
    onNewOptionClick: any,
    newOptionCreator: any,
    cache: boolean,
    placeholder: string,
    style: any,
    searchable?: boolean,
    disabled?: boolean,
    autoClick?: boolean
};

type State = {};
let timoutVar;
const Svg = p => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        focusable="false"
        role="presentation"
        {...p}
    />
);
const ChevronDown = () => (
    <Svg style={{marginRight: -6}}>
        <path
            d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </Svg>
);

//class SelectInput extends React.Component<Props, State> {
    export default class SelectInput extends React.Component<Props, State> {
    _isMounted = false;
    myRef = React.createRef();
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.state = {
            tooltipOpen: false,
            value: props.value ? props.value : null
        };
    }

    static defaultProps = {
        valueKey: "value",
        labelKey: "label",
        async: true,
        multi: false,
        label: "",
        required: false,
        returnFullObject: false,
        clearable: false,
        creatable: false,
        cache: true,
        placeholder: "",
        size: 12,
        id: moment.now.toString(),
        loadingPlaceholder: "Carregando"
    };

    handleSelectChange = (input: any) => {
        let props = this.props;
        if (this.props.returnFullObject) {
            //set valor com all opion no state e retorna sem all option
            let resultSemAll =
                props.isMulti && input
                    ? input.filter(
                    option =>
                        option[props.valueKey] !== props.allOption[props.valueKey]
                    )
                    : input;
            this.setState({value: input}, () => {
                this.props.onChange(this.props.name, resultSemAll);
            });
        } else {
            this.props.onChange(
                this.props.name,
                (input ? input[this.props.valueKey] : null), input
            );
        }
    };

    onChange = (selected, event) => {
        let props = this.props;
        if (props.value != undefined && props.value.length >= props.size) {
            return props.error("É permitido adicionar até " + props.size + " atendimentos em cada operação.");
        } else {
            if (props.allowSelectAll) {
                if (selected !== null && selected.length > 0) {
                    if (
                        selected[selected.length - 1][props.valueKey] ===
                        props.allOption[props.valueKey]
                    ) {
                        return this.handleSelectChange([props.allOption, ...props.options]);
                    }
                    let result = [];
                    if (selected.length === props.options.length) {
                        if (selected.includes(props.allOption)) {
                            result = selected.filter(
                                option =>
                                    option[props.valueKey] !== props.allOption[props.valueKey]
                            );
                        } else if (event.action === "select-option") {
                            result = [props.allOption, ...props.options];
                        }
                        return this.handleSelectChange(result);
                    }
                }
            }

            return this.handleSelectChange(selected);
        }
    };

    handleBorderColor = messageValidate => {
        let borderColor, borderWidth;
        if (messageValidate) {
            borderColor = "#ea3a45";
            borderWidth = 2;
        }
        return {
            borderColor,
            borderWidth
        };
    };

    handleFocus = () => {
        const {messageValidate} = this.props;
        if (messageValidate) {
            return (
                <ToolTipFeedBack
                    target={this.props.id}
                    messageValidate={messageValidate}
                />
            );
        }
    };

    clearThisTimout = () => {
        clearTimeout(timoutVar);
    };

    fetchData = (input: string, callback) => {
        this.clearThisTimout();
        let p: Promise<any> = Promise.resolve({options: []});
        if (!input) {
            return p;
        }
        if (!this.props.minLength) {
            timoutVar = setTimeout(() => {
            this.load(input,callback,p)
            }, 1000);
        }else{
          if(input.length>=this.props.minLength){
            this.load(input,callback,p) ;
          }
        }
    };

    blurRef = () => {
        return this.myRef.current.blur();
    }

    focusRef = () => {
        return this.myRef.current.focus();
    }

    load = (input,callback,p) => {
        if (this.props.onFetchData) {
            let fetch;
            if (this.props.parent !== null && this.props.parent) {
                fetch = this.props.onFetchData(this.props.parent, input);
            } else {
                fetch = this.props.onFetchData(input);
            }

            fetch.then(
                json => {
                    let result = json.data.data ? json.data.data : json.data;
                    if (this.props.autoClick) {
                        let uniqueResults = result;
                        if (this.props.value) {
                            let propsValues = this.props.value;
                            propsValues.forEach(e => {
                                const isIdUnique = !uniqueResults.some(item => item.id === e.id);
                                if (isIdUnique) {
                                    uniqueResults.push(e);
                                }
                            });
                        }
                        this.blurRef();
                        this.focusRef();
                        this.handleSelectChange(uniqueResults);
                    } else {
                        callback(result);
                    }
                },
                () => {
                    callback([]);
                }
            );
        } else {
            callback(p);
        }
    }

    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    };

    arrowRenderer = () => {
        return (
            <div className={""}>{!this.props.removeDropDown && <ChevronDown/>}</div>
        );
    };

    render() {
        const {messages, name, alertMensagem} = this.props;
        // let colSize = "col";
        // if (this.props.size) {
        //   colSize += "-" + this.props.size;
        // }
        let value = this.props.value || "";
        if (
            !this.props.returnFullObject &&
            this.props.options &&
            this.props.options.length > 0
        ) {
            value = this.props.options.filter(option => {
                return value === option[this.props.valueKey];
            });
        }
        if (!this.props.value && this.props.defaultValue) {
            value = this.props.defaultValue;
        }
        let TypeComponent;
        if (this.props.onFetchData) {
            if (this.props.creatable) {
                TypeComponent = Select.AsyncCreatable;
            } else {
                TypeComponent = Async;
            }
        } else {
            if (this.props.creatable) {
                TypeComponent = Select.Creatable;
            } else {
                TypeComponent = Select;
            }
        }
        let messageValidate;
        // if (messages != null && messages.length > 0) {
        //   messages.forEach(function(message, index) {
        //     if (message.fieldName === name) {
        //       messageValidate = translate(message.message.code);
        //     }
        //   });
        // }
        if (this.props.erroMensagem) {
            messageValidate = this.props.erroMensagem;
        }


        let mensagemAlert;
        if (alertMensagem) {
            let tipoGuia = objectsConstants.TIPOS_GUIA.find(element => { if (element.label === localStorage.getItem("tipoGuia")) return element; });

            mensagemAlert= alertMensagem.find(element =>
              (this.props.name && this.props.name.toLowerCase().includes(element.campoAtendimentoTipoGuia.campoAtendimento.nomeCampo.toLowerCase()))
              && (this.props.label && this.props.label.toLowerCase().includes(element.campoAtendimentoTipoGuia.campoAtendimento.label.toLowerCase()))
              && tipoGuia.id == element.tipoGuia.id);
        }

        let props = this.props;
        const menuPortalTarget = document.getElementById("root");
        return (
            <div
                className={`${this.props.required ? "input-required" : ""} ${this.props.style}`}
            >
                {this.props.label && (
                    <React.Fragment>
                        <Label
                            for={this.props.name}
                            className="label-nowrap"
                            title={this.props.label}
                        >
                            {this.props.label}
                            {this.props.required && "*"}
                        </Label>{" "}
                        {mensagemAlert && (
                            <React.Fragment>
                                <i className="icon-exclamation" id={'message_alert_' + this.props.id} />
                                <ToolTipHelp
                                    target={'message_alert_' + this.props.id}
                                    message={mensagemAlert.mensagemAlerta}
                                />
                            </React.Fragment>
                        )}
                        {this.props.helpText && (
                            <React.Fragment>
                                <i className="icon-duvida" id={"help_" + this.props.id}/>
                                <ToolTipHelp
                                    target={"help_" + this.props.id}
                                    message={this.props.helpText}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
                <TypeComponent
                    components={this.props.components}
                    formatOptionLabel={this.props.formatOptionLabel}
                    optionRenderer={this.props.optionRender && this.props.optionRender}
                    id={this.props.id ? this.props.id : this.props.name}
                    name={this.props.name}
                    loadOptions={this.fetchData}
                    onChange={this.onChange}
                    getOptionLabel={data => {
                        // "data" contains the current option object, so you just use your "labelKey" to return the value of that property.
                        return _.get(data, this.props.labelKey);
                    }}
                    getOptionValue={data => {
                        // "data" contains the current option object, so you just use your "valueKey" to return the value of that property.
                        return data[this.props.valueKey];
                    }}
                    value={value}
                    searchable={this.props.searchable}
                    noOptionsMessage={() =>
                        !this.props.noResultsText
                            ? "Nenhum resultado encontrado."
                            : this.props.noResultsText
                    }
                    searchPromptText="Digite para buscar"
                    placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
                    clearValueText="Limpar valor"
                    loadingPlaceholder={this.props.loadingPlaceholder}
                    clearAllText="Limpar todos"
                    promptTextCreator={(label: string) => {
                        return `Deseja cadastrar "${label}"?`;
                    }}
                    menuPlacement="auto"
                    menuPortalTarget={this.props.apendRoot && menuPortalTarget}
                    cache={this.props.cache && {}}
                    style={this.handleBorderColor(messageValidate)}
                    multi={this.props.multi}
                    options={
                        !props.allowSelectAll
                            ? this.props.options
                            : [props.allOption, ...props.options]
                    }
                    clearable={this.props.clearable}
                    isClearable={this.props.clearable}
                    isCreatable={this.props.creatable}
                    onNewOptionClick={this.props.onNewOptionClick}
                    newOptionCreator={this.props.newOptionCreator}
                    required={this.props.required}
                    isDisabled={this.props.disabled}
                    className={`Select clearIndicator width-full defaultSelect ${this.props.className} ${messageValidate ? "is-invalid" : ""
                    }`}
                    optionClassName={"selectMenuItem"}
                    classNamePrefix="react-select"
                    isMulti={this.props.isMulti}
                    menuIsOpen={this.props.menuIsOpen}
                    closeMenuOnSelect={this.props.closeMenuOnSelect}
                    hideSelectedOptions={this.props.hideSelectedOptions}
                    onClickDropDown={this.props.onClickDropDown}
                    onBlur={this.props.onBlur}
                    ref={this.myRef}
                />
                {messageValidate && (
                    <React.Fragment>
                    <ToolTipFeedBack
                        target={this.props.id ? this.props.id : this.props.name}
                        messageValidate={messageValidate}
                    />
                    </React.Fragment>
                )}
            </div>
        );
    }
}

SelectInput.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        nome: PropTypes.string,
        id: PropTypes.string
    })
};

//SelectInput.defaultProps = {
//    allOption: {
//       nome: "Selecionar todos",
//        id: "-1"
//    }
//};
SelectInput.defaultProps = {
    allOption: {
        nome: "Selecionar todos",
        id: "-1"
    }
};
/*
const mapDispatch = ({ alert: { error, success } }) => ({
    error: message => error({ message }),
    success: message => success({ message })
  });
  export default connect(
    null,
    mapDispatch,
    null,
    { withRef: true }
  )(SelectInput);*/
  
