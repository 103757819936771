import React from 'react';
import { components } from 'react-select';

const Control = ({ children, ...props }) => (
  <components.Control {...props}>
    <button
      type="button"
      className="btn-mais"
    >
      <span className="icon-btn-add"></span>
    </button>
    {children}
  </components.Control>
);

export default Control;