import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Avatar from "../../components/Avatar/Avatar";
import RoleSelectInput from "../../components/Inputs/RoleSelectInput";
import { dateHelper } from "../../helpers/date.helper";
import { formatterHelper } from "../../helpers/formatter.helper";
import iconPhoto from "../../img/icon_photo.svg";
import Icon_usuario_cooperado from "../../img/icon_usuarioCooperado.svg";
import { userService } from "../../services/user.service";

class UsuarioTopoCrud extends PureComponent<Props, State> {
  render() {
    const {
      values,
      handleSubmit,
      handleCancel,
      onChange,
      onChangeProfile
    } = this.props;
    return (
      //add classe "bg-branco" abaixo quando esição/criação
      <React.Fragment>
        <div className="px-sm-4 py-sm-2 my-sm-3 bg-branco rounded d-flex flex-column flex-sm-row justify-content-between align-items-center">
          <div className="bloco-dados-user mr-sm-auto d-flex flex-column flex-sm-row align-items-center align-self-stretch align-self-sm-center">
            <div className="bloco-image-user m-sm-2 mr-sm-4">
              {!values.usuarioPerfis && (
                <img
                  src={Icon_usuario_cooperado}
                  className="avatar"
                  alt="iconFoto"
                />
              )}
              {values.usuarioPerfis && (
                <Avatar
                  user={values}
                  editable={true}
                  name="midia"
                  onChange={(name, value) => onChange(name, value)}
                >
                  {values.usuarioPerfis && (
                    <div className="icon-foto-user photo d-flex align-items-center ">
                      <img src={iconPhoto} alt="iconPerfil" />
                    </div>
                  )}
                </Avatar>
              )}
            </div>
            <div className="info-user col-12">
              {values.usuarioPerfis && (
                <React.Fragment>
                  {" "}
                  <p>
                    {this.props.user.usuarioCooperativas.map(cooperativa => {
                      return cooperativa.cooperativa.sigla;
                    })}
                  </p>
                  <h2 className="font-weight-bold">
                    {formatterHelper.getNameOrHifen(values.nome)}
                  </h2>
                  <p className="font-italic">
                    {" "}
                    {userService.getPerfilAtual(values)}
                  </p>
                </React.Fragment>
              )}
              {(!values.id || this.props.edit) && (
                <React.Fragment>
                  <label>Perfil</label>
                  <RoleSelectInput
                    className="min-width-180"
                    name={"usuarioPerfis"}
                    placeholder={"Escolha o perfil do novo usuário"}
                    returnFullObject={true}
                    labelKey={"displayName"}
                    valueKey={"fieldName"}
                    value={
                      values.usuarioPerfis && values.usuarioPerfis.length>0
                        ? values.usuarioPerfis[0].perfil
                        : null
                    }
                    onChange={(name, value) =>
                      onChangeProfile(name, value ? [{ perfil: value }] : null)
                    }
                  />
                </React.Fragment>
              )}
            </div>
          </div>
          {values.usuarioPerfis && (
            <div className="info-texto px-sm-3 mt-2 mt-sm-0">
              Cadastrado em:
              <div className="font-weight-bold">
                {dateHelper.format(values.creationDateTime, { mode: "DATE" })}
              </div>
            </div>
          )}
          {!values.usuarioPerfis && (
            <div className="info-texto px-sm-3 mt-2 mt-sm-0">
              <span className="font-weight-bold font-italic helpText">
                Quer ajuda?
              </span>
              <p className="font-italic">
                01- Escolha ao lado o perfil que deseja criar
              </p>
            </div>
          )}
          <div className="px-sm-3 mb-3 border-left justify-content-around">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={handleCancel}
            >
              <span className={`icon-btn-fechar`}></span>
              {"Cancelar"}
            </button>
            {values.usuarioPerfis && (
              <button
                type="submit"
                className="btn btn-primary "
                onClick={handleSubmit}
              >
                <span className={`icon-btn-check`}></span>
                {"Salvar"}
              </button>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(UsuarioTopoCrud));
