import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Avatar from "../../components/Avatar/Avatar";
import UsuarioCardTopo from "../usuario/UsuarioCardTopo";
import EstabelecimentoCardTopo from "../usuario/EstabelecimentoCardTopo";
import iconPerfil from "../../img/icon_perfil.svg";
import { dateHelper } from "../../helpers/date.helper";
import { Button } from "reactstrap";
import { userService } from "../../services/user.service";

class TopoFiltroMulti extends PureComponent<> {
  render() {
    return (
      <div className="px-sm-5 py-sm-2 bg-cinza my-sm-3 rounded d-flex flex-column flex-sm-row align-items-center justify-content-between">
        {this.props.filter &&
          !this.props.filter.cooperado &&
          !this.props.filter.estabelecimento && (
            <div
              className="bloco-dados-user  mr-sm-auto d-flex flex-column flex-sm-row align-items-center pointer"
              onClick={() => {
                this.props.history.push("/perfil/view/" + this.props.user.id);
              }}
            >
              <div className="bloco-image-user m-sm-2 mr-sm-4">
                <div className="icon-foto-user d-flex align-items-center ">
                  <img src={iconPerfil} alt="iconPerfil" />
                </div>
                <Avatar user={this.props.user}></Avatar>
              </div>
              <div className="info-user">
                <p className="txtCooperativa">
                  {this.props.user.cooperativa &&
                    this.props.user.cooperativa.sigla}
                </p>
                <h2 className="font-weight-bold">{this.props.user.nome}</h2>

                {!this.props.user.setorAtual && (
                  <p className="font-italic txtPerfil">
                    {userService.getPerfilAtual(this.props.user)}
                  </p>
                )}
                {this.props.user.setorAtual && (
                  <p className="font-italic txtPerfil ">
                    {this.props.user.setorAtual.nome}
                  </p>
                )}
              </div>
            </div>
          )}
        {this.props.filter && this.props.filter.cooperado && (
          <UsuarioCardTopo
            changeFilterProps={this.props.changeFilterProps}
            user={this.props.filter.cooperado}
          />
        )}
        {this.props.filter && this.props.filter.estabelecimento && (
          <EstabelecimentoCardTopo
            changeFilterProps={this.props.changeFilterProps}
            estabelecimento={this.props.filter.estabelecimento}
          />
        )}
        <div className={"d-flex flex-column ml-sm-auto"}>
          {this.props.filter && this.props.filter.beneficiario && (
            <span class="tag">
              <span>
                {" "}
                <b>{this.props.filter.beneficiario.nomeBeneficiario}</b>
              </span>

              <span className="pl-1">
                <button
                  className="btn btn-icon-only"
                  onClick={() => {
                    this.props.removeFilter("beneficiario");
                    this.props.changeFilterProps("beneficiario");
                  }}
                >
                  <span className="icon-btn-fechar-filtro-red"></span>
                </button>
              </span>
            </span>
          )}
          {this.props.filter && this.props.filter.convenios && (
            <>
              {this.props.filter.convenios.map((convenio, index) => (
                <span key={index} className="tag">
                  <span>
                    {" "}
                    <b>{convenio.nome}</b>
                  </span>
                  <span className="pl-1">
                    <button
                      className="btn btn-icon-only"
                      onClick={() => {
                        this.props.removeFilter("convenios", convenio);
                        this.props.changeFilterProps("convenios", convenio);
                      }}
                    >
                      <span className="icon-btn-fechar-filtro-red"></span>
                    </button>
                  </span>
                </span>
              ))}
            </>
          )}

          {/* {this.props.filter && this.props.filter.convenios && (
            <span className="tag">
              <span>
                {" "}
                <b>{this.props.filter.convenios[0].nome}</b>
              </span>

              <span className="pl-1">
                <button
                  className="btn btn-icon-only"
                  onClick={() => {
                    this.props.removeFilter("convenios");
                    this.props.changeFilterProps("convenios");
                  }}
                >
                  <span className="icon-btn-fechar-filtro-red"></span>
                </button>
              </span>
            </span>
          )} */}

          {this.props.filter && this.props.filter.mes && (
            <span className="tag">
              <span>
                {" "}
                <b>{this.props.filter.mes}</b>
              </span>

              <span className="pl-1">
                <button
                  className="btn btn-icon-only"
                  onClick={() => {
                    this.props.removeFilter("mes");
                    this.props.changeFilterProps("mes");
                  }}
                >
                  <span className="icon-btn-fechar-filtro-red"></span>
                </button>
              </span>
            </span>
          )}

          {this.props.filter && this.props.filter.data && (
            <span className="tag">
              <span>
                {" "}
                <b>
                  {dateHelper.format(this.props.filter.data, { mode: "DATE" })}
                </b>
              </span>

              <span className="pl-1">
                <button
                  className="btn btn-icon-only"
                  onClick={() => {
                    this.props.removeFilter("data");
                    this.props.changeFilterProps("data");
                  }}
                >
                  <span className="icon-btn-fechar-filtro-red"></span>
                </button>
              </span>
            </span>
          )}

          {this.props.filter &&
            this.props.filter.periodo &&
            (this.props.filter.periodo.inicio ||
              this.props.filter.periodo.fim) && (
              <span className="tag">
                <span>
                  {" "}
                  <b>{` ${
                    this.props.filter.periodo.inicio
                      ? dateHelper.format(this.props.filter.periodo.inicio, {
                          mode: "DATE",
                        })
                      : ""
                  } ${
                    this.props.filter.periodo.inicio &&
                    this.props.filter.periodo.fim
                      ? "a"
                      : ""
                  } ${
                    this.props.filter.periodo.fim
                      ? dateHelper.format(this.props.filter.periodo.fim, {
                          mode: "DATE",
                        })
                      : ""
                  }`}</b>
                </span>

                <span className="pl-1">
                  <button
                    className="btn btn-icon-only"
                    onClick={() => {
                      this.props.removeFilter("periodo");
                      this.props.changeFilterProps("periodo");
                    }}
                  >
                    <span className="icon-btn-fechar-filtro-red"></span>
                  </button>
                </span>
              </span>
            )}

          {this.props.filter &&
            this.props.filter.dataEncaminhamento &&
            (this.props.filter.dataEncaminhamento.inicio ||
              this.props.filter.dataEncaminhamento.fim) && (
              <span className="tag">
                <span>
                  {" "}
                  <b>{` ${
                    this.props.filter.dataEncaminhamento.inicio
                      ? dateHelper.format(
                          this.props.filter.dataEncaminhamento.inicio,
                          {
                            mode: "DATE",
                          }
                        )
                      : ""
                  } ${
                    this.props.filter.dataEncaminhamento.inicio &&
                    this.props.filter.dataEncaminhamento.fim
                      ? "a"
                      : ""
                  } ${
                    this.props.filter.dataEncaminhamento.fim
                      ? dateHelper.format(
                          this.props.filter.dataEncaminhamento.fim,
                          {
                            mode: "DATE",
                          }
                        )
                      : ""
                  }`}</b>
                </span>

                <span className="pl-1">
                  <button
                    className="btn btn-icon-only"
                    onClick={() => {
                      this.props.removeFilter("dataEncaminhamento");
                      this.props.changeFilterProps("dataEncaminhamento");
                    }}
                  >
                    <span className="icon-btn-fechar-filtro-red"></span>
                  </button>
                </span>
              </span>
            )}

          {this.props.filter &&
            this.props.filter.dataRecebimento &&
            (this.props.filter.dataRecebimento.inicio ||
              this.props.filter.dataRecebimento.fim) && (
              <span className="tag">
                <span>
                  {" "}
                  <b>{` ${
                    this.props.filter.dataRecebimento.inicio
                      ? dateHelper.format(
                          this.props.filter.dataRecebimento.inicio,
                          {
                            mode: "DATE",
                          }
                        )
                      : ""
                  } ${
                    this.props.filter.dataRecebimento.inicio &&
                    this.props.filter.dataRecebimento.fim
                      ? "a"
                      : ""
                  } ${
                    this.props.filter.dataRecebimento.fim
                      ? dateHelper.format(
                          this.props.filter.dataRecebimento.fim,
                          {
                            mode: "DATE",
                          }
                        )
                      : ""
                  }`}</b>
                </span>

                <span className="pl-1">
                  <button
                    className="btn btn-icon-only"
                    onClick={() => {
                      this.props.removeFilter("dataRecebimento");
                      this.props.changeFilterProps("dataRecebimento");
                    }}
                  >
                    <span className="icon-btn-fechar-filtro-red"></span>
                  </button>
                </span>
              </span>
            )}

          {this.props.filter && this.props.filter.ano && (
            <span className="tag">
              <span>
                {" "}
                <b>{this.props.filter.ano}</b>
              </span>

              <span className="pl-1">
                <button
                  className="btn btn-icon-only"
                  onClick={() => {
                    this.props.removeFilter("ano");
                    this.props.changeFilterProps("ano");
                  }}
                >
                  <span className="icon-btn-fechar-filtro-red"></span>
                </button>
              </span>
            </span>
          )}

          {this.props.filter && this.props.filter.numeroAtendimento && (
            <span className="tag">
              <span>
                {" "}
                <b>{this.props.filter.numeroAtendimento}</b>
              </span>

              <span className="pl-1">
                <button
                  className="btn btn-icon-only"
                  onClick={() => {
                    this.props.removeFilter("numeroAtendimento");
                    this.props.changeFilterProps("numeroAtendimento");
                  }}
                >
                  <span className="icon-btn-fechar-filtro-red"></span>
                </button>
              </span>
            </span>
          )}
          {this.props.filter && this.props.filter.statusEncaminhamento && (
            <span className="tag">
              <span>
                {" "}
                <b>{this.props.filter.statusEncaminhamento.nome}</b>
              </span>

              <span className="pl-1">
                <button
                  className="btn btn-icon-only"
                  onClick={() => {
                    this.props.removeFilter("statusEncaminhamento");
                    this.props.changeFilterProps("statusEncaminhamento");
                  }}
                >
                  <span className="icon-btn-fechar-filtro-red"></span>
                </button>
              </span>
            </span>
          )}
          {this.props.filter && this.props.filter.numeroGuia && (
            <span className="tag">
              <span>
                {" "}
                <b>{this.props.filter.numeroGuia}</b>
              </span>

              <span className="pl-1">
                <button
                  className="btn btn-icon-only"
                  onClick={() => {
                    this.props.removeFilter("numeroGuia");
                    this.props.changeFilterProps("numeroGuia");
                  }}
                >
                  <span className="icon-btn-fechar-filtro-red"></span>
                </button>
              </span>
            </span>
          )}
          {this.props.filter && this.props.filter.possuiEvolucaoClinica && (
            <span className="tag">
              <span>
                {" "}
                <b>
                  Possui Evolução Clinica:{" "}
                  {this.props.filter.possuiEvolucaoClinica.nome}
                </b>
              </span>

              <span className="pl-1">
                <button
                  className="btn btn-icon-only"
                  onClick={() => {
                    this.props.removeFilter("possuiEvolucaoClinica");
                    this.props.changeFilterProps("possuiEvolucaoClinica");
                  }}
                >
                  <span className="icon-btn-fechar-filtro-red"></span>
                </button>
              </span>
            </span>
          )}
        </div>

        <div className="px-sm-3 mt-4 mb-3 mt-sm-0 mb-sm-0">
          {Object.getOwnPropertyNames(this.props.filter).length === 1 &&
            this.props.filter.activeIndex >= 0 && (
              <Button
                className="btn btn-light"
                onClick={this.props.toogleFiltro}
              >
                <span className="icon-btn-filtro"></span>
                Filtrar
              </Button>
            )}
          {Object.getOwnPropertyNames(this.props.filter).length >= 1 &&
            (this.props.filter.activeIndex === undefined ||
              this.props.filter.activeIndex >= 0) && (
              <button
                type="button"
                className={`btn btn-primary`}
                onClick={this.props.toogleFiltro}
              >
                <span className="icon-btn-filtro-branco"></span>
                Filtros ativos
              </button>
            )}
        </div>
      </div>
    );
  }
}
const mapDispatch = ({ filtro: { toogleFiltro, removeFilter } }) => ({
  toogleFiltro: () => toogleFiltro(),
  removeFilter: (filterRemove) => removeFilter({ filterRemove }),
});
function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions,
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(TopoFiltroMulti));
