import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FormInput from '../../components/Inputs/FormInput';
import { mascaraEmail } from "../../helpers/mascaraEmail.helper";

/*CSS*/
import Key from '../../img/key_03.svg';

class EsqueciSenha extends PureComponent<Props, State> {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 text-center move-up">
            <img src={Key} alt="chave" height="50px" />
          </div>
          <div className="col-12 text-center mt-3">
            <h2 className="text-primary">
              <b>{mascaraEmail.insereMascaraEmail(this.props.emailUsuario)}</b>
            </h2>
          </div>
          <div className="col-8 offset-2 text-center">
            <p>Informa abaixo seu e-mail cadastrado, para redefinir sua senha:</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 inputSenha">
            <FormInput
              name={'email'}
              id={'email'}
              label={"E-mail"}
              value={this.props.email}
              onChange={this.props.onChange}
              type={'email'}
              required={true}
              placeholder="E-mail"

            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 esqueciSenha">
          <a className="link-cinza"
              href="https://fencom.coop.br/webkit/recuperarAcesso.php" target="_blank">
              Cooperado, clique aqui para recuperar sua senha
            </a>
          </div>
        </div>

        <div className="row">
          <div className="col mb-15-negative text-center">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={this.props.recuperarSenha}
            >
              Redefinir senha
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert
  };
}

const mapDispatch = ({
  alert: { error, clear, success, info },
  load: { loading }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  info: msg => info(msg)
});
export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(EsqueciSenha));
