import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import Datatable from "../../sascComponents/datagrid/Datatable";
import TopoListagem from "../../sascComponents/topo/TopoListagem";
import { ErrorImportacaoNotificationService } from "../../services/errorImportacaoNotificationService";
import { integracaoAtendimentoService } from "../../services/integracaoAtendimento.service";
import { userService } from "../../services/user.service";
import CardErrorIntegracaoAtendimentoNotification from "../errorImportacaoNotification/CardErrorIntegracaoAtendimentoNotification";
import TopoTitleComponente from "../home/TopoTitleComponente";
import { v4 as uuidv4 } from 'uuid';

const fields = [
    {
        "path": "ImportacaoIntegracaoAtendimento",
        "fieldName": "dataEnvio",
        "fieldDescription": "Data do envio",
        "type": "DATE"
    },
    {
        "path": "ImportacaoIntegracaoAtendimento",
        "fieldName": "numeroLote",
        "fieldDescription": "Número do lote",
        "type": "STRING"
    },
    {
        "path": "ImportacaoIntegracaoAtendimento.usuarioCriador",
        "fieldName": "nome",
        "fieldDescription": "Usuário",
        "type": "STRING"
    },
    {
        "path": "ImportacaoIntegracaoAtendimento.convenio",
        "fieldName": "sigla",
        "fieldDescription": "Convênio",
        "type": "STRING"
    },
    {
        "path": "ImportacaoIntegracaoAtendimento",
        "fieldName": "quantidadeGuiasRecebidas",
        "fieldDescription": "Quantidade total de atendimentos recebidos",
        "type": "NUMBER"
    },
    {
        "path": "ImportacaoIntegracaoAtendimento",
        "fieldName": "quantidadeGuiasProcessadas",
        "fieldDescription": "Quantidade total de atendimentos processados",
        "type": "NUMBER"
    },
    {
        "path": "ImportacaoIntegracaoAtendimento.status",
        "fieldName": "descricao",
        "url": "/integracaoAtendimento/getStatusArquivoIntegracaoAtendimentos",
        "fieldDescription": "Situação",
        "type": "SELECT"
    },
    {
        "path": "ImportacaoIntegracaoAtendimento",
        "fieldName": "boolean_option",
        "url": "/enum/getFilterBoolean",
        "fieldDescription": "Inconsistências",
        "type": "SELECT"
    }

]

class IntegracaoAtendimentosList extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        const isConsultorio = userService.isConsultorio(this.props.user);
        const extension = userService.getExtensionUser(this.props.user);
        const idCooperativa = (this.props.user.cooperativa.id);
        let initFilter = null
        if (isConsultorio) {
            initFilter = [{
                path: "ImportacaoIntegracaoAtendimento",
                fieldName: "estabelecimento",
                fieldDescription: "id",
                value: extension,
                type: "NUMBER",
                condition: { description: "=" }

            },
            {
                path: "ImportacaoIntegracaoAtendimento",
                fieldName: "cooperativa",
                fieldDescription: "id",
                value: idCooperativa,
                type: "NUMBER",
                condition: { description: "=" }
            }];
        } else {
            initFilter = [{
                path: "ImportacaoIntegracaoAtendimento",
                fieldName: "cooperativa",
                fieldDescription: "id",
                value: idCooperativa,
                type: "NUMBER",
                condition: { description: "=" }
            }];
        }

        this.state = {
            initFilter,
            showModalErroIntegracao: false,
            errorImportacaoIntegracaoList: []
        };
    }
    closeMenu = () => {
        this.props.showMenu();
    };
    handleRowClick = (fieldName, rowData) => {
        if (fieldName === 'inconsistencia' && rowData.inconsistencia) {
            this.findErros(rowData.id)
        }
    };

    findErros = (id) => {
        ErrorImportacaoNotificationService.buscarErrosIntegracaoAtendimentos(id).then(response => {
            this.setState({ errorImportacaoIntegracaoList: response.data });
            this.showModalErroIntegracao()
        });
    }

    atualizaListaErros = (id) => {
        ErrorImportacaoNotificationService.buscarErrosIntegracaoAtendimentos(id).then(response => {
            this.setState({ errorImportacaoIntegracaoList: response.data });
        });
        this.dataTable.initData();
    }

    showModalErroIntegracao = () => {
        this.setState({ showModalErroIntegracao: !this.state.showModalErroIntegracao })
    }

    render() {
        return (
            <div className="container-fluid container-com-topo">
                <TopoTitleComponente
                    mainTitle="Integração"
                    subTitle=" "
                    canBack={false}
                />
                <TopoListagem />
                <hr className="text-center w-75"></hr>

                <div className="background-lines">
                    <div className="bg-riscos"></div>
                </div>
                <Modal
                    isOpen={this.state.showModalErroIntegracao}
                    toggle={this.showModalErroIntegracao}
                    backdrop="static"
                    modalClassName=""
                    centered={true}
                    size={"lg"}
                >
                    <ModalBody>
                        <div>
                            <div className="row text-center bodyNotification d-flex flex-column h-100 mx-sm-auto">
                                <h2 className="font-weight-bold">Inconsistências</h2>
                                <div className="container-error-notifications container-error-notifications-integracao d-nome">
                                    {this.state.errorImportacaoIntegracaoList.map((error) => {
                                        return <CardErrorIntegracaoAtendimentoNotification errorImportacaoNotifications={error} key={uuidv4()} findErros={this.atualizaListaErros} />;
                                    })}
                                </div>
                            </div>

                            <div className="row">
                                <div className="flex-grow px-1 m-auto justify-content-center">
                                    <button
                                        className="btn btn-secondary white mt-3 mb-4 mx-2"
                                        type={"button"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.showModalErroIntegracao()
                                        }}
                                    >
                                        Fechar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Datatable
                    showCheckboxColumn={true}
                    idRow={"id"}
                    name={"Lote(s)"}
                    service={integracaoAtendimentoService}
                    classHeader={[
                        "headerLeft",
                        "headerLeft",
                        "headerLeft",
                        "headerLeft",
                        "headerLeft",
                        "headerLeft",
                        "headerLeft",
                        "headerLeft",
                    ]}
                    classColumn={[]}
                    onRowClick={this.handleRowClick}
                    initFilter={this.state.initFilter}
                    listFields={fields}
                    wrappedComponentRef={c => (this.dataTable = c)}
                />
            </div>
        );
    }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
    showMenu: () => showMenu(),
    hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
    const { loggedIn, user, permissions } = state.authentication;
    const { alert } = state;

    return {
        loggedIn,
        alert,
        user,
        permissions
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(IntegracaoAtendimentosList));
