import _ from "lodash";
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from "react-router-dom";
import { exameService } from '../../../services/exame.service';
import { pacoteExameService } from "../../../services/pacoteExame.service";
import { procedimentoService } from "../../../services/procedimento.service";
import { FormGroup, Modal, ModalBody } from "reactstrap";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import SelectInput from "../../../components/Inputs/SelectInput";
import { Formik } from "formik";
import * as Yup from "yup";
import Control from "../../../components/Buttons/ControlSelectInput";
import MultiValueRemove from "../../../components/Buttons/CustomCloseSelectInput";
import ClearIndicator from "../../../components/Buttons/CustomClearIndicatorSelectInput";
import FormSelectInput from "../../../components/Inputs/FormSelectInput";
import { ConfirmAcationService } from "../../../services/ConfirmAcationService";
import { objectsConstants } from "../../../constants/objects.constants";
import moment from "moment";

class LinhaAcaoPrescreverExames extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showModalExames: false,
            exames: []
        };
    }

    separarAtendimentosPrazo = async () => {
        let atendimentos = this.props.rowsCheckedValue;
        let exames = _.cloneDeep(this.formRef.state.values.exames);
        this.setState({ exames: exames })
        let confirmList = [];
        let saveList = [];

        for (let a of atendimentos) {
            let ae = {
                atendimento: a.id,
                exames: exames,
                data: a.atendimentoExecucaos.length > 0 && a.atendimentoExecucaos[0].dataAtendimento,
                convenio: a.convenio.id,
                estabelecimento: a.estabelecimento.id,
                numeroCarteira: a.numeroCarteira
            }
            try {
                const response = await exameService.checkPrazo(ae);
                if (response.data.length > 0) {
                    let atenAe = { atendimento: a, ae: response.data }
                    confirmList.push(atenAe);
                } else {
                    saveList.push(a);
                }
            } catch (error) {
                console.error(error);
            }
        }

        this.salvarAtendimentoListSemConflito(saveList);

        for (let item of confirmList) {
            await this.salvarAtendimentoListComConflito(item);
        }

        if (this.props.initData) {
            this.props.initData();
        }
        this.toogleModalExames();
    }

    salvarAtendimentoListSemConflito = async (atendimentos) => {
        this.props.loading(true);
        for (let a of atendimentos) {
            await this.saveExames(a);
        }
    };

    saveExames = async (atend) => {
        let newValues;
        newValues = atend;
        newValues.exames = this.state.exames;

        let ae = {atendimento: newValues.id, exames: newValues.exames, pacotes: newValues.pacotes}

        try {
            await exameService.doSave(ae);
            this.props.success({
                message: "Exames do atendimento " + atend.numeroAtendimento + " prescritos com sucesso!"
            });
        } catch (error) {
            console.error(error);
            this.props.error({
                message: "Ocorreu um erro ao prescrever os exames do atendimento " + atend.numeroAtendimento
            });
        } finally {
            this.props.loading(false);
        }
    };

    salvarAtendimentoListComConflito = async (item) => {
        let codExames = [];
        let dataAten = [];
        let cooperadosAten = [];
        let atend = item.atendimento;

        item.ae.map(ep => {
            codExames.push(ep.procedimento)
            dataAten.push(ep.exame.atendimento.dataAtendimento)
            cooperadosAten.push(ep.exame.atendimento.cooperado)
        });

        const allEqual = arr => arr.every(val => val.id === arr[0].id);
        const isEqual = allEqual(cooperadosAten);

        if (isEqual) {
            let cooperado = cooperadosAten[0];
            cooperadosAten = [];
            cooperadosAten.push(cooperado);
        }

        try {
            const userConfirmed = await new Promise((resolve, reject) => {
                ConfirmAcationService.confirmMsg(
                    () => {
                        resolve(true);
                    },
                    "Exame realizado - " + atend.numeroAtendimento,
                    <span>
                        {" "}
                        Este beneficiário realizou o(s) exame(s){" "}
                        {codExames.map(e => {
                            return <span className="font-bold">{e.codigo} - {e.descricao}{", "}</span>
                        })}
                        {" "}solicitado(s) pelo(s) cooperado(s){" "}
                        {cooperadosAten.map(e => {
                            return <span className="font-bold">{e.nome}{", "}</span>
                        })}
                        {" "}na(s) data(s){" "}
                        {dataAten.map(data => {
                            return <span className="font-bold">{moment(data).format("DD/MM/YYYY")}{", "}</span>
                        })}
                        . Deseja prosseguir?
                    </span>,
                    <span className="text-danger">{ }</span>,
                    "Não",
                    "Sim",
                    objectsConstants.TYPE_FORWARD,
                    () => {
                        reject(true);
                    }
                );
            });

            if (userConfirmed) {
                this.saveExames(atend);
            }

        } catch (error) {
            console.error(error)
        }
    }

    toogleModalExames = () => {
        this.setState({ showModalExames: !this.state.showModalExames })
    }

    IdsCooperadosAtendimento = () => {
        let atendimentos = this.props.rowsCheckedValue;
        let ids = []
        for (let atendimento of atendimentos) {
            if (atendimento.cooperado && atendimento.cooperado.id) {
                ids.push(atendimento.cooperado.id)
            }
            if (atendimento.identificacaoAtendimentoExecucao) {
                for (let i = 0; i < atendimento.identificacaoAtendimentoExecucao.length; i++) {
                    if (atendimento.identificacaoAtendimentoExecucao[i].cooperado && atendimento.identificacaoAtendimentoExecucao[i].cooperado.id) {
                        ids.push(atendimento.identificacaoAtendimentoExecucao[i].cooperado.id)
                    }
                }
            }
        }

        return ids;
    }

    render() {
        return <React.Fragment>
            <Modal
                isOpen={this.state.showModalExames}
                toggle={this.toogleModalExames}
                backdrop="static"
                modalClassName=""
                centered={true}
                size={"lg"}
            >
                <ModalBody>
                    <Formik
                        validationSchema={Yup.object().shape({ exames: Yup.string().required('Obrigatorio').nullable() })}
                        validateOnBlur={false}
                        validateOnChange={false}
                        enableReinitialize={true}
                        onSubmit={(values, actions) => { this.separarAtendimentosPrazo() }}
                        ref={form => {
                            this.formRef = form;
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            validateForm,
                            setValues,
                            touched
                        }) => {
                            return (
                                <form onSubmit={handleSubmit}>

                                    <div className="modalExames">
                                        <div className="row">
                                            <div className="col-11 text-center my-2">
                                                <h2 className="font-weight-bold">Exames solicitados em massa:</h2>
                                                <p>Para os atendimentos selecionados, caso sejam encontrados exames solicitados dentro do período de repetição, o sistema irá sinalizar os exames que estão em duplicidade separado por atendimento.</p>
                                            </div>

                                        </div>


                                        <div className="row">
                                            <div className="flex-fill px-5 m-auto justify-content-center overflow-auto">
                                                <FormGroup className="">
                                                    <InputViewEdit
                                                        label={"Pacote(s) de exame(s)"}
                                                        component={FormSelectInput}
                                                        multi={true}
                                                        isMulti={true}
                                                        onChange={(name, date) => {
                                                            setFieldValue(name, date);

                                                            let examesList = [];
                                                            let oldExames = values.exames ? values.exames : [];
                                                            let oldPacotes = values.pacotes ? values.pacotes : [];

                                                            oldPacotes && oldPacotes.map((oldP) => {
                                                                oldP.exames.map((eOld) => {
                                                                    oldExames = oldExames.filter(objeto => objeto.id !== eOld.id);
                                                                })
                                                            })

                                                            date && date.map((e) => {
                                                                e.exames.map((item) => {
                                                                    examesList.push(item);
                                                                })
                                                            })

                                                            oldExames && oldExames.map((item) => {
                                                                examesList.push(item);
                                                            })

                                                            const arrUniq = [...new Map(examesList.map(v => [v.id, v])).values()]

                                                            setFieldValue(`exames`, arrUniq)

                                                        }}
                                                        required={false}
                                                        name={"pacotes"}
                                                        placeholder="Pacote(s) de exame(s)"
                                                        value={values.pacotes}
                                                        clearable={true}
                                                        labelKey={"nomePacote"}
                                                        valueKey={"nomePacote"}
                                                        returnFullObject={true}
                                                        parent={this.IdsCooperadosAtendimento()}
                                                        service={pacoteExameService.findByCooperado}
                                                        components={{ Control, MultiValueRemove, ClearIndicator }}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="">
                                                    <InputViewEdit
                                                        label={"Descrição do(s) exame(s)"}
                                                        component={SelectInput}
                                                        multi={true}
                                                        isMulti={true}
                                                        onChange={(name, date) => {
                                                            setFieldValue(name, date);
                                                        }}
                                                        required={false}
                                                        name={"exames"}
                                                        type={"text"}
                                                        value={values.exames}
                                                        defaultValue={values.exames ? values.exames.map((e, index) => {
                                                            let value = e.descricao;
                                                            if (index > 0) {
                                                                value = ', ' + value
                                                            }
                                                            return value;
                                                        }) : ''}
                                                        clearable={true}
                                                        labelKey={"descricao"}
                                                        valueKey={"descricao"}
                                                        returnFullObject={true}
                                                        onFetchData={procedimentoService.find}
                                                        components={{ Control, MultiValueRemove, ClearIndicator }}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="">
                                                    <InputViewEdit
                                                        label={"Código(s) de exame(s)"}
                                                        component={SelectInput}
                                                        multi={true}
                                                        isMulti={true}
                                                        onChange={(name, date) => {
                                                            setFieldValue(name, date);
                                                        }}
                                                        required={false}
                                                        name={"exames"}
                                                        type={"text"}
                                                        value={values.exames}
                                                        clearable={true}
                                                        labelKey={"codigo"}
                                                        valueKey={"codigo"}
                                                        returnFullObject={true}
                                                        onFetchData={procedimentoService.find}
                                                        erroMensagem={errors.exames}
                                                        components={{ Control, MultiValueRemove, ClearIndicator }}
                                                    />
                                                </FormGroup>

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="flex-grow px-1 m-auto justify-content-center">

                                                <button
                                                    className="btn btn-secondary white mt-3 mb-4 mx-2"
                                                    type={"button"}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.toogleModalExames()
                                                    }}
                                                >
                                                    Cancelar
                                                </button>
                                                <button
                                                    className="btn btn-primary white mt-3 mb-4 mx-2"
                                                    type={'submit'}
                                                >
                                                    Salvar
                                                </button>

                                            </div>

                                        </div>

                                    </div>
                                </form>)
                        }}
                    </Formik>
                </ModalBody>
            </Modal>
            <button type="button" className="btn btn-primary" onClick={() => {
                this.toogleModalExames()
            }}>
                <span className="icon-btn-add-branco"></span>
                Prescrever exames em massa
            </button>
        </React.Fragment>;
    }
}

const mapDispatch = ({
    alert: { success, error, clear },
    load: { loading }
}) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear()
});

export default connect(
    null,
    mapDispatch,
    null,
    { withRef: true }
)(withRouter(LinhaAcaoPrescreverExames));
