import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { FormGroup } from '../../components/FormGroup';
import { objectsConstants } from "../../constants/objects.constants";
import CpfInput from '../../components/Inputs/CpfInput';
import FormInput from '../../components/Inputs/FormInput';
import InputViewEdit from '../../components/Inputs/InputViewEdit';
import PhoneInput from '../../components/Inputs/PhoneInput';
import { Can } from '@casl/react';
import { userService } from '../../services/user.service';
import FormSelectInput from '../../components/Inputs/FormSelectInput';
import { enumService } from '../../services/enum.service';
import { SubStatusService } from '../../services/SubStatus.service';

class UsuarioForm extends PureComponent<Props, State> {
  goToEdit = () => {
    this.props.history.push({
      pathname: '/usuario/edit/' + this.props.values.id,
      state: { entity: this.props.values }
    });
  }

  habilitaBotao = () => {
    let tipoPerfilLogado = this.props.user.perfilAtual.perfil.tipoPerfil;
    if(this.props.user.perfilAtual&&this.props.user.perfilAtual.perfil&&this.props.values&&this.props.values.perfilAtual) {
    let tipoPerfilUsuario = this.props.values.perfilAtual.perfil.tipoPerfil;

    if (this.props.user.id === this.props.values.id || ((tipoPerfilLogado === objectsConstants.ADMINISTRADOR || tipoPerfilLogado === objectsConstants.SUDO || tipoPerfilLogado === objectsConstants.PROTOCOLO) && tipoPerfilUsuario !== objectsConstants.COOPERADO)) {
      return true;
    } else {
      return false;
    }
  }
  return (tipoPerfilLogado === objectsConstants.ADMINISTRADOR || tipoPerfilLogado === objectsConstants.SUDO);

  }

  canEditSubStatus = () => {
    let tipoPerfilLogado = this.props.user.perfilAtual.perfil.tipoPerfil;
    if (this.props.user.perfilAtual && this.props.user.perfilAtual.perfil && this.props.values && this.props.values.perfilAtual) {
      if (tipoPerfilLogado === objectsConstants.ADMINISTRADOR || tipoPerfilLogado === objectsConstants.SUDO || tipoPerfilLogado === objectsConstants.PROTOCOLO) {
        return true;
      } else {
        return false;
      }
    }
  }

  render() {
    const {
      values,
      setFieldValue,
      errors,
      handleChange,
      isSubmitting,
      handleSubmit,
      viewMode
    } = this.props;
    return (
      <React.Fragment>
        <div className="item">
          <Card className="cardCrud">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mr-sm-auto card-title">Dados</div>
                <div className="px-sm-3">
                  {viewMode && this.habilitaBotao() && (
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={this.goToEdit}
                    >
                      <span className="icon-btn-editar"></span>Editar
                    </button>

                  )}
                </div>
              </div>
            </CardHeader>
            <CardHeader className="subheader">
              {' '}
              {userService.getPerfilAtual(values)}

            </CardHeader>
            <CardBody className="form-inside ml-3">
              <div className="row section-form">
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={'Nome'}
                    type="text"
                    id="nome"
                    name="nome"
                    placeholder="Digite o nome do usuário"
                    value={values.nome}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.nome}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={'E-mail'}
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Digite o e-mail"
                    value={values.email}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.email}
                    viewMode={viewMode}
                    required={false}
                  />
                </FormGroup>
                <FormGroup className="fixo">
                  <InputViewEdit
                    component={PhoneInput}
                    type="number"
                    label="Celular"
                    name="celular"
                    placeholder="Digite o celular"
                    erroMensagem={errors.celular}
                    id={'celular'}
                    onChange={handleChange}
                    value={values.celular}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="fixo">
                  <InputViewEdit
                    component={PhoneInput}
                    label="Telefone Comercial"
                    type="number"
                    name="telefone"
                    placeholder="(00) 0000-0000"
                    erroMensagem={errors.telefone}
                    id={'telefone'}
                    onChange={handleChange}
                    value={values.telefone}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="item">
          <Card className="cardCrud">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mr-sm-auto card-title">Login</div>
              </div>
            </CardHeader>
            <CardHeader className="subheader">Usuário</CardHeader>
            <CardBody className="form-inside ml-3">
              <div className="row section-form">
                <FormGroup className="">
                  <InputViewEdit
                    component={CpfInput}
                    label="CPF"
                    placeholder="Digite o CPF"
                    erroMensagem={errors.cpf}
                    name="cpf"
                    id="cpf"
                    value={values.cpf}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label="Senha"
                    type="password"
                    name={'password'}
                    placeholder={'Digite a Senha'}
                    messageValidate={errors.password}
                    required={values.id ? false : true}
                    noSize={true}
                    id={'password'}
                    onChange={(name, value) => setFieldValue(name, value)}
                    value={values.password}
                    viewMode={viewMode}
                    defaultValue={'**********'}
                    viewPassword={true}
                  />
                </FormGroup>
                <FormGroup className="">
                </FormGroup>
              </div>
              {!values.id && (
                <div className="d-flex flex-row flex-sm-row pt-2 pb-3 justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary d-blokc d-sm-none"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Salvar
                  </button>
                </div>
              )}
            </CardBody>
          </Card>
        </div>
        {this.canEditSubStatus() && <div className="item">
          <Card className="cardCrud">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mr-sm-auto card-title">Bandeirinhas permitidas para usuário</div>
              </div>
            </CardHeader>
            <CardHeader className="subheader">Bandeirinhas permitidas para usuário</CardHeader>
            <CardBody className="form-inside ml-3">
              <div className="row section-form">
                <FormGroup className="">
                  <InputViewEdit
                    placeholder="Bandeirinhas permitidas para usuário"
                    component={FormSelectInput}
                    service={SubStatusService.findSubStatusByCooperativaFilter}
                    name={"usuarioSubStatus"}
                    returnFullObject={true}
                    searchable={true}
                    labelKey={"nome"}
                    required={false}
                    valueKey={"situacao"}
                    isMulti={true}
                    defaultValue={values.usuarioSubStatus ? values.usuarioSubStatus.map((e, index) => {
                      let value = e.nome;
                      if (index > 0) {
                        value = ', ' + value
                      }
                      return value;
                    }) : ''}
                    value={values.usuarioSubStatus}
                    viewMode={viewMode}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                  />
                </FormGroup>
              </div>
            </CardBody>
          </Card>
        </div>}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(UsuarioForm));
