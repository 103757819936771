import React, { PureComponent } from "react";
import { datatableHelper } from "./helper/datatable.helper";
import { datatableConstants } from "./constants/datatable.constants";
export default class Column extends PureComponent<> {
  constructor(props) {
    super(props);
  }

  split = (source, target) => {
    let listFilterBreakValue = [];
    try {
      let split = new String(source.toLowerCase()).split(target.toLowerCase());
    let indexAux = source.toLowerCase().indexOf(target.toLowerCase());
    let sourceAux = "";
    
    if (indexAux != -1) {
      sourceAux = source.substring(indexAux, indexAux + target.length);
    }
    
    for (let i = 0; i < split.length; i++) {
      let splitValue = split[i];
      indexAux = source.toLowerCase().indexOf(splitValue);
      let splitAux = source.substring(indexAux, indexAux + splitValue.length);
      listFilterBreakValue.push({ expression: splitAux, filter: sourceAux });
    }
    } catch (error) {
      console.error(error);
    }
    
    return listFilterBreakValue; 
  };

  render() {
    const { title, filter, name, type } = this.props;

    let value = datatableHelper.getValue(
      this.props.value,
      type,
      this.props.row,
      this.props.labelArrayKey,
      this.props.column
    );
    
    let listFilterBreakValue = [];
    if (filter) {
      for (let i = 0; i < filter.length; i++) {
        const filterValue = filter[i];
        if (
          filterValue &&
          filterValue.filter &&
          (filterValue.filter.fieldName === name ||
            filterValue.filter.fieldName ===
              datatableConstants.ALL_COLUMNS_DATATABLE_FILTER.fieldName)
        ) {
          listFilterBreakValue = this.split(value, filterValue.value);
        }
      }
    }

   if(typeof(value) === "string") {
    value = <span dangerouslySetInnerHTML={{__html: value }}></span>;
   }
   
    return (
      <td
        data-title={title}
        className={` ${this.props.className} ${datatableHelper.getClassByType(
          this.props.value,
          type,
          this.props.row,
          this.props.labelArrayKey,
          this.props.column
        )}`}
        onClick={this.props.columnClick}
      >

        {listFilterBreakValue &&
          listFilterBreakValue.length > 0 &&
          listFilterBreakValue.map(
            (filterBreakValue, indexFilterBreakValue) => {
              
              if (indexFilterBreakValue > 0) {
                return (
                  <React.Fragment key={indexFilterBreakValue}>
                    <span className="highlight" dangerouslySetInnerHTML={{__html: filterBreakValue.filter }}></span>
                    <i dangerouslySetInnerHTML={{__html: filterBreakValue.expression }}></i>
                  </React.Fragment>
                );
              } else {
                return <span dangerouslySetInnerHTML={{__html: filterBreakValue.expression }}></span>
              }
            }
          )}

          

        {(!listFilterBreakValue || listFilterBreakValue.length === 0) && value}
      </td>
    );
  }
}
