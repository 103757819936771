import { urlsConstants } from "../constants/url.constant";
import { defaultService } from './defaultService';

export const estabelecimentoUsuarioService = {
    findTableColumns,
    findSearchableColumns,
    findByFilter,
    ativarUsuario,
    inativarUsuario,
    vincularUsuario,
    exportToExcel
};

function findTableColumns() {
    return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO_USUARIO}list/fields`);
}

function findSearchableColumns() {
    return defaultService.doGet(`${urlsConstants.ESTABELECIMENTO_USUARIO}search/fields`);
}

function findByFilter(options, filter): Promise<Array<>> {
    return defaultService.findByFiltro(
        `${urlsConstants.ESTABELECIMENTO_USUARIO}list`,
        options,
        filter
    );
}

function ativarUsuario(params) {
    return defaultService.doPut(`${urlsConstants.ESTABELECIMENTO_USUARIO}ativarEstabelecimentoCooperado`, params);
}

function inativarUsuario(params) {
    return defaultService.doPut(`${urlsConstants.ESTABELECIMENTO_USUARIO}inativarEstabelecimentoCooperado`, params);
}

function vincularUsuario(params) {
    return defaultService.doPut(`${urlsConstants.ESTABELECIMENTO_USUARIO}vincularEstabelecimentoCooperado`, params);

}

function exportToExcel(filter, fields, labels) {
    return defaultService.exportToExcel("estabelecimentoUsuario", filter, fields, labels);
}