import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SelectInput from "../../components/Inputs/SelectInput";
import { objectsConstants } from "../../constants/objects.constants";
import { userService } from "../../services/user.service";
import * as _ from "lodash";

class SelectPerfilAtual extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { perfilAtual: null, meusPerfils: [] };
  }

  componentWillMount() {
    userService.findMyProfiles().then(response => {
      let options = response.data;
      const unicos = _.uniqBy(options,  function(elem) { return [_.get(elem,'perfil.tipoPerfil'), _.get(elem,'extensao')].join(); });
      this.setState({ meusPerfils: unicos });
      if(this.state.meusPerfils.length < 2) {
        this.state.perfilAtual = this.state.meusPerfils[0];
        this.setUserInSetor();
      }
    });

  }

  handleChange = (name, value) => {
    this.setState({ perfilAtual: value });
    localStorage.removeItem('rascunho');
  };

  setUserInSetor = () => {
    this.props.loading(true);
    const { perfilAtual } = this.state;
    userService.setPerfilAtual(perfilAtual.id).then(response => {
      const user = response.data;
      let showAuditor = userService.isPerfil(user, objectsConstants.AUDITOR);
      this.props.closeSelectPerfil({
        user, selectPerfilAuditor: showAuditor,
        selectPerfilAtual: false
      });
      this.props.removeAllFilters()
      this.props.loading(false);
    });
  };

  render() {
    const { meusPerfils, perfilAtual } = this.state;
    const { selectPerfilAtual } = this.props;
    return (
      <div className={`box menu-auditor ${!selectPerfilAtual ? "fadeOut" : ""}`}>
        <div className="box-auditor">
          <h2>Como deseja acessar?</h2>
          <p>
            Selecione abaixo o perfil que deseja acessar o Sasc Conect:
          </p>
          <label>Perfil</label>
          <SelectInput
            className=""
            name={"setor"}
            value={perfilAtual}
            options={meusPerfils}
            onChange={this.handleChange}
            returnFullObject={true}
            labelKey={"nome"}
            valueKey={"id"}
            placeholder="Selecione o Perfil"
          />
        </div>
        <div className="botao-box">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.setUserInSetor}
            disabled={!perfilAtual}
          >
            Confirmar
          </button>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { selectPerfilAtual } = state.authentication;
  return {
    selectPerfilAtual
  };
}

const mapDispatch = ({
  alert: { error, clear, success },
  authentication: { closeSelectPerfil },
  load: { loading },
  filtro: { removeAllFilters },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: msg => success(msg),
  closeSelectPerfil: payload => closeSelectPerfil(payload),
  loading: (load: boolean) => loading({ load }),
  removeAllFilters: () => removeAllFilters(),
});
export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(SelectPerfilAtual));
