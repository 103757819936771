//@flow
import { objectsConstants } from '../constants/objects.constants';
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const cooperadoService = {

    findByFilter,
    findTableColumns,
    ativarCooperado,
    inativarCooperado,
    ativarCooperadoByFilter,
    inativarCooperadoByFilter,
    exportToExcel,
    findSearchableColumns,
    find,
    doGet,
    addEstabelecimento,
    getFullCooperado,
    findByNome,
    findByNomeEstabelecimento,
    findByFilterAtendimento,
    findByNomeOuConselho,
    findByNomeOuConselhoEstabelecimento,
    findByCpf,
    findByEstabelecimento,
    findAll,
    doSave,
    findByNomeOuConselhoFilter,
    vincularCooperado
};

function doSave(entity: any) {
    return defaultService.doSave(urlsConstants.COOPERADO, entity);
}


function findByEstabelecimento(idEstabelecimento) {
    return defaultService.doGet(`${urlsConstants.COOPERADO}findByEstabelecimento/${idEstabelecimento}`);
}

function getFullCooperado(id) {
    return defaultService.doGet(`${urlsConstants.COOPERADO}getFullCooperado/${id}`);
}
function findByCpf(input) {
    return defaultService.doGet(`${urlsConstants.COOPERADO}findByCpf/${input}`);
}

function findByNome(input) {
    return defaultService.doGet(`${urlsConstants.COOPERADO}findByNome/${input}`);
}

function findByNomeEstabelecimento(parent,input) {
    return defaultService.doGet(`${urlsConstants.COOPERADO}findByNomeEstabelecimento/${parent}/${input}`);
}

function doGet(id) {
    return defaultService.doGet(`${urlsConstants.COOPERADO}${id}`);
}

function addEstabelecimento(entity: any) {

    return defaultService.doPost(urlsConstants.COOPERADO, entity);
}

function find(input) {
    return defaultService.doGet(`${urlsConstants.COOPERADO}find/${input}`);
}
function findByNomeOuConselho( input) {
    return defaultService.doGet(`${urlsConstants.COOPERADO}findByNomeOuConselho/${input}`);
}

function findByNomeOuConselhoEstabelecimento(parent,input) {
    return defaultService.doGet(`${urlsConstants.COOPERADO}findByNomeOuConselhoEstabelecimento/${parent}/${input}`);
}

function findByNomeOuConselhoFilter(parent,input){
    return defaultService.doGet(`${urlsConstants.COOPERADO}findByNomeOuConselho/${input}`);
}
function exportToExcel(filter, fields, labels) {
    return defaultService.exportToExcel('cooperado', filter, fields, labels);
}


function findTableColumns() {
    return defaultService.doGet(`${urlsConstants.COOPERADO}list/fields`);
}
function findSearchableColumns() {
    return defaultService.doGet(`${urlsConstants.COOPERADO}search/fields`);
}
function inativarCooperado(cooperadosIds,estabelecimentoId) {
    return defaultService.doPost(`${urlsConstants.COOPERADO}inativar`, {cooperadosIds,estabelecimentoId});
}

function vincularCooperado(cooperadosIds,estabelecimentoId) {
    return defaultService.doPost(`${urlsConstants.COOPERADO}vincularCooperado`, {cooperadosIds,estabelecimentoId});
}

function ativarCooperado(cooperadosIds,estabelecimentoId) {
    return defaultService.doPost(`${urlsConstants.COOPERADO}ativar`, {cooperadosIds,estabelecimentoId});
}

function ativarCooperadoByFilter(listFields) {
    return defaultService.doPost(`${urlsConstants.COOPERADO}ativarByFilter`, {
        pageSize: objectsConstants.PAGE_MAX,
        page: 0,
        listFields
    })
}
function findByFilterAtendimento(filter, input) {
    filter.descricaoFiltro = input;
    return defaultService.doPost(`${urlsConstants.ATENDIMENTO}getCooperadoByFiltroAtendimento`, filter);

}
function inativarCooperadoByFilter(listFields) {
    return defaultService.doPost(`${urlsConstants.COOPERADO}inativarByFilter`, {
        pageSize: objectsConstants.PAGE_MAX,
        page: 0,
        listFields
    })
}

function findAll(){
    return defaultService.doGet(`${urlsConstants.COOPERADO}findAll`)
}

function findByFilter(
    options: Options,
    filter: any
): Promise<Array<>> {
    return defaultService.findByFiltro(
        `${urlsConstants.COOPERADO}list`,
        options,
        filter
    );
}

