import * as _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { objectsConstants } from "../../constants/objects.constants";
import Datatable from "../../sascComponents/datagrid/Datatable";
import { convenioService } from "../../services/convenio.service";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import ConveniosTopo from "./ConveniosTopo";
class ConveniosList extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        let initFilter = [{ path: "Convenio",orderBy:"ASC", fieldName: "status", fieldDescription: "Status", columnOrder: 2, searchableField: true, type: "ENUM", condition: { description: "=" } }];
        const extension = userService.getExtensionUser(this.props.user);
        if (extension) {
            initFilter.push({
                path: "Atendimento.estabelecimentoConvenio.estabelecimento",
                fieldName: "id",
                fieldDescription: "Id",
                columnOrder: 2,
                searchableField: true,
                value: extension
            });
        }
        this.state = {
            initFilter,
            qtdeAuditoria: 0,
            qtdePendente: 0,
            qtdeFaturamento: 0,
            qtdeGlosado: 0,
            qtdeRepassado: 0,
            totalAtendimentos: 0,
            minDateAtendimento: null
        };
    }

    componentDidMount() {
        // atendimentoService.countStatus().then((response) => {
        //     let totalAtendimentos = 0;
        //     const qtdeAuditoria = response.data.totalAtendimentosAditoria;
        //     totalAtendimentos += qtdeAuditoria;
        //     const qtdePendente = response.data.totalAtendimentosPendente;
        //     totalAtendimentos += qtdePendente;
        //     const qtdeFaturamento = response.data.totalAtendimentosFaturamento;
        //     totalAtendimentos += qtdeFaturamento;
        //     const qtdeGlosado = response.data.totalAtendimentosGlosado;
        //     totalAtendimentos += qtdeGlosado;
        //     const qtdeRepassado = response.data.totalAtendimentosRepassado;
        //     totalAtendimentos += qtdeRepassado;
        //     const minDateAtendimento = response.data.minDateAtendimento;
        //     this.setState({ qtdeAuditoria, qtdePendente, qtdeFaturamento, qtdeGlosado, qtdeRepassado, totalAtendimentos, minDateAtendimento })
        // })
    }


    onChangeStatus = (status) => {
        let initFilter = _.cloneDeep(this.state.initFilter);
        let value;
        switch (status) {
            case objectsConstants.STATUS_ATENDIMENTO_INDEX.EM_AUDITORIA:
                value = objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA
                break;
            case objectsConstants.STATUS_ATENDIMENTO_INDEX.PENDENTE:
                value = objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE
                break;
            case objectsConstants.STATUS_ATENDIMENTO_INDEX.FATURAMENTO:
                value = objectsConstants.SITUACAO_ATENDIMENTO.FATURAMENTO
                break;
            case objectsConstants.STATUS_ATENDIMENTO_INDEX.GLOSADO:
                value = objectsConstants.SITUACAO_ATENDIMENTO.GLOSADO
                break;
            case objectsConstants.STATUS_ATENDIMENTO_INDEX.REPASSADO:
                value = objectsConstants.SITUACAO_ATENDIMENTO.REPASSADO
                break;
            default:
                value = null;

        }
        initFilter[0].value = value;
        this.setState({ initFilter }, () => {
            this.dataTable.initData();
        })
    }
    handleRowClick = (fieldName, rowData) => {
        this.props.history.push({
            pathname: "/convenios/view/" + rowData.id,
            state: { entity: rowData }
        });
    };

    render() {
        //const { permissions } = this.props;
        return <React.Fragment>
            <div className="container-fluid container-com-topo">
                <TopoTitleComponente
                    mainTitle="Convênios"
                    subTitle=" "
                    canBack={false}
                />
                <ConveniosTopo />

                <hr className="text-center w-75"></hr>

                <div className="background-lines">
                    <div className="bg-riscos"></div>
                </div>

                <div className="container-fluid container-com-topo mb-sm-3"> </div>
                <Datatable
                    showCheckboxColumn={true}
                    onRowClick={this.handleRowClick}
                    filter={this.state.initFilter}
                    idRow={"id"}
                    columns={[{ "path": "Convenio", "fieldName": "nome", "onlyNumber": false, "fieldDescription": "Nome", "type": "STRING" }, { "path": "Convenio", "fieldName": "prazoFaturamento", "onlyNumber": false, "fieldDescription": "Prazo para faturamento no mês", "type": "DATE" }, { "path": "Convenio", "fieldName": "dataEnvio", "onlyNumber": false, "fieldDescription": "Data de envio", "type": "DATE" }, { "path": "Convenio", "fieldName": "status", "onlyNumber": false, "fieldDescription": "Status", "type": "ENUM", "enumListValues": [{ "name": "INATIVO", "description": "Inativo" }, { "name": "ATIVO", "description": "Ativo" }] }]}
                    classHeader={[
                        "headerLeft",
                    ]}
                    classColumn={[
                        "colunaGra",
                    ]}
                    service={convenioService}
                    name={"Convênio(s)"}
                    showAllColumnsFilter={false}
                //ActionLine={LinhaAcaoAtivarInativarConvenios}
                />
            </div>
        </React.Fragment>

    }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
    showMenu: () => showMenu(),
    hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
    const { loggedIn, user, permissions } = state.authentication;
    const { alert } = state;

    return {
        loggedIn,
        alert,
        user,
        permissions
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(ConveniosList));
