//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";

type Props = {
  title: string,
  className: string,
  type: string,
  onSubmit: any,
  disabled: boolean,
  child?: any
};

class BtnEncaminhamentoEmMassa extends PureComponent<Props> {
  render() {
    return (
        <button
            type="button"
            className="btn btn-light btDefaultGrid d-none d-lg-block"
            onClick={this.props.toogleProtocol}
        >
          <span className="icon-btn-encaminhar"></span>
          Encaminhamento em massa
        </button>
    );
  }
}

function mapStateToProps(state) {
  const {   permissions } = state.authentication;

  return {
    permissions
  };
}

export default connect(
    mapStateToProps

)(withRouter(BtnEncaminhamentoEmMassa));
