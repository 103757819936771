// @flow

import React from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import SelectInput from "../../components/Inputs/SelectInput";


type Props = {
  label: string,
  name: string,
  value: any,
  onChange: any,
  returnFullObject: boolean,
  messageValidate?: any,
  disabled?: boolean,
  size?: number,
  required: boolean,
  service: any
};

type State = {
  options: Array<any>
};


class BuscaAtendimentoInput extends React.PureComponent<Props, State> {
  _isMounted = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      options: []
    };
  }  

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {...otherProps}=this.props;
    return (
      <SelectInput        
        components={{ DropdownIndicator }}
        {...otherProps}       
      />
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(BuscaAtendimentoInput);

const DropdownIndicator = props => {
  const {        
    innerProps: { ref, ...restInnerProps },hasValue
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
    >
      <div
        // style={{ padding: "0px 5px" }}
        onMouseDown={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        className="input-group-append"
      >
        <Button
                  className={`btn btn-input ${(props.hasValue)&&"verde-destaque"}`}
                  disabled={!props.hasValue}
                  onClick={props.selectProps.onClickDropDown}
                >
                  OK
                </Button>
       
      </div>
    </div>
  );
};


