//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotificacaoEdit from './NotificacaoEdit';
import NotificacaoList from './NotificacaoList';
import NotificacaoView from './NotificacaoView';
export default class NotificacoesConsultorios extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/notificacoesConsultorios" component={NotificacaoList} exact />
          <Route path="/notificacoesConsultorios/nova" component={NotificacaoEdit} />
          <Route path="/notificacoesConsultorios/edit/:id" component={NotificacaoEdit} />
          <Route path="/notificacoesConsultorios/view/:id" component={NotificacaoView} />
        </Switch>
      </React.Fragment>
    );
  }
}