//@flow
import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";
import {objectsConstants} from "../constants/objects.constants";

export const procedimentoService = {
  find,
  findByCodigoConvenioTipoGuia
};



function find(codigo) {
  return defaultService.doGet(`${urlsConstants.PROCEDIMENTO}${codigo}`);
}

function findByCodigoConvenioTipoGuia(parent,codigo) {
  const {convenio,tipoGuia} = parent;
if((!convenio||!convenio.id)||!tipoGuia){
    return defaultService.doGet(`${urlsConstants.PROCEDIMENTO}${codigo}`);
}

let tipoGuiaID;
objectsConstants.TIPOS_GUIA_REGRA_CONVENIO.map(tipoGuiaRegra =>{
    if(tipoGuia === tipoGuiaRegra.label){
      tipoGuiaID = tipoGuiaRegra.id
    }
  })
  let possuiProcedimentosPermitidos = false;
  if(convenio && convenio.listaRegrasSenhaConvenio){ 
  convenio.listaRegrasSenhaConvenio.map(regra => {
    if(regra.tipoGuia.id === tipoGuiaID){
      if(regra.procedimentosPermitidosTipoGuia && regra.procedimentosPermitidosTipoGuia.length > 0){
        possuiProcedimentosPermitidos = true;
      }
    }
  });
}

  return defaultService.doGet(`${urlsConstants.PROCEDIMENTO}${codigo}/${convenio.id}/${tipoGuiaID}/${possuiProcedimentosPermitidos}`);
}