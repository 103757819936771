//@flow
import { AbilityBuilder } from '@casl/ability';
import { objectsConstants } from '../constants/objects.constants';

const DEFAULT = AbilityBuilder.define(can => {});

export const ABILITIES = {
  DEFAULT,

};
