//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const ErrorImportacaoNotificationService = {

  findByFilter,
  saveNewCnpjConvenio,
  saveNewCnpjEstabelecimento,
  saveNewCpfCooperado,
  buscarErrosIntegracaoAtendimentos
};

function saveNewCnpjConvenio(values, id) {
  return defaultService.doPost(`${urlsConstants.ERROR_NOTIFICACAO}saveNewCnpjConvenio/${id}`, values)
}
function saveNewCnpjEstabelecimento(values, id) {
  return defaultService.doPost(`${urlsConstants.ERROR_NOTIFICACAO}saveNewCnpjEstabelecimento/${id}`, values)
}

function saveNewCpfCooperado(values, id) {
  return defaultService.doPost(`${urlsConstants.ERROR_NOTIFICACAO}saveNewCpfCooperado/${id}`, values)
}

function findByFilter() {
  return defaultService.doPost(`${urlsConstants.ERROR_NOTIFICACAO}findNotResolved`);
}

function buscarErrosIntegracaoAtendimentos(id) {
  return defaultService.doPost(`${urlsConstants.ERROR_NOTIFICACAO}buscarErrosIntegracaoAtendimentos/${id}`);
}
