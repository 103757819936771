import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";
import { objectsConstants } from "../constants/objects.constants";

export const campoAtendimentoService = {
    find
}

function find(tipoGuia,campoAtendimento) {
    let data = defaultService.doPost(`${urlsConstants.CAMPOATENDIMENTO}searchCampoAtendimento`,{
        tipoGuia : tipoGuia,
        campoAtendimento: campoAtendimento
    });
    return data;
}