import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup } from "../../../components/FormGroup";
import FormSelectInput from "../../../components/Inputs/FormSelectInput";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import SelectInput from "../../../components/Inputs/SelectInput";
import { objectsConstants } from "../../../constants/objects.constants";
import { pacoteExameService } from "../../../services/pacoteExame.service";
import { procedimentoService } from "../../../services/procedimento.service";
import { userService } from "../../../services/user.service";

class DadosExamesSolicitadosGuiaForm extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
    }

    IdsCooperadosAtendimento = (atendimento) => {
        let ids = []
        if (atendimento.cooperado && atendimento.cooperado.id) {
            ids.push(atendimento.cooperado.id)
        }
        for (let i = 0; i < atendimento.identificacaoAtendimentoExecucao.length; i++) {
            if (atendimento.identificacaoAtendimentoExecucao[i].cooperado && atendimento.identificacaoAtendimentoExecucao[i].cooperado.id) {
                ids.push(atendimento.identificacaoAtendimentoExecucao[i].cooperado.id)
            }
        }
        return ids;
    }

    sendToStorange = (values) => {
        try {
          values.rascunho && localStorage.setItem('rascunho', JSON.stringify(values))
        } catch (error) {
          if (error instanceof DOMException && (
            error.code === 22 ||
            error.code === 1014 ||
            error.name === 'QuotaExceededError' ||
            error.name === 'NS_ERROR_DOM_QUOTA_REACHED'
          )) {
            console.error('Limite de armazenamento local atingido');
          } else {
            console.error('Erro ao salvar no armazenamento local:', error.message);
          }
        }
      }

    render() {
        const {
            permissions,
            setFieldValue,
            values,
            handleChange,
            errors,
            viewMode
        } = this.props;
        if (values.identificacaoAtendimentoExecucao == null || values.identificacaoAtendimentoExecucao.length == 0) {
            setFieldValue("identificacaoAtendimentoExecucao", objectsConstants.INIT_IDENTIFICACAO_ATENDIMENTO_EXECUCAO);
        }
        return (
            <React.Fragment>
                <div className="row section-form">
                    <h6 className="w-100">
                        Exames solicitados
                    </h6>
                    {!viewMode && (<> {userService.isCooperado(this.props.user) && <FormGroup className="">
                        <InputViewEdit
                            label={"Pacote(s) de exame(s)"}
                            component={FormSelectInput}
                            viewMode={viewMode}
                            multi={true}
                            isMulti={true}
                            onChange={(name, date) => {
                                setFieldValue(name, date);

                                let examesList = [];
                                let oldExames = values.exames ? values.exames : [];
                                let oldPacotes = values.pacotes ? values.pacotes : [];

                                if (!values.pacotes) {
                                    let ve = this.props.atendimento.exames;

                                    ve && ve.map((item) => {
                                        examesList.push(item);
                                    })
                                }

                                oldPacotes && oldPacotes.map((oldP) => {
                                    oldP.exames.map((eOld) => {
                                        oldExames = oldExames.filter(objeto => objeto.id !== eOld.id);
                                    })
                                })

                                date && date.map((e) => {
                                    e.exames.map((item) => {
                                        examesList.push(item);
                                    })
                                })

                                oldExames && oldExames.map((item) => {
                                    examesList.push(item);
                                })

                                const arrUniq = [...new Map(examesList.map(v => [v.id, v])).values()]

                                setFieldValue(`exames`, arrUniq)
                            }}
                            onBlur={() => this.sendToStorange(values)}
                            required={false}
                            name={"pacotes"}
                            placeholder="Selecione o(s) pacote(s) de exame(s)"
                            type={"text"}
                            value={values.pacotes}
                            defaultValue={values.pacotes ? values.pacotes.map((e, index) => {
                                let value = e.nomePacote;
                                if (index > 0) {
                                    value = ', ' + value
                                }
                                return value;
                            }) : ''}
                            clearable={true}
                            labelKey={"nomePacote"}
                            valueKey={"nomePacote"}
                            returnFullObject={true}
                            parent={this.IdsCooperadosAtendimento(values)}
                            service={pacoteExameService.findByCooperado}
                            erroMensagem={errors.pacotes}
                        />
                    </FormGroup>}
                    <FormGroup className="">
                        <InputViewEdit
                            label={"Código(s) de exame(s)"}
                            component={SelectInput}
                            viewMode={viewMode || (!viewMode && !userService.isCooperado(this.props.user))}
                            multi={true}
                            isMulti={true}
                            onChange={(name, date) => {
                                setFieldValue(name, date);
                            }}
                            onBlur={() => this.sendToStorange(values)}
                            required={false}
                            name={"exames"}
                            type={"text"}
                            value={values.exames}
                            defaultValue={values.exames ? values.exames.map((e, index) => {
                                let value = e.codigo;
                                if (index > 0) {
                                    value = ', ' + value
                                }
                                return value;
                            }) : ''}
                            clearable={true}
                            labelKey={"codigo"}
                            valueKey={"codigo"}
                            returnFullObject={true}
                            onFetchData={procedimentoService.find}
                            erroMensagem={errors.exames}
                        />
                    </FormGroup></>)}
                    <FormGroup className="">
                        <InputViewEdit
                            label={"Descrição do(s) exame(s)"}
                            component={SelectInput}
                            viewMode={viewMode || (!viewMode && !userService.isCooperado(this.props.user))}
                            multi={true}
                            isMulti={true}
                            onChange={(name, date) => {
                                setFieldValue(name, date);
                            }}
                            onBlur={() => this.sendToStorange(values)}
                            required={false}
                            name={"exames"}
                            type={"text"}
                            value={values.exames}
                            defaultValue={values.exames ? values.exames.map((e, index) => {
                                let value = e.descricao;
                                if (index > 0) {
                                    value = ', ' + value
                                }
                                return value;
                            }) : ''}
                            clearable={true}
                            labelKey={"descricao"}
                            valueKey={"descricao"}
                            returnFullObject={true}
                            onFetchData={procedimentoService.find}
                            erroMensagem={errors.exames}
                        />
                    </FormGroup>


                </div>
            </React.Fragment>
        );
    }
}

const mapDispatch = ({ menu: { showMenu, hideMenu }, alert: { info, clear }, }) => ({
    info: msg => info(msg),
    clear: () => clear(),
    showMenu: () => showMenu(),
    hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
    const { loggedIn, user, permissions } = state.authentication;
    const { alert } = state;

    return {
        loggedIn,
        alert,
        user,
        permissions
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(DadosExamesSolicitadosGuiaForm));
