import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {FormGroup} from "../../../components/FormGroup";
import DatePickerInput from "../../../components/Inputs/DatePickerInput";
import * as _ from "lodash";
import {dateHelper} from "../../../helpers/date.helper";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import SelectInput from "../../../components/Inputs/SelectInput";
import {objectsConstants} from "../../../constants/objects.constants";
import { convenioService } from "../../../services/convenio.service";
import ConvenioFormSelect from "../../../sascComponents/convenios/ConvenioFormSelect";


class FiltroRelatorioMonitoramentoMovimentacaoGuia extends PureComponent<Props, State> {
    constructor(props) {
        super(props);

    }

    render() {
        const {values,errors,setFieldValue} = this.props;
        return (
            <React.Fragment>
                <div className={'d-flex flex-fill flex-row align-items-center justify-content-around borda-separacao'}>
                    <FormGroup className="col-3">
                        <InputViewEdit
                            label={"Data início"}
                            component={DatePickerInput}
                            onChange={(name, date) => {
                                setFieldValue(name, date);
                            }}
                            erroMensagem={
                                _.get(errors, `dataInicio`)
                            }
                            defaultValue={
                                values.dataInicio
                                    ? dateHelper.format(values.dataInicio)
                                    : "--"
                            }
                            mask={"99/99/9999"}
                            name={"dataInicio"}
                            id={"dataInicio"}
                            placeholder=""
                            required={true}
                            type={"text"}
                            viewMode={false}
                            value={values.dataInicio}
                        />
                    </FormGroup>
                    <FormGroup className="col-3">
                        <InputViewEdit
                            label={"Data fim"}
                            component={DatePickerInput}
                            onChange={(name, date) => {
                                setFieldValue(name, date);
                            }}
                            erroMensagem={
                                _.get(errors, `dataFim`)
                            }
                            defaultValue={
                                values.dataFim
                                    ? dateHelper.format(values.dataFim)
                                    : "--"
                            }
                            mask={"99/99/9999"}
                            name={"dataFim"}
                            id={"dataFim"}
                            placeholder=""
                            required={true}
                            type={"text"}
                            viewMode={false}
                            value={values.dataFim}
                        />
                    </FormGroup>
                    <FormGroup className="col-3">
                    <ConvenioFormSelect
                        label={"Convênio"}
                        required={false}
                        placeholder="Selecione um convênio"
                        component={ConvenioFormSelect}
                        sortKey={"nome"}
                        service={convenioService.findAll}
                        className={"destaque"}
                        name={"convenio"}
                        returnFullObject={false}
                        searchable={true}
                        labelKey={"nome"}
                        valueKey={"id"}
                        value={values.convenio}
                        parent={values}
                        onChange={(name, value) => {
                            setFieldValue(name, value);
                        }}
            />
                    </FormGroup>
                    <FormGroup className="col-3">
                    <SelectInput
                        required={false}
                        label={"Tipo de guia"}
                        className=""
                        name={"tipoGuia"}
                        value={values.tipoGuia}
                        options={
                                 _.orderBy(objectsConstants.TIPOS_GUIA_RELATORIO, ['name'],
                                ['asc'])
                        }
                        onChange={(name, value) => {
                            setFieldValue(name, value);
                        }}
                        returnFullObject={false}
                        labelKey={"name"}
                        valueKey={"id"}
                        placeholder="Tipo de guia de atendimento"
                    />
                    </FormGroup>

                </div>

            </React.Fragment>
        );
    }
}

const mapDispatch = ({ filtro: { toogleFiltro, removeFilter }, alert: { success, error }, load: { loading } }) => ({
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    success: msg => success(msg),
    toogleFiltro: () => toogleFiltro(),
    removeFilter: (filterRemove) => removeFilter({ filterRemove })
});

function mapStateToProps(state) {
    const { showMenu } = state.filtro;
    const { permissions } = state.authentication;
    const { filterRemove } = state.filtro;

    return {
        showMenu,
        permissions,
        filterRemove
    };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(FiltroRelatorioMonitoramentoMovimentacaoGuia));
