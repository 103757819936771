//@flow
import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";

export const cidService = {
  find,
  findByDescricao  
};



function find(codigo) {
  return defaultService.doGet(`${urlsConstants.CID}${codigo}`);
}
function findByDescricao(descricao) {
  return defaultService.doGet(`${urlsConstants.CID}findByDescricao/${descricao}`);
}