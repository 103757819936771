import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SelectInput from "../../components/Inputs/SelectInput";
import { objectsConstants, cooperativaID } from "../../constants/objects.constants";
import BeneficiarioFilterSelectInput from "../../sascComponents/filtroAtendimento/BeneficiarioFilterSelectInput";
import ClinicaFilterSelectInput from "../../sascComponents/filtroAtendimento/ClinicaFilterSelectInput";
import ConvenioFilterSelectInput from "../../sascComponents/filtroAtendimento/ConvenioFilterSelectInput";
import CooperadoFilterSelectInput from "../../sascComponents/filtroAtendimento/CooperadoFilterSelectInput";
import EvolucaoClinicaFilterSelectInput from "../../sascComponents/filtroAtendimento/EvolucaoClinicaFilterSelectInput";
import NumeroAtendimentoFilterSelectInput from "../../sascComponents/filtroAtendimento/NumeroAtendimentoFilterSelectInput";
import NumeroGuiaFilterSelectInput from "../../sascComponents/filtroAtendimento/NumeroGuiaFilterSelectInput";
import StatusEncaminhamentoFilterSelectInput from "../../sascComponents/filtroAtendimento/StatusEncaminhamentoFilterSelectInput";
import { userService } from "../../services/user.service";
const FILTROS_OPTIONS = [{ label: "Beneficiário", value: objectsConstants.BENEFICIARIO_FILTER },
{ label: "Convênio", value: objectsConstants.CONVENIO_FILTER },
{ label: "Consultório", value: objectsConstants.CONSULTORIO_FILTER },
{ label: "Cooperado", value: objectsConstants.COOPERADO_FILTER },
{ label: "Número da guia", value: objectsConstants.N_GUIA_FILTER },
{ label: "Número do atendimento", value: objectsConstants.N_ATENDIMENTO_FILTER },
{ label: "Status de encaminhamento", value: objectsConstants.STATUS_ENCAMINHAMENTO_FILTER },
{ label: "Evolução Clínica", value: objectsConstants.EVOLUCAO_CLINICA_FILTER }]
class FiltroAtendimento extends PureComponent<Props, State> {
    constructor(props) {
        super(props);

        this.state = { filter: null, value: null };
    }

    getFilterType() {
        let options = [];
        let filtros = [];
        if (userService.getCurrentUser().cooperativa && userService.getCurrentUser().cooperativa.id === cooperativaID.SANTACOOP.id) {
            filtros = FILTROS_OPTIONS;
        }else{
            filtros = FILTROS_OPTIONS.filter(filtro => filtro.label !== "Evolução Clínica");
        }
          
        for (let indexOption in filtros) {
            const filterOption = filtros[indexOption];
            if (!this.props.filter || !this.props.filter[filterOption.value] || this.state.filter === filterOption.value) {
                if (this.props.typefilter === "dataRecebimento" && filterOption.value === "statusEncaminhamento") {
                } else {
                    options.push(filterOption);
                }
            }
        }
        return options
    }
    handleChange = (name, value) => {
        let state = _.cloneDeep(this.state);
        const lastFilter = _.cloneDeep(this.state.filter);
        state[name] = value;
        if (name !== 'value' && name !== lastFilter) {
            state['value'] = null;
        }
        this.setState(state, () => {
            this.props.onChangeFilter(this.state.filter, this.state.value, lastFilter);
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.filterRemove !== null && prevState.filter === nextProps.filterRemove) {
            nextProps.removeFilterList(nextProps.id, prevState.filter);
            nextProps.removeFilter(null);
        }
        if (nextProps.filterRemove !== null) {
            for (let i = 0; i < objectsConstants.DATA_FILTER.length; i++) {
                if (objectsConstants.DATA_FILTER[i].name === nextProps.filterRemove) {
                    nextProps.removeFilterList(nextProps.id, objectsConstants.DATA_FILTER[i].name);
                    nextProps.removeFilter(null);
                }
            }
        }

    }

    getFilterCombo = () => {
        if (this.state.filter) {
            switch (this.state.filter) {
                case objectsConstants.COOPERADO_FILTER:
                    return CooperadoFilterSelectInput;
                case objectsConstants.STATUS_ENCAMINHAMENTO_FILTER:
                    return StatusEncaminhamentoFilterSelectInput;
                case objectsConstants.BENEFICIARIO_FILTER:
                    return BeneficiarioFilterSelectInput;
                case objectsConstants.CONSULTORIO_FILTER:
                    return ClinicaFilterSelectInput;
                case objectsConstants.CONVENIO_FILTER:
                    return ConvenioFilterSelectInput;
                case objectsConstants.N_ATENDIMENTO_FILTER:
                    return NumeroAtendimentoFilterSelectInput;
                case objectsConstants.N_GUIA_FILTER:
                    return NumeroGuiaFilterSelectInput;
                case objectsConstants.EVOLUCAO_CLINICA_FILTER:
                    return EvolucaoClinicaFilterSelectInput;
                default:
                    return false;

            }
        }
    }

    isMultiValidation = () => {
        if (this.state.filter) {
            switch (this.state.filter) {
                case objectsConstants.CONVENIO_FILTER:
                    return true;
                default:
                    return false;
            }
        }
        return false;
    }


    render() {
        const Filter = this.getFilterCombo();
        return (
            <React.Fragment>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <SelectInput closeMenuOnSelect={true} placeholder="Escolha uma opção" label={'O que deseja filtrar?'} name={'filter'} options={this.getFilterType()} value={this.state.filter} returnFullObject={false} onChange={this.handleChange} labelKey={'label'} valueKey={'value'} />
                    </div>
                    <div className={'col-12'}>
                        {this.state.filter && Filter && (
                            <Filter returnFullObject={true} className={'col-12'} filter={{}} id={`filter${this.state.filter}`} isMulti={this.isMultiValidation()} onChange={this.handleChange} name={'value'} value={this.state.value} />
                        )}

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapDispatch = ({ filtro: { toogleFiltro, removeFilter }, alert: { success, error }, load: { loading } }) => ({
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    success: msg => success(msg),
    toogleFiltro: () => toogleFiltro(),
    removeFilter: (filterRemove) => removeFilter({ filterRemove })
});

function mapStateToProps(state) {
    const { showMenu } = state.filtro;
    const { permissions } = state.authentication;
    const { filterRemove } = state.filtro;

    return {
        showMenu,
        permissions,
        filterRemove
    };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(FiltroAtendimento));
