import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import MensagemList from './MensagemList';
import MensagemEdit from './MensagemEdit';
import MensagemView from './MensagemView';

export default class Mensagem extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/mensagem" component={MensagemList} exact />
          <Route path="/mensagem/novo" component={MensagemEdit} exact />
          <Route path="/mensagem/edit/:id" component={MensagemEdit} />
          <Route path="/mensagem/view/:id" component={MensagemView} exact />

        </Switch>
      </React.Fragment>
    );
  }
}