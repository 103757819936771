import React, { PureComponent } from "react";
import Avatar from "react-avatar";
import { connect } from "react-redux";
import { formatterHelper } from "../../helpers/formatter.helper";
import { midiaService } from "../../services/midia.service";
import CropperView from "../../components/Imagen/CropperView";


// the :export directive is the magic sauce for webpack


let fileInput;

class AvatarEstabelecimento extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {
      image: null,
      dataUrl: null
    };
  }

  getColorDefault = () => {
      return 'avatarPerfil_Consultorio';
  };






  render() {

    return (
      <div
        className={`${this.props.className}`}

      >

        {this.props.children}

        <Avatar
          maxInitials={2}
          name={formatterHelper.getNameAndLastName(this.props.estabelecimento.nome)}
          unstyled={true}
          className={`avatarCustom ${
            this.props.className
            } ${this.getColorDefault()}`}
          size={100}

        />
      </div>
    );
  }
}
export default connect()(AvatarEstabelecimento);
