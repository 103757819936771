import React, { PureComponent } from "react";
import _, { values } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardFooter, CardHeader, FormGroup } from "reactstrap";
import FormInput from "../../components/Inputs/FormInput";
import FormSelectInput from "../../components/Inputs/FormSelectInput";
import FormTextCKEditor from "../../components/Inputs/FormTextCKEditor";
import InputViewEdit from "../../components/Inputs/InputViewEdit";
import SelectInput from "../../components/Inputs/SelectInput";
import { objectsConstants } from "../../constants/objects.constants";
import { cooperadoService } from "../../services/cooperado.service";
import { especialidadesService } from "../../services/especialidades.service";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { ToolTipFeedBack } from "../../components/Utils/ToolTipFeedBack";
import ImageInput from "../../components/Inputs/ImageInput";
import logoDefault from '../../img/logoDefault.png';
import { mensagemService } from "../../services/mensagem.service";
import PhoneInput from "../../components/Inputs/PhoneInput";
import { midiaService } from "../../services/midia.service";
import { userService } from "../../services/user.service";

class MensagemForm extends PureComponent<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            listaHistoricos: [],
            dataUrl: logoDefault
        };
    }

    insertAtCaret = (event, areaId, text, values, setFieldValue) => {
        event.preventDefault();
        let position;
        let newText;
        if (values[areaId] && values[areaId].length >= 4) {
            position = values[areaId].length - 4;

            newText = [
                values[areaId].slice(0, position),
                text,
                values[areaId].slice(position),
            ].join('');
        } else {
            newText = text;
        }

        setFieldValue(areaId, newText);
    };

    limpaCampo = (name, values) => {
        if (name === 'profissional')
            values.especialidades = null;
        if (name === 'especialidade')
            values.cooperados = null;
    }

    goToEdit = () => {
        this.props.history.push({
            pathname: "/mensagem/edit/" + this.props.values.id,
            state: { entity: this.props.values }
        });
    };

    excluirMensagem = () => {
        this.props.loading(true);
        let id = [this.props.values.id];
        mensagemService.doDelete(id).then(
            () => {
              this.props.success({
                message: `Mensagem excluida sucesso!`
              });
              this.props.history.push({
                pathname: "/mensagem"
            });
              this.props.loading(false);
            },
            erros => {
              console.error(erros.response);
              this.props.error({
                message: "Não foi possível excluir a Mensagem."
              });
              this.props.loading(false);
            }
          );
    }

    imageUrl = (valuesAttr) => {
        if (valuesAttr && valuesAttr.id) {
          return midiaService.url(valuesAttr.id);
        } else {
          if (valuesAttr) {
            return valuesAttr.midiaConteudoVO.foto64;
          } else {
            return logoDefault;
          }
        }
      };
    
    render() {
        const {
            values,
            setFieldValue,
            errors,
            handleChange,
            isSubmitting,
            handleSubmit,
            handleCancel,
            viewMode,
            validateForm,
            setValues,
            mensagemTipo
        } = this.props;

        if(values && values.id && values.id) {
            if(values.cooperados && values.cooperados.length > 0) {
                values.tipo = 'cooperado';
            } else if(values.especialidades && values.especialidades.length > 0) {
                values.tipo = 'especialidade';
            }
        }
        return (
            <React.Fragment>
                <div className="item">
                    <Card className="cardCrud content-form">
                        <CardHeader>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="mr-sm-auto card-title">Dados</div>
                                {((!values.cooperativa_criador && userService.isCooperado(this.props.user)) || !userService.isCooperado(this.props.user))
                                && (<><div className="px-sm-3">
                                    {viewMode && (
                                        <button
                                            type="button"
                                            className="btn btn-light"
                                            onClick={this.goToEdit}
                                        >
                                            <span className="icon-btn-editar"></span>Editar
                                        </button>
                                    )}
                                </div>
                                <div className="px-sm-3">
                                    {viewMode && (
                                        <button type="button" className="btn btn-secondary" onClick={() => {
                                            ConfirmAcationService.confirmMsg(
                                                this.excluirMensagem,
                                                "Excluir Mensagen",
                                                "Você confirma que deseja excluir a mensagem",
                                                null,
                                                "Cancelar",
                                                "Excluir",
                                                objectsConstants.TYPE_WARNING
                                            );
                                        }}>
                                            <span className="icon-btn-fechar"></span>
                                            Excluir mensagem
                                        </button>
                                    )}
                                </div></>)}
                            </div>
                        </CardHeader>
                        {!userService.isCooperado(this.props.user) && <CardBody className={"form-inside viewMode"}>
                            <div className="row section-form">
                                <h6 className="w-100">Remetentes da Mensagem</h6>
                            </div>
                            { !viewMode && (
                            <div className="row section-form">
                                <FormGroup>
                                    <InputViewEdit
                                        required={true}
                                        component={SelectInput}
                                        className=""
                                        label={"Selecione os profissionais ou as especialidades para mensagem"}
                                        name={"tipo"}
                                        id={"tipo"}
                                        value={values.tipo}
                                        defaultValue={
                                            viewMode && values.tipo && values.tipo.id ?
                                                objectsConstants.MENSAGEM_TIPO[values.tipo.id].name : true
                                        }
                                        multi={false}
                                        isMulti={false}
                                        searchable={true}
                                        options={objectsConstants.MENSAGEM_TIPO}
                                        onChange={(name, value) => {
                                            setFieldValue(name, value);
                                            this.limpaCampo(value, values);
                                        }}
                                        returnFullObject={false}
                                        labelKey={"name"}
                                        valueKey={"label"}
                                        placeholder="Tipo de guia de atendimento"
                                        viewMode={viewMode}
                                    />
                                </FormGroup>
                            </div>
    )}
                            {values.tipo && values.tipo === 'cooperado' &&
                                (<div className="row section-form">
                                    <FormGroup className="">
                                        <InputViewEdit
                                            label={"Nome do profissional"}
                                            required={true}
                                            component={SelectInput}
                                            onFetchData={cooperadoService.findByNome}
                                            //onFetchData={cooperadoService.findByNome}
                                            className={"destaque"}
                                            name={"cooperados"}
                                            id={"cooperados"}
                                            erroMensagem={
                                                _.get(errors, `cooperados`)
                                            }
                                            returnFullObject={true}
                                            searchable={true}
                                            defaultValue={
                                                values.cooperados ? values.cooperados.map((coop, index) => {
                                                    let value = coop.nome;
                                                    if (index > 0) {
                                                        value = ', ' + value
                                                    }
                                                    return value;
                                                }) : ''
                                            }
                                            labelKey={"nome"}
                                            valueKey={"id"}
                                            value={values.cooperados}
                                            viewMode={viewMode}
                                            onChange={(name, value) => {
                                                setFieldValue(name, value);
                                            }}
                                            onBlur={this.verificaRetorno}
                                            alertMensagem={values.convenio ? values.convenio.listRegraMensagemTipoGuia : null}
                                            multi={true}
                                            isMulti={true}
                                        />
                                    </FormGroup>
                                </div>
                                )}

                            {values.tipo && values.tipo === 'especialidade' && (
                                <div className="row section-form">
                                    <FormGroup className="">
                                        <InputViewEdit
                                            label={"Especialidade"}
                                            component={FormSelectInput}
                                            required={true}
                                            placeholder="Digite a Especialidade"
                                            service={especialidadesService.findAll}
                                            className={"destaque"}
                                            id={`especialidades`}
                                            name={`especialidades`}
                                            returnFullObject={true}
                                            multi={true}
                                            isMulti={true}
                                            erroMensagem={_.get(errors, `especialidades`)}
                                            searchable={true}
                                            labelKey={"nome"}
                                            valueKey={"id"}
                                            defaultValue={
                                                values.especialidades ? values.especialidades.map((esp, index) => {
                                                    let value = esp.nome;
                                                    if (index > 0) {
                                                        value = ', ' + value
                                                    }
                                                    return value;
                                                }) : ''
                                            }
                                            value={values.especialidades}
                                            viewMode={viewMode}
                                            onChange={(name, value) => {
                                                setFieldValue(name, value);
                                            }}
                                        />
                                    </FormGroup>


                                </div>


                            )}
                        </CardBody>}
                        {values.tipo && (
                            <>
                                <CardBody className={"form-inside viewMode"}>
                                    <div className="row section-form">
                                        <h6 className="w-100">Informações da Mensagem</h6>
                                    </div>
                                    <div className="row section-form">
                                        <FormGroup className="">
                                            <InputViewEdit
                                                component={FormInput}
                                                label={"Nome"}
                                                type="text"
                                                id="nome"
                                                name="nome"
                                                placeholder="Digite o nome"
                                                value={values.nome}
                                                onChange={(name, value) => setFieldValue(name, value)}
                                                noSize={true}
                                                erroMensagem={errors.nome}
                                                viewMode={viewMode}
                                                required={true}
                                            />
                                        </FormGroup>
                                        <FormGroup className="">
                                            <InputViewEdit
                                                className=""
                                                component={SelectInput}
                                                required={true}
                                                label={"Categoria"}
                                                name={"categoria"}
                                                id={"categoria"}
                                                value={values.categoria}
                                                defaultValue={
                                                    viewMode && values.categoria ?
                                                        objectsConstants.MENSAGEM_CATEGORIA.map((m, i) => {
                                                            return m.label === values.categoria ? m.name : true
                                                        }) : true
                                                }
                                                options={_.orderBy(objectsConstants.MENSAGEM_CATEGORIA, 'name', 'asc')}
                                                onChange={(name, value) => { setFieldValue(name, value) }}
                                                returnFullObject={false}
                                                labelKey={"name"}
                                                valueKey={"label"}
                                                placeholder="Selecione o tipo da categoria"
                                                viewMode={viewMode}
                                                multi={false}
                                                isMulti={false}
                                                erroMensagem={errors.categoria}
                                            />
                                        </FormGroup>
                                        <FormGroup className="">
                                            <InputViewEdit
                                                component={FormInput}
                                                label={"Descrição"}
                                                type="text"
                                                id="descricao"
                                                name="descricao"
                                                placeholder="Digite a descrição"
                                                value={values.descricao}
                                                onChange={(name, value) => setFieldValue(name, value)}
                                                noSize={true}
                                                erroMensagem={errors.descricao}
                                                viewMode={viewMode}
                                                required={false}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className="row section-form">
                                        <FormGroup className="W-10p">
                                            <label>
                                                Logo
                                            </label>
                                            <ImageInput
                                                hmaxWidth={120}
                                                maxHeight={120}
                                                height={120}
                                                required={false}
                                                onChange={
                                                    (imageVO) => {
                                                        setFieldValue('midia', imageVO)
                                                }}
                                                dataUrl={this.imageUrl(values.midia)}
                                                id="imagem"
                                                name="imagem"
                                                className=""
                                                readOnly={viewMode}
                                                viewMode={viewMode}
                                                erroMensagem={
                                                    _.get(errors, `imagem`)
                                                }
                                            />
                                        </FormGroup>
                                        <FormGroup className="">
                                            <InputViewEdit
                                                component={FormInput}
                                                label={"Informações adicionais(coluna 1)"}
                                                type="text"
                                                id="infoadicional1"
                                                name="infoadicional1"
                                                placeholder="Digite as informações adicionais"
                                                value={values.infoadicional1}
                                                onChange={(name, value) => setFieldValue(name, value)}
                                                noSize={true}
                                                erroMensagem={errors.infoadicional1}
                                                viewMode={viewMode}
                                                required={false}
                                            />
                                        </FormGroup>
                                        <FormGroup className="">
                                            <InputViewEdit
                                                component={FormInput}
                                                label={"Informações adicionais(coluna 2)"}
                                                type="text"
                                                id="infoadicional2"
                                                name="infoadicional2"
                                                placeholder="Digite as informações adicionais"
                                                value={values.infoadicional2}
                                                onChange={(name, value) => setFieldValue(name, value)}
                                                noSize={true}
                                                erroMensagem={errors.infoadicional2}
                                                viewMode={viewMode}
                                                required={false}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="row section-form">
                                        <FormGroup className="w-20">
                                            <InputViewEdit
                                                label={"Modelo da Mensagem"}
                                                component={FormTextCKEditor}
                                                onChange={(name, value) => {
                                                    setFieldValue(name, value);
                                                }}
                                                viewMode={viewMode}
                                                required={false}
                                                name={`textoCorpo`}
                                                placeholder="Digite as instruções para atendimento"
                                                type={"text"}
                                                value={values.textoCorpo}
                                                clearable={true}
                                                defaultValue={<div
                                                    dangerouslySetInnerHTML={{ __html: values.textoCorpo ? values.textoCorpo : '' }}></div>}
                                                id={`textoCorpo`}
                                                erroMensagem={
                                                    _.get(errors, `textoCorpo`)
                                                }
                                            />
                                            <div className="row section-form">
                                        {!viewMode && (
                                            <div className="offset-2 col-10 mt-1">
                                                <div className="my-1">
                                                    <b>Adicionar variáveis</b>
                                                </div>
                                                <button
                                                    className="btn btn-primary mr-1 my-1"
                                                    onClick={(event) =>
                                                        this.insertAtCaret(
                                                            event,
                                                            'textoCorpo',
                                                            ' _NOME_DO_PACIENTE_ ',
                                                            values,
                                                            setFieldValue
                                                        )
                                                    }
                                                >
                                                    Nome do paciente
                                                </button>
                                                {/*<button
                                                    className="btn btn-primary mr-1 my-1"
                                                    onClick={(event) =>
                                                        this.insertAtCaret(
                                                            event,
                                                            'textoCorpo',
                                                            ' _ENDERECO_DO_PACIENTE_ ',
                                                            values,
                                                            setFieldValue
                                                        )
                                                    }
                                                >
                                                    Endereço do paciente
                                                </button>*/}
                                                <button
                                                    className="btn btn-primary mr-1 my-1"
                                                    onClick={(event) =>
                                                        this.insertAtCaret(
                                                            event,
                                                            'textoCorpo',
                                                            ' _NOME_DO_PROFISSIONAL_ ',
                                                            values,
                                                            setFieldValue
                                                        )
                                                    }
                                                >
                                                    Nome do profissional
                                                </button>
                                                <button
                                                    className="btn btn-primary mr-1 my-1"
                                                    onClick={(event) =>
                                                        this.insertAtCaret(
                                                            event,
                                                            'textoCorpo',
                                                            ' _NUMERO_DO_CONSELHO_ ',
                                                            values,
                                                            setFieldValue
                                                        )
                                                    }
                                                >
                                                    Número do conselho
                                                </button>
                                                <button
                                                    className="btn btn-primary mr-1 my-1"
                                                    onClick={(event) =>
                                                        this.insertAtCaret(
                                                            event,
                                                            'textoCorpo',
                                                            ' _DATA_DA_CONSULTA_',
                                                            values,
                                                            setFieldValue
                                                        )
                                                    }
                                                >
                                                    Data da consulta/exame
                                                </button>
                                                <button
                                                    className="btn btn-primary mr-1 my-1"
                                                    onClick={(event) =>
                                                        this.insertAtCaret(
                                                            event,
                                                            'textoCorpo',
                                                            ' _IDADE_DO_PACIENTE_ ',
                                                            values,
                                                            setFieldValue
                                                        )
                                                    }
                                                >
                                                    Idade do paciente
                                                </button>
                                            </div>
                                        )}

                                    </div>
                                        </FormGroup>
                                    </div>
                                    
                                </CardBody>
                                <CardBody className={"form-inside viewMode"}>
                                    <div className="row section-form">
                                        {!viewMode && (
                                            <div className="form-group d-flex justify-content-end align-items-center">

                                                <button
                                                    type="button"
                                                    className="btn btn-secondary mr-2"
                                                    onClick={
                                                        () => ConfirmAcationService.confirmMsg(
                                                            handleCancel,
                                                            "Cancelar edição do mensagem de notificação de documentos",
                                                            "Todos os dados preenchidos serão perdidos ao cancelar a edição da mensagem de notificação de documentos.",
                                                            null,
                                                            "Cancelar",
                                                            "Confirmar",
                                                            objectsConstants.TYPE_WARNING
                                                        )
                                                    }
                                                >
                                                    <span className={`icon-btn-fechar`}></span>
                                                    {"Cancelar"}
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary "
                                                    onClick={handleSubmit}
                                                >
                                                    <span className={`icon-btn-check`}></span>
                                                    {"Salvar"}
                                                </button>

                                            </div>
                                        )}
                                    
                                    </div>
                                </CardBody>
                            </>
                        )}
                    </Card>
                </div>
            </React.Fragment >
        )
    }
}

const mapDispatch = ({
    alert: { success, error, clear, info },
    load: { loading }
}) => ({
    success: msg => success(msg),
    loading: (load: Boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear(),
    info: msg => info(msg)
});

function mapStateToProps(state) {
    const { user, permissions } = state.authentication;

    return {
        user,
        permissions
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(MensagemForm));