//@flow
import * as React from "react";
//import { connect } from "react-redux";
//import makeAnimated from "react-select/animated";
import { convenioService } from "../../services/convenio.service";
//import MultiSelect from "../../components/Inputs/MultiSelect";
import { components } from "react-select";
//import SelectInput from "../../components/Inputs/SelectInput";
import FormSelectInput from "../../components/Inputs/FormSelectInput";

type Props = {
  label: string,
  name: string,
  value: any,
  onChange: any,
  returnFullObject: boolean,
  messageValidate?: any,
  disabled?: boolean,
  size?: number,
  required: boolean,
  service: any
};

type State = {
  options: Array<any>
};

const Option = props => {
  return (
    <div className="checkbox control control--checkbox">
      <components.Option {...props} className="react-select__option">
        <span className="pl-4">{props.label}</span>
        <span className="pl-4">{props.status}</span>
        <div className="control__indicator"></div>
      </components.Option>
    </div>
  );
};
const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.nome}</span>
  </components.MultiValue>
);
//const animatedComponents = makeAnimated();

export default class ConvenioFormSelect extends React.PureComponent<Props, State> {
  _isMounted = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      options: []
    };

  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    if (this.props.parent.tipoGuia != prevProps.parent.tipoGuia) {
      convenioService.findAll(this.props.parent).then(response => {
        let options = response.data;
        if (this._isMounted) {
          this.setState({ options });
        }
      });
    }

  }

  componentDidMount() {
    this._isMounted = true;

    convenioService.findAll(this.props.parent).then(response => {
      let options = response.data;
      if (this._isMounted) {
        this.setState({ options });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  formatOptionLabel = ({ status,nome, sigla }) => (
    <div style={{ display: "flex", alignItems: "center" }}>      
      <div>{sigla}</div>
      {status.name === "INATIVO" && (
        <div
        className='pl-1'
          style={{
            color: "red"
          }}
        >
          - Inativo
        </div>
      )}
    </div>
  );

  render() {
    const { ...otherProps } = this.props;
    return (
      <FormSelectInput
        {...otherProps}
        formatOptionLabel={this.formatOptionLabel}
      />
    );
  }
}


