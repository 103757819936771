import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import {Input} from "reactstrap";
import {objectsConstants} from "../../constants/objects.constants";
import {ConfirmAcationService} from "../../services/ConfirmAcationService";
import {withRouter} from "react-router-dom";
import { avisosService } from '../../services/avisos.service';

class LinhaAcaoAtivarInativarAvisos extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    success = () =>{
        this.props.success({message: "Alterado com sucesso."});
        this.props.loading(false);
        if (this.props.initData) {
            this.props.initData();
        }
    }
    errorAtivar = () =>{
        this.props.success("Não foi possível ativar o aviso.");
        this.props.loading(false);
    }

    errorInativar = () =>{
        this.props.success("Não foi possível inativar o aviso.");
        this.props.loading(false);
    }


    ativarAviso = () =>{
        this.props.loading(true);
        if(this.props.checkedAllPages){
            const filter = this.props.getFilter();
            avisosService.ativarAvisoByFilter(filter).then(() => {
                this.success();
            }).catch(() => {
                this.errorAtivar();
            });
        }else {
            avisosService.ativarAviso(this.props.rowsCheckedValue.map((rowValue) => {
                return rowValue.id;
            })).then(() => {
                this.success();
            }).catch(() => {
               this.errorAtivar();
            });
        }
    }

    
    inativarAviso = () =>{
        this.props.loading(true);
        if(this.props.checkedAllPages){
            const filter = this.props.getFilter();
            avisosService.inativarAvisoByFilter(filter).then(() => {
                this.success();
            }).catch(() => {
                this.errorInativar();
            });
        }else {
            avisosService.inativarAviso(this.props.rowsCheckedValue.map((rowValue) => {
                return rowValue.id;
            })).then(() => {
                this.success();
            }).catch(() => {
                this.errorInativar();
            });
        }
    }



    render() {
        let statusAvisoAtivo = false;
        let statusAvisoInativo = false;
        this.props.rowsCheckedValue.map((rowValue)=>{
            if(rowValue.ativo){
                statusAvisoAtivo =true;
            }else{
                statusAvisoInativo = true;
            }
        })

        return <React.Fragment>

                        {!statusAvisoAtivo&&statusAvisoInativo&& (
                            <button type="button" className="btn btn-primary" onClick={() => {
                                ConfirmAcationService.confirmMsg(
                                    this.ativarAviso,
                                    "Ativar Avisos",
                                    "Você confirma que deseja ativar os avisos selecionados",
                                    null,
                                    "Cancelar",
                                    "Confirmar",
                                    objectsConstants.TYPE_WARNING
                                );
                            }}>
                                <span className="icon-btn-check"></span>
                                Ativar Aviso
                            </button>
                        )}
                        {statusAvisoAtivo&&!statusAvisoInativo&& (
                            <button type="button" className="btn btn-secondary"onClick={() => {
                                ConfirmAcationService.confirmMsg(
                                    this.inativarAviso,
                                     "Inativar Avisos",
                                    "Você confirma que deseja inativar os avisos selecionados",
                                    null,
                                    "Cancelar",
                                    "Confirmar",
                                    objectsConstants.TYPE_WARNING
                                );
                            }}>
                                <span className="icon-btn-fechar"></span>
                                Inativar Aviso
                            </button>
                        )}
                        {statusAvisoAtivo&&statusAvisoInativo && (<span className={'labelInfoAction'}>Existem dois status diferentes selecionados</span>)}
                </React.Fragment>;
    }


}


const mapDispatch = ({
    alert: { success, error, clear },
    load: { loading }
}) => ({
success: msg => success(msg),
loading: (load: boolean) => loading({ load }),
error: msg => error(msg),
clear: () => clear()
});

export default connect(
null,
mapDispatch,
null,
{ withRef: true }
)(withRouter(LinhaAcaoAtivarInativarAvisos));
