import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Collapse, FormGroup } from "reactstrap";
import { Formik } from "formik";
import _ from "lodash";
import { Can } from "@casl/react";
import FormSelectInput from "../../components/Inputs/FormSelectInput";
import { SubStatusService } from "../../services/SubStatus.service";
import { translate } from "../../helpers/message.helper";
import BtnLink from "../../components/Buttons/BtnLink";
import InputViewEdit from "../../components/Inputs/InputViewEdit";
import { objectsConstants } from "../../constants/objects.constants";
import { userService } from "../../services/user.service";

class BandeirasUsuarioForm extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            collapse: true,
            viewMode: false,
            listaHistoricos: [],
            //viewMode: true
        };
    }

    toggle = () => {
        this.setState(state => ({ collapse: !state.collapse }));
    };

    changeViewMode = e => {
        e.stopPropagation();
        this.setState({ viewMode: !this.state.viewMode });
    };

    confirmCancel = setValues => {
        this.setState({ viewMode: true, collapse: true }, () => {
            setValues(this.props.convenio);
        });
    };
    editMode = e => {
        e.stopPropagation();
        this.setState({ viewMode: false, collapse: true });
    };

    render() {
        return (
            <React.Fragment>
                <Formik
                    validationSchema={[{}]}
                    validateOnBlur={false}
                    validateOnChange={false}
                    enableReinitialize={true}
                    initialValues={this.props.values}
                    onSubmit={(values, actions) => {
                        this.props.loading(true);
                        userService.salvarBandeirinhas(values).then(
                            response => {
                                this.props.success({
                                    message: `Bandeirinhas salvas com sucesso!`
                                });

                                //_this.goToViewUser(values);
                            },
                            erros => {
                                console.error(erros.response);
                                this.props.error({
                                    message: "Não foi possível salvar as bandeirinhas."
                                });
                                try {
                                    let response = erros.response.data;
                                    if (response && response.messages) {
                                        for (var i = 0; i < response.messages.length; i++) {


                                            let erroItem = response.messages[i];
                                            if (erroItem.target === "FIELD") {


                                                actions.setFieldError(
                                                    erroItem.fieldName,
                                                    translate(erroItem.message.code)
                                                );
                                            } else {
                                                this.props.error({
                                                    message: translate(erroItem.message.code)
                                                });
                                            }
                                        }
                                    }
                                } catch (error) {
                                    console.error(error);
                                }

                                this.props.loading(false);
                            }
                        );
                        this.props.loading(false);
                        actions.setSubmitting(false);
                    }}
                    ref={form => {
                        this.formRef = form;
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        validateForm,
                        setValues
                    }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <div
                                className={`mt-4 accordion ${this.state.collapse ? "open" : ""
                                    }`}
                            >
                                <div className="card content-form cardGuia cardCrud">
                                    <div
                                        className="card-header d-flex align-items-center flex-column flex-sm-row b-bottom"
                                        id="headingOne"
                                        onClick={this.toggle}
                                    >
                                        <div className="flex-grow-1 d-flex align-items-center">
                                            <div className="p-1 status-guia">Bandeirinhas permitidas para usuário</div>
                                        </div>
                                        <div className="buttons-right ml-auto">
                                            {this.state.viewMode && (
                                                <Can
                                                    I={objectsConstants.ALTERAR}
                                                    a={objectsConstants.EDITAR_CONVENIO}
                                                    ability={this.props.permissions}
                                                >
                                                    <BtnLink
                                                        type={"button"}
                                                        className={""}
                                                        icone={"icon-btn-editar"}
                                                        title={"Editar"}
                                                        onSubmit={this.editMode}
                                                        disabled={false}
                                                    />
                                                </Can>
                                            )}
                                            {!this.state.viewMode && (
                                                <Can
                                                    I={objectsConstants.ALTERAR}
                                                    a={objectsConstants.EDITAR_CONVENIO}
                                                    ability={this.props.permissions}
                                                >
                                                    <button
                                                        type={"button"}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            validateForm(values).then(errors => {
                                                                if (_.isEmpty(errors)) {
                                                                    handleSubmit(values, { setValues });
                                                                } else {
                                                                    this.props.error({
                                                                        message: "Não foi possível salvar as bandeirinhas de usuário."
                                                                    });
                                                                }
                                                            });
                                                        }}
                                                        className={"btn btn-primary btn-pequeno mr-2"}
                                                        icone={"icon-btn-check-black"}
                                                    >
                                                        Salvar
                                                    </button>
                                                </Can>
                                            )}
                                        </div>
                                        <span
                                            className={`accordion ${this.state.collapse ? "icon-traco" : "icon-seta-baixo"
                                                }`}
                                        ></span>
                                    </div>
                                    <Collapse isOpen={this.state.collapse}>
                                        <div
                                            id="guiaUm"
                                            className="form-inside collapse show"
                                            aria-labelledby="headingOne"
                                            data-parent="#guiaUm"
                                        >
                                            <React.Fragment>
                                                <div class="row section-form">
                                                    <h6 class="w-100">Bandeirinhas permitidas para usuário</h6>
                                                    <FormGroup className="">
                                                        <InputViewEdit
                                                            placeholder="Bandeirinhas permitidas para usuário"
                                                            component={FormSelectInput}
                                                            service={SubStatusService.findSubStatusByCooperativaFilter}
                                                            name={"usuarioSubStatus"}
                                                            returnFullObject={true}
                                                            searchable={true}
                                                            labelKey={"nome"}
                                                            required={false}
                                                            valueKey={"situacao"}
                                                            isMulti={true}
                                                            defaultValue={values.usuarioSubStatus ? values.usuarioSubStatus.map((e, index) => {
                                                                let value = e.nome;
                                                                if (index > 0) {
                                                                    value = ', ' + value
                                                                }
                                                                return value;
                                                            }) : ''}
                                                            value={values.usuarioSubStatus}
                                                            //viewMode={viewMode}
                                                            onChange={(name, value) => {
                                                                setFieldValue(name, value);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </React.Fragment>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}

const mapDispatch = ({
    menu: { showMenu, hideMenu },
    load: { loading },
    alert: { success, error, clear }
}) => ({
    showMenu: () => showMenu(),
    hideMenu: () => hideMenu(),
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear()
});

function mapStateToProps(state) {
    const { loggedIn, user, permissions } = state.authentication;
    const { alert } = state;

    return {
        loggedIn,
        alert,
        user,
        permissions
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(BandeirasUsuarioForm));
