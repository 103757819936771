//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import SolicitacaoExameEdit from './SolicitacaoExameEdit.js';
export default class SolicitacaoExame extends React.PureComponent<{}> {
    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route path="/solicitaexame" component={SolicitacaoExameEdit} />
                </Switch>
            </React.Fragment>
        );
    }
}
