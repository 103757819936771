import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormInput from "../../../../components/Inputs/FormInput";
import InputViewEdit from "../../../../components/Inputs/InputViewEdit";
import { conselhoService } from "../../../../services/conselho.service";
import { userService } from "../../../../services/user.service";
import SelectableInput from "../../../../sascComponents/inputs/SelectableInput";

class DadosSolicitanteGuiaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }
  findConselho = () => {
    conselhoService
      .conselhoSolicitante(this.props.values.numeroCarteirinha)
      .then(response => { });
  };

  sendToStorange = (values) => {
    try {
      values.rascunho && localStorage.setItem('rascunho', JSON.stringify(values))
    } catch (error) {
      if (error instanceof DOMException && (
        error.code === 22 ||
        error.code === 1014 ||
        error.name === 'QuotaExceededError' ||
        error.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      )) {
        console.error('Limite de armazenamento local atingido');
      } else {
        console.error('Erro ao salvar no armazenamento local:', error.message);
      }
    }
  }

  render() {
    const {
      setFieldValue,
      values,
      errors,
      viewMode
    } = this.props;
    const extension = userService.getExtensionUser(this.props.user);


    return (
      <div className="row section-form">
        <h6 className="w-100">Dados do Contratado (onde foi executado o procedimento)</h6>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo mobile-100"
          name={"codigoOperadora"}
          label={"Código na operadora"}
          value={values.codigoOperadora}
        >
          <InputViewEdit
            label={"Código na operadora"}
            component={FormInput}
            required={true}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            onBlur={() => this.sendToStorange(values)}
            name={"codigoOperadora"}
            id={"codigoOperadora"}
            erroMensagem={errors.codigoOperadora}
            placeholder=""
            type={"text"}
            viewMode={extension || viewMode}
            value={values.codigoOperadora}
            disabled={true}

          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className=""
          name={"nome"}
          label={"Nome do contratado"}
          value={values.nome}>
          <InputViewEdit
            label={"Nome do contratado"}
            component={FormInput}
            required={false}
            disabled={false}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            onBlur={() => this.sendToStorange(values)}
            name={"nome"}
            erroMensagem={errors.nome}
            placeholder=""
            type={"text"}
            viewMode={extension || viewMode}
            value={values.nome}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo mobile-100"
          name={"cnes"}
          label={"Código CNES"}
          value={values.cnes}>
          <InputViewEdit
            label={"Código CNES"}
            component={FormInput}
            required={false}
            disabled={false}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            onBlur={() => this.sendToStorange(values)}
            name={"cnes"}
            erroMensagem={errors.cnes}
            placeholder=""
            type={"text"}
            viewMode={extension || viewMode}
            value={values.cnes}
          />
        </SelectableInput>

      </div>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosSolicitanteGuiaForm));
