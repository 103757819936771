import * as _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { atendimentoService } from "../../services/atendimento.service";
import { userService } from "../../services/user.service";

let download = require('downloadjs');

class AtendimentosEmAtrasoCooperativa extends PureComponent<Props, State> {

    constructor() {
        super();
        this.state = {
            atendimentosAtrasados: [],
            atendimentosRemovidosLote: [],
            showPaginationAtendimentosAtrasados: false,
            atendimentosAtrasadosTotalSize: 0,
            showAtendimentos: false,
            showModal: userService.getAviso(),
            listaConvenio: []
        }
    }

    componentDidMount() {
        if (this.state.showModal) {
            this.initAtendimentosAtrasados();
        }
    }

    toogleShowAtendimentos = () => {
        this.setState({showAtendimentos: !this.state.showAtendimentos})
    }

    toogleModal = () => {
        userService.setAviso(!this.state.showModal)
        this.setState({showModal: !this.state.showModal})
    }

    exportarExcel = () => {
        this.props.loading(true);

        atendimentoService.exportMonitoramentoMovimentacaoGuia(null,null).then(
            response => {
                download(
                    response.data,
                    'Atendimentos.csv',
                    'text/html'
                );
                this.props.loading(false);
            },
            () => {
                this.props.loading(false);
                this.props.error('Não foi possível exportar arquivo.');
            }
        );
    }


    initAtendimentosAtrasados = () => {
        let page;
        if (this.state.atendimentosAtrasados.length > 0) {
            page = this.state.atendimentosAtrasados.length / 10;
        } else {
            page = 0;
        }

        atendimentoService.findByAtendimentosAtrasadosCooperativa(page, 10).then(response => {
          const atendimentosAtrasadosTotalSize = response.data.dataTotalSize;
          let atendimentosAtrasados = _.cloneDeep(this.state.atendimentosAtrasados);

          let showPaginationAtendimentosAtrasados;
          if (response.data.data.length < 10) {
            showPaginationAtendimentosAtrasados = false;
          } else {
            showPaginationAtendimentosAtrasados = true;
          }
          atendimentosAtrasados = atendimentosAtrasados.concat(response.data.data);
          this.setState(
              {
                atendimentosAtrasados,
                atendimentosAtrasadosTotalSize,
                showPaginationAtendimentosAtrasados

              }
          );
        });
        atendimentoService.findByAtendimentosRmovidosLoteCooperativa(page, 10).then(response => {
            let atendimentosRemovidosLote = _.cloneDeep(this.state.atendimentosRemovidosLote);
            atendimentosRemovidosLote = atendimentosRemovidosLote.concat(response.data.data);
            this.setState(
                {
                  atendimentosRemovidosLote,
  
                }
            );
          });

          atendimentoService.monitoramentoMovimentacaoGuiaListaConvenios(null, null).then(response => {
            this.setState(
                {
                    listaConvenio : response.data
  
                }
            );
          });      

    }

    render() {
        return (
            <Modal
                isOpen={this.state.showModal
                        && this.state.atendimentosAtrasados.length > 0
                        && this.state.listaConvenio.length > 0}
                toggle={this.toogleModalEstabelecimento}
                backdrop="static"
                modalClassName=""
                className="modal-lg"
                centered={true}
            >
                <ModalBody className={''}>
                    <div>
                        <div className="row">
                            <div className="col-11 text-center my-2">
                                <h1 className={'txtGreenDark text-weight-bold'}>ATENÇÃO</h1>
                            </div>
                            <div className="col-11 text-center my-2">
                                <h2 className="font-weight-bold">Existem guias recebidas a mais de 15 dias
                                    <p>Favor encaminhar as guias para faturamento.</p>
                                    <p className="txt-Red-Bold">Convênios: {this.state.listaConvenio.join(", ")}</p>
                                </h2>
                                {(this.state.atendimentosRemovidosLote.length > 0 &&
                                <h2 className="font-weight-bold">Existem guias removidas do lote
                                    <p>Importante verificar, pois a pendência inviabiliza o faturamento.</p>
                                </h2>
                                )}
                                <p>Só sera possível prosseguir com o faturamento deste tipo e guia e deste convênio, após resolver as pendências das guias recebidas.</p>
                            </div>

                        </div>
                        <div className={'row justify-content-around py-2 px-4 mx-3 align-content-center'}>

                                <button
                                 type="button"
                                 className="btn btn-light btDefaultGrid d-none d-lg-block"
                                 onClick={this.exportarExcel}
                                >
                                <span className="icon-btn-ecxel"></span>
                                Exportar para excel
                                </button>

                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                    this.toogleModal();
                                }}
                            >

                                OK, estou ciente
                            </button>
                        </div>
                        {this.state.showAtendimentos && (
                            <React.Fragment>
                                <div
                                    className={'row bg-cinza justify-content-between py-2 px-4 mx-3 align-content-center'}>
                                    <div className={'fz-sm-11 align-middle'}
                                         style={{lineHeight: 2.5}}>{this.state.atendimentosAtrasadosTotalSize} Atendimentos
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-light btDefaultGrid d-none d-lg-block"
                                        onClick={this.exportarExcel}
                                    >
                                        <span className="icon-btn-ecxel"></span>
                                        Exportar para excel
                                    </button>
                                </div>
                                {/*<div className="row">
                                    <div className="flex-fill px-5 m-auto justify-content-center fz-sm-11  ">
                                        <div className={'row bg-cinza2 font-weight-bold'}>
                                            <div className={'col-2 border text-center p-2'}>Número do atendimento</div>
                                            <div className={'col-2 border text-center  p-2'}>Data do atendimento</div>
                                            <div className={'col-2 border text-center  p-2'}>Status</div>
                                            <div className={'col-2 border text-center  p-2'}>Convenio</div>
                                            <div className={'col-2 border text-center  p-2'}>Tipo de guia</div>
                                            <div className={'col-2 border text-center  p-2'}>Dias em atraso</div>
                                        </div>
                                        {this.state.atendimentosAtrasados.map(a => {
                                            return (<div className={'row'}>
                                                <div
                                                    className={'col-2 border text-center font-weight-bold  p-2'}>{a.numeroAtendimento}</div>
                                                <div
                                                    className={'col-2 border text-center  p-2'}>{dateHelper.format(a.dataAtendimento, { mode: 'DATE' }) }</div>
                                                <div className={'col-2 border text-center  p-2'}>{a.status}</div>
                                                <div className={'col-2 border text-center  p-2'}>{a.convenio}</div>
                                                <div className={'col-2 border text-center  p-2'}>{a.tipoGuia}</div>
                                                <div className={'col-2 border text-center  p-2'}>{a.diasEmAtraso}</div>
                                            </div>)
                                        })}

                                    </div>
                                    </div>*/}
                                {this.state.showPaginationAtendimentosAtrasados && (
                                    <div className="row justify-content-end p-1 mx-3 bg-cinza">
                                        <button
                                            type="button"
                                            className="btn btn-light"
                                            onClick={() => {
                                                this.initAtendimentosAtrasados();
                                            }}
                                        >
                                            <span className="icon-btn-add"></span>
                                            Exibir mais
                                        </button>

                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </ModalBody>
            </Modal>

        );
    }
}

function mapStateToProps(state) {
    const {user, permissions} = state.authentication;

    return {
        user,
        permissions
    };
}

const mapDispatch = ({alert: {error}, load: {loading}}) => ({
    error: (message, code) => error({message, code}),
    loading: (load: boolean) => loading({load})
});
export default connect(mapStateToProps, mapDispatch)(withRouter(AtendimentosEmAtrasoCooperativa));
