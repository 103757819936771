import React, { PureComponent } from "react";
import icon_usuarioCooperado from "../../img/icon_usuarioCooperado_C.svg";
import fechar from "../../img/icon_fechar_BLACK.svg";;

export default class ComoSerUmCooperado extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
    <div className="modalCooperado">
      <div className="row">
        <div className="col-12">
          <a onClick={() => {this.props.togleComoSerUmCooperado(false);}}
            className="btn-fechar d-flex justify-content-end">
            <img src={fechar} alt="fechar"/>
          </a>
        </div>
        <div className="col-12 iconeCooperados text-center my-2">
          <img src={icon_usuarioCooperado} alt="pessoas" />
        </div>
        <div className="col-12 text-center my-2">
          <h2 className="font-weight-bold">Como ser um Cooperado</h2>
        </div>
      </div>

      <div className="row">
        <div className="col overflow-auto txtModal">
          <p>
            Para ser um Cooperado e poder se conectar ao portal de
            transparência <b>Sasc Conect,</b>
            você precisa se encaixar em alguns dos itens abaixo:
          </p>
          <br></br>
          <p>
            <b>01 - The standard Lorem Ipsum passage</b>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor inididunt ut labore et dolore magna aliqua.
          </p>
          <br></br>
          <p>
            <b>02 - Enim ad minim veniam</b>
            Ut enim ad minim veniam, quis nostrud exercitation ullamco
            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu
            fugiat nulla pariatur.
          </p>
          <br></br>
          <p>
            <b>03 - Excepteur sint occaecat</b>
            Excepteur sint occaecat cupidatat non proident, sunt in culpa
            qui officia deserunt mollit anim id est laborum.
          </p>
          <br></br>
          <p>
            Se não conseguiu se encaixar em nenhum dos itens acima entre em
            contato com a gente pelo telefone abaixo ou pelo e-mail.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12 text-center">
          <a href="#">
            <button type="button" className="btn-secondary mt-5 mb-2">
              (31) 3232-5050
            </button>
          </a>
        </div>
        <div className="col-12 text-center">
          <a href="#">
            <button type="button" className="btn-secondary mb-4">
              faleconosco@sascconect.com.br
            </button>
          </a>
        </div>
      </div>

    </div>
    );
  }
}
