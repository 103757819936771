import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


/*CSS*/
import Key1 from '../../img/key_01.svg';

class SenhaEnviada extends PureComponent<Props, State> {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 text-center py-4 move-up">
            <img src={Key1} alt="chave" width="100px" />
          </div>
          <div className="col-8 offset-2 text-center mt-3">
            <h2 className="text-primary">
              <b>Foi enviado para seu e-mail um link para redefinir a sua senha!</b>
            </h2>
          </div>
          <div className="col-8 offset-2 text-center">
            <p>Acesse seu e-mail e confira o recebimento. Se não receber, tente novamente mais tarde ou entre contato com nosso suporte</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 inputSenha">

          </div>
        </div>

        <div className="row">
          <div className="col-12 esqueciSenha">

          </div>
        </div>

        <div className="row">
          <div className="col mb-15-negative text-center">
            <a
              className="btn btn-secondary"
              type="button"
              href="mailto:suporte@sascconect.com.br"
            >
              suporte@sascconect.com.br
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert
  };
}

const mapDispatch = ({
  alert: { error, clear, success, info },
  load: { loading }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  info: msg => info(msg)
});
export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(SenhaEnviada));
