//@flow
import * as React from "react";
import { connect } from "react-redux";
import makeAnimated from "react-select/animated";
import { convenioService } from "../../services/convenio.service";
import MultiSelect from "../../components/Inputs/MultiSelect";
import { components } from "react-select";
import SelectInput from "../../components/Inputs/SelectInput";
import {OptionCooperado} from "../cooperado/OptionCooperado";
import {cooperadoService} from "../../services/cooperado.service";
import { userService } from "../../services/user.service";

type Props = {
    label: string,
    name: string,
    value: any,
    onChange: any,
    returnFullObject: boolean,
    messageValidate?: any,
    disabled?: boolean,
    size?: number,
    required: boolean,
    service: any
};

type State = {
    options: Array<any>
};

const animatedComponents = makeAnimated();

class CooperadoFilterSelectInput extends React.PureComponent<Props, State> {
    _isMounted = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            options: []
        };
    }

    getExtension=()=>{
        let extension ='0';
         const isAuditor = userService.isAuditor(this.props.user);
         if(userService.getExtensionUser(this.props.user) && !isAuditor){
            extension=userService.getExtensionUser(this.props.user);
         }
         else if(this.props.filtroAtendimento && this.props.filtroAtendimento.estabelecimento){
            extension=this.props.filtroAtendimento.estabelecimento.id;
         }

         return extension;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <SelectInput
                isMulti={this.props.isMulti}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                components={{ Option: OptionCooperado }}
                onChange={this.props.onChange}
                name={this.props.name}
                id={this.props.id}
                value={this.props.value}
                valueKey={"id"}
                labelKey={"nome"}
                parent={this.getExtension()}
                onFetchData={cooperadoService.findByNomeOuConselhoFilter}
                returnFullObject={this.props.returnFullObject}
                required={this.props.required}
                clearable={true}
                className={this.props.className}
                placeholder={"Digite nome ou Nº do conselho"}
                label={'Cooperado'}
                erroMensagem={this.props.erroMensagem}
            />
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { filtroAtendimento } = state.filtro;
    return {
        user,
        filtroAtendimento
    };
}

export default connect(mapStateToProps)(CooperadoFilterSelectInput);
