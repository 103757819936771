//@flow
import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";

export const conselhoService = {
  find,
  findAll,
  findListUfsConselho,
  findUfsConselho
};

function find() {
  return defaultService.doGet(`${urlsConstants.CONSELHO}findAll}`);
}

function findAll() {
  return defaultService.doGet(`${urlsConstants.CONSELHO}findAll`);
}

function findListUfsConselho() {
  return defaultService.doGet(`${urlsConstants.CONSELHO}findListUfsConselho`);
}

function findUfsConselho(idConselho) {
  if(idConselho){
    return defaultService.doGet(`${urlsConstants.CONSELHO}findUfsConselho/${idConselho}`);
  }else{
   return Promise.resolve({data:[]});
  }
  
}