//@flow
import { Formik } from "formik";
import _ from "lodash";
import * as React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import connect from "react-redux/es/connect/connect";
import * as Yup from "yup";
import { translate } from "../../helpers/message.helper";
import { beneficiarioService } from "../../services/beneficiario.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import BeneficiarioForm from "./BeneficiarioForm";
import BeneficiarioTopoCrud from "./BeneficiarioTopoCrud";

const NewBeneficiario = Yup.object().shape({
    beneficiarioConvenioBloqueio: Yup.object().shape({
        convenio: Yup.object().shape({
            id: Yup.string().required('Obrigatório')
        }),
        bloquear_digitacao : Yup.boolean().required('Obrigatório')
    })
});

class BeneficiarioEdit extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {
            entity: {}
        };
    }

    goToView = values => {
        this.props.history.push({
            pathname: "/beneficiarios/view/" + (values.id),
            state: { entity: values }
        });
    };

    handleCancel = () => {
        this.props.history.goBack();
    };

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.loading(true);
            beneficiarioService.doGet(this.props.match.params.id).then(
                response => {
                    let user = response.data;
                    this.setState({ entity: user }, () => {
                        this.props.loading(false);
                    });
                },
                error => {
                    console.error(error);
                    this.props.loading(false);
                }
            );
        }
    }

    componentDidUpdate() {
        if (this.props.match.params.id && !this.state.entity.id && this.state.entity.id !== this.props.match.params.id) {
            this.props.loading(true);
            beneficiarioService.doGet(this.props.match.params.id).then(
                response => {
                    let user = response.data;
                    this.setState({ entity: user }, () => {
                        this.props.loading(false);
                    });
                },
                error => {
                    console.error(error);
                    this.props.loading(false);
                }
            );
        }
    }

    handleChange = (name, value) => {
        const entity = _.cloneDeep(this.formRef.state.values);
        _.set(entity, name, value);
        this.setState({ entity });
    };

    handleSubmit = e => {
        this.formRef.validateForm(this.formRef.values).then(erros => {
            console.error(erros);
            if (_.isEmpty(erros)) {
                this.formRef.handleSubmit();
            }
        });
    };

    getServiceDoSave = () => {
        //let entity;
        if (this.formRef) {
            //entity = _.cloneDeep(this.formRef.state.values);
        } else {
            //entity = this.props.location && this.props.location.state.entity;
        }
        return beneficiarioService;
    };

    render() {
        let _this = this;
        const { entity } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid container-com-topo">
                    <div
                        className={`action-line ${this.state.activeClass} fixed-container-topo`}
                    >
                        <TopoTitleComponente
                            mainTitle={`${entity.id ? "Editar " : "Novo "}  beneficiário`}
                            canBack={true}
                            backUrl={!entity.id ? null : "/beneficiarios"}
                        />
                        <BeneficiarioTopoCrud
                            values={entity}
                            onChange={this.handleChange}
                            handleSubmit={this.handleSubmit}
                            handleCancel={this.handleCancel}
                        ></BeneficiarioTopoCrud>
                    </div>
                    <div className={`contentComTopoFix pt-4`}>
                        <Formik
                            validationSchema={NewBeneficiario}
                            validateOnBlur={false}
                            validateOnChange={false}
                            enableReinitialize={true}
                            initialValues={_this.state.entity}
                            onSubmit={(values, actions) => {
                                this.props.loading(true);
                                let serviceSave = beneficiarioService.doSave(entity.id, values);
                                serviceSave.then(
                                    response => {
                                        this.props.success({
                                            message: `Beneficiário ${values.nomeBeneficiario}, foi salvo com sucesso!`
                                        });

                                        _this.goToView(values);
                                    },
                                    erros => {
                                        console.error(erros.response);
                                        this.props.error({
                                            message: "Não foi possível salvar o beneficiário."
                                        });
                                        try {
                                            let response = erros.response.data;
                                            if (response && response.messages) {
                                                for (var i = 0; i < response.messages.length; i++) {


                                                    let erroItem = response.messages[i];
                                                    if (erroItem.target === "FIELD") {


                                                        actions.setFieldError(
                                                            erroItem.fieldName,
                                                            translate(erroItem.message.code)
                                                        );
                                                    } else {
                                                        this.props.error({
                                                            message: translate(erroItem.message.code)
                                                        });
                                                    }
                                                }
                                            }
                                        } catch (error) {
                                            console.error(error);
                                        }

                                        this.props.loading(false);
                                    }
                                );
                                this.props.loading(false);
                                actions.setSubmitting(false);

                            }}
                            ref={form => {
                                this.formRef = form;
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                validationSchema,
                                validateForm,
                                setValues
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <React.Fragment>
                                        <BeneficiarioForm
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                            handleChange={handleChange}
                                            touched={touched}
                                            validateForm
                                            setValues
                                        ></BeneficiarioForm>
                                    </React.Fragment>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatch = ({
    alert: { success, error, clear },
    load: { loading },
    authentication: { doRefresh }


}) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear(),
    doRefresh: () => doRefresh()
});

function mapStateToProps(state) {
    const { user } = state.authentication;
    return {
        user
    };
}
export default connect(mapStateToProps, mapDispatch, null, { withRef: true })(
    BeneficiarioEdit
);
