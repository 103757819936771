//import { Can } from "@casl/react";
import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import BtnLink from "../../components/Buttons/BtnLink";
import { FormGroup } from "../../components/FormGroup";
import CpfCnpjInput from "../../components/Inputs/CpfCnpjInput";
import CpfInput from "../../components/Inputs/CpfInput";
import DateTimePicker from "../../components/Inputs/DateTimePicker";
import FormInput from "../../components/Inputs/FormInput";
import InputViewEdit from "../../components/Inputs/InputViewEdit";
import PhoneInput from "../../components/Inputs/PhoneInput";
import ZipCodeInput from "../../components/Inputs/ZipCodeInput";
import { objectsConstants } from "../../constants/objects.constants";
import BancoSelectInput from "../../sascComponents/banco/BancoSelectInput";
import ConvenioSelectInput from "../../sascComponents/convenios/ConvenioSelectInput";
import EspecialidadesSelectInput from "../../sascComponents/especialidades/EspecialidadesSelectInput";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { estabelecimentoService } from "../../services/estabelecimento.service";
import { userService } from "../../services/user.service";
import MenuHistorico from "../guias/historico/MenuHistorico";
import CheckBoxInput from "../../components/Inputs/CheckBoxInput";
//import ConsultorioUsuarioForm from "./ConsultorioUsuarioForm";

class ConsultorioForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: true,
      listaHistoricos: []
    };
  }
  goToEdit = () => {
    this.props.history.push({
      pathname: "/consultorios/edit/" + this.props.values.id,
      state: { entity: this.props.values }
    });
  };

  addUsuario = (setFieldValues, values) => {
    let usuarios = _.cloneDeep(values.usuarios);
    usuarios.push({ idKey: new Date().getTime() });
    setFieldValues("usuarios", usuarios);
  };

  addUsuarioAndEdit = (setFieldValues, values) => {
    this.setState({ viewMode: false });
  };

  removerUsuario = (setFieldValues, values, index) => {
    let usuarios = _.cloneDeep(values.usuarios);
    usuarios.splice(index, 1);
    setFieldValues("usuarios", usuarios);
  };

  excluirUsuario = (consultorio, usuario) => {
    this.props.loading(true);
    estabelecimentoService.removerUsuario(consultorio, usuario).then(
      response => {
        this.props.success({
          message: `Usuário foi excluido com sucesso!`
        });
        if (this.props.refreshEntity) {
          this.props.refreshEntity();
        }
      },
      erros => {
        console.error(erros.response);
        this.props.error({
          message: "Não foi possível excluir usuário!"
        });
        this.props.loading(false);
      }
    );
    this.props.loading(false);
  };

  cancelEditMode = (e, setValues) => {
    e.stopPropagation();
    ConfirmAcationService.confirmMsg(
      () => {
        this.confirmCancel(setValues);
      },
      `Cancelar Edição `,
      "Você confirma que deseja cancelar a edição dos cronogramas",
      null,
      "Não",
      "Sim",
      objectsConstants.TYPE_CANCEL
    );
  };

  confirmCancel = setValues => {
    this.setState({ viewMode: true });
  };

  userCreated = (setFieldValues, user, values) => {
    this.setState({ viewMode: true }, () => {
      if (this.props.refreshEntity && this.props.viewMode) {
        this.props.refreshEntity();
      } else {
        let usuarios = _.cloneDeep(values.usuarios);
        usuarios.push(user);
        setFieldValues("usuarios", usuarios);
      }
    });
  };

  loadHistoryConsultorio = () => {
    this.props.loading(true);
    estabelecimentoService.historicoConsultorio(this.props.values.id).then(result => {
      this.setState({ listaHistoricos: result.data }, () => {
        this.props.loading(false);
      });
    }, () => {
      this.props.loading(false);
    });
  }

  setMenuHistorico = status => {
    this.setState({ openMenuHistorico: status }, () => {
      if (this.state.openMenuHistorico) {
        this.loadHistoryConsultorio()
        document.body.classList.add("bodyNoScroll");
        document.getElementById("bodyMenu").scrollIntoView();
      } else {
        document.body.classList.remove("bodyNoScroll");
      }
    });
  };

  toggleHistorico = toggle => {
    this.setMenuHistorico(!this.state.openMenuHistorico);
  };

  render() {
    const {
      values,
      setFieldValue,
      errors,
      handleChange,
      isSubmitting,
      handleSubmit,
      viewMode,
      validateForm,
      setValues
    } = this.props;
    let exibirBotaoExcluirUsuario = this.props.match.path.includes("/consultorios/edit/");
    return (
      <React.Fragment>
        <div className="item">
          <Card className="cardCrud content-form">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mr-sm-auto card-title">Dados</div>
                <div className="px-sm-3">
                <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-historico"}
                              title={"Histórico"}
                              onSubmit={e => {
                                e.stopPropagation();
                                this.toggleHistorico();
                              }}
                              disabled={false}
                            />
                  {viewMode && (
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={this.goToEdit}
                    >
                      <span className="icon-btn-editar"></span>Editar
                    </button>
                  )}
                </div>
              </div>
            </CardHeader>

            <CardBody className={"form-inside viewMode"}>
              <div className="row section-form">
                <h6 className="w-100">Consultório</h6>
              </div>
              <div className="row section-form">
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Nome"}
                    type="text"
                    id="nome"
                    name="nome"
                    placeholder="Digite o nome da clínica"
                    value={values.nome}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.nome}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="fixo">
                  <InputViewEdit
                    component={DateTimePicker}
                    label={"Início de atendimento"}
                    id="inicioAtendimento"
                    name="inicioAtendimento"
                    placeholder="Início de atendimento"
                    value={values.inicioAtendimento}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.inicioAtendimento}
                    viewMode={viewMode}
                    showTimeSelect={true}
                    showTimeSelectOnly={true}
                    required={false}
                    format={"HH:mm"}
                  />
                </FormGroup>
                <FormGroup className="fixo">
                  <InputViewEdit
                    component={DateTimePicker}
                    label={"Fim de atendimento"}
                    id="fimAtendimento"
                    name="fimAtendimento"
                    placeholder="Fim de atendimento"
                    value={values.fimAtendimento}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.fimAtendimento}
                    viewMode={viewMode}
                    showTimeSelect={true}
                    showTimeSelectOnly={true}
                    required={false}
                    format={"HH:mm"}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={CpfCnpjInput}
                    label={"CPF ou CNPJ"}
                    id="cnpj"
                    name="cnpj"
                    placeholder="Digite o CPF ou CNPJ"
                    value={values.cnpj}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.cnpj}
                    viewMode={viewMode}
                    required={true}
                    onBlur={() => {
                      let match = null
                      if (values.cnpj) {
                        match = values.cnpj.match(/\d+/g);
                      }
                      if (match != null && match.join("").length === 11) {
                        this.props.loading(true);
                        userService.existByCpf(values.cnpj).then(response => {
                          if (response.data) {
                            if (values.usuarios.length === 1) {
                              setFieldValue(`usuarios[${0}]`, response.data);
                            }
                          }
                          this.props.loading(false);
                        }, () => {
                          this.props.loading(false);

                        });
                      }
                    }}
                  />
                </FormGroup>
                <div className="quebra"></div>
                <FormGroup className="">
                  <InputViewEdit
                    component={EspecialidadesSelectInput}
                    label={"Especialidades"}
                    name="especialidades"
                    id="especialidades"
                    multi={true}
                    placeholder="Selecione as especialidades"
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    returnFullObject={true}
                    value={values.especialidades}
                    className="sasc-select"
                    required={true}
                    erroMensagem={errors.especialidades && "Obrigatório"}
                    viewMode={viewMode}
                    defaultValue={
                      viewMode && values.especialidades &&
                      values.especialidades.map(item => item.nome).join(", ")
                    }
                  ></InputViewEdit>
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={BancoSelectInput}
                    label={"Banco"}
                    id="pessoaDadoBancarioBancoNome"
                    name="pessoaDadoBancario.banco"
                    placeholder="Digite o nome do banco"
                    value={_.get(values, "pessoaDadoBancario.banco")}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    noSize={true}
                    viewMode={viewMode}
                    required={false}
                    returnFullObject={true}
                    defaultValue={
                      viewMode && _.get(values, "pessoaDadoBancario.banco.nome")
                    }
                  />
                </FormGroup>
                <FormGroup className="central">
                  <InputViewEdit
                    component={FormInput}
                    label={"Agência"}
                    type="text"
                    id="pessoaDadoBancarioAgencia"
                    name="pessoaDadoBancario.agencia"
                    placeholder="Digite a agência"
                    value={_.get(values, "pessoaDadoBancario.agencia")}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    required={false}
                  />
                </FormGroup>
                <FormGroup className="fixo">
                  <InputViewEdit
                    component={FormInput}
                    label={"Conta"}
                    type="text"
                    id="pessoaDadoBancarioContaCorrente"
                    name="pessoaDadoBancario.contaCorrente"
                    placeholder="Digite a conta"
                    value={_.get(values, "pessoaDadoBancario.contaCorrente")}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    required={false}
                  />
                </FormGroup>
                <div className="quebra"></div>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"E-mail"}
                    type="email"
                    id="email"
                    name="email"
                    value={_.get(values, "email")}
                    erroMensagem={errors.email}
                    placeholder="Digite o e-mail"
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="fixo">
                  <InputViewEdit
                    component={PhoneInput}
                    type="number"
                    id="secretariaCelular"
                    name="celular"
                    value={_.get(values, "celular")}
                    label="Celular"
                    placeholder="Digite o celular"
                    onChange={handleChange}
                    noSize={true}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="fixo">
                  <InputViewEdit
                    component={PhoneInput}
                    label="Telefone Comercial"
                    type="number"
                    id="secretariaTelefone"
                    name="endereco.telefone"
                    value={_.get(values, "endereco.telefone")}
                    placeholder="(00) 0000-0000"
                    onChange={handleChange}
                    required={true}
                    viewMode={viewMode}
                    erroMensagem={errors.endereco && errors.endereco.telefone}
                  />
                </FormGroup>
                <CheckBoxInput
                  className="mb-0 pb-1"
                  label="Permite envio da Clínica no cooperado recebedor"
                  name="checkItem"
                  disabled={viewMode}
                  checked={_.get(values, "enviarClinica")}
                  onChange={(name, checked) => {
                    _.set(values, 'enviarClinica', checked);
                  }}
                />
                <h6 className="w-100">Endereço</h6>
                <FormGroup className="fixo">
                  <InputViewEdit
                    component={ZipCodeInput}
                    type="number"
                    label="CEP"
                    id="enderecoId"
                    name="endereco.cep"
                    value={_.get(values, "endereco.cep")}
                    erroMensagem={errors.endereco && errors.endereco.cep}
                    required={true}
                    placeholder="CEP"
                    onChangeCEP={(name,value)=>{
                      setFieldValue(name, value);
                    }}
                    onChange={(name, value) => {
                      if (values.endereco) {
                        value.telefone = values.endereco.telefone;
                        value.id = values.endereco.id;
                      }
                      setFieldValue("endereco", value);
                    }}
                    viewMode={viewMode}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Rua"}
                    type="text"
                    id="enderecoLogradouro"
                    name="endereco.logradouro"
                    value={_.get(values, "endereco.logradouro")}
                    erroMensagem={errors.endereco && errors.endereco.logradouro}
                    placeholder="Digite a rua"
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="fixo">
                  <InputViewEdit
                    component={FormInput}
                    label={"Número"}
                    type="text"
                    id="enderecoNumero"
                    name="endereco.numero"
                    placeholder="Número"
                    value={_.get(values, "endereco.numero")}
                    erroMensagem={errors.endereco && errors.endereco.numero}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="fixo">
                  <InputViewEdit
                    component={FormInput}
                    label={"Complemento"}
                    type="text"
                    id="enderecoComplemento"
                    name="endereco.complemento"
                    placeholder="Complemento"
                    value={_.get(values, "endereco.complemento")}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    required={false}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Bairro"}
                    type="text"
                    id="enderecoBairro"
                    name="endereco.bairro"
                    placeholder="Bairro"
                    value={_.get(values, "endereco.bairro")}
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    erroMensagem={errors.endereco && errors.endereco.bairro}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="">
                  <InputViewEdit
                    component={FormInput}
                    label={"Cidade"}
                    type="text"
                    id="enderecoCidadeNome"
                    name="endereco.cidade.nome"
                    placeholder="Cidade"
                    value={_.get(values, "endereco.cidade.nome")}
                    erroMensagem={
                      errors.endereco &&
                      errors.endereco.cidade &&
                      errors.endereco.cidade.nome
                    }
                    onChange={(name, value) => setFieldValue(name, value)}
                    noSize={true}
                    viewMode={viewMode}
                    required={true}
                  />
                </FormGroup>
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="item pt-3">
          <Card className="cardCrud">
            <CardHeader className=" d-flex align-items-center flex-column flex-sm-row b-bottom">
              <div className="flex-grow-1 d-flex align-items-center">
                <div className="mr-sm-auto card-title">Usuários</div>
              </div>
              <div className="buttons-right ml-auto">
                {!viewMode && (
                  <BtnLink
                    type={"button"}
                    className={""}
                    icone={"icon-btn-add"}
                    title={"Adicionar usuário"}
                    onSubmit={e => {
                      this.addUsuario(setFieldValue, values);
                    }}
                    disabled={false}
                  />
                )}

              </div>
            </CardHeader>
            <CardBody className={"form-inside viewMode"}>
              {viewMode && (
                <div className="row section-form">
                  <h6 className="w-100">Usuários do consultório</h6>
                </div>
              )}
              {values &&
                values.usuarios &&
                values.usuarios.map((usuario, index) => {
                  return (
                    <React.Fragment
                      key={usuario.id ? usuario.id : usuario.idKey}
                    >
                      <div className="row section-form">
                        <FormGroup className="">
                          <InputViewEdit
                            component={CpfInput}
                            label="CPF"
                            id={`secretariacpf${index}`}
                            name={`usuarios[${index}].cpf`}
                            value={usuario.cpf}
                            placeholder="Digite o CPF"
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            noSize={true}
                            viewMode={viewMode}
                            required={true}
                            disabled={usuario.id ? true : false}
                            erroMensagem={_.get(
                              errors,
                              `usuarios[${index}].cpf`
                            )}
                            onBlur={() => {
                              if (usuario.cpf && usuario.cpf.length) {
                                this.props.loading(true);
                                userService.existByCpf(usuario.cpf).then(
                                  response => {
                                    if (response.data) {
                                      if (!values.usuarios.some(usuario => usuario.cpf !== response.data.cpf)
                                        || values.usuarios.findIndex(usuario => usuario.cpf === response.data.cpf) === index) {
                                        setFieldValue(
                                          `usuarios[${index}]`,
                                          response.data
                                        );
                                      } else {
                                        setFieldValue(
                                          `usuarios[${index}].cpf`,
                                          ''
                                        );
                                        this.props.error({
                                          message: `O usuário já esta vinculado ao estabelecimento!`
                                        });
                                      }
                                    }
                                    this.props.loading(false);
                                  },
                                  () => {
                                    this.props.loading(false);
                                  }
                                );
                              }
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="">
                          <InputViewEdit
                            component={FormInput}
                            label={"Nome"}
                            type="text"
                            id={`secretariaNome${index}`}
                            name={`usuarios[${index}].nome`}
                            value={usuario.nome}
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            noSize={true}
                            placeholder="Digite o nome"
                            viewMode={viewMode}
                            required={true}
                            disabled={usuario.id ? true : false}
                            erroMensagem={_.get(
                              errors,
                              `usuarios[${index}].nome`
                            )}
                          //disabled={userService.isCooperado(usuario)}
                          />
                        </FormGroup>
                        <FormGroup className="">
                          <InputViewEdit
                            component={FormInput}
                            label="Senha"
                            type="password"
                            placeholder={"Digite a Senha"}
                            id={`usuarios${index}password`}
                            name={`usuarios[${index}].password`}
                            value={usuario.password}
                            required={values.id ? false : true}
                            onChange={(name, value) =>
                              setFieldValue(name, value)
                            }
                            viewMode={viewMode}
                            defaultValue={"**********"}
                            erroMensagem={_.get(
                              errors,
                              `usuarios[${index}].password`
                            )}
                            //disabled={userService.isCooperado(usuario)}
                            disabled={usuario.id ? true : false}
                            viewPassword={true}
                            noSize={true}
                          />
                        </FormGroup>
                        {exibirBotaoExcluirUsuario ? <FormGroup className="central fixo">
                          <BtnLink
                            type={"button"}
                            className={""}
                            icone={"icon-btn-excluir"}
                            title={"Excluir"}
                            onSubmit={e => {
                              if (viewMode) {
                                e.stopPropagation();
                                ConfirmAcationService.confirmMsg(
                                  () => {
                                    this.excluirUsuario(values, usuario);
                                  },
                                  `Excluir Usuário `,
                                  "Você confirma que deseja remover este usuário",
                                  null,
                                  "Não",
                                  "Sim",
                                  objectsConstants.TYPE_EXCLUIR
                                );
                              } else {
                                this.removerUsuario(
                                  setFieldValue,
                                  values,
                                  index
                                );
                              }
                            }}
                            disabled={false}
                          />
                        </FormGroup> : <></>}
                      </div>
                    </React.Fragment>
                  );
                })}
            </CardBody>
          </Card>
        </div>

        <div className="item pt-3">
          <Card className="cardCrud">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mr-sm-auto card-title">
                  Convênios que os cooperados atendem
                </div>
              </div>
            </CardHeader>
            <CardBody className={"form-inside viewMode"}>
              {/* <CheckConvenios></CheckConvenios> */}
              <FormGroup className="">
                <ConvenioSelectInput
                  name="convenios"
                  id="convenios"
                  bloquearConsultorio={false}
                  isMulti={true}
                  placeholder="Selecione os convênios"
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  erroMensagem={errors.convenios}
                  returnFullObject={true}
                  value={values.convenios}
                  className="sasc-select"
                  disabled={viewMode}
                  required={true}
                ></ConvenioSelectInput>
              </FormGroup>
            </CardBody>
          </Card>
        </div>
        <div className="item">
          {!viewMode && (
            <div className="d-flex flex-row flex-sm-row pt-2 pb-3 justify-content-center">
              <button
                type="submit"
                className="btn btn-primary d-blokc d-sm-none"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Salvar
              </button>
            </div>
          )}
        </div>
        <MenuHistorico
                  titulo={this.props.values && this.props.values.nome}
                  subTitulo={"Consultório"}
                  listaHistoricos={this.state.listaHistoricos}
                  toggleHistorico={this.toggleHistorico}
                  openMenuHistorico={this.state.openMenuHistorico}
                />
      </React.Fragment>
    );
  }
}
const mapDispatch = ({
  alert: { success, error, clear, info },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear(),
  info: msg => info(msg)
});

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(ConsultorioForm));
