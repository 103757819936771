import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Datatable from "../../sascComponents/datagrid/Datatable";
import TopoTitleComponente from "../home/TopoTitleComponente";
import LinhaAcaoAtivarInativarNotificacao from "./LinhaAcaoAtivarInativarNotificacao";
import { notificacaoService } from "../../services/notificacao.service";
import { objectsConstants } from "../../constants/objects.constants";
import TopoListagem from "../../sascComponents/topo/TopoListagem";

class NotificacaoList extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
      this.state = {

     initFilter: [
        {
          columnOrder: 1,
          searchableField: false,
          fieldName: "tipoAviso", fieldDescription: "Tipo", type: "ENUM",  value: objectsConstants.NOTIFICACAO_CONSULTORIO, condition: { description: "=" }, }
        ]
    };
  }

  handleRowClick = (fieldName, rowData) => {
    this.props.history.push({
      pathname: "/notificacoesConsultorios/view/" + rowData.id,
      state: { entity: rowData }
    });
  };

  closeMenu = () => {
    this.props.showMenu();
  };


  render() {
    const { hideTopo } = this.props;
    return (

      <div className="container-fluid container-com-topo">
        {!hideTopo && (
          <React.Fragment>
            <TopoTitleComponente
              mainTitle="Notificações"
              subTitle=" "
              canBack={false}
            />
            <TopoListagem
              securityActionDo={objectsConstants.VISUALIZAR}
              securityActionOn={objectsConstants.BTN_NOTIFICACOES_CONSULTORIOS}
              buttonActionClassName={"btn-light"}
              buttonAction={() => {
                this.props.history.push("/notificacoesConsultorios/nova");
              }}
              securityActionIcon={"icon-btn-add"}
              securityActionName={"Nova Notificação"}
            />

            <hr className="text-center w-75"></hr>

            <div className="background-lines">
              <div className="bg-riscos"></div>
            </div>
          </React.Fragment>
        )}

        <Datatable
          showCheckboxColumn={true}
          idRow={"id"}
          name={"Notificações"}
          service={notificacaoService}
          showAllColumnsFilter={false}
          ActionLine={LinhaAcaoAtivarInativarNotificacao}
          onRowClick={this.handleRowClick}
          noCacheFilter={this.props.noCacheFilter}
          filter={{}}
        />
      </div>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(NotificacaoList));
