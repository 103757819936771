import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {userService} from "../../services/user.service";
import * as _ from "lodash";
import {atendimentoService} from "../../services/atendimento.service";
import {Modal, ModalBody} from "reactstrap";
import {objectsConstants} from "../../constants/objects.constants";
import {dateHelper} from "../../helpers/date.helper";

let download = require('downloadjs');

const columns = [

    {
        "path": "Atendimento",
        "fieldName": "numeroAtendimento",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": "",
        "url": "",
        "fieldDescription": "Nº do atendimento",
        "type": "STRING"
    },
    {
        "path": "Atendimento",
        "fieldName": "dataAtendimento",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": "",
        "url": "",
        "fieldDescription": "Data do Atendimento",
        "type": "STRING"
    },
    {
        "path": "Atendimento.status",
        "fieldName": "nome",
        "onlyNumber": false,
        "searchEmpty": false,
        "emptyTextSearch": "",
        "url": "/atendimento/getStatusByFiltroAtendimento",
        "fieldDescription": "Status",
        "type": "SELECT"
    }]

class AtendimentosEmAtraso extends PureComponent<Props, State> {

    constructor() {
        super();
        this.state = {
            atendimentosAtrasados: [],
            showPaginationAtendimentosAtrasados: false,
            atendimentosAtrasadosTotalSize: 0,
            showAtendimentos: false,
            showModal: true,
            atendimentosPrimeiroStatusAtrasados: false,
          atendimentosPendentesAtrasados: false
        }
    }

    componentDidMount() {

        this.initAtendimentosAtrasados();
    }

    toogleShowAtendimentos = () => {
        this.setState({showAtendimentos: !this.state.showAtendimentos})
    }

    toogleModal = () => {
        this.setState({showModal: !this.state.showModal})
    }

    exportarExcel = () => {
        this.props.loading(true);


            atendimentoService.exportAtendimentosAtrasados(
                _.map(columns, column => {
                    if (column.path && column.path.indexOf(".") !== -1) {
                        return (
                            column.path.substring(column.path.indexOf(".") + 1) +
                            "." +
                            column.fieldName
                        );
                    } else {
                        return column.fieldName;
                    }
                }),
                _.map(columns, column => {
                    return column.fieldDescription;
                })
            ).then(response => {
                download(
                    response.data,

                    'Atendimentos.csv'
                    ,
                    'text/html'
                );
                this.props.loading(false);
            });

    }


    initAtendimentosAtrasados = () => {
        let page;
        if (this.state.atendimentosAtrasados.length > 0) {
            page = this.state.atendimentosAtrasados.length / 10;
        } else {
            page = 0;
        }
      atendimentoService.statusAtendimentosEmAtraso().then(r => {
        let atendimentosPendentesAtrasados = false;
        let atendimentosPrimeiroStatusAtrasados = false;
        r.data.map(status => {
          if (status === objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA) {
            atendimentosPrimeiroStatusAtrasados = true;
          }
          if (status === objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE) {
            atendimentosPendentesAtrasados = true;
            this.props.existeAtendimentoPendente(atendimentosPendentesAtrasados);
          }
        });

        this.setState({atendimentosPrimeiroStatusAtrasados, atendimentosPendentesAtrasados})
        atendimentoService.findByAtendimentosAtrasados(page, 10).then(response => {
          const atendimentosAtrasadosTotalSize = response.data.dataTotalSize;
          let atendimentosAtrasados = _.cloneDeep(this.state.atendimentosAtrasados);

          let showPaginationAtendimentosAtrasados;
          if (response.data.data.length < 10) {
            showPaginationAtendimentosAtrasados = false;
          } else {
            showPaginationAtendimentosAtrasados = true;
          }
          atendimentosAtrasados = atendimentosAtrasados.concat(response.data.data);
          this.setState(
              {
                atendimentosAtrasados,
                atendimentosAtrasadosTotalSize,
                showPaginationAtendimentosAtrasados

              }
          );
        });
      });
    }

    render() {
        return (
            <Modal
                isOpen={this.state.showModal && this.state.atendimentosAtrasados.length > 0}
                toggle={this.toogleModalEstabelecimento}
                backdrop="static"
                modalClassName=""
                className="modal-lg"
                centered={true}
            >
                <ModalBody className={''}>
                    <div>
                        <div className="row">
                            <div className="col-11 text-center my-2">
                                <h1 className={'txtGreenDark text-weight-bold'}>ATENÇÃO</h1>
                            </div>
                            <div className="col-11 text-center my-2">
                              {this.state.atendimentosPrimeiroStatusAtrasados&&(
                                <h2 className="font-weight-bold">Existem guias aguardando envio para a cooperativa com
                                    mais de {userService.getCurrentUser().cooperativa.diasGerarNotificacaoAtraso} dias.
                                    <p>Favor encaminhar as guias físicas para a cooperativa.</p>
                                </h2>
                              )}
                                {this.state.atendimentosPendentesAtrasados&&(
                                    <h2 className="font-weight-bold">
                                        Existem guias pendentes com mais
                                        de {userService.getCurrentUser().cooperativa.diasGerarNotificacaoAtraso} dias.
                                        <p>Importante verificar, pois a pendência inviabiliza o faturamento.</p>
                                        {userService.getCurrentUser().cooperativa.id === objectsConstants.COOPMED.id && <div className="text-left">
                                            <p>Atenção!</p>
                                            <p>Existem guias geradas a mais de 15 dias que não foram entregues na cooperativa.
                                                Encaminhe as guias físicas mensalmente para COOPMED-RS e evite a perda do prazo de faturamento,
                                                ressaltamos a necessidade de envio por normativas da ANS e exigência dos convênios.</p>
                                            <p>** Favor verificar e seguir as orientações abaixo para guias de Consulta e SP/SADT:</p>
                                            <p>1. Verificar data de atendimento da guia;</p>
                                            <p>2. Verificar se guias possuem assinatura;</p>
                                            <p>3. Gerar protocolo;</p>
                                            <p>4. Anexar comprovantes Laudo e Descrição, quando necessário;</p>
                                            <p>5. Inserir nas urnas de coleta, entregar na cooperativa ou via correio;</p>
                                            <p>** Guias de Honorários - Gerar guia e anexar os documentos necessários;
                                                Importante protocolar as mesmas e efetuar a entrega das guias para controle do fluxo.
                                                Estamos à disposição para esclarecimentos em nossos canais de contato.</p>
                                        </div>}
                                        <p>Só será possível criar um novo atendimento, após resolver as guias com pendência</p>
                                    </h2>
                                )}
                            </div>

                        </div>
                        <div className={'row justify-content-around py-2 px-4 mx-3 align-content-center'}>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    this.toogleShowAtendimentos();
                                }}
                            >

                                {this.state.showAtendimentos ? 'Ocultar ' : 'Exibir '}atendimentos
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                    this.toogleModal();
                                }}
                            >

                                OK, estou ciente
                            </button>
                            {/*<button
                    type="button"
                    className="btn btn-primary btDefaultGrid d-none d-lg-block p-2"
                    onClick={this.exportarExcel}
                >
                  <span className="icon-btn-ecxel"></span>
                  Exportar Atendimentos para excel
                </button>*/}
                        </div>
                        {this.state.showAtendimentos && (
                            <React.Fragment>
                                <div
                                    className={'row bg-cinza justify-content-between py-2 px-4 mx-3 align-content-center'}>
                                    <div className={'fz-sm-11 align-middle'}
                                         style={{lineHeight: 2.5}}>{this.state.atendimentosAtrasadosTotalSize} Atendimentos
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-light btDefaultGrid d-none d-lg-block"
                                        onClick={this.exportarExcel}
                                    >
                                        <span className="icon-btn-ecxel"></span>
                                        Exportar para excel
                                    </button>
                                </div>
                                <div className="row">
                                    <div className="flex-fill px-5 m-auto justify-content-center fz-sm-11  ">
                                        <div className={'row bg-cinza2 font-weight-bold'}>
                                            <div className={'col-4 border text-center p-2'}>Número do atendimento</div>
                                            <div className={'col-4 border text-center  p-2'}>Data do atendimento</div>
                                            <div className={'col-4 border text-center  p-2'}>Status</div>
                                        </div>
                                        {this.state.atendimentosAtrasados.map(a => {
                                            return (<div className={'row'}>
                                                <div
                                                    className={'col-4 border text-center font-weight-bold  p-2'}>{a.numeroAtendimento}</div>
                                                <div
                                                    className={'col-4 border text-center  p-2'}>{dateHelper.format(a.dataAtendimento, { mode: 'DATE' }) }</div>
                                                <div className={'col-4 border text-center  p-2'}>{a.status.nome}</div>
                                            </div>)
                                        })}

                                    </div>
                                </div>
                                {this.state.showPaginationAtendimentosAtrasados && (
                                    <div className="row justify-content-end p-1 mx-3 bg-cinza">
                                        <button
                                            type="button"
                                            className="btn btn-light"
                                            onClick={() => {
                                                this.initAtendimentosAtrasados();
                                            }}
                                        >
                                            <span className="icon-btn-add"></span>
                                            Exibir mais
                                        </button>

                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </ModalBody>
            </Modal>

        );
    }
}

function mapStateToProps(state) {
    const {user, permissions} = state.authentication;

    return {
        user,
        permissions
    };
}

const mapDispatch = ({alert: {error}, load: {loading}}) => ({
    error: (message, code) => error({message, code}),
    loading: (load: boolean) => loading({load})
});
export default connect(mapStateToProps, mapDispatch)(withRouter(AtendimentosEmAtraso));
