import { Formik } from "formik";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormSelectInput from "../../components/Inputs/FormSelectInput";
import { enumTipoErrors } from "../../constants/objects.constants";
import { convenioService } from "../../services/convenio.service";
import { cooperadoService } from "../../services/cooperado.service";
import { ErrorImportacaoNotificationService } from "../../services/errorImportacaoNotificationService";
import { estabelecimentoService } from "../../services/estabelecimento.service";
import { v4 as uuidv4 } from "uuid";

type Props = {
  errorImportacaoNotifications: ?any,
  findErros: ?any,
};

class CardErrorIntegracaoAtendimentoNotification extends PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      showUpdate: false,
    };
  }

  render() {
    let { showUpdate } = this.state;
    let { errorImportacaoNotifications } = this.props;
    return (
      <React.Fragment key={uuidv4()}>
        <div className="row align-items-center notification">
          <div className="col-2 col-md-1 py-2 pl-3 pr-1"></div>
          <div className="col-10 col-md-10 pr-0">
            <h2 id="tituloNotif" className="m-0 font-weight-bold">
              {errorImportacaoNotifications.tipoErro.description +
                " Numero Guia: " +
                errorImportacaoNotifications.numeroGuia +
                " Lote: " +
                errorImportacaoNotifications.importacaoIntegracaoAtendimento
                  .numeroLote}
            </h2>
            {errorImportacaoNotifications.tipoErro.description ===
              enumTipoErrors.ATENDIMENTO_CONVENIO_NAO_ENCONTRADO && (
              <>
                <p id="descricaoNotif" className="m-2 descricao color-red">
                  CNPJ do Convênio não encontrado:{" "}
                  {errorImportacaoNotifications.causaErro}
                </p>
                {errorImportacaoNotifications.numeroAtendimento && (
                  <p id="descricaoNotif" className="m-2 descricao color-green">
                    Atendimento:{" "}
                    {errorImportacaoNotifications.numeroAtendimento}
                  </p>
                )}
              </>
            )}
            {errorImportacaoNotifications.tipoErro.description ===
              enumTipoErrors.ATENDIMENTO_ESTABELECIMENTO_NAO_ENCONTRADO && (
              <>
                <p id="descricaoNotif" className="m-2 descricao color-green">
                  CNPJ do Estabelecimento não encontrado:{" "}
                  {errorImportacaoNotifications.causaErro}
                </p>
                {errorImportacaoNotifications.numeroAtendimento && (
                  <p id="descricaoNotif" className="m-2 descricao color-green">
                    Atendimento:{" "}
                    {errorImportacaoNotifications.numeroAtendimento}
                  </p>
                )}
              </>
            )}
            {errorImportacaoNotifications.tipoErro.description ===
              enumTipoErrors.ATENDIMENTO_COOPERADO_NAO_ENCONTRADO && (
              <>
                <p id="descricaoNotif" className="m-2 descricao color-green">
                  CPF do Cooperado não encontrado:{" "}
                  {errorImportacaoNotifications.causaErro}
                </p>
                {errorImportacaoNotifications.observacaoErro && (
                  <p id="descricaoNotif" className="m-2 descricao color-green">
                    Nome do Cooperado:{" "}
                    {errorImportacaoNotifications.observacaoErro}
                  </p>
                )}
                {errorImportacaoNotifications.numeroAtendimento && (
                  <p id="descricaoNotif" className="m-2 descricao color-green">
                    Atendimento:{" "}
                    {errorImportacaoNotifications.numeroAtendimento}
                  </p>
                )}
              </>
            )}
          </div>
          {showUpdate === false && (
            <div className="col-12 col-md-12 mt-4 mt-md-0 px-1 text-center text-md-center">
              <button
                onClick={() =>
                  this.setState({
                    showUpdate: true,
                  })
                }
                className="btn btn-primary white mt-3"
              >
                Alterar
              </button>
            </div>
          )}

          {showUpdate && (
            <React.Fragment>
              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={errorImportacaoNotifications}
                enableReinitialize={true}
                ref={(form) => {
                  this.formRef = form;
                }}
                onSubmit={(values, actions) => {
                  {
                    errorImportacaoNotifications.tipoErro.description ===
                      enumTipoErrors.ATENDIMENTO_CONVENIO_NAO_ENCONTRADO &&
                      ErrorImportacaoNotificationService.saveNewCnpjConvenio(
                        values.convenio,
                        errorImportacaoNotifications.id
                      )
                        .then((response) => {
                          actions.setSubmitting(false);
                          ErrorImportacaoNotificationService.buscarErrosIntegracaoAtendimentos(
                            errorImportacaoNotifications
                              .importacaoIntegracaoAtendimento.id
                          ).then((response) => {
                            this.props.findErros(
                              errorImportacaoNotifications
                                .importacaoIntegracaoAtendimento.id
                            );
                          });
                        })
                        .catch(() => {
                          actions.setSubmitting(false);
                          this.props.loading(false);
                        });
                  }
                  {
                    errorImportacaoNotifications.tipoErro.description ===
                      enumTipoErrors.ATENDIMENTO_ESTABELECIMENTO_NAO_ENCONTRADO &&
                      ErrorImportacaoNotificationService.saveNewCnpjEstabelecimento(
                        values.estabelecimento,
                        errorImportacaoNotifications.id
                      )
                        .then((response) => {
                          actions.setSubmitting(false);
                          ErrorImportacaoNotificationService.buscarErrosIntegracaoAtendimentos(
                            errorImportacaoNotifications
                              .importacaoIntegracaoAtendimento.id
                          ).then((response) => {
                            this.props.findErros(
                              errorImportacaoNotifications
                                .importacaoIntegracaoAtendimento.id
                            );
                          });
                        })
                        .catch(() => {
                          actions.setSubmitting(false);
                          this.props.loading(false);
                        });
                  }
                  {
                    errorImportacaoNotifications.tipoErro.description ===
                      enumTipoErrors.ATENDIMENTO_COOPERADO_NAO_ENCONTRADO &&
                      ErrorImportacaoNotificationService.saveNewCpfCooperado(
                        values.cooperado,
                        errorImportacaoNotifications.id
                      )
                        .then((response) => {
                          actions.setSubmitting(false);
                          ErrorImportacaoNotificationService.buscarErrosIntegracaoAtendimentos(
                            errorImportacaoNotifications
                              .importacaoIntegracaoAtendimento.id
                          ).then((response) => {
                            this.props.findErros(
                              errorImportacaoNotifications
                                .importacaoIntegracaoAtendimento.id
                            );
                          });
                        })
                        .catch(() => {
                          actions.setSubmitting(false);
                          this.props.loading(false);
                        });
                  }
                }}
              >
                {({
                  values,
                  errors,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="col-12 col-md-12 mt-4 mt-md-4  m-5 text-center text-md-center">
                      <div className="col-12 col-md-12 mt-4 mt-md-4 px-0 ml-4">
                        {errorImportacaoNotifications.tipoErro.description ===
                          enumTipoErrors.ATENDIMENTO_CONVENIO_NAO_ENCONTRADO && (
                          <React.Fragment>
                            <div className="form-group col-12 col-lg-12">
                              <label>Convênio*:</label>
                              <FormSelectInput
                                id="convenio"
                                service={convenioService.findAll}
                                labelKey="nome"
                                name="convenio"
                                placeholder="Selecione um convênio "
                                value={values.convenio}
                                returnFullObject={true}
                                onChange={setFieldValue}
                                clearable={true}
                                errorMensagem={errors.convenio}
                              />
                            </div>
                          </React.Fragment>
                        )}
                        {errorImportacaoNotifications.tipoErro.description ===
                          enumTipoErrors.ATENDIMENTO_ESTABELECIMENTO_NAO_ENCONTRADO && (
                          <React.Fragment>
                            <div className="form-group col-12 col-lg-12">
                              <label>Estabelecimento*:</label>
                              <FormSelectInput
                                id="estabelecimento"
                                service={estabelecimentoService.findAll}
                                labelKey="nome"
                                name="estabelecimento"
                                placeholder="Selecione um estabelecimento "
                                value={values.estabelecimento}
                                returnFullObject={true}
                                onChange={setFieldValue}
                                clearable={true}
                                errorMensagem={errors.estabelecimento}
                              />
                            </div>
                          </React.Fragment>
                        )}
                        {errorImportacaoNotifications.tipoErro.description ===
                          enumTipoErrors.ATENDIMENTO_COOPERADO_NAO_ENCONTRADO && (
                          <div className="form-group col-12 col-lg-12">
                            <label>CPF do Cooperado*:</label>
                            <FormSelectInput
                              id="cooperado"
                              service={cooperadoService.findAll}
                              labelKey="nome"
                              name="cooperado"
                              placeholder="Selecione um cooperado "
                              value={values.cooperado}
                              returnFullObject={true}
                              onChange={setFieldValue}
                              clearable={true}
                              errorMensagem={errors.cooperado}
                            />
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-row flex-sm-row ">
                        <div className="col-6 col-md-6 mt-4 mt-md-4 px-0 m-3">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary white"
                          >
                            Salvar
                          </button>
                        </div>
                        <div className="col-6 col-md-6 mt-4 mt-md-4 px-0 ">
                          <button
                            onClick={() =>
                              this.setState({
                                showUpdate: false,
                              })
                            }
                            className="btn btn-primary white"
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  load: { loading },
  errorImportacaoNotifications: {
    toogleErrorImportacaoNotification,
    addAllErrors,
  },
}) => ({
  toogleErrorImportacaoNotification: () => toogleErrorImportacaoNotification(),
  loading: (load: boolean) => loading({ load }),
  addAllErrors: (errorsImportacaoNotifications) =>
    addAllErrors(errorsImportacaoNotifications),
});

function mapStateToProps(state) {
  const { showError, errorsImportacaoNotifications } =
    state.errorImportacaoNotifications;
  const { user } = state.authentication;

  return {
    showError,
    user,
    errorsImportacaoNotifications,
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(CardErrorIntegracaoAtendimentoNotification));
