export const datatableConstants = {
    ALL_COLUMNS_DATATABLE_FILTER:{fieldName:'allColumns',fieldDescription:'Em todas as colunas'},
    NUMBER:'NUMBER',
    STRING:'STRING',
    DATE:'DATE',
    PHOTO:'PHOTO',
    BOOLEAN:'BOOLEAN',
    OBJECT:'OBJECT',
    ARRAY:'ARRAY',
    ENUM:'ENUM',
    ARRAY_STRING:'ARRAY_STRING',
    DOCUMENT:'DOCUMENT',
    SELECT:'SELECT',
};