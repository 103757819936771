//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';
import { objectsConstants } from "../constants/objects.constants";
export const statusAtendimentoService = {
  findAllSetor,
  setSetorAtual,
  findAllByAtendimentos,
  findAllByAtendimentosComProtocolo,
  findAllByAtendimentosViewComProtocolo,
  findAllFaturamento,
  findStatusByCooperativa,
  findAllByAtendimentosStatusRecebido15Dias,
  findCriacaoLoteConvenioPorTipoGuia,
  statusEncaminhamentoEmMassa,
  porAtendimentoPendenteGuiaRecusada
};


function findAllByAtendimentos(idsAtendimento) {
    return defaultService.doPost(`${urlsConstants.STATUS_ATENDIMENTO}porAtendimento`,idsAtendimento);
}

function findAllByAtendimentosComProtocolo(atendimentos) {
  if (atendimentos.length === 0) {
    return defaultService.doPost(`${urlsConstants.STATUS_ATENDIMENTO}porAtendimento`);
  } else {
    return defaultService.doPost(`${urlsConstants.STATUS_ATENDIMENTO}porAtendimento/${atendimentos[0].numeroProtocolo}`);
  }
}

function statusEncaminhamentoEmMassa() {
    return defaultService.doPost(`${urlsConstants.STATUS_ATENDIMENTO}statusEncaminhamentoEmMassa`);
}

 function findAllByAtendimentosViewComProtocolo() {
   return defaultService.doPost(`${urlsConstants.STATUS_ATENDIMENTO}porAtendimentoComProtocolo`);
}

function findAllFaturamento() {
  return defaultService.doGet(`${urlsConstants.STATUS_ATENDIMENTO}statusFaturamento`);
}

function findAllSetor() {
  return defaultService.doGet(`${urlsConstants.STATUS_ATENDIMENTO_SETORES}`);
}
function setSetorAtual(idSetor) {
  return defaultService.doGet(`${urlsConstants.USUARIO}setSetorAtual/${idSetor}`);
}

function findStatusByCooperativa() {
  return defaultService.doGet(`${urlsConstants.STATUS_ATENDIMENTO}statusPorCooperativa`);
}

function findAllByAtendimentosStatusRecebido15Dias(type, convenio) {
  return defaultService.doPost(`${urlsConstants.ATENDIMENTO}atendimentosAtrasadosConvenioTipoGuiaCooperativa`, {type: type, convenio: convenio});
}

function findCriacaoLoteConvenioPorTipoGuia(atendimento){
  let type;
  for(let i=0; i< objectsConstants.TIPOS_GUIA_CONVENIO.length;i++){
    if(objectsConstants.TIPOS_GUIA_CONVENIO[i].label===atendimento.tipoGuia){
     type = objectsConstants.TIPOS_GUIA_CONVENIO[i].id;
    }
  }

  if(atendimento&&atendimento.convenio&&atendimento.tipoGuia) {
    return defaultService.doGet(`${urlsConstants.ATENDIMENTO}findByRegraConvenioCriarLotePorTipoGuia/${atendimento.convenio.id}/${type}`);
  }

}

function porAtendimentoPendenteGuiaRecusada(idsAtendimento) {
  return defaultService.doPost(`${urlsConstants.STATUS_ATENDIMENTO}porAtendimentoPendenteGuiaRecusada`,idsAtendimento);
}