//@flow
import thunk from 'redux-thunk';
import { init } from '@rematch/core';
import { alert } from '../reducers/alert.reducer';
import { fieldMessage } from '../reducers/fieldMessage.reducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import * as models from '../models';

export const store = init({
    models,
    redux: {
        reducers: {
            alert,
            toastr: toastrReducer,
            fieldMessage
        },
        middlewares: [thunk]
    }
});
