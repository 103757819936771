import * as _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup } from "../../../../components/FormGroup";
import FormInput from "../../../../components/Inputs/FormInput";
import InputViewEdit from "../../../../components/Inputs/InputViewEdit";
import MoneyInput from "../../../../components/Inputs/MoneyInput";
import SelectInput from "../../../../components/Inputs/SelectInput";
import { formatterHelper } from "../../../../helpers/formatter.helper";
import SelectableInput from "../../../../sascComponents/inputs/SelectableInput";
import { opmService } from "../../../../services/opm.service";

class DadosSolicitacaoOpmForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  addProcedimento = () => {
    const { values, setFieldValue, setValues } = this.props;
    let newValues = _.cloneDeep(values);
    let numero = newValues.guiaInternacaoOpms.length + 1;
    newValues.guiaInternacaoOpms.push({ idList: new Date().getTime(), numero });
    // newValues.atendimentoExecucaos.push({ idList: new Date().getTime(), numero, type: objectsConstants.ENUM_TIPOS_GUIA.SADT });
    // newValues.identificacaoAtendimentoExecucao.push({ idList: new Date().getTime(), numero });

    setValues(newValues);
  };

  removeProcedimento = indexProcedimento => {
    const { values, setFieldValue, setValues } = this.props;
    let newValues = _.cloneDeep(values);

    // let procedimentoExecucao = newValues.guiaInternacaoOpms[indexProcedimento];
    newValues.guiaInternacaoOpms.splice(indexProcedimento, 1);
    //remove execucao relacionada 
    // _.remove(newValues.atendimentoExecucaos, { numero: procedimentoExecucao.numero });
    // _.remove(newValues.identificacaoAtendimentoExecucao, { numero: procedimentoExecucao.numero });
    // //se remover todos os elementos add um padrão
    // if (!newValues.identificacaoAtendimentoExecucao.length) {
    //   newValues.identificacaoAtendimentoExecucao = [{
    //     numero: 1,
    //     codigoCbo: null,
    //     grau: "",

    //   }]
    // }
    // if (!newValues.atendimentoExecucaos.length) {
    //   newValues.atendimentoExecucaos = [objectsConstants.ATENDIMENTO_EXECUCAO]
    // }
    // //reordena lista de execucao para bater index
    // newValues.identificacaoAtendimentoExecucao.map((identificacao, indexIdentificacao) => {
    //   identificacao.numero = indexIdentificacao + 1;
    // })
    // //reordena lista de dados execucao para bater index
    // newValues.atendimentoExecucaos.map((atendimentoExecucao, indexIdentificacao) => {
    //   atendimentoExecucao.numero = indexIdentificacao + 1;
    // })


    setValues(newValues);
    this.props.clear()
    this.props.info({
      message: `Procedimento removido com sucesso!`
    });

  };

  validarProcedimento = (values, codigo) => {
    if(values.convenio && values.convenio.listRegrasProcedimentoTipoGuia) {
      let validacao = values.convenio.listRegrasProcedimentoTipoGuia.some(r => {
        if(values.type.includes(r.tipoGuia.nome))
          return r.procedimentos.some(p => p.codigo === codigo);
        return false;
      })
      return validacao;
    }
  }

  render() {
    const {
      permissions,
      setFieldValue,
      values,
      handleChange,
      viewMode,
      errors
    } = this.props;

    if (values.guiaInternacaoOpms == null || values.guiaInternacaoOpms.length == 0) {
      values.guiaInternacaoOpms = [{numero: 1,valorTotal: 0}];
    }
    
    return (

      < div className="row section-form" >
        <h6 className="w-100">
          OPMs solicitados
          </h6>


        {
          values &&
          values.guiaInternacaoOpms &&
          values.guiaInternacaoOpms.map((procedimento, indexProcedimento) => {
            return (
              <React.Fragment>
                <div className="quebra" key={procedimento.codigo}></div>
                <FormGroup className="central fixo">
                  <label>Nº</label>
                  <p>{indexProcedimento + 1}</p>
                </FormGroup>
                 
                <SelectableInput viewMode={viewMode} className="central"
                  name={`guiaInternacaoOpms[${indexProcedimento}].tabela`}
                  label={"Tabela"}
                                 atendimento={values}
                  value={values.guiaInternacaoOpms[indexProcedimento].tabela}
                  id={values.id}
                >
                  <InputViewEdit
                    label={"Tabela"}
                    required={false}
                    component={FormInput}
                    className={"destaque"}
                    onChange={(name, date) => {
                      setFieldValue(name, date);
                    }}
                    viewMode={viewMode}
                    name={`guiaInternacaoOpms[${indexProcedimento}].tabela`}
                    id={`guiaInternacaoOpms${indexProcedimento}.tabela`}
                    erroMensagem={
                      _.get(errors, `guiaInternacaoOpms[${indexProcedimento}].tabela`)
                    }
                    placeholder=""
                    type={"text"}
                    //defaultValue={lenght}
                    value={values.guiaInternacaoOpms[indexProcedimento].tabela}
                    alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                  />
                </SelectableInput>

                <SelectableInput viewMode={viewMode} className="fixo"
                  name={`guiaInternacaoOpms[${indexProcedimento}].opm.codigo`}
                  label={"Código OPM"}  atendimento={values}
                  value={_.get(values, `guiaInternacaoOpms[${indexProcedimento}].opm.codigo`)}
                  id={values.id}
                >

                  <InputViewEdit
                    label={"Código OPM"}
                    component={SelectInput}
                    onChange={(name, date) => {
                      setFieldValue(name,date);
                    }}
                    viewMode={viewMode}
                    required={false}
                    name={`guiaInternacaoOpms[${indexProcedimento}].opm`}
                    placeholder="Digite o código"
                    type={"text"}
                    value={
                      _.get(values, `guiaInternacaoOpms[${indexProcedimento}].opm`)
                    }
                    defaultValue={_.get(values, `guiaInternacaoOpms[${indexProcedimento}].opm.codigo`)}
                    id={`guiaInternacaoOpms${indexProcedimento}codigo`}
                    erroMensagem={
                      _.get(errors, `guiaInternacaoOpms[${indexProcedimento}].opm.codigo`)
                    }
                    labelKey={"codigo"}
                    valueKey={"id"}
                    returnFullObject={true}
                    onFetchData={opmService.find}
                    alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                  />
                </SelectableInput>
                <SelectableInput viewMode={viewMode} className=""
                  name={`guiaInternacaoOpms[${indexProcedimento}].opm.descricao`}
                  label={"Descrição"}  atendimento={values}
                  value={values.guiaInternacaoOpms[indexProcedimento]
                    .descricao}
                  id={values.id}
                >
                  <InputViewEdit
                    label={"Descrição"}
                    component={SelectInput}
                    onChange={(name, date) => {
                      setFieldValue(name, date);
                    }}
                    viewMode={viewMode}
                    required={false}
                    name={`guiaInternacaoOpms[${indexProcedimento}].opm`}
                    placeholder="Digite a descrição"
                    value={
                      _.get(values, `guiaInternacaoOpms[${indexProcedimento}].opm`)
                    }
                    defaultValue={_.get(values, `guiaInternacaoOpms[${indexProcedimento}].opm.descricao`)}
                    id={`guiaInternacaoOpms${indexProcedimento}descricao`}
                    erroMensagem={
                      _.get(errors, `guiaInternacaoOpms[${indexProcedimento}].opm.descricao`)
                    }
                    labelKey={"descricao"}
                    valueKey={"id"}
                    returnFullObject={true}
                    onFetchData={opmService.findByDescricao}
                    alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                  />
                </SelectableInput>
                <SelectableInput viewMode={viewMode} className="fixo"
                  name={`guiaInternacaoOpms[${indexProcedimento}].opm.fabricante`}
                  label={"Fabricante"}  atendimento={values}
                  value={_.get(values, `guiaInternacaoOpms[${indexProcedimento}].opm.fabricante`)}
                  id={values.id}
                >
                  <InputViewEdit
                    label={"Fabricante"}
                    //required={true}
                    component={FormInput}
                    className={"destaque"}
                    onChange={(name, date) => {
                      setFieldValue(name, date);
                    }}
                    viewMode={viewMode}
                    disabled={_.isNil(_.get(values, `guiaInternacaoOpms[${indexProcedimento}].opm.id`))}
                    //defaultValue={lenght}
                    value={_.get(values, `guiaInternacaoOpms[${indexProcedimento}].opm.fabricante.nome`)}
                    name={`guiaInternacaoOpms[${indexProcedimento}].opm.fabricante.nome`}
                    id={`guiaInternacaoOpms${indexProcedimento}fabricante`}
                    placeholder=""
                    erroMensagem={
                      _.get(errors, `guiaInternacaoOpms[${indexProcedimento}].opm.fabricante`)
                    }
                  />
                </SelectableInput>
                <SelectableInput viewMode={viewMode} className="central"
                  name={`guiaInternacaoOpms[${indexProcedimento}].quantidade`}
                  label={"Qtde."}  atendimento={values}
                  value={values.guiaInternacaoOpms[indexProcedimento].quantidade}
                  id={values.id}
                >
                  <InputViewEdit
                    label={"Qtde."}
                    required={false}
                    component={FormInput}
                    className={"destaque"}
                    onChange={(name, date) => {
                      setFieldValue(name, date);
                    }}
                    viewMode={viewMode}
                    name={`guiaInternacaoOpms[${indexProcedimento}].quantidade`}
                    id={`guiaInternacaoOpms${indexProcedimento}quantidade`}
                    erroMensagem={
                      _.get(errors, `guiaInternacaoOpms[${indexProcedimento}].quantidade`)
                    }
                    placeholder=""
                    type={"number"}
                    //defaultValue={lenght}
                    value={values.guiaInternacaoOpms[indexProcedimento].quantidade}
                    disabled={
                      values &&
                      values.convenio !== undefined &&
                      this.validarProcedimento(values, values.guiaInternacaoOpms[indexProcedimento].codigoProcedimento) &&
                      true
                    }
                    alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                  />
                </SelectableInput>
                <SelectableInput viewMode={viewMode} className="central"
                  atendimento={values}
                  name={`guiaInternacaoOpms[${indexProcedimento}].valorTotal`}
                  label={"Valor total"}
                  value={
                    values.guiaInternacaoOpms[indexProcedimento].valorTotal
                  }
                >
                  <InputViewEdit
                    label="Valor total"
                    component={MoneyInput}
                    onChange={(name, date) => {
                      setFieldValue(name, date);
                    }}
                    name={`guiaInternacaoOpms[${indexProcedimento}].valorTotal`}
                    placeholder="0,00"
                    viewMode={viewMode}
                    value={
                      values.guiaInternacaoOpms[indexProcedimento]
                        .valorTotal
                    }
                    defaultValue={formatterHelper.money(values.guiaInternacaoOpms[indexProcedimento]
                      .valorTotal)}
                  />
                </SelectableInput>
                {!viewMode && (
                  <React.Fragment>
                    <FormGroup className="central fixo mobile-100">
                      {indexProcedimento + 1 !==
                        values.guiaInternacaoOpms.length && (
                          <React.Fragment>
                            <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                              <button
                                className="btn-menos"
                                onClick={() => {
                                  this.removeProcedimento(indexProcedimento);
                                }}
                              ></button>
                            </div>
                            <div className="bg-branco d-block d-sm-none col-12 p-2 text-center">
                              <button
                                className="btn-menos"
                                onClick={() => {
                                  this.removeProcedimento(indexProcedimento);
                                }}
                              ></button>
                            </div>
                          </React.Fragment>
                        )}
                      {indexProcedimento + 1 ===
                        values.guiaInternacaoOpms.length && (
                          <React.Fragment>
                            <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                              {values.guiaInternacaoOpms.length > 1 && <button
                                className="btn-menos"
                                onClick={() => {
                                  this.removeProcedimento(indexProcedimento);
                                }}
                              ></button>}
                              <button
                                className="btn-mais"
                                onClick={this.addProcedimento}
                              ></button>
                            </div>
                            <div className="bg-branco d-block d-sm-none col-12 p-2 text-center">
                              <button
                                className="btn-mais"
                                onClick={this.addProcedimento}
                              ></button>
                            </div>
                          </React.Fragment>
                        )}
                    </FormGroup>
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          })
        }
      </div >
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu }, alert: { info, clear }, }) => ({
  info: msg => info(msg),
  clear: () => clear(),
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosSolicitacaoOpmForm));
