import { Can } from "@casl/react";
import { Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Collapse } from "reactstrap";
import * as Yup from "yup";
import BtnLink from "../../../components/Buttons/BtnLink";
import { FormGroup } from "../../../components/FormGroup";
import DatePickerInput from "../../../components/Inputs/DatePickerInput";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import SelectInput from "../../../components/Inputs/SelectInput";
import { objectsConstants } from "../../../constants/objects.constants";
import { messages } from "../../../helpers/message.helper";
import { ConfirmAcationService } from "../../../services/ConfirmAcationService";
import { convenioService } from "../../../services/convenio.service";
import MenuHistorico from "../../guias/historico/MenuHistorico";
const EditFaturamentoConvenio = Yup.object().shape({
  // custum validation de um campo
  convenioFaturamentos: Yup.array().of(
    Yup.object().shape({
      mes: Yup.string().required("Obrigatório"),
      ano: Yup.string().required("Obrigatório"),
      prazoFaturamento: Yup.string().required("Obrigatório"),
      dataEnvio: Yup.string().required("Obrigatório")
    })
  )
});
class CronogramaConveniosForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      viewMode: true,
      listaHistoricos: [
      ]
    };
  }

  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  };

  cancelEditMode = (e, setValues) => {
    e.stopPropagation();
    ConfirmAcationService.confirmMsg(
      () => {
        this.confirmCancel(setValues);
      },
      `Cancelar Edição `,
      "Você confirma que deseja cancelar a edição dos cronogramas",
      null,
      "Não",
      "Sim",
      objectsConstants.TYPE_CANCEL
    );
  };

  confirmCancel = setValues => {
    this.setState({ viewMode: true, collapse: true }, () => {
      setValues(this.getRegrasObjectDefault());
    });
  };

  addMes = e => {
    e.stopPropagation();
  };
  loadHistoryCronograma = () => {
    this.props.loading(true);
    convenioService.historicoCronograma(this.props.convenio.id).then(result => {
      this.setState({ listaHistoricos: result.data }, () => {
        this.props.loading(false);
      });
    }, () => {
      this.props.loading(false);
    });
  }
  setMenuHistorico = status => {
    this.setState({ openMenuHistorico: status }, () => {
      if (this.state.openMenuHistorico) {
        this.loadHistoryCronograma()
        document.body.classList.add("bodyNoScroll");
        document.getElementById("bodyMenu").scrollIntoView();
      } else {
        document.body.classList.remove("bodyNoScroll");
      }
    });
  };
  toggleHistorico = toggle => {
    this.setMenuHistorico(!this.state.openMenuHistorico);
  };

  salvar = e => {
    e.stopPropagation();
    this.setState({ viewMode: true, collapse: true });
  };

  editMode = e => {
    this.setState({ viewMode: false, collapse: true });
  };

  getYearsOptions = () => {
    const yearAtual = moment().year();
    let years = [];
    for (let i = 0; i < 3; i++) {
      years.push({ label: yearAtual + i, value: yearAtual + i });
    }
    return years;
  };

  getMonthOfYear = () => {
    let months = [];
    for (let i = 0; i < 11; i++) {
      let month = moment()
        .month(i)
        .format("MMMM");
      months.push({ label: month, value: month });
    }
    return months;
  };

  editAndAddMes = (e, setFieldValues, values) => {
    e.stopPropagation();
    this.setState({ viewMode: false, collapse: true }, () => {
      this.addFaturamento(setFieldValues, values);
    });
  };

  addFaturamento = (setFieldValues, values) => {
    let convenioFaturamentos = _.cloneDeep(values.convenioFaturamentos);
    convenioFaturamentos.push({ idKey: new Date().getTime() });
    setFieldValues("convenioFaturamentos", convenioFaturamentos);
  };

  removeFaturamento = (setFieldValues, values, index) => {
    let convenioFaturamentos = _.cloneDeep(values.convenioFaturamentos);
    convenioFaturamentos.splice(index, 1);
    setFieldValues("convenioFaturamentos", convenioFaturamentos);
  };

  removeFaturamentoAndEdit = (setFieldValues, values, index) => {
    this.setState({ viewMode: false, collapse: true }, () => {
      let convenioFaturamentos = _.cloneDeep(values.convenioFaturamentos);
      convenioFaturamentos.splice(index, 1);
      setFieldValues("convenioFaturamentos", convenioFaturamentos);
    });
  };
  getRegrasObjectDefault=()=>{
    return this.props.convenio &&
              this.props.convenio.convenioFaturamentos &&
              this.props.convenio.convenioFaturamentos.length > 0
              ? {
                convenioFaturamentos: this.props.convenio.convenioFaturamentos
              }
              : { convenioFaturamentos: [{}] }
  }
  render() {
    // let canEdit = situacao === objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA || situacao === objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE;

    //let canEdit = true;
    return (
      <React.Fragment>
        <Formik
          validationSchema={EditFaturamentoConvenio}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={
            this.getRegrasObjectDefault()
          }
          onSubmit={(values, { setSubmitting, setValues }) => {
            this.props.loading(true);
            convenioService
              .saveFaturamento(
                values.convenioFaturamentos,
                this.props.convenio.id
              )
              .then(
                response => {
                  this.props.success({
                    message: `Cronograma para faturamento, foi salvo com sucesso!`
                  });
                  this.props.loading(false);
                  this.setState({ viewMode: true, collapse: true }, () => {
                    this.props.loadConvenio();
                    setValues({ convenioFaturamentos: response.data });
                  });
                },
                errors => {
                  this.props.error({
                    message:
                      "Não foi possível salvar Cronograma para faturamento."
                  });
                  this.props.loading(false);
                }
              );
          }}
          ref={form => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validateForm,
            setValues
          }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div
                  className={`mt-4 accordion ${
                    this.state.collapse ? "open" : ""
                    }`}
                >
                  <div className="card content-form cardGuia cardCrud">
                    <div
                      className="card-header d-flex align-items-center flex-column flex-sm-row b-bottom"
                      id="headingOne"
                      onClick={this.toggle}
                    >
                      <div className="flex-grow-1 d-flex align-items-center">
                        <div className="p-1 status-guia">Cronograma</div>
                      </div>
                      <div className="buttons-right ml-auto">
                        {this.state.viewMode && (
                            <Can
                                I={objectsConstants.ALTERAR}
                                a={objectsConstants.EDITAR_CONVENIO}
                                ability={this.props.permissions}
                            >
                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-historico"}
                              title={"Histórico"}
                              onSubmit={e => {
                                e.stopPropagation();
                                this.toggleHistorico();
                              }}
                              disabled={false}
                            />
                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-add"}
                              title={"Adicionar mês"}
                              onSubmit={e =>
                                this.editAndAddMes(e, setFieldValue, values)
                              }
                              disabled={false}
                            />
                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-editar"}
                              title={"Editar"}
                              onSubmit={e => {
                                e.stopPropagation();
                                this.editMode();
                              }}
                              disabled={false}
                            />
                          </Can>
                        )}
                        {!this.state.viewMode && (
                            <Can
                                I={objectsConstants.ALTERAR}
                                a={objectsConstants.EDITAR_CONVENIO}
                                ability={this.props.permissions}
                            >
                            <button
                              type={"button"}
                              className={"btn btn-secondary btn-pequeno mr-3"}
                              onClick={e => {
                                this.cancelEditMode(e, setValues);
                              }}
                            >
                              Cancelar
                          </button>
                            <button
                              type={"button"}
                              onClick={e => {
                                e.stopPropagation();
                                validateForm(values).then(errors => {
                                  if (_.isEmpty(errors)) {
                                    handleSubmit(values, { setValues });
                                  } else {
                                    this.props.error({
                                      message:
                                        messages.ERRO_CRIAR_CRONOGRAMA_CONVENIO
                                    });
                                  }
                                });
                              }}
                              className={"btn btn-primary btn-pequeno mr-2"}
                              icone={"icon-btn-check-black"}
                            >
                              Salvar
                          </button>
                          </Can>
                        )}
                      </div>
                      <span
                        className={`accordion ${
                          this.state.collapse ? "icon-traco" : "icon-seta-baixo"
                          }`}
                      ></span>
                    </div>
                    <Collapse isOpen={this.state.collapse}>
                      <div
                        id="guiaUm"
                        className="form-inside collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#guiaUm"
                      >
                        <React.Fragment>
                          <div className="row section-form">
                            <h6 className="w-100">Cronograma para faturamento</h6>
                            {values &&
                              values.convenioFaturamentos.map(
                                (faturamento, index) => {
                                  return (
                                    <React.Fragment
                                      key={
                                        faturamento.id
                                          ? faturamento.id
                                          : faturamento.idKey
                                      }
                                    >

                                      {index > 0 ? <div className="quebra"></div> : ""}
                                      <FormGroup className="">
                                        <InputViewEdit
                                          component={SelectInput}
                                          onChange={(name, date) => {
                                            setFieldValue(name, date);
                                          }}
                                          label={"Mês"}
                                          id={`convenioFaturamentos${index}mes`}
                                          options={this.getMonthOfYear()}
                                          name={`convenioFaturamentos[${index}].mes`}
                                          placeholder=""
                                          erroMensagem={
                                            errors &&
                                              errors.convenioFaturamentos &&
                                              errors.convenioFaturamentos.length >
                                              index &&
                                              errors.convenioFaturamentos[index]
                                              ? errors.convenioFaturamentos[index]
                                                .mes
                                              : null
                                          }
                                          className={""}
                                          viewMode={this.state.viewMode}
                                          returnFullObject={false}
                                          searchable={true}
                                          labelKey={"label"}
                                          valueKey={"value"}
                                          value={faturamento.mes}
                                        />
                                      </FormGroup>
                                      <FormGroup className="">
                                        <InputViewEdit
                                          component={SelectInput}
                                          onChange={(name, date) => {
                                            setFieldValue(name, date);
                                          }}
                                          id={`convenioFaturamentos${index}ano`}
                                          erroMensagem={
                                            errors &&
                                              errors.convenioFaturamentos &&
                                              errors.convenioFaturamentos.length >
                                              index &&
                                              errors.convenioFaturamentos[index]
                                              ? errors.convenioFaturamentos[index]
                                                .ano
                                              : null
                                          }
                                          label={"Ano"}
                                          options={this.getYearsOptions()}
                                          name={`convenioFaturamentos[${index}].ano`}
                                          placeholder=""
                                          className={""}
                                          viewMode={this.state.viewMode}
                                          returnFullObject={false}
                                          searchable={true}
                                          labelKey={"label"}
                                          valueKey={"value"}
                                          value={faturamento.ano}
                                        />
                                      </FormGroup>
                                      <FormGroup className="">
                                        <InputViewEdit
                                          component={DatePickerInput}
                                          label={"Prazo para faturamento no mês"}
                                          onChange={(name, date) => {
                                            setFieldValue(name, date);
                                          }}
                                          id={`convenioFaturamentos${index}prazoFaturamento`}
                                          erroMensagem={
                                            errors &&
                                              errors.convenioFaturamentos &&
                                              errors.convenioFaturamentos.length >
                                              index &&
                                              errors.convenioFaturamentos[index]
                                              ? errors.convenioFaturamentos[index]
                                                .prazoFaturamento
                                              : null
                                          }
                                          maxDate={
                                            faturamento.ano && faturamento.mes
                                              ? moment()
                                                .month(faturamento.mes)
                                                .year(faturamento.ano)
                                                .add("months", 1)
                                                .date(1)
                                                .subtract("days", 1)
                                              : null
                                          }
                                          minDate={
                                            faturamento.ano && faturamento.mes
                                              ? moment()
                                                .month(faturamento.mes)
                                                .year(faturamento.ano)
                                                .date(1)
                                                .subtract("days", 1)
                                              : null
                                          }
                                          mask={"99/99/9999"}
                                          name={`convenioFaturamentos[${index}].prazoFaturamento`}
                                          placeholder=""
                                          defaultValue={
                                            faturamento.prazoFaturamento
                                              ? moment(
                                                faturamento.prazoFaturamento
                                              ).format("DD/MM/YYYY")
                                              : "--"
                                          }
                                          viewMode={this.state.viewMode}
                                          type={"text"}
                                          value={faturamento.prazoFaturamento}
                                        />
                                      </FormGroup>
                                      <FormGroup className="">
                                        <InputViewEdit
                                          component={DatePickerInput}
                                          label={"Data de envio"}
                                          onChange={(name, date) => {
                                            setFieldValue(name, date);
                                          }}
                                          id={`convenioFaturamentos${index}dataEnvio`}
                                          erroMensagem={
                                            errors &&
                                              errors.convenioFaturamentos &&
                                              errors.convenioFaturamentos.length >
                                              index &&
                                              errors.convenioFaturamentos[index]
                                              ? errors.convenioFaturamentos[index]
                                                .dataEnvio
                                              : null
                                          }
                                          maxDate={
                                            faturamento.ano && faturamento.mes
                                              ? moment()
                                                .month(faturamento.mes)
                                                .year(faturamento.ano)
                                                .add("months", 1)
                                                .date(1)
                                                .subtract("days", 1)
                                              : null
                                          }
                                          minDate={
                                            faturamento.ano && faturamento.mes
                                              ? moment()
                                                .month(faturamento.mes)
                                                .year(faturamento.ano)
                                                .date(1)
                                                .subtract("days", 1)
                                              : null
                                          }
                                          mask={"99/99/9999"}
                                          name={`convenioFaturamentos[${index}].dataEnvio`}
                                          defaultValue={
                                            faturamento.dataEnvio
                                              ? moment(
                                                faturamento.dataEnvio
                                              ).format("DD/MM/YYYY")
                                              : "--"
                                          }
                                          placeholder=""
                                          viewMode={this.state.viewMode}
                                          type={"text"}
                                          value={faturamento.dataEnvio}
                                        />
                                      </FormGroup>
                                      {!this.state.viewMode && (
                                        <FormGroup className="central fixo">
                                          <BtnLink
                                            type={"button"}
                                            className={""}
                                            icone={"icon-btn-excluir"}
                                            title={"Excluir"}
                                            onSubmit={() => {
                                              this.removeFaturamento(
                                                setFieldValue,
                                                values,
                                                index
                                              );
                                            }}
                                            disabled={false}
                                          />
                                        </FormGroup>
                                      )}
                                      {this.state.viewMode && (
                                        <FormGroup className="central fixo">
                                          <BtnLink
                                            type={"button"}
                                            className={""}
                                            icone={"icon-btn-excluir"}
                                            title={"Excluir"}
                                            onSubmit={() => {
                                              this.removeFaturamentoAndEdit(
                                                setFieldValue,
                                                values,
                                                index
                                              );
                                            }}
                                            disabled={false}
                                          />
                                        </FormGroup>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                          </div>
                          {!this.state.viewMode && (
                            <Can
                              I={objectsConstants.ALTERAR}
                              a={objectsConstants.CRUD_ATENDIMENTOS_CRIAR}
                              ability={this.props.permissions}
                            >
                              <div className="d-flex justify-content-end align-items-center mb-2  mt-2">
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  onClick={() => {
                                    this.addFaturamento(setFieldValue, values);
                                  }}
                                >
                                  <span className="icon-btn-add"></span>
                                Adicionar
                              </button>
                              </div>
                            </Can>
                          )}
                        </React.Fragment>
                      </div>
                    </Collapse>
                  </div>
                </div>
                <MenuHistorico
                  titulo={this.props.convenio && this.props.convenio.nome}
                  subTitulo={"Cronograma"}
                  listaHistoricos={this.state.listaHistoricos}
                  toggleHistorico={this.toggleHistorico}
                  openMenuHistorico={this.state.openMenuHistorico}
                />
              </form>
            )}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  menu: { showMenu, hideMenu },
  load: { loading },
  alert: { success, error, clear }
}) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu(),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(CronogramaConveniosForm));
