//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ConsultorioEdit from './ConsultorioEdit';
import ConsultorioList from './ConsultorioList';
import ConsultorioView from './ConsultorioView';
export default class Consultorios extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/consultorios" component={ConsultorioList} exact />
          <Route path="/consultorios/novo" component={ConsultorioEdit} />
          <Route path="/consultorios/edit/:id" component={ConsultorioEdit} />
          <Route path="/consultorios/view/:id" component={ConsultorioView} />
        </Switch>
      </React.Fragment>
    );
  }
}
