import { Can } from "@casl/react";
import _ from "lodash";
import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BtnLink from "../../components/Buttons/BtnLink";
import DatePickerInput from "../../components/Inputs/DatePickerInput";
import SelectInput from "../../components/Inputs/SelectInput";
import { objectsConstants } from "../../constants/objects.constants";
import FiltroAtendimento from "./FiltroAtendimento";

class MenuFiltro extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      options: [
        { tipo: "data", nome: "Data", value: null },
        { tipo: "mes", nome: "Mês", value: null },
        { tipo: "ano", nome: "Ano", value: null },
        { tipo: "periodo", nome: "Período", value: null },
        { tipo: "dataEncaminhamento", nome: "Data de encaminhamento", value: null },
        { tipo: "dataRecebimento", nome: "Data de recebimento", value: null }
      ],
      typeDate: 'data',
      filtro: {},
      listFiltroIndex: [new Date().getTime()]
    };
  }

  componentWillReceiveProps(nextProps) {

  }

  componentDidMount() {

  }


  handleChange = (name, value) => {
    let filtro = _.cloneDeep(this.state.filtro);
    _.set(filtro, name, value);
    this.setState({ filtro });
  };
  handleFilter = () => {
    this.props.changeFilter(this.state.filtro);
    this.props.toogleFiltro();
  };

  onChangeFilter = (filtroPropsName, filtroPropsValue, lastPropsName) => {
    let filtro = _.cloneDeep(this.state.filtro);
    if (lastPropsName) {
      delete filtro[lastPropsName];
    }
    if (filtroPropsName) {
      filtro[filtroPropsName] = filtroPropsValue;
    }
    this.setState({ filtro });
  }

  isFilterNull = () => {
    if (Object.getOwnPropertyNames(this.state.filtro).length < this.state.listFiltroIndex.length) {
      return true;
    }
    for (var prop in this.state.filtro) {

      if (!this.state.filtro[prop]) return true;
    }
    return false;
  }
  addFilter = () => {
    let listFiltroIndex = _.cloneDeep(this.state.listFiltroIndex);
    listFiltroIndex.push(new Date().getTime());
    this.setState({ listFiltroIndex })
  }

  removeFilter = (id, props) => {
    let listFiltroIndex = _.cloneDeep(this.state.listFiltroIndex);
    let filtro = _.cloneDeep(this.state.filtro);

    delete filtro[props];
    let index = listFiltroIndex.indexOf(id);
    listFiltroIndex.splice(index, 1);
    if (listFiltroIndex.length === 0) {
      listFiltroIndex.push(new Date().getTime())
    }
    this.setState({ listFiltroIndex, filtro });
  }

  onChangeDate = (name, value) => {
    let filtro = _.cloneDeep(this.state.filtro);
    delete filtro.mes;
    delete filtro.ano;
    delete filtro.data;
    delete filtro.periodo;
    delete filtro.dataEncaminhamento;
    delete filtro.dataRecebimento;
    this.setState({ typeDate: value, filtro })
  }

  getMonthOfYear = () => {
    let months = [];
    for (let i = 0; i <= 11; i++) {
      let month = moment().month(i).format("MMMM")
      months.push({ label: month, value: month });
    }
    return months;
  }

  getYearsOptions = () => {
    const yearAtual = moment().year();
    let years = [];
    for (let i = 0; i < 5; i++) {
      years.push({ label: yearAtual - i, value: yearAtual - i });
    }
    return years;
  }

  render() {
    return (
      <div className={`menu-filtro ${this.props.showMenu ? "aberto" : ""}`}>
        <div className="topo-notificar text-center py-3 d-flex align-items-center justify-content-between">
          <BtnLink
            type={"button"}
            className={""}
            icone={""}
            title={"Cancelar"}
            onSubmit={this.props.toogleFiltro}
            disabled={false}
          />
          <h1 className="font-bold">
            <span className="icon-btn-filtro-bar"></span>Filtros
          </h1>
          <span className="float-left font-12px acerto-p-m invisible">

          </span>
        </div>

        <hr className={"quebra-linha"} />
        <div className={"box-list-perfil"}>
          <div className={"row"}>
            <SelectInput
              label={" "}
              options={this.state.options}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={this.onChangeDate}
              name={"data"}
              value={this.state.typeDate}
              valueKey={"tipo"}
              labelKey={"nome"}
              returnFullObject={false}
              clearable={false}

              style={`col-12 col-md-6`}
            />

            {this.state.typeDate === 'data' && (
              <DatePickerInput
                label={"Data"}
                onChange={this.handleChange}
                placeholder={"--/--/----"}
                mask={"99/99/9999"}
                name={"data"}
                id={"dataValidadeSenha"}
                value={this.state.filtro.data}
                style={"col-12 col-md-6 "}
              />
            )}
            {this.state.typeDate === 'mes' && (
              <SelectInput
                onChange={this.handleChange}
                label={'Mês'}
                id={`filtro_mes`}
                options={this.getMonthOfYear()}
                name={`mes`}
                placeholder=""
                style={"col-12 col-md-6"}
                returnFullObject={false}
                searchable={true}
                labelKey={"label"}
                valueKey={"value"}
                value={
                  this.state.filtro.mes
                }
              />
            )}
            {this.state.typeDate === 'ano' && (
              <SelectInput
                onChange={this.handleChange}
                label={'Ano'}
                id={`filtro_ano`}
                options={this.getYearsOptions()}
                name={`ano`}
                placeholder=""
                style={"col-12 col-md-6"}
                returnFullObject={false}
                searchable={true}
                labelKey={"label"}
                valueKey={"value"}
                value={
                  this.state.filtro.ano
                }
              />
            )}
          </div>
          {this.state.typeDate === 'periodo' && (
            <div className={'row'}>
              <DatePickerInput
                label={"Inicio"}
                onChange={this.handleChange}
                placeholder={"--/--/----"}
                mask={"99/99/9999"}
                name={"periodo.inicio"}
                id={"dataValidadeSenha"}
                value={this.state.filtro.periodo ? this.state.filtro.periodo.inicio : null}
                style={"col-12 col-md-6 "}
              />
              <DatePickerInput
                label={"Fim"}
                onChange={this.handleChange}
                placeholder={"--/--/----"}
                mask={"99/99/9999"}
                name={"periodo.fim"}
                id={"dataValidadeSenha"}
                value={this.state.filtro.periodo ? this.state.filtro.periodo.fim : null}
                style={"col-12 col-md-6 "}
              />
            </div>
          )}

          {this.state.typeDate === 'dataEncaminhamento' && (
              <div className={'row'}>
                <DatePickerInput
                    label={"Inicio"}
                    onChange={this.handleChange}
                    placeholder={"--/--/----"}
                    mask={"99/99/9999"}
                    name={"dataEncaminhamento.inicio"}
                    id={"encaminhamento"}
                    value={this.state.filtro.dataEncaminhamento ? this.state.filtro.dataEncaminhamento.inicio : null}
                    style={"col-12 col-md-6 "}
                />
                <DatePickerInput
                    label={"Fim"}
                    onChange={this.handleChange}
                    placeholder={"--/--/----"}
                    mask={"99/99/9999"}
                    name={"dataEncaminhamento.fim"}
                    id={"encaminhamento"}
                    value={this.state.filtro.dataEncaminhamento ? this.state.filtro.dataEncaminhamento.fim : null}
                    style={"col-12 col-md-6 "}
                />
              </div>
          )}

            {this.state.typeDate === 'dataRecebimento' && (
              <div className={'row'}>
                <DatePickerInput
                    label={"Inicio"}
                    onChange={this.handleChange}
                    placeholder={"--/--/----"}
                    mask={"99/99/9999"}
                    name={"dataRecebimento.inicio"}
                    id={"recebimento"}
                    value={this.state.filtro.dataRecebimento ? this.state.filtro.dataRecebimento.inicio : null}
                    style={"col-12 col-md-6 "}
                />
                <DatePickerInput
                    label={"Fim"}
                    onChange={this.handleChange}
                    placeholder={"--/--/----"}
                    mask={"99/99/9999"}
                    name={"dataRecebimento.fim"}
                    id={"recebimento"}
                    value={this.state.filtro.dataRecebimento ? this.state.filtro.dataRecebimento.fim : null}
                    style={"col-12 col-md-6 "}
                />
              </div>
          )}

        </div>
        <hr className={"quebra-linha"} />

        {this.state.listFiltroIndex.map((id, index) => {
          return (
            <React.Fragment key={id}>
              <div className={"box-list-usuario"}>
                <FiltroAtendimento
                  onChangeFilter={this.onChangeFilter}
                  filter={this.state.filtro}
                  removeFilterList={this.removeFilter}
                  typefilter={this.state.typeDate}
                  id={id}
                  key={id} />

              </div>
              {this.state.listFiltroIndex.length > (index + 1) && (
                <hr className={"quebra-linha"} />
              )}
            </React.Fragment>
          )
        })}


        {!this.isFilterNull() && (
          <div className={'position-relative'}>
            <button className="btn btn-light btn btn-secondary btn-add-filter" onClick={this.addFilter}><span className="icon-btn-add"></span>Adiciona campo de filtro</button>
            <hr className={"quebra-linha"} />
          </div>)}
        <div className={"text-center "}>
          <button
            className="btn btn-primary white mt-3 mb-4 mx-2 btn-aplicar "
            onClick={this.handleFilter}
          >
            aplicar
          </button>
        </div>
        <Can
          I={objectsConstants.ALTERAR}
          a={objectsConstants.CRUD_PENDENCIA_CRIAR}
          ability={this.props.permissions}
        >
          <div></div>
        </Can>
      </div>
    );
  }
}

const mapDispatch = ({ filtro: { toogleFiltro }, alert: { success, error }, load: { loading } }) => ({
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  success: msg => success(msg),
  toogleFiltro: () => toogleFiltro(),


});

function mapStateToProps(state) {
  const { showMenu } = state.filtro;
  const { permissions } = state.authentication;

  return {
    showMenu,
    permissions
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(MenuFiltro));
