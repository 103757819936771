import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Avatar from "../../../components/Avatar/Avatar";
import { objectsConstants } from "../../../constants/objects.constants";
import { formatterHelper } from "../../../helpers/formatter.helper";
import { ConfirmAcationService } from "../../../services/ConfirmAcationService";
import { pacoteExameService } from "../../../services/pacoteExame.service";

class PacoteExameTopoView extends PureComponent<Props, State> {
    excluirPacoteExame = () => {
        this.props.loading(true);
        let id = [this.props.values.id];
        pacoteExameService.doDelete(id).then((res) => {
            this.props.history.push({
                pathname: "/pacoteexame"
            });
        }).catch((err) => {
            console.error(err);
        });
    }

    getBtnExcluir = () => {
    
        return <React.Fragment>
            <button type="button" className="btn btn-secondary" onClick={() => {
                ConfirmAcationService.confirmMsg(
                    this.excluirPacoteExame,
                    "Excluir pacote",
                    "Você confirma que deseja excluir os pacotes selecionados",
                    null,
                    "Cancelar",
                    "Excluir",
                    objectsConstants.TYPE_WARNING
                );
            }}>
                <span className="icon-btn-fechar"></span>
                Excluir pacote
            </button>
        </React.Fragment>;

    };
    render() {
        const { values, qtdeAtendimento } = this.props;
        return (
            //add classe "bg-branco" abaixo quando esição/criação
            <React.Fragment>
                <div className="px-sm-5 py-sm-2 my-sm-3 bg-branco rounded d-flex flex-column flex-sm-row justify-content-between align-items-center pb-2">
                    <div className="bloco-dados-user mr-sm-auto d-flex flex-column flex-sm-row align-items-center align-self-stretch align-self-sm-center">
                        <div className="bloco-image-user m-sm-2 mr-sm-4">
                            {<Avatar user={values}></Avatar>}
                        </div>
                        <div className="info-user col-12">
                            <React.Fragment>
                                {" "}
                                <h2 className="font-weight-bold">
                                    {formatterHelper.getNameOrHifen(values.nome)}
                                </h2>
                                <p className="font-italic">
                                    {" "}
                                    Pacote Exame
                                </p>
                            </React.Fragment>
                        </div>
                    </div>

                    <div className="px-sm-3 mt-2 mt-sm-0 border-left">
                        {/* <button type="button" className="btn btn-light">
            <span className="icon-btn-add"></span>Novo Usuário
          </button> */}
                        {this.getBtnExcluir()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { user, permissions } = state.authentication;

    return {
        user,
        permissions
    };
}

const mapDispatch = ({
    alert: { success, error, clear },
    load: { loading }
}) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear()
});

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(PacoteExameTopoView));
