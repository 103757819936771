//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import AvisosList from './AvisosList';
import AvisosEdit from './AvisosEdit';
import AvisosView from './AvisosView';

export default class Avisos extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/avisos" component={AvisosList} exact />
          <Route path="/avisos/nova" component={AvisosEdit} />
          <Route path="/avisos/edit/:id" component={AvisosEdit} />
          <Route path="/avisos/view/:id" component={AvisosView} />
        </Switch>
      </React.Fragment>
    );
  }
}