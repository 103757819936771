//@flow
import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";

export const opmService = {
  find,
  findByDescricao  
};



function find(codigo) {
  return defaultService.doGet(`${urlsConstants.OPM}${codigo}`);
}
function findByDescricao(descricao) {
  return defaultService.doGet(`${urlsConstants.OPM}findByDescricao/${descricao}`);
}