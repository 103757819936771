//@flow
import * as React from "react";
import { connect } from "react-redux";
import SelectInput from "../../components/Inputs/SelectInput";
import { convenioService } from "../../services/convenio.service";

type Props = {
  label: string,
  name: string,
  value: any,
  onChange: any,
  returnFullObject: boolean,
  messageValidate?: any,
  disabled?: boolean,
  size?: number,
  required: boolean,
  service: any
};

type State = {
  options: Array<any>
};

//const animatedComponents = makeAnimated();

class ConvenioFilterSelectInput extends React.PureComponent<Props, State> {
  _isMounted = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      options: []
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <SelectInput
        isMulti={this.props.isMulti}
        closeMenuOnSelect={this.props.isMulti ? false : true}
        hideSelectedOptions={false}
        onChange={this.props.onChange}
        name={this.props.name}
        id={this.props.id}
        value={this.props.value}
        valueKey={"id"}
        labelKey={"nome"}
        parent={this.props.filter}
        onFetchData={convenioService.findByFilterAtendimento}
        returnFullObject={this.props.returnFullObject}
        required={this.props.required}
        clearable={true}
        className={this.props.className}
        placeholder={"Convênio"}
        label={`Convênio`}
        erroMensagem={this.props.erroMensagem}
      />
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(ConvenioFilterSelectInput);
