//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import CooperadoSudoList from './CooperadoSudoList';
import CooperadoSudoEdit from './CooperadoSudoEdit';
import CooperadoSudoView from './CooperadoSudoView';
export default class CooperadoSudo extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/cooperadosSudo" component={CooperadoSudoList} exact/>
          <Route path="/cooperadosSudo/novo" component={CooperadoSudoEdit}  />
          <Route path="/cooperadosSudo/edit/:id" component={CooperadoSudoEdit}  />
          <Route path="/cooperadosSudo/view/:id" component={CooperadoSudoView}  />
        </Switch>
      </React.Fragment>
    );
  }
}
