import Avatar from "../../components/Avatar/Avatar";
import _ from "lodash";
import React from "react";

export const OptionAuditor = props => {
    const { data, innerProps } = props;
    return (
        <div className="d-inline-block" {...innerProps}>
            <div className="d-flex flex-row align-items-center justify-content-around react-select__option action">
                <div className="col-1 d-flex flex-column align-items-stretch">
                    <Avatar size={16} user={data} />
                </div>
                <div className="col-11 d-flex flex-column align-items-stretch pl-4">
                    <div className="font-weight-bold customOption">{data.nome}</div>
                </div>
            </div>
        </div>
    );
};