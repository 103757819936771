import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Avatar from "../../components/Avatar/Avatar";
import { objectsConstants } from "../../constants/objects.constants";
import iconPerfil from "../../img/icon_perfil.svg";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { userService } from "../../services/user.service";
class NotificacaoTopoView extends PureComponent<Props, State> {
  isAtivo = values => {
    return values.status && values.status.name === "ATIVO" ? true : false;
  };

  getBtnActiveInative = () => {
    const { values } = this.props;
    if (this.props.disableEnabledUser) {
      let textAction = this.isAtivo(values) ? "Inativar" : "Ativar";
      return (
        <button
          type="button"
          className={`btn btn-${
            this.isAtivo(values) ? "secondary" : "primary"
          }`}
          onClick={() => {
            ConfirmAcationService.confirmMsg(
              this.props.disableEnabledUser,
              textAction + " Usuário",
              "Você confirma que deseja " +
                textAction +
                " o usuário: " +
                values.nome,
              null,
              "Cancelar",
              "Confirmar",
              objectsConstants.TYPE_WARNING
            );
          }}
        >
          <span
            className={`icon-btn-${this.isAtivo(values) ? "fechar" : "check"}`}
          ></span>
          {textAction} Usuário
        </button>
      );
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="px-sm-5 py-sm-2 my-sm-3 bg-branco rounded d-flex flex-column flex-sm-row justify-content-between align-items-center pb-2">
            <div className="bloco-dados-user mr-sm-auto d-flex flex-column flex-sm-row align-items-center">
              <div className="bloco-image-user m-sm-2 mr-sm-4">
                <div className="icon-foto-user d-flex align-items-center ">
                  <img src={iconPerfil} alt="iconPerfil" />
                </div>
                <Avatar user={this.props.user}></Avatar>
              </div>
              <div className="info-user">
                <p>
                  {this.props.user.cooperativa && this.props.user.cooperativa.sigla}
                </p>
                <h2 className="font-weight-bold">{this.props.user.nome}</h2>
                <p className="font-italic">
                  {userService.getPerfilAtual(this.props.user)}
                </p>
              </div>
            </div>

            <div className="px-sm-3 mt-2 mt-sm-0"></div>
            <div className="px-sm-3 mb-3 border-left justify-content-end">
              <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  onClick={this.props.edit}
              >
                <span className={`icon-btn-editar-white`}></span>
                {"Editar"}
              </button>

            </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(NotificacaoTopoView));
