//@flow
import { Formik } from "formik";
import * as React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import connect from "react-redux/es/connect/connect";
import * as Yup from "yup";
import { translate } from "../../helpers/message.helper";
import { cooperadoService } from "../../services/cooperado.service";
import CooperadoForm from "./CooperadoForm";
import CooperadoTopoNew from "./CooperadoTopoNew";
import { estabelecimentoService } from "../../services/estabelecimento.service";
import _ from "lodash";
import {userService} from "../../services/user.service";
import {createYupSchema} from "../../helpers/createYupSchema";
import {objectsConstants} from "../../constants/objects.constants";


const NewCooperado = Yup.object().shape({
  // custum validation de um campo
  nome: Yup.string().required("Obrigatório"),
  cpf: Yup.string().required("Obrigatório"),
  conveniosEstabelecimento:Yup.array()
  .min(1, "É necessario informar os convênios que o cooperado irá atender")
  ,

});

class CooperadoEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    let  editConvenio  = props.location.state && this.props.location.state.editConvenio;
    this.state = {
      entity: {
        status: { name: "ATIVO" }
      },
      editConvenio
    };
  }

  goToViewUser = values => {
    this.props.history.push({
      pathname: "/cooperados/view/" + values.id,
      state: { entity: values }
    });
  };

  
  changeCooperado = (name, cooperado) => {
    this.setState({ entity: cooperado });
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      cooperadoService.doGet(this.props.match.params.id).then(
        response => {
          let user = response.data.data;
          this.setState({ entity: user }, () => {
            this.props.loading(false);
          });
        },
        error => {
          console.error(error);
          this.props.loading(false);
        }
      );
    }
    let extension;
    if(userService.isConsultorio(this.props.user)){
      extension = this.props.user.perfilAtual.extensao;
    }else{
      extension = this.props.user.estabelecimentoProprietario.id;
    }
    estabelecimentoService.doGet(extension).then(response => {
      let estabelecimento = response.data.data;      
      this.setState({ estabelecimento });
    });
    
  }

  render() {
    let _this = this;
    const { entity ,editConvenio} = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          <Formik
            validationSchema={NewCooperado}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
            initialValues={entity}
            onSubmit={(values, actions) => {
              this.props.loading(true);
              let serviceSave;
              if(editConvenio ){
                serviceSave= cooperadoService.doSave(values);
              }else{
                serviceSave=cooperadoService.addEstabelecimento(values);
              }
              serviceSave.then(
                response => {
                  this.props.success({
                    message: `Cooperado ${values.nome}, foi salvo com sucesso!`
                  });

                  _this.goToViewUser(values);
                },
                erros => {
                  console.error(erros.response);
                  this.props.error({
                    message: "Não foi possível incluir cooperado."
                  });
                  try {
                    let response = erros.response.data;
                    if (response && response.messages) {
                      for (var i = 0; i < response.messages.length; i++) {


                        let erroItem = response.messages[i];
                        if(erroItem.target === "FIELD"){


                        actions.setFieldError(
                          erroItem.fieldName,
                          translate(erroItem.message.code)
                        );
                        }else{
                          this.props.error({
                            message: translate(erroItem.message.code)
                          });
                        }
                      }
                    }
                  } catch (error) {
                    console.error(error);
                  }

                  this.props.loading(false);
                }
              );
              this.props.loading(false);
              actions.setSubmitting(false);
            }}
            ref={form => {
              this.formRef = form;
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              validationSchema,
              validateForm
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <React.Fragment>
                  <CooperadoTopoNew
                    values={values}
                    changeCooperado={this.changeCooperado}
                    isSubmitting={isSubmitting}
                    getProfileName={_this.getProfileName}
                    validateForm={validateForm}
                    setFieldValue={setFieldValue}
                    estabelecimento={this.state.estabelecimento}
                    createMode={!editConvenio && true}
                    handleSubmit={handleSubmit}
                    ></CooperadoTopoNew>
                  {values.id && values.usuarioPerfis && (
                    <CooperadoForm
                      setFieldValue={setFieldValue}
                      errors={errors}
                      handleChange={handleChange}
                      touched={touched}
                      getProfileName={_this.getProfileName}
                      values={values}
                      estabelecimento={this.state.estabelecimento}
                      viewMode={true}
                      createMode={!editConvenio && true}
                      editConvenio={editConvenio}
                      canEdit={false}
                    ></CooperadoForm>
                  )}
                </React.Fragment>
              </form>
            )}
          </Formik>

          <div className="background-lines">
            <div className="bg-riscos"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

function mapStateToProps(state) {  
  const { user } = state.authentication;
  return {    
    user
  };
}

export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  { withRef: true }
)(CooperadoEdit);
