import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { cooperativaService } from '../../services/cooperativa.service';
import TopoTitleComponente from '../home/TopoTitleComponente';
import CooperativaForm from './CooperativaForm';
import CooperativaTopoView from './CooperativaTopoView';
class CooperativaView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true }
    };
  }

  componentDidMount() {
    this.loadCooperativa();
  }

  loadCooperativa = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      cooperativaService.doGet(this.props.match.params.id).then(
        response => {
          let cooperativa = response.data.data;
          this.setState({ entity: cooperativa }, () => {
            this.props.loading(false);
          });
        },
        error => {
          console.error(error);
          this.props.loading(false);
        }
      );
    }
  };

  render() {

    //let _this = this;
    const { entity } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo pb-5">
          <React.Fragment>
            <TopoTitleComponente
              mainTitle={`Dados da Cooperativa`}
              canBack={true}
              backUrl={'/cooperativa'}
            />
            <CooperativaTopoView
              values={entity}
              viewMode={true}
              qtdeAtendimento={1.636}
              qtdeProfissionais={47}
            ></CooperativaTopoView>
            <CooperativaForm
              values={entity}
              viewMode={true}
              showButtonAdd={false}
              errors={[]}
            ></CooperativaForm>

          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(withRouter(CooperativaView));
