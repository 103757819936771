//@flow
import * as React from "react";
import { connect } from "react-redux";
import SelectInput from "../../components/Inputs/SelectInput";
import { bancoService } from "../../services/banco.service";


type Props = {
  label: string,
  name: string,
  value: any,
  onChange: any,
  returnFullObject: boolean,
  messageValidate?: any,
  disabled?: boolean,
  size?: number,
  required: boolean,
  service: any
};

type State = {
  options: Array<any>
};


class BancoSelectInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      options: []
    };
  }

  componentDidMount() {
    bancoService.findAll().then(response => {
      let options = response.data;
      this.setState({ options });
    });

  }

  render() {
    return (

      <SelectInput
        label={this.props.label}
        options={this.state.options}
        onChange={this.props.onChange}
        allowSelectAll={false}
        name={this.props.name}
        id={this.props.id}
        value={this.props.value}
        valueKey={"id"}
        labelKey={"nome"}
        returnFullObject={true}
        required={this.props.required}
        clearable={true}
        className={this.props.className}
        placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
        erroMensagem={this.props.erroMensagem}
        disabled={this.props.disabled}
      //menuIsOpen={true}

      />

    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(BancoSelectInput);
