import * as _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UserAvatar from "../../../components/Avatar/Avatar";
import BtnLink from "../../../components/Buttons/BtnLink";
import CascadeSelectInput from "../../../components/Inputs/CascadeSelectInput";
import { objectsConstants } from "../../../constants/objects.constants";
import IconFaturado from "../../../img/icon_faturado_BLACK.svg";
import BuscaAtendimentoInput from "../../../sascComponents/inputs/BuscaAtendimentoInput";
import { atendimentoService } from "../../../services/atendimento.service";
import { ConfirmAcationService } from "../../../services/ConfirmAcationService";
import { loteAtendimentoService } from "../../../services/loteAtendimento.service";
import { statusAtendimentoService } from "../../../services/statusAtendimento.service";
import { translate } from "../../../helpers/message.helper";
import { ToolTipHelp } from "../../../components/Utils/ToolTipHelp";
import SelectInput from "../../../components/Inputs/SelectInput";
import { userService } from "../../../services/user.service";

let download = require("downloadjs");


class LotesTopo extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      atendimento: null,
      atendimentosAdicionais: [],
      filter: {},
    };
  }

  handleChange = (name, value) => {
    let newState = _.cloneDeep(this.state);
    _.set(newState, name, value);
    this.setState(newState);
  };

  addAtendimenosInLote = (atendimentos, lote) => {
    this.props.loading(true);

    loteAtendimentoService.addAtendimentos(lote.id, atendimentos).then(
      response => {
        this.props.success({
          message: `Atendimentos adicionados com sucesso!`
        });
        if (this.props.onAddAtendimento) {
          this.props.onAddAtendimento(response.data);
        }
        response.data.forEach(t => {
          if (!this.props.atendimentos.some(atendimento => t.id == atendimento.id))
            this.props.atendimentos.push(t);
        });
        this.props.loading(false);
        this.setState({ atendimento: response.data });
      },
      erros => {
        console.error(erros.response);
        try {
          let response = erros.response.data;
          if (response && response.messages) {
            for (var i = 0; i < response.messages.length; i++) {
              let erroItem = response.messages[i];
              this.props.error({
                message: translate(erroItem.message.code)
              });
            }
          }
        } catch (error) {
          console.error(error);
        }

        this.props.loading(false);
      }
    );
  };

  getDownLoadAnexoAtendimentoPorLote = () => {
    this.props.loading(true);
    const { lote } = this.props;
    this.props.loading(true);
    loteAtendimentoService.getDownLoadAnexoAtendimentoPorLote(lote.id).then(
      (response) => {
        var base64 = _.get(response, "data.data");
        if (base64 != null) {
          let asciiString = atob(base64);
          let array = new Uint8Array(
            [...asciiString].map((char) => char.charCodeAt(0))
          );
          const filename = `Lote_${lote.id}.zip`;
          const file = new Blob([array], { type: "application/zip" });
          download(file, filename);
          this.props.loading(false);
        }
      },
      () => {
        this.props.loading(false);
        this.props.error("Ocorreu um erro ao efetuar o download do arquivo");
      }
    );
  };

  lotePossuiAtendimentosComAnexo = () => {
    return (
      this.props.atendimentos &&
      this.props.atendimentos.length > 0 &&
      this.props.atendimentos.some(
        (atendimento) =>
          atendimento.atendimentoAnexos &&
          atendimento.atendimentoAnexos.length > 0
      )
    );
  };

  getStatus = () => {
    var countBy = _.countBy(this.props.atendimentos, "status.nome");
    let keys = Object.keys(countBy);
    if (keys.length == 1) {
      return keys[0] !== "undefined" ? keys[0] : "--";
    } else {
      return keys.length + " status selecionados";
    }
  };

  getSelectEncaminhento = () => {
    let bottonContent = (
      <button
        type="submit"
        className="btn btn-primary btn-small-mobile"
        disabled={!this.state.filter.statusLote}
        onClick={() => {
          ConfirmAcationService.confirmMsg(
            () => {
              this.props.handleEncaminharLoteAtendimento(
                this.state.filter.statusLote,
                this.props.lote
              );
              this.setState({ filter: {} });
            },
            "Encaminhar lote",
            <span>
              {" "}
              Você concorda que ao encaminhar o lote para o faturamento, todos
              os atendimentos já foram devidamentes auditados e estão todos
              corretos.{" "}
              <p className="font-bold pt-2">
                Deseja realmente encaminhar o lote para Faturamento?
              </p>
            </span>,
            `${this.state.filter.statusLote.nome} `,
            "Não",
            "Sim",
            objectsConstants.TYPE_FORWARD
          );
        }}
      >
        {"Encaminhar"}
      </button>
    );
    let inputContent = (
      <React.Fragment>
        <label>Encaminhar lote</label>
        <CascadeSelectInput
          className="min-width"
          name={"filter.statusLote"}
          placeholder={"Selecione o status"}
          returnFullObject={true}
          labelKey={"nome"}
          valueKey={"id"}
          service={statusAtendimentoService.findAllFaturamento}
          value={this.state.filter.statusLote}
          onChange={this.handleChange}
          parent={this.props.rowsCheckedIdValue}
          loadingPlaceholder={"Selecione um atendimento"}
        />
      </React.Fragment>
    );
    if (this.props.rowsCheckedValue.length > 0) {
      bottonContent = (
        <button
          type="submit"
          className="btn btn-primary "
          disabled={!this.state.filter.statusAtendimento}
          onClick={() => {
            ConfirmAcationService.confirmMsg(
              () => {
                this.props.handleEncaminharAtendimento(
                  this.state.filter.statusAtendimento
                );
                this.setState({ filter: {} });
              },
              "Encaminhar atendimentos",
              <span>
                {" "}
                Deseja realmente encaminhar o atendimentos para o status de{" "}
                <span className="font-bold">
                  {this.state.filter.statusAtendimento.nome}
                </span>
                ?
              </span>,
              `${this.state.filter.statusAtendimento.nome} `,
              "Não",
              "Sim",
              objectsConstants.TYPE_FORWARD
            );
          }}
        >
          <span className={`icon-btn-check`}></span>
          {"Encaminhar"}
        </button>
      );

      inputContent = (
        <React.Fragment>
          <label>Encaminhar atendimentos</label>
          <CascadeSelectInput
            className="min-width"
            name={"filter.statusAtendimento"}
            placeholder={"Selecione o status"}
            returnFullObject={true}
            labelKey={"nome"}
            valueKey={"id"}
            service={statusAtendimentoService.findAllByAtendimentos}
            value={this.state.filter.statusAtendimento}
            onChange={this.handleChange}
            parent={this.props.rowsCheckedIdValue}
            loadingPlaceholder={"Selecione um atendimento"}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="flex-grow pl-3 pr-2 pb-4 m-auto justify-content-center">
          {inputContent}
        </div>
        <div className="flex-grow pr-3 m-auto justify-content-center">
          {bottonContent}
        </div>
      </React.Fragment>
    );
  };

  changeAtendimentoAdicionais= (name, value) => {
    if (this.state.bar_code && this.state.atendimentosAdicionais && value) {
      if (this.state.atendimentosAdicionais.length === value.length) {
        this.props.warning({ message: "Atendimento já foi selecionado ou atendimento não encontrado." })
      }
    }
    this.setState({ atendimentosAdicionais: value })
  }

  getAdicionarAtendimentos = (lote) => {
    let input = (
      <React.Fragment>
        <SelectInput
          isMulti={true}
          closeMenuOnSelect={true}
          hideSelectedOptions={false}
          size={24}
          onChange={this.changeAtendimentoAdicionais}
          name={'atendimentosAdicionais'}
          id={'atendimentosAdicionais'}
          value={this.state.atendimentosAdicionais}
          valueKey={"id"}
          labelKey={"numeroAtendimento"}
          label={"Adicionar atendimento"}
          onFetchData={atendimentoService.findByNumeroAtendimento}
          returnFullObject={true}
          required={this.props.required}
          clearable={true}
          className={"com-icone custom-padding-select"}
          placeholder={"Número do atendimento"}
        />
      </React.Fragment>
    )

    let button = (
      <button
        type="submit"
        className="btn btn-primary btn-small-mobile"
        disabled={this.state.atendimentosAdicionais != null &&this.state.atendimentosAdicionais.length > 0 ? false : true}
        onClick={() => {this.validaAdicaoAtendimentoLote(lote)}}
      >
        {"Adicionar"}
      </button>
    );

    return (
      <React.Fragment>
        <div className="flex-grow pl-2 pr-0 pb-0 align-items-center justify-content-center width-lote-add">
          {input}
        </div>
        <div className="flex-grow pr-0 ml-2 mb-2 mr-3 button-add align-items-center justify-content-center">
          {button}
        </div>
      </React.Fragment>
    );
  }

  validaAdicaoAtendimentoLote = (lote) => {
    const atendimentos = _.cloneDeep(this.state.atendimentosAdicionais);
    const convenioLote = _.cloneDeep(this.props.atendimentos[0].convenio);
    const quantAtendimentos = this.props.atendimentos.length;
    const quantAtendimentosAdicionais = atendimentos.length;
    let atendimentosNaoValidos = [];
    let atendimentosValidos = [];
    let atendimentosConveniosNaoValidos = [];

    atendimentos.forEach(atendimento => {
      if(atendimento.status.situacao === objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA ||
        atendimento.status.situacao === objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA ||
        atendimento.status.situacao === objectsConstants.SITUACAO_ATENDIMENTO.PENDENCIA_RESOLVIDA ||
        atendimento.status.situacao === objectsConstants.SITUACAO_ATENDIMENTO.REMOVIDAS_DO_LOTE) {
          atendimentosValidos.push(atendimento)
      } else {
        atendimentosNaoValidos.push(atendimento)
      }      
      if(atendimento.convenio.nome !== convenioLote.nome)
        atendimentosConveniosNaoValidos.push(atendimento);
    });

    if (atendimentosValidos.length == quantAtendimentosAdicionais 
      && (atendimentosConveniosNaoValidos == null || atendimentosConveniosNaoValidos.length == 0)
      && (quantAtendimentos + quantAtendimentosAdicionais <= 100)) {
        ConfirmAcationService.confirmMsg(
        () => {
          this.addAtendimenosInLote(
            [...atendimentos],
            lote
          );
          this.state.atendimentosAdicionais = [];
        },
        "Adicionar atendimentos",
        <span>
          {" "}
          Deseja adicionar o(s) atendimento(s) selecionado(s) no lote
          existente?{" "}
        </span>,
        <span className="verde-destaque">{`Atendimento(s) ${atendimentos.map(a => a.numeroAtendimento.toString()).join(', ')}`}</span>,
        "Não",
        "Sim",
        objectsConstants.TYPE_ADD_IN_LOTE
      );
    } else if(atendimentosConveniosNaoValidos !== null && atendimentosConveniosNaoValidos.length > 0) {
      ConfirmAcationService.confirmMsg(
        () => { },
        "Atendimento(s) com convênio(s) diferente(s)",
        <span>
          <p>{`Atendimento(s) ${atendimentosConveniosNaoValidos.map(a => a.numeroAtendimento.toString()).join('  ')}`}</p>
          {" "}
          Não pode existe atendimentos com convênios diferentes, para serem adicionados
          ao lote;
        </span>,
        null,
        "OK",
        null,
        objectsConstants.TYPE_WARNING
      );
    } else if((quantAtendimentos + quantAtendimentosAdicionais) > 100) {
      ConfirmAcationService.confirmMsg(
        () => { },
        "Limite de atendimentos excedido",
        <span>
          {" "}
          Limite de 100 atendimentos para um lote esta sendo excedida; 
        </span>,
        null,
        "OK",
        null,
        objectsConstants.TYPE_WARNING
      );
    } else {
      ConfirmAcationService.confirmMsg(
        () => { },
        "Atendimento(s) já faturado(s)",
        <span>
          {atendimentosNaoValidos.length > 0 ? `Atendimento(s) ${atendimentosNaoValidos.map(a => a.numeroAtendimento.toString()).join(' ')}` : ''}
          {" "}
          Este(s) atendimento(s) já esta faturado(s) e não pode(m) ser
          adicionado(s) ao lote.
        </span>,
        null,
        "OK",
        null,
        objectsConstants.TYPE_WARNING
      );
    }
  }

  render() {
    const { lote, atendimentos } = this.props;
    return (
      <React.Fragment>
        <div className="barra-topo topo-guia bg-branco">
          <div className="d-flex flex-column flex-sm-row align-content-stretch">
          <div className="barra-lotes">
            <div className="box-lote">
              <div className="box-lote-info p-3">
                <div className="box-lote-txt d-flex flex-row">
                  <div className="w-25 d-sm-flex align-items-center justify-content-center">
                    <img
                      src={IconFaturado}
                      className="icon-lote"
                      alt="iconFoto"
                    />
                  </div>
                  <div className="w-75">
                    <h2>{`Lote ${lote.id}`}</h2>
                    <p className="sub-title">
                      {_.get(atendimentos[0], "convenio.nome")}
                    </p>
                    <p className="verde-destaque italic">
                      {`${atendimentos.length} atendimentos`}
                    </p>
                  </div>
                </div>
                <div className=" mt-3 box-lote-criador d-flex flex-row">
                  <div className="w-25 d-sm-flex align-items-center justify-content-center">
                    <UserAvatar
                      className=" float-right"
                      user={lote.usuario}
                      size={34}
                    />
                  </div>
                  <div className="w-75">
                    <p className="fz-sm-10">{lote.usuario.nome}</p>
                    <p className="fz-sm-10 txtGray">{lote.statusAtual}</p>
                  </div>
                </div>
              </div>
            </div>            
          </div>
          {this.lotePossuiAtendimentosComAnexo() && (
            <div className="d-flex borda-separacao align-items-center align-self-stretch p-3 pb-4">
              <button
                type="button"
                className={"btn btn-primary"}
                onClick={() => {
                  this.getDownLoadAnexoAtendimentoPorLote();
                }}
              >
                <span className={"icon-visualizar"}></span>
                Download em massa de anexos
              </button>
            </div>
          )}
          
          <div
            className={`${
              lote.situacaoLote ===
                objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA &&
              "borda-separacao"
            } d-flex justify-content-left align-items-center align-self-stretch text-center px-3 ${
              lote.situacaoLote ===
                objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA && "flex-fill"
            }`}
          >
            <div className="m-auto ">
              <p className="mb-0 italic verde-destaque"> Status </p>
              <h2 className="status-txt">{this.getStatus()}</h2>
            </div>
            {lote.mensagemErroProcessamento && (
              <div className="m-auto ">
                <p className="mb-0 italic text-danger"> Erro </p>
                <React.Fragment>
                  <i className="icon-danger" id={"help_" + lote.id} />
                  <ToolTipHelp
                    className={"danger"}
                    target={"help_" + lote.id}
                    message={
                      <p
                        dangerouslySetInnerHTML={{
                          __html: lote.mensagemErroProcessamento
                            ? lote.mensagemErroProcessamento
                            : "",
                        }}
                      ></p>
                    }
                  />
                </React.Fragment>
              </div>
            )}
          </div>

          {lote.situacaoLote ===
            objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA && (
            <div className="d-flex flex-sm-row borda-separacao justify-content-center align-items-center align-self-stretch flex-fill">
              {this.getSelectEncaminhento()}
            </div>
          )}

          {lote.situacaoLote ===
            objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA &&
            atendimentos.find(
              (atendimento) =>
                atendimento.status.situacao !== "FATURAMENTO" &&
                atendimento.status.situacao !== "FATURADO_PARCIALMENTE" &&
                atendimento.status.situacao !== "FATURADO"
            ) && (
              <div className="d-flex justify-content-center align-items-center align-self-stretch flex-fill">
                <BtnLink
                  type={"button"}
                  className={"float-right"}
                  icone={"icon-btn-excluir"}
                  title={"Excluir lote"}
                  onSubmit={(e) => {
                    ConfirmAcationService.confirmMsg(
                      () => {
                        this.props.handleDeleteLote(lote);
                      },
                      "Excluir Lote",
                      <span>
                        {" "}
                        Deseja realmente excluir o lote{" "}
                        <span className="font-bold">{lote.id}</span>?
                      </span>,
                      <span className="text-danger">{`${
                        lote.listAtendimentoLength
                      }${
                        lote.listAtendimentoLength === 1
                          ? " atendimento, ficará sem lote"
                          : " atendimentos, ficarão sem lote"
                      } `}</span>,
                      "Não",
                      "Sim",
                      objectsConstants.TYPE_EXCLUIR
                    );
                  }}
                  disabled={false}
                />
              </div>
            )}
            
         
          </div>

          <div className="d-flex flex-column flex-sm-row align-content-stretch border-top">
            {lote.situacaoLote ===
              objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA && (
                <div className="d-flex flex-sm-row borda-separacao flex-fill p-3">
                  {this.getAdicionarAtendimentos(lote)}
                </div>
              )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(null, mapDispatch)(withRouter(LotesTopo));
