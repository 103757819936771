import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { FormGroup } from "../../components/FormGroup";
import FormTextArea from "../../components/Inputs/FormTextArea";
import { objectsConstants } from "../../constants/objects.constants";
import Datatable from "../../sascComponents/datagrid/Datatable";
import TopoListagem from "../../sascComponents/topo/TopoListagem";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { justificativaService } from "../../services/justificativa.service";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import LinhaAcaoExcluirJustificativa from "./LinhaAcaoExcluirJustificativa";


class JustificativaList extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        const extension = userService.getExtensionUser(this.props.user);
        const isAuditor = userService.isAuditor(this.props.user);
        let initFilter = []
        if (extension && !isAuditor) {
            initFilter.push({
                path: "Justificativa.cooperativa",
                fieldName: "id",
                fieldDescription: "Id",
                columnOrder: 2,
                searchableField: true,
                value: extension,
                type: "NUMBER",
                condition: { description: "=" }
            });
        }

        this.state = {
            initFilter,
            toogleModalEdit: false,
            toogleEdit: false
        };
    }
    closeMenu = () => {
        this.props.showMenu();
    };
    handleRowClick = (fieldName, rowData) => {
        this.setState({ toogleModalEdit: true, viewJust: rowData, editJust: rowData.texto });
    };

    saveJustificativa = (texto) => {
        this.dataTable.props.loading(true);
        if (texto === '' || texto === undefined || texto === null) {
            this.dataTable.props.error({ message: `O texto da justificativa não pode estar vazio!` });
            this.dataTable.props.loading(false);
        } else {
            justificativaService.doSave(texto).then(
                response => {
                    this.setState({ newJust: null })
                    this.dataTable.initData();
                    this.dataTable.props.success({ message: `Justificativa criada com sucesso!` });
                    this.dataTable.props.loading(false);
                },
                erros => {
                    console.error(erros.response);
                    this.dataTable.props.loading(false);
                }
            )
        }

    };

    updateJustificativa = (texto) => {
        this.dataTable.props.loading(true);
        if (texto === '' || texto === undefined || texto === null) {
            this.dataTable.props.error({ message: `O texto da justificativa não pode estar vazio!` });
            this.dataTable.props.loading(false);
            this.setState({ toogleEdit: true })
        } else {
            justificativaService.doUpdate(this.state.viewJust.id, texto).then(
                response => {
                    this.dataTable.props.success({message: `Justificativa alterada com sucesso!`});
                    this.dataTable.props.loading(false);
                    this.dataTable.initData();
                },
                erros => {
                    console.error(erros.response);
                    this.dataTable.props.loading(false);
                }
            )
            this.setState({ toogleModalEdit: false, toogleEdit: false })
        }
    };

    componentDidUpdate = () => {}

    handleToogleEdit = () => {
        this.setState({ toogleEdit: !this.state.toogleEdit })
    }


    render() {
        //const { permissions } = this.props;
        let _this = this;
        //
        if (this.state.toogleEdit) {
            ConfirmAcationService.confirmMsg(
                () => this.updateJustificativa(_this.state.editJust),
                "Editar",
                <div className=" col-12">
                    <FormGroup className={``}>
                        <FormTextArea
                            type={"textarea"}
                            disabled={false}
                            required={true}
                            onChange={e => {
                                const { value } = e.currentTarget;
                                _this.setState({ editJust: value });
                            }}
                            name={"justificativa"}
                            id={"justificativa"}
                            maxLength={255}
                            placeholder=""
                            viewMode={false}
                            value={_this.state.editJust}
                        />
                    </FormGroup>
                </div>,
                null,
                "Cancelar",
                "Salvar",
                objectsConstants.TYPE_FORWARD,
                () => this.handleToogleEdit()
            )
        }

        return (

            <div className="container-fluid container-com-topo">
                <Modal
                    isOpen={this.state.toogleModalEdit}
                    toggle={true}
                    backdrop="static"
                    modalClassName=""
                    className=""
                    centered={true}
                >
                    <ModalBody>
                        <div className="">
                            <div className="row">
                                <div className="col-11 text-center my-2">
                                    <h2 className="font-weight-bold">Justificativa</h2>
                                </div>
                                <div className="col-11 text-center my-2">
                                    <h1 className="font-weight-bold">{this.state.viewJust && this.state.viewJust.texto}</h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="flex-grow px-3 m-auto  pt-3 justify-content-center">
                                    <button className="btn-secondary" onClick={() => this.setState({ toogleModalEdit: false })}>
                                        Fechar
                                    </button>
                                    <button className="btn-primary" onClick={() => this.handleToogleEdit()}>
                                        Editar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <TopoTitleComponente
                    mainTitle="Justificativas Retorno"
                    subTitle=" "
                    canBack={false}
                />
                <TopoListagem
                    securityActionDo={objectsConstants.ALTERAR}
                    securityActionOn={objectsConstants.CRUDCONSULTORIOS_CADASTRAR}
                    buttonActionClassName={"btn-light"}
                    buttonAction={() => {
                        ConfirmAcationService.confirmMsg(
                            () => this.saveJustificativa(this.state.newJust),
                            "Nova Justificativa",
                            <div className=" col-12">
                                <FormGroup className={``}>
                                    <FormTextArea
                                        type={"textarea"}
                                        disabled={false}
                                        maxLength={255}
                                        required={true}
                                        onChange={e => {
                                            const { value } = e.currentTarget;
                                            this.setState({ newJust: value });
                                        }}
                                        name={"justificativa"}
                                        id={"justificativa"}
                                        placeholder="Insira o texto da nova justificativa"
                                        viewMode={false}
                                        value={this.state.newJust}
                                    />
                                </FormGroup>
                            </div>,
                            null,
                            "Cancelar",
                            "Salvar",
                            objectsConstants.TYPE_ADD_IN_LOTE
                        );
                    }}
                    securityActionIcon={"icon-btn-add"}
                    securityActionName={"Nova Justificativa"}
                    showfilter={false}
                />
                <hr className="text-center w-75"></hr>

                <div className="background-lines">
                    <div className="bg-riscos"></div>
                </div>

                <Datatable
                    showCheckboxColumn={true}
                    onRowClick={(this.handleRowClick)}
                    idRow={"id"}
                    keyTagItem={"nome"}
                    classHeader={[
                        "headerCenter",
                    ]}
                    classColumn={[
                        "colunaGra",
                    ]}
                    service={justificativaService}
                    ActionLine={LinhaAcaoExcluirJustificativa}
                    name={"Justificativa(s)"}
                    showAllColumnsFilter={false}
                    initFilter={this.state.initFilter}
                    wrappedComponentRef={c => (this.dataTable = c)}
                />
            </div >
        );
    }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
    showMenu: () => showMenu(),
    hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
    const { loggedIn, user, permissions } = state.authentication;
    const { alert } = state;

    return {
        loggedIn,
        alert,
        user,
        permissions
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(JustificativaList));
