import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Avatar from "../../components/Avatar/Avatar";
import { objectsConstants } from "../../constants/objects.constants";
import { dateHelper } from "../../helpers/date.helper";
import { formatterHelper } from "../../helpers/formatter.helper";
import Icon_atendimentos from "../../img/icon_atendimentos.svg";
import Icon_usuario_cooperado from "../../img/icon_usuarioCooperado.svg";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import TopoTitleComponente from "../home/TopoTitleComponente";
import { userService } from "../../services/user.service";

class CooperadoTopoView extends PureComponent<Props, State> {

  isAtivo = values => {
    return values.statusEstabelecimento && values.statusEstabelecimento.name === "ATIVO" ? true : false;
  };

  getBtnActiveInative = () => {
    const { values } = this.props;
    let textAction = this.isAtivo(values) ? "Inativar" : "Ativar";
    return (
      <button
        type="button"
        className={`btn btn-${this.isAtivo(values) ? "secondary" : "primary"}`}
        onClick={() => {
          ConfirmAcationService.confirmMsg(
            this.props.disableEnabledUser,
            textAction + " Usuário",
            "Você confirma que deseja " +
              textAction +
              " o cooperado: " +
              values.nome,
            null,
            "Cancelar",
            "Confirmar",
            objectsConstants.TYPE_WARNING
          );
        }}
      >
        <span
          className={`icon-btn-${this.isAtivo(values) ? "fechar" : "check"}`}
        ></span>
        {textAction} Cooperado
      </button>
    );
  };

  render() {
    let _this = this;
    const { values } = this.props;   
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={`${
            values.nome
          }`}
          canBack={true}
                backUrl={"/cooperados"}
        />
        <div className="px-sm-5 py-sm-2 my-sm-3 bg-branco rounded d-flex flex-column flex-sm-row justify-content-between align-items-center pb-2">
          <div className="bloco-dados-user mr-sm-auto d-flex flex-column flex-sm-row align-items-center col-12 col-sm-4">
            <div className="bloco-image-user m-sm-2 mr-sm-4">
              {!values.id && (
                <img
                  src={Icon_usuario_cooperado}
                  className="avatar"
                  alt="iconFoto"
                />
              )}
              {values.id && <Avatar user={values}></Avatar>}
            </div>
            <div className="info-user col-12">
              {values.id && (
                <React.Fragment>
                  {" "}
                  <p>
                  {this.props.user.cooperativa && this.props.user.cooperativa.sigla}
                  </p>
                  <h2 className="font-weight-bold">
                    {formatterHelper.getNameOrHifen(values.nome)}
                  </h2>
                  <p className="font-italic">
                    {" "}
                    {userService.getPerfilAtual(values)}
                  </p>
                </React.Fragment>
              )}
              {!values.id && (
                <React.Fragment>
                  {" "}
                  <label>Cooperado</label>
                  {values.nome}
                </React.Fragment>
              )}
            </div>
          </div>
          {values.id && (
            <React.Fragment>
              <div className="mr-sm-auto d-flex flex-column flex-sm-row flex-sm-row align-items-center">
                  <div className="d-flex align-items-center ">
                    <img
                      src={Icon_atendimentos}
                      alt="iconPerfil"
                      className="icon-atentimento"
                    />
                  </div>
                  <div className="info-texto px-sm-3 mt-2 mt-sm-0 text-center text-sm-left">
                    Total realizado
                    <div className={`font-weight-bold f-16`}>
                      {values.qtdeAtendimento?values.qtdeAtendimento:0}
                    </div>
                  </div>
                </div>
              <div className="mr-sm-auto info-texto px-sm-3 mt-2 mt-sm-0">
                Cadastrado em:
                <div className="font-weight-bold">
                  {dateHelper.format(values.creationDatetimeEstabelecimento)}
                </div>
              </div>
              <div className="mr-sm-auto info-texto px-sm-3 mt-2 mt-sm-0">
            Status
            <div
              className={`font-weight-bold f-16 ${values.statusEstabelecimento &&
                values.statusEstabelecimento.className}`}
            >
              {values.statusEstabelecimento && values.statusEstabelecimento.description}
            </div>
          </div>
            </React.Fragment>
          )}
          
          <div className="px-sm-3 mt-2 mt-sm-0 border-left">
            {/* <button type="button" className="btn btn-light">
            <span className="icon-btn-add"></span>Novo Usuário
          </button> */}
            {this.getBtnActiveInative()}
          </div>
        </div>
        <div className="row"></div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(CooperadoTopoView));
