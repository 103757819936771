import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Datatable from "../../sascComponents/datagrid/Datatable";
import TopoTitleComponente from "../home/TopoTitleComponente";
import TopoListagem from "../../sascComponents/topo/TopoListagem";
import { beneficiarioService } from "../../services/beneficiario.service";

class BeneficiarioList extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
    }

    handleRowClick = (fieldName, rowData) => {
        this.props.history.push({
            pathname: "/beneficiarios/view/" + (rowData.id),
            state: { entity: rowData }
          });
    };

    closeMenu = () => {
        this.props.showMenu();
    };


    render() {
        return (
            <div className="container-fluid container-com-topo">
                <React.Fragment>
                    <TopoTitleComponente
                        mainTitle="Pacientes"
                        subTitle=" "
                        canBack={false}
                    />
                    <TopoListagem />

                    <hr className="text-center w-75"></hr>

                    <div className="background-lines">
                        <div className="bg-riscos"></div>
                    </div>
                </React.Fragment>

                <React.Fragment>
                    <Datatable
                        showCheckboxColumn={false}
                        idRow={"id"}
                        name={"Paciente(s)"}
                        service={beneficiarioService}
                        showAllColumnsFilter={false}
                        classHeader={[
                            "headerLeft",
                            "headerLeft",
                            "headerLeft",
                            "headerLeft"
                        ]}
                        classColumn={[]}
                        onRowClick={this.handleRowClick}
                    />
                </React.Fragment>
            </div>
        );
    }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
    showMenu: () => showMenu(),
    hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
    const { loggedIn, user, permissions } = state.authentication;
    const { alert } = state;

    return {
        loggedIn,
        alert,
        user,
        permissions
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(BeneficiarioList));
