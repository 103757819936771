import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormGroup } from '../../../../components/FormGroup';
import FormInput from '../../../../components/Inputs/FormInput';
import InputViewEdit from '../../../../components/Inputs/InputViewEdit';
import SelectableInput from '../../../../sascComponents/inputs/SelectableInput';
import FormSelectInput from '../../../../components/Inputs/FormSelectInput';
import { enumService } from '../../../../services/enum.service';
import { cidService } from '../../../../services/cid.service';
import SelectInput from '../../../../components/Inputs/SelectInput';
import _ from "lodash";
import { objectsConstants } from "../../../../constants/objects.constants";

class DadosHipotesesDiagnosticos extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  addProcedimento = () => {
    const { values, setFieldValue, setValues } = this.props;
    let newValues = _.cloneDeep(values);
    let numero = newValues.guiaInternacaoCids.length + 1;
    newValues.guiaInternacaoCids.push({ idList: new Date().getTime(), numero });
    // newValues.atendimentoExecucaos.push({ idList: new Date().getTime(), numero, type: objectsConstants.ENUM_TIPOS_GUIA.SADT });
    // newValues.identificacaoAtendimentoExecucao.push({ idList: new Date().getTime(), numero });

    setValues(newValues);
  };

  removeProcedimento = indexProcedimento => {
    const { values, setFieldValue, setValues } = this.props;
    let newValues = _.cloneDeep(values);

    // let procedimentoExecucao = newValues.guiaInternacaoCids[indexProcedimento];
    newValues.guiaInternacaoCids.splice(indexProcedimento, 1);
    //remove execucao relacionada 
    // _.remove(newValues.atendimentoExecucaos, { numero: procedimentoExecucao.numero });
    // _.remove(newValues.identificacaoAtendimentoExecucao, { numero: procedimentoExecucao.numero });
    //se remover todos os elementos add um padrão
    // if (!newValues.identificacaoAtendimentoExecucao.length) {
    //   newValues.identificacaoAtendimentoExecucao = [{
    //     numero: 1,
    //     codigoCbo: null,
    //     grau: "",

    //   }]
    // }
    // if (!newValues.atendimentoExecucaos.length) {
    //   newValues.atendimentoExecucaos = [objectsConstants.ATENDIMENTO_EXECUCAO]
    // }
    // //reordena lista de execucao para bater index
    // newValues.identificacaoAtendimentoExecucao.map((identificacao, indexIdentificacao) => {
    //   identificacao.numero = indexIdentificacao + 1;
    // })
    // //reordena lista de dados execucao para bater index
    // newValues.atendimentoExecucaos.map((atendimentoExecucao, indexIdentificacao) => {
    //   atendimentoExecucao.numero = indexIdentificacao + 1;
    // })


    setValues(newValues);
    this.props.clear()
    this.props.info({
      message: `Procedimento removido com sucesso!`
    });

  };

  render() {
    const {
      permissions,
      setFieldValue,
      values,
      errors,
      handleChange,
      viewMode
    } = this.props;

    if (values.guiaInternacaoCids == null || values.guiaInternacaoCids.length == 0) {
      values.guiaInternacaoCids = [{}];
    }
    return (
      <div className="row section-form">
        <h6 className="w-100">Hipóteses diagnósticas</h6>
        {

          _.get(values,'guiaInternacaoCids',[{}]).map((guiaInternacaoCid, index) => {
            return (
              <React.Fragment>
                <div className="quebra" key={index}></div>
                <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
                  name={`guiaInternacaoCids[${index}].tipoDoenca`}
                  label={"Tipo da doença"}
                  value={values.guiaInternacaoCids[index].tipoDoenca}
                >
                  <InputViewEdit
                    label="Tipo da doença"
                    component={FormSelectInput}
                    service={enumService.findTipoDoenca}
                    name={`guiaInternacaoCids[${index}].tipoDoenca`}
                    id={`guiaInternacaoCids${index}tipoDoenca`}
                    returnFullObject={true}
                    searchable={true}
                    labelKey={"description"}
                    required={true}
                    valueKey={"name"}
                    value={values.guiaInternacaoCids[index].tipoDoenca}
                    erroMensagem={ _.get(errors, `guiaInternacaoCids[${index}].tipoDoenca`)}
                    viewMode={viewMode}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    defaultValue={ _.get(values, `guiaInternacaoCids[${index}].tipoDoenca.description`)}
                    alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                  />
                </SelectableInput>

                <SelectableInput viewMode={viewMode} atendimento={values} className="fixo6"
                  name={`guiaInternacaoCids[${index}].tempoDoenca`}
                  label={"Tempo de doença referida"}
                  value={values.guiaInternacaoCids[index].tempoDoenca}>
                  <InputViewEdit
                    label="Tempo de doença referida"
                    component={FormInput}
                    required={false}
                    onChange={(name, date) => {
                      setFieldValue(name, date);
                    }}
                    required={true}
                    id={`guiaInternacaoCids${index}tempoDoenca`}
                    name={`guiaInternacaoCids[${index}].tempoDoenca`}
                    placeholder="Tempo de doença referida"
                    erroMensagem={ _.get(errors, `guiaInternacaoCids[${index}].tempoDoenca`)}
                    type={"text"}
                    viewMode={viewMode}
                    value={values.guiaInternacaoCids[index].tempoDoenca}
                    alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                  />
                </SelectableInput>
                <SelectableInput viewMode={viewMode} atendimento={values} className="fixo6"
                                name={`guiaInternacaoCids[${index}].tipoTempoDoenca`}
                                label={"Tempo de doença referida"}
                                value={values.guiaInternacaoCids[index].tipoTempoDoenca}>
                  <InputViewEdit
                      label="Tempo de doença referida"
                      component={FormSelectInput}
                      service={enumService.findTipoTempoDoenca}
                      name={`guiaInternacaoCids[${index}].tipoTempoDoenca`}
                      id={`guiaInternacaoCids${index}tipoTempoDoenca`}
                      returnFullObject={true}
                      searchable={true}
                      labelKey={"description"}
                      required={true}
                      valueKey={"name"}
                      value={values.guiaInternacaoCids[index].tipoTempoDoenca}
                      erroMensagem={ _.get(errors, `guiaInternacaoCids[${index}].tipoTempoDoenca`)}
                      viewMode={viewMode}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      defaultValue={ _.get(values, `guiaInternacaoCids[${index}].tipoTempoDoenca.description`)}
                      alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                  />
                </SelectableInput>
                <SelectableInput viewMode={viewMode} atendimento={values} className=""
                  name={`guiaInternacaoCids[${index}].indicacaoAcidente`}
                  label={"Indicação de Acidente (acidente ou doença relacionada)"}
                  value={values.guiaInternacaoCids[index].indicacaoAcidente}>

                  <InputViewEdit
                    label="Indicação de Acidente (acidente ou doença relacionada)"
                    component={FormSelectInput}
                    service={enumService.findIndicacaoAcidente}
                    name={`guiaInternacaoCids[${index}].indicacaoAcidente`}
                    id={`guiaInternacaoCids${index}indicacaoAcidente`}
                    returnFullObject={true}
                    searchable={true}
                    labelKey={"description"}
                    required={true}
                    valueKey={"name"}
                    value={values.guiaInternacaoCids[index].indicacaoAcidente}
                    erroMensagem={ _.get(errors, `guiaInternacaoCids[${index}].indicacaoAcidente`)}
                    alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                    viewMode={viewMode}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    defaultValue={ _.get(values, `guiaInternacaoCids[${index}].indicacaoAcidente.description`)}
                  />
                </SelectableInput>

                <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
                  name={`guiaInternacaoCids[${index}].cid`}
                  label={"CID"}
                  value={values.guiaInternacaoCids[index].cid}>

                  <InputViewEdit
                    label="CID"
                    component={SelectInput}
                    onFetchData={cidService.find}
                    name={`guiaInternacaoCids[${index}].cid`}
                    id={"cid"}
                    returnFullObject={true}
                    searchable={true}
                    labelKey={"codigo"}
                    valueKey={"id"}
                    value={values.guiaInternacaoCids[index].cid}
                    viewMode={viewMode}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    defaultValue={ _.get(values, `guiaInternacaoCids[${index}].cid.codigo`)}
                    alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                  />
                </SelectableInput>


                <SelectableInput viewMode={viewMode} atendimento={values} className="fixo6"
                  name={`guiaInternacaoCids[${index}].cid`}
                  label={"CID (Descrição)"}
                  value={values.guiaInternacaoCids[index].cid}
                >
                  <InputViewEdit
                    label="CID (Descrição)"
                    component={SelectInput}
                    onFetchData={cidService.findByDescricao}
                    name={`guiaInternacaoCids[${index}].cid`}
                    id={"descricao"}
                    returnFullObject={true}
                    searchable={true}
                    labelKey={"descricao"}
                    valueKey={"id"}
                    value={values.guiaInternacaoCids[index].cid}
                    viewMode={viewMode}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    defaultValue={ _.get(values, `guiaInternacaoCids[${index}].cid.descricao`)}
                    alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                  />
                </SelectableInput>

                {!viewMode && (
                  <React.Fragment>
                    <FormGroup className="central fixo mobile-100">
                      {index + 1 !==
                        values.guiaInternacaoCids.length && (
                          <React.Fragment>
                            <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                              <button
                                className="btn-menos"
                                onClick={() => {
                                  this.removeProcedimento(index);
                                }}
                              ></button>
                            </div>
                            <div className="bg-branco d-block d-sm-none col-12 p-2 text-center">
                              <button
                                className="btn-menos"
                                onClick={() => {
                                  this.removeProcedimento(index);
                                }}
                              ></button>
                            </div>
                          </React.Fragment>
                        )}
                      {index + 1 ===
                        values.guiaInternacaoCids.length && (
                          <React.Fragment>
                            <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                              {values.guiaInternacaoCids.length > 1 && <button
                                className="btn-menos"
                                onClick={() => {
                                  this.removeProcedimento(index);
                                }}
                              ></button>}
                              <button
                                className="btn-mais"
                                onClick={this.addProcedimento}
                              ></button>
                            </div>
                            <div className="bg-branco d-block d-sm-none col-12 p-2 text-center">
                              <button
                                className="btn-mais"
                                onClick={this.addProcedimento}
                              ></button>
                            </div>
                          </React.Fragment>
                        )}
                    </FormGroup>
                  </React.Fragment>
                )}
              </React.Fragment>
            )
          })
        }
      </div>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu }, alert: { info, clear }, }) => ({
  info: msg => info(msg),
  clear: () => clear(),
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosHipotesesDiagnosticos));
