import { Can } from "@casl/react";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Avatar from "../../components/Avatar/Avatar";
import { objectsConstants } from "../../constants/objects.constants";
import btnVoltar from "../../img/btn_voltar.svg";
import Icon_convenios from "../../img/icon_convenios.svg";
import Icon_atendimentos from "../../img/icon_menu_atendimentos.svg";
import Icon_notificacao from "../../img/icon_notificacao.svg";
import Icon_Consultorio from "../../img/icon_consultorio.svg";
import Icon_notifica_consutorio from "../../img/icon_notifica_consutorio.svg";
import Icon_sair from "../../img/icon_sair.svg";
import Icon_usuario_cooperado from "../../img/icon_usuarioCooperado.svg";
import Logo from "../../img/Logo.svg";
import SelectSetorAuditor from "../selectSetorAuditor/SelectSetorAuditor";
import { midiaService } from "../../services/midia.service";
import { userService } from "../../services/user.service";
import SelectPerfilAtual from "../perfil/SelectPerfilAtual";
import SelectCooperativa from "./selectCooperativa/SelectCooperativa";
import * as _ from "lodash";

const Svg = p => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);

class Menu extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { showSelectCoop: false };
  }

  gotoPage = (page, entity) => {
    this.props.hideMenu();
    this.props.history.push({
      pathname: page,
      state: {
        entity,
      }
    });
  };

  getProfileName = () => {
    const { selectPerfilAuditor } = this.props;
    if (selectPerfilAuditor || !this.props.user.setorAtual) {
      return (
        <p className='d-flex m-auto'>{userService.getPerfilAtual(this.props.user)}</p>
      );
    } else if (!selectPerfilAuditor && this.props.user.setorAtual) {
      return <p className='d-flex m-auto'>{this.props.user.setorAtual.nome}</p>;
    }
  };
  getLogo = idMidia => {
    return midiaService.url(idMidia);
  };

  handleLogout = () => {
    this.props.logout();
    this.props.removeAllFilters();
    this.props.fechaLotes();
  };

  verificaCooperativas = () => {
    let options = this.props.user.usuarioPerfis;
    const coop = _.filter(options, ['perfil.tipoPerfil', this.props.user.perfilAtual.perfil.tipoPerfil]);
    if (coop.length > 1 ||(_.get(this.props.user,'perfilAtual.perfil.tipoPerfil')) === objectsConstants.SUDO) {
      this.setState({ showSelectCoop: true });
    } else {
      this.props.warning({
        message: `Você é ${this.props.user.perfilAtual.perfil.nome} somente da ${this.props.user
          .cooperativa && this.props.user.cooperativa.sigla}`
      })
    }
  }

  fecharJanela = () => {
    this.setState({ showSelectCoop: false });
  }

  render() {
    const {
      permissions,
      showMenu,
      selectPerfilAuditor,
      selectPerfilAtual,
      isAuditor,
      isMultiPerfil,
    } = this.props;

    const { showSelectCoop
    } = this.state;



    let podeEscolherPerfil = this.props.user.usuarioPerfis && this.props.user.usuarioPerfis.length > 1;
    return (
      <div
        id="menuContent"
        className={`d-flex flex-column fixed-top bg-riscos background-menu  ${showMenu ? "move-in" : "move-out"
          }`}
      >
        <header className="navbar fixed-top topo iconMenu px-4">
          {!selectPerfilAuditor && !selectPerfilAtual && (
            <div
              className="btnVoltar mr-auto bd-highlight pl-3"
              onClick={() => {
                this.props.hideMenu();
              }}
            >
              <img src={btnVoltar} alt="Voltar" className="backMenu" />
            </div>
          )}

          <div
            className={`${isAuditor &&
              selectPerfilAuditor &&
              isMultiPerfil &&
              selectPerfilAtual
              ? "m-auto"
              : "mr-auto"
              } bd-highlight`}
          >
            <img src={Logo} alt="Logo" className="iconLogo" />
          </div>
        </header>

        <div
          className="bodyMenu d-flex flex-column h-100 mx-sm-auto"
          id="bodyMenu"
        >
          <div className="container-box">
            <div
              className="box-perfil "

            >
              <div className="box row menu-user" onClick={() => {
                this.gotoPage(
                  "/perfil/view/" + this.props.user.id,
                  this.props.user
                );
              }}>
                <div className="col-md-12 col-3 p-0 align-self-center">
                  <h2 className="roboto text-center font-weight-bold">
                    Olá =)
                  </h2>
                </div>
                <div className="col-md-12 col-4 p-0 align-self-center">
                  <Avatar
                    className="avatarMenu"
                    user={this.props.user}
                  ></Avatar>
                </div>
                <div className="col-md-12 col-5 pl-2 pr-0 align-self-md-end text-left align-self-center text-md-center">
                  <p className="my-1">
                    <b>{this.props.user.nome}</b>
                  </p>
                </div>
              </div>
              {podeEscolherPerfil && <div
                className={`box-perfil menu-user-perfil ${userService.getColorDefault(
                  this.props.user
                )} d-flex align-content-center flex-wrap`}
                onClick={e => {
                  this.props.loadSelectPerfil({
                    user: this.props.user,
                    selectPerfilAuditor: false,
                    selectPerfilAtual: true,
                    isMultiPerfil: true,
                    isAuditor: false
                  });
                }}
              >
                <div className="d-flex m-auto multi">
                  {" "}
                  {this.getProfileName()}{" "}
                  <Svg style={{ marginRight: -6 }}>
                    <path
                      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
                      fill="currentColor"
                      fillRule="evenodd"
                    />
                  </Svg>
                </div>
              </div>}
              {!podeEscolherPerfil && <div
                className={`box-perfil menu-user-perfil d-flex box`}
              >
                <div className="d-flex m-auto  inativo">
                  {" "}
                  {this.getProfileName()}{" "}
                </div>
              </div>}
            </div>

            {isMultiPerfil && <SelectPerfilAtual></SelectPerfilAtual>}
            {isAuditor && <SelectSetorAuditor></SelectSetorAuditor>}
            {showSelectCoop && <SelectCooperativa
              show={showSelectCoop}
              fecharJanela={this.fecharJanela}
              userId={this.props.user.id}
              tipoPerfilAtual={_.get(this.props.user,'perfilAtual.perfil.tipoPerfil')}
            ></SelectCooperativa>}
            <div
              className="box menu-felicoop "
              onClick={() =>
                this.verificaCooperativas()
              }
            >
              {this.props.user.cooperativa&&this.props.user.cooperativa.midia && (
                <img
                  src={this.getLogo(this.props.user.cooperativa.midia.id)}
                  className="logoMenu"
                  alt="Logo"
                />
              )}
              <p>
                <b>Cooperativa</b>
              </p>
            </div>
            <Can
              I={objectsConstants.EXECUTAR}
              a={objectsConstants.BTN_COOPERATIVAS}
              ability={permissions}
            >
              <div
                className="box menu-atendimentos"
                onClick={() => {
                  this.gotoPage("/cooperativa");
                }}
              >
                <img
                  src={Icon_atendimentos}
                  className="icon-menu"
                  alt="Ícone Cooperativa"
                />
                <p>
                  <b>Cooperativas</b>
                </p>
              </div>
            </Can>
            <Can
              I={objectsConstants.EXECUTAR}
              a={objectsConstants.BTN_EXAME}
              ability={permissions}
            >{(userService.isCooperado(this.props.user)) && (
              <div
                className="box menu-exames"
                style={{backgroundColor: "#00cb7d" }}
                onClick={() => {
                  this.gotoPage("/solicitaexame");
                }}
              >
                <img
                  src={Icon_atendimentos}
                  className="icon-menu"
                  alt="Ícone Exames"
                />
                <p style={{color:  "white" }}>
                  <b>Solicitação de Exames</b>
                </p>
              </div>
            )}
            </Can>
            <Can
              I={objectsConstants.EXECUTAR}
              a={objectsConstants.BTN_ATENDIMENTOS}
              ability={permissions}
            >
              <div
                className="box menu-atendimentos"
                onClick={() => {
                  this.gotoPage("/atendimento");
                }}
              >
                <img
                  src={Icon_atendimentos}
                  className="icon-menu"
                  alt="Ícone Atendimentos"
                />
                <p>
                  <b>Atendimentos</b>
                </p>
              </div>
            </Can>
           {this.props.user.cooperativa &&
           this.props.user.cooperativa.utilizarIntegracaoAtendimento &&
            <Can
              I={objectsConstants.EXECUTAR}
              a={objectsConstants.BTN_INTEGRACAO}
              ability={permissions}
            >
              <div
                className="box menu-notificacoes"
                onClick={() => {
                    this.gotoPage("/integracaoAtendimento");
                }}
              >
                <img
                  src={Icon_Consultorio}
                  className="icon-menu"
                  alt="Ícone Notificação"
                />
                <p>
                  <b>Integração</b>
                </p>
              </div>
            </Can>}

            <Can
              I={objectsConstants.EXECUTAR}
              a={objectsConstants.BTN_CONSULTORIOS}
              ability={permissions}
            >
              <div
                className="box menu-notificacoes"
                onClick={() => {
                  if (
                    userService.isPerfil(
                      this.props.user,
                      objectsConstants.CONSULTORIO
                    )
                  ) {
                    this.gotoPage(
                      "/consultorios/view/" +
                      this.props.user.perfilAtual.extensao
                    );
                  } else if( userService.isPerfil(
                      this.props.user,
                      objectsConstants.COOPERADO
                  )){
                    this.gotoPage("/consultorios")

                  }else {
                    this.gotoPage("/consultorios");
                  }
                }}
              >
                <img
                  src={Icon_Consultorio}
                  className="icon-menu"
                  alt="Ícone Notificação"
                />
                <p>
                  <b>Consultórios</b>
                </p>
              </div>
            </Can>

            <Can
              I={objectsConstants.EXECUTAR}
              a={objectsConstants.BTN_NOTIFICACOES}
              ability={permissions}
            >
              <div
                className="box menu-notificacoes"
                onClick={this.props.toogleNotification}
              >
                <img
                  src={Icon_notificacao}
                  className="icon-menu"
                  alt="Ícone Notificação"
                />
                <p>
                  <b>Notificação</b>
                </p>
              </div>
            </Can>
            <Can
              I={objectsConstants.VISUALIZAR}
              a={objectsConstants.CRUDUSUARIO}
              ability={permissions}
            >
              <div
                className="box menu-usuarios"
                onClick={() => {
                  this.gotoPage("/usuario");
                }}
              >
                <img
                  src={Icon_usuario_cooperado}
                  className="icon-menu"
                  alt="Ícone Cooperados"
                />
                <p>
                  <b>Usuários</b>
                </p>
              </div>
            </Can>
            <Can
              I={objectsConstants.EXECUTAR}
              a={objectsConstants.BTN_COOPERADOS}
              ability={permissions}
            >
              {(!userService.isCooperado(this.props.user)||this.props.user.estabelecimentoProprietario)&&(
              <div
                className="box menu-usuarios"
                onClick={() => {    
                  if((_.get(this.props.user,'perfilAtual.perfil.tipoPerfil')) === objectsConstants.SUDO){
                    this.gotoPage("/cooperadosSudo");
                  }else{
                    this.gotoPage("/cooperados");
                  }                  
                }}
              >
                <img
                  src={Icon_usuario_cooperado}
                  className="icon-menu"
                  alt="Ícone Cooperados"
                />
                <p>
                  <b>Cooperados</b>
                </p>
              </div>
              )}
            </Can>
            <Can
              I={objectsConstants.EXECUTAR}
              a={objectsConstants.BTN_CONVENIOS}
              ability={permissions}
            >
              <div
                className="box menu-convenios"
                onClick={() => {
                  this.gotoPage("/convenios");
                }}
              >
                <img
                  src={Icon_convenios}
                  className="icon-menu"
                  alt="Ícone Convênios"
                />
                <p>
                  <b>Convênios</b>
                </p>
              </div>
            </Can>
            
            <Can
              I={objectsConstants.VISUALIZAR}
              a={objectsConstants.BTN_NOTIFICACOES_CONSULTORIOS}
              ability={permissions}
            >
              <div
                className="box menu-notificacoes"
                onClick={() => {
                  this.gotoPage("/notificacoesConsultorios");
                }}
              >
                <img
                  src={Icon_notifica_consutorio}
                  className="icon-menu"
                  alt="Ícone Notificações Consultórios"
                />
                <p>
                  <b>Notificações de Consultórios</b>
                </p>
              </div>
            </Can>
            
            <Can
                I={objectsConstants.EXECUTAR}
                a={objectsConstants.BTN_CONVENIOS}
                ability={permissions}
            >
              <div
                  className="box menu-atendimentos"
                  onClick={() => {
                    this.gotoPage("/relatorios");
                  }}
              >
                <img
                    src={Icon_atendimentos}
                    className="icon-menu"
                    alt="Ícone Cooperativa"
                />
                <p>
                  <b>Relatorios</b>
                </p>
              </div>
            </Can>
            <Can
                I={objectsConstants.EXECUTAR}
                a={objectsConstants.BTN_EXAME}
                ability={permissions}
            >
              <div
                  className="box menu-exames"
                  onClick={() => {
                    this.gotoPage("/exame");
                  }}
              >
                <img
                    src={Icon_atendimentos}
                    className="icon-menu"
                    alt="Ícone Exames"
                />
                <p>
                  <b>Exames</b>
                </p>
              </div>
            </Can>
            <Can
                I={objectsConstants.EXECUTAR}
                a={objectsConstants.BTN_EXAME}
                ability={permissions}
            >
              {(
              <div
                  className="box menu-mensagem"
                  onClick={() => {
                    this.gotoPage("/mensagem");
                  }}
              >
                <img
                    src={Icon_notifica_consutorio}
                    className="icon-menu"
                    alt="Ícone Exames"
                />
                <p>
                  <b>Mensagens de documentos</b>
                </p>
              </div>)}
                </Can>
            <Can
              I={objectsConstants.EXECUTAR}
              a={objectsConstants.BTN_USUARIOS}
              ability={permissions}
            >
              <div
                className="box menu-atendimentos"
                onClick={() => {
                  this.gotoPage("/beneficiarios");
                }}
              >
                <img
                  src={Icon_usuario_cooperado}
                  className="icon-menu"
                  alt="Ícone Cooperados"
                />
                <p>
                  <b>Pacientes</b>
                </p>
              </div>
            </Can>
                <Can
              I={objectsConstants.EXECUTAR}
              a={objectsConstants.BTN_AVISOS}
              ability={permissions}
            >
              <div
                className="box menu"
                onClick={() => {
                  this.gotoPage("/avisos");
                }}
              >
                <img
                  src={Icon_notificacao}
                  className="icon-menu"
                  alt="Ícone Avisos"
                />
                <p>
                  <b>Avisos</b>
                </p>
              </div>
            </Can>
            <div className="box menu-sair" onClick={this.handleLogout}>
              <img
                src={Icon_sair}
                className="icon-menu"
                alt="Ícone Sair do sistema"
              />
              <p>
                <b>Sair do sistema</b>
              </p>
            </div>
          </div>
          <hr className="max-hr"></hr>
        </div>

        <footer>
          <p>© {new Date().getFullYear()} Sasc Conect - Fencom</p>
        </footer>
      </div >
    );
  }
}

const mapDispatch = ({
  menu: { openMenu, hideMenu },
  notification: { toogleNotification },
  authentication: { logout, loadSelectPerfil },
  alert: { warning },
  filtro: { removeAllFilters },
  lotesCollapse: { fechaLotes }
}) => ({
  openMenu: () => openMenu(),
  hideMenu: () => hideMenu(),
  toogleNotification: () => toogleNotification(),
  logout: () => logout(),
  warning: msg => warning(msg),
  removeAllFilters: () => removeAllFilters(),
  fechaLotes: () => fechaLotes(),
  loadSelectPerfil: payload => loadSelectPerfil(payload)
});

function mapStateToProps(state) {
  const {
    loggedIn,
    user,
    permissions,
    selectPerfilAuditor,
    isAuditor,
    isMultiPerfil
  } = state.authentication;
  const { alert } = state;
  const { showMenu } = state.menu;
  const { showNotification } = state.notification;

  return {
    loggedIn,
    alert,
    user,
    permissions,
    showMenu,
    showNotification,
    selectPerfilAuditor,
    isMultiPerfil,
    isAuditor
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(Menu));
