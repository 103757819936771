import { defaultService } from "./defaultService";
import { urlsConstants } from "../constants/url.constant";

export const examePacoteExameService = {
    findAll,
    listPacoteSelecaoQuantidade,
    listEspecialidadeProcedimentoQuantidade,
    findTableColumns,
    findSearchableColumns,
    findByFilter
};

function findAll() {
    return defaultService.doGet(`${urlsConstants.EXAMEPACOTEEXAME}list`);
}

function listPacoteSelecaoQuantidade(filter) {
    return defaultService.doPost(`${urlsConstants.EXAMEPACOTEEXAME}listPacoteSelecaoQuantidade`, filter);
}

function listEspecialidadeProcedimentoQuantidade(filter) {
    return defaultService.doPost(`${urlsConstants.EXAMEPACOTEEXAME}listEspecialidadeProcedimentoQuantidade`, filter);
}

function findTableColumns() {
    return defaultService.doGet(`${urlsConstants.EXAMEPACOTEEXAME}list/fields`);
}

function findSearchableColumns() {
    return defaultService.doGet(`${urlsConstants.EXAMEPACOTEEXAME}search/fields`);
}

function findByFilter(options: Options, filter: any): Promise<Array<>> {
    return defaultService.findByFiltro(
        `${urlsConstants.EXAMEPACOTEEXAME}list`,
        options,
        filter
    );
}