import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from "react-router-dom";
import { objectsConstants } from "../../constants/objects.constants";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { estabelecimentoUsuarioService } from '../../services/estabelecimentoUsuario.service';
import { userService } from "../../services/user.service";

class LinhaAcaoAtivarInativarEstabelecimentoUsuario extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    success = () => {
        this.props.success({ message: "Alterado com sucesso." });
        this.props.loading(false);
        if (this.props.initData) {
            this.props.initData();
        }
    }

    errorAtivar = () => {
        this.props.success("Não foi possível ativar o usuário.");
        this.props.loading(false);
    }

    errorInativar = () => {
        this.props.success("Não foi possível inativar o usuário.");
        this.props.loading(false);
    }

    // errorInativar = () => {
    //     this.props.success("Não foi possível vincular o usuário.");
    //     this.props.loading(false);
    // }

    ativarUsuario = () => {
        this.props.loading(true);
        if (this.props.checkedAllPages) {
            const filter = this.props.getFilter();
            userService.ativarUsuarioByFilter(filter).then(() => {
                this.success();
            }).catch(() => {
                this.errorAtivar();
            });
        } else {
            estabelecimentoUsuarioService.ativarUsuario(this.props.rowsCheckedValue.map((rowValue) => {
                return rowValue.idEstabelecimentoUsuario;
            })).then(() => {
                this.success();
            }).catch(() => {
                this.errorAtivar();
            });
        }
    }

    inativarUsuario = () => {
        this.props.loading(true);
        if (this.props.checkedAllPages) {
            const filter = this.props.getFilter();
            userService.inativarUsuarioByFilter(filter).then(() => {
                this.success();
            }).catch(() => {
                this.errorInativar();
            });
        } else {
            estabelecimentoUsuarioService.inativarUsuario(this.props.rowsCheckedValue.map((rowValue) => {
                return rowValue.idEstabelecimentoUsuario;
            })).then(() => {
                this.success();
            }).catch(() => {
                this.errorInativar();
            });
        }
    }

    vincularUsuario = () => {
        this.props.loading(true);
        if (this.props.checkedAllPages) {
            const filter = this.props.getFilter();
            userService.inativarUsuarioByFilter(filter).then(() => {
                this.success();
            }).catch(() => {
                this.errorInativar();
            });
        } else {
            estabelecimentoUsuarioService.vincularUsuario(this.props.rowsCheckedValue.map((rowValue) => {                
                return [rowValue.estabelecimento.id, this.props.match.params.id]; 
            })).then(() => {
                this.success();
            }).catch(() => {
                this.errorInativar();
            });
        }
    }

    render() {
        let statusUsuarioAtivo = false;
        let statusUsuarioInativo = false;
        let statusUsuarioNaoVinculado = false;

        this.props.rowsCheckedValue.map((rowValue) => {
            if (rowValue.inativo && rowValue.inativo === objectsConstants.ATIVO) {
                statusUsuarioAtivo = true;
            } else if (rowValue.inativo && rowValue.inativo === objectsConstants.INATIVO){
                statusUsuarioInativo = true;
            } else {
                statusUsuarioNaoVinculado = true;
            }
        })

        return <React.Fragment>
            {!statusUsuarioAtivo && statusUsuarioInativo && !statusUsuarioNaoVinculado && (
                <button type="button" className="btn btn-primary" onClick={() => {
                    ConfirmAcationService.confirmMsg(
                        this.ativarUsuario,
                        "Ativar Usuário",
                        "Você confirma que deseja ativar o usuário no(s) estabelecimento(s) selecionado(s)",
                        null,
                        "Cancelar",
                        "Confirmar",
                        objectsConstants.TYPE_WARNING
                    );
                }}>
                    <span className="icon-btn-check"></span>
                    Ativar Usuário
                </button>
            )}
            {statusUsuarioAtivo && !statusUsuarioInativo && !statusUsuarioNaoVinculado &&(
                <button type="button" className="btn btn-secondary" onClick={() => {
                    ConfirmAcationService.confirmMsg(
                        this.inativarUsuario,
                        "Inativar Usuário",
                        "Você confirma que deseja inativar o usuário no(s) estabelecimento(s) selecionado(s)",
                        null,
                        "Cancelar",
                        "Confirmar",
                        objectsConstants.TYPE_WARNING
                    );
                }}>
                    <span className="icon-btn-fechar"></span>
                    Inativar Usuário
                </button>
            )}
            {!statusUsuarioAtivo && !statusUsuarioInativo && statusUsuarioNaoVinculado && (
                <button type="button" className="btn btn-secondary" onClick={() => {
                    ConfirmAcationService.confirmMsg(
                        this.vincularUsuario,
                        "Vincular Usuário",
                        "Você confirma que deseja vincular o usuário no(s) estabelecimento(s) selecionado(s)",
                        null,
                        "Cancelar",
                        "Confirmar",
                        objectsConstants.TYPE_WARNING
                    );
                }}>
                    <span className="icon-btn-fechar"></span>
                    Vincular Usuário
                </button>
            )}
            {((statusUsuarioAtivo && statusUsuarioInativo) || 
            (statusUsuarioAtivo && statusUsuarioNaoVinculado) || 
            (statusUsuarioInativo && statusUsuarioNaoVinculado))
            && (<span className={'labelInfoAction'}>Existem dois status diferentes selecionados</span>)}
        </React.Fragment>;
    }
}

const mapDispatch = ({
    alert: { success, error, clear },
    load: { loading }
}) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear()
});

export default connect(
    null,
    mapDispatch,
    null,
    { withRef: true }
)(withRouter(LinhaAcaoAtivarInativarEstabelecimentoUsuario));
