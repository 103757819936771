//@flow
import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";

export const cooperativaService = {
  findAll,
  findSearchableColumns,
  findTableColumns,
  findByFilter,
  findAllByIdUser,
  doSave,
  doGet,
  isExibirIconeIntegracao,
  exportToExcel,
  imprimirCooperativa,
};

function findAll() {
  return defaultService.doGet(`${urlsConstants.COOPERATIVA}findAll`);
}

function findAllByIdUser(userId) {
  return defaultService.doGet(
    `${urlsConstants.COOPERATIVA}findAllByIdUser/${userId}`
  );
}

function findTableColumns() {
  return defaultService.doGet(`${urlsConstants.COOPERATIVA}list/fields`);
}

function findSearchableColumns() {
  return defaultService.doGet(`${urlsConstants.COOPERATIVA}search/fields`);
}

function findByFilter(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.COOPERATIVA}list`,
    options,
    filter
  );
}

function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.COOPERATIVA, entity);
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.COOPERATIVA}${id}`);
}

function isExibirIconeIntegracao(cooperativa) {
  if (cooperativa && cooperativa.exibirIconeIntegracao) {
    return true;
  }
  return false;
}

function exportToExcel(filter, fields, labels) {
  return defaultService.exportToExcel("cooperativa", filter, fields, labels);
}

function imprimirCooperativa(id){
  return  defaultService.doGet(
    `${urlsConstants.COOPERATIVA}imprimirCooperativa/${id}`
  );
}